import {
    Card, Stack, Table, Avatar, Dialog, Button, TableRow, TableBody, TableCell, IconButton, Typography, TableContainer, DialogContent, TablePagination, Divider, DialogTitle, DialogActions, Grid,
} from '@mui/material'

import { useState } from 'react'
import { enumFunctions } from 'utils'
import { CreateReminderSection, UserNoteToUserSection } from 'sections';
import { CompanyCandidateCard, CustomTableHeader, ICustomTableHeadCell, Iconify, MuiTransition, TableEmptyData, TableLoading } from 'components'
import { IHrAdvisorCandidatesItem, IHrAdvisorCandidatesVariables, IHrAdvisorDiscoverFormsItem, useGetHrAdvisorCandidatesQuery, useGetHrAdvisorDiscoverFormsQuery } from '../context';
import { DriverLicenseStatusEnum, GenderEnum, HigherEducationDegreeEnum, LanguageLevelEnum, SexEnum } from 'types';
import moment from 'moment';



export const DiscoverPage = () => {

    const [variables, setVariables] = useState<IHrAdvisorCandidatesVariables>({
        pagination: {
            page: 1,
            pageSize: 10,
        }
    })

    const { data, isLoading } = useGetHrAdvisorDiscoverFormsQuery({})

    console.log(data)

    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }


    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Özgelecek Keşif
            </Typography>

            <HrAdvisorDiscoverTable
                data={data}
                pagination={variables?.pagination}
                loading={isLoading}
                handleChangePageSize={handleChangePageSize}
                handleChangePage={handleChangePage}
            />

        </Stack>
    )
}



const HrAdvisorDiscoverTable = ({ data, pagination, loading, handleChangePageSize, handleChangePage }: any) => {

    const [detailDialog, setDetailDialog] = useState<any>(false)
    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'companyName',
            numeric: false,
            disablePadding: false,
            label: 'Şirket Adı',
        },
        {
            id: 'nameSurname',
            numeric: false,
            disablePadding: false,
            label: 'Adı Soyadı',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Pozisyon Adı',
        },
        {
            id: 'gender',
            numeric: false,
            disablePadding: false,
            label: 'Tahmini Başlangıç Tarihi',
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Aksiyonlar',
        },
    ];



    return (
        <>

            <Card sx={{ p: 3 }}>

                <TableContainer>
                    <Table>

                        <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                        <TableBody>
                            {
                                !loading ? (
                                    data?.count ? (
                                        data?.data?.map((row: IHrAdvisorDiscoverFormsItem, index: number) => {

                                            const labelId = `enhanced-table-checkbox-${index}`;



                                            return (
                                                <TableRow
                                                    key={row?._id ?? index}
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    sx={{ cursor: 'pointer' }}
                                                >

                                                    <TableCell align='center'>
                                                        {index + 1 + ((pagination?.page - 1) * pagination?.pageSize)}
                                                    </TableCell>

                                                    <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <Stack direction="column">
                                                                <Typography>
                                                                    {row?.createdBy?.companyInformation?.companyName}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <Stack direction="column">
                                                                <Typography>
                                                                    {row?.createdBy?.personalInformation?.name} {row?.createdBy?.personalInformation?.surname}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </TableCell>


                                                    <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <Stack direction="column">
                                                                <Typography>
                                                                    {row?.position}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell
                                                        align="left"
                                                        sx={{
                                                            whiteSpace: "nowrap"
                                                        }}
                                                    >
                                                        {row?.estimatedStartDate ? moment(row?.estimatedStartDate).format("DD MMMM YYYY") : "-"}
                                                    </TableCell>

                                                    <TableCell align="left" >
                                                        <Stack direction="row" spacing={1}>
                                                            {/* <UserNoteToUserSection targetId={row?._id} /> */}

                                                            <IconButton
                                                                sx={{ bgcolor: 'secondary.light' }}
                                                                onClick={() => setDetailDialog(row)}
                                                                title="Detayı gör."
                                                            >
                                                                <Iconify icon="ph:eye-fill" sx={{ color: 'primary.main' }} />
                                                            </IconButton>

                                                            {/* <CreateReminderSection user={row} content={content} /> */}
                                                        </Stack>
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableEmptyData colspan={headCells.length} text="Hiç bir aday bulunamadı!" />
                                    )
                                ) : (
                                    <TableLoading colspan={headCells.length} />
                                )
                            }
                        </TableBody>

                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                    count={data?.count ?? 0} // toplam eleman sayısı
                    rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                    page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                    labelRowsPerPage="Sayfa başına gösterim"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangePageSize}
                />

            </Card>
            {/* position: string;
  workingType: string;
  estimatedStartDate: string;
  gender: string;
  ageInterval: string;
  numberOfEmployee: string;
  shiftNumber: string;
  disabledStatus: string;
  workDescription: string;
  desiredFeatures: string;
  wage: string;
  premium: string;
  bonus: string;
  travelExpenses: string;
  mealExpenses: string;
  education: string;
  foreignLanguage: string;
  workerMeetingDate: string;
  serviceRoute: string; */}

            <Dialog
                open={!!detailDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setDetailDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="simple-dialog-title">Özgelecek Keşif Detayı</DialogTitle>
                <DialogContent sx={{ width: { xs: '100%', sm: "600px" } }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} display={"flex"} flexDirection={"column"} gap={1}>
                            <Typography><strong>Şirket Adı: </strong> {detailDialog?.createdBy?.companyInformation?.companyName}</Typography>
                            <Typography><strong>Adı Soyadı: </strong> {detailDialog?.createdBy?.personalInformation?.name} {detailDialog?.createdBy?.personalInformation?.surname}</Typography>
                            <Typography><strong>Pozisyon Adı: </strong> {detailDialog?.position}</Typography>
                            <Typography><strong>Çalışma Şekli: </strong> {detailDialog?.workingType}</Typography>
                            <Typography><strong>Tahmini Başlangıç Tarihi: </strong> {detailDialog?.estimatedStartDate ? moment(detailDialog?.estimatedStartDate).format("DD MMMM YYYY") : "-"}</Typography>
                            <Typography><strong>Cinsiyet: </strong>{detailDialog?.gender}</Typography>
                            <Typography><strong>Yaş Aralığı: </strong>{detailDialog?.ageInterval}</Typography>
                            <Typography><strong>Çalışan Sayısı: </strong>{detailDialog?.numberOfEmployee}</Typography>
                            <Typography><strong>Vardiya Sayısı: </strong>{detailDialog?.shiftNumber}</Typography>
                            <Typography><strong>Engelli Durumu: </strong>{detailDialog?.disabledStatus}</Typography>
                            <Typography><strong>İş Açıklaması: </strong>{detailDialog?.workDescription}</Typography>
                            <Typography><strong>İstenen Özellikler: </strong>{detailDialog?.desiredFeatures}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} display={"flex"} flexDirection={"column"} gap={1}>
                            <Typography><strong>Maaş: </strong>{detailDialog?.wage}</Typography>
                            <Typography><strong>Prim: </strong>{detailDialog?.premium}</Typography>
                            <Typography><strong>İkramiye: </strong>{detailDialog?.bonus}</Typography>
                            <Typography><strong>Seyahat Masrafları: </strong>{detailDialog?.travelExpenses}</Typography>
                            <Typography><strong>Yemek Masrafları: </strong>{detailDialog?.mealExpenses}</Typography>
                            <Typography><strong>Eğitim: </strong>{detailDialog?.education}</Typography>
                            <Typography><strong>Yabancı Dil: </strong>{detailDialog?.foreignLanguage}</Typography>
                            <Typography><strong>İşçi Toplantı Tarihi: </strong>{detailDialog?.workerMeetingDate ? moment(detailDialog?.workerMeetingDate).format("DD MMMM YYYY") : "-"}</Typography>
                            <Typography><strong>Hizmet Rota: </strong>{detailDialog?.serviceRoute}</Typography>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setDetailDialog(null)
                    }}
                        color="primary" variant='outlined'>
                        Kapat
                    </Button>
                </DialogActions>

                {/* <DialogContent>
                    <Stack spacing={2} py={3}>

                        <Stack spacing={2} direction={"row"} alignItems={"center"} bgcolor={"secondary.lighter"} p={2} borderRadius={2}>
                            <Avatar alt={detailDialog?._id} src={detailDialog?.personalInformation?.profileImageUrl} sx={{ width: 80, height: 80 }} />

                            <Stack spacing={0.5}>
                                <Typography variant={"h6"}>
                                    {detailDialog?.personalInformation?.name} {detailDialog?.personalInformation?.surname}
                                </Typography>
                                <Typography fontSize={"small"}>
                                    📞 <a href={`tel:${detailDialog?.personalInformation?.phoneNumber}`} style={{ color: 'black', fontWeight: "bold" }}>
                                        {detailDialog?.personalInformation?.phoneNumber}
                                    </a>
                                </Typography>
                                <Typography fontSize={"small"}>
                                    📧 <a href={`mailto:${detailDialog?.contact?.email}`} style={{ color: 'black', fontWeight: "bold" }}>
                                        {detailDialog?.contact?.email
                                        }</a>
                                </Typography>
                            </Stack>
                        </Stack>

                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} justifyContent="space-between" alignItems="flex-start">
                            <Stack spacing={1} width={{ xs: '100%', sm: "300px" }} >
                                <Typography fontWeight={"bold"}>Kişisel Bilgiler</Typography>
                                <Typography fontSize={"small"}><strong>Doğum Tarihi: </strong> {moment(detailDialog?.personalInformation?.birthDate).format("DD MMMM YYYY")}</Typography>
                                <Typography fontSize={"small"}><strong>Cinsiyet: </strong> {enumFunctions.findAndDisplayName(SexEnum, detailDialog?.personalInformation?.sex)}</Typography>
                                <Typography fontSize={"small"}><strong>Cinsel Tercih:</strong> {enumFunctions.findAndDisplayName(GenderEnum, detailDialog?.personalInformation?.gender)} {enumFunctions.findAndDisplayName(GenderEnum, detailDialog?.personalInformation?.genderDetail)}  </Typography>
                                <Typography fontSize={"small"}><strong>Uyruk: </strong> {detailDialog?.personalInformation?.nationality}</Typography>
                                <Typography fontSize={"small"}><strong>Farklı Vatandaşlık: </strong> {detailDialog?.personalInformation?.citizenship}</Typography>
                                <Typography fontSize={"small"}><strong>Adres: </strong> {detailDialog?.personalInformation?.district}, {detailDialog?.personalInformation?.city} | {detailDialog?.personalInformation?.address}</Typography>
                                <Typography fontSize={"small"}><strong>Açıklama: </strong> {detailDialog?.personalInformation?.description}</Typography>
                                <Typography fontSize={"small"}><strong>Sigara Kullanımı: </strong>{detailDialog?.smoking ? "İçiyor" : "İçmiyor"}</Typography>

                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Eğitim Bilgileri</Typography>
                                    <Typography fontSize={"small"}><strong>Lise: </strong> {detailDialog?.education?.highSchool?.join(', ')}</Typography>
                                    <Typography fontSize={"small"}><strong>Yükseköğrenim: </strong> {detailDialog?.education?.higherEducation?.map((degree: any) => `${degree?.name} - ${degree?.department} - ${enumFunctions.findAndDisplayName(HigherEducationDegreeEnum, degree?.higherEducationDegree)} - ${degree?.gpa}`).join('<br/> ')}</Typography>
                                </Stack>

                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Sertifikalar</Typography>
                                    {
                                        detailDialog?.certificates?.map((certificate: any, index: number) => (
                                            <Typography fontSize={"small"} key={index}>
                                                <strong>{certificate?.name}, </strong> {certificate?.type} - {moment(certificate?.issuedDate).format("MM.YYYY")} - {moment(certificate?.validUntil).format("MM.YYYY")} - {certificate?.certificateInstitution}
                                            </Typography>
                                        ))
                                    }
                                </Stack>
                            </Stack>

                            <Divider orientation="vertical" flexItem />

                            <Stack spacing={1} width={{ xs: '100%', sm: "300px" }}>
                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Bildiği Diller</Typography>
                                    {
                                        detailDialog?.languages?.map((language: any, index: number) => (
                                            <Typography fontSize={"small"} key={index}>
                                                <strong>{language?.name?.toUpperCase()}:</strong> Okuma: {enumFunctions.findAndDisplayName(LanguageLevelEnum, language?.reading)}, Yazma: {enumFunctions.findAndDisplayName(LanguageLevelEnum, language?.writing)}, Dinleme: {enumFunctions.findAndDisplayName(LanguageLevelEnum, language?.listening)}
                                            </Typography>
                                        ))
                                    }
                                </Stack>

                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Sürücü Belgesi</Typography>
                                    <Typography fontSize={"small"}>{enumFunctions.findAndDisplayName(DriverLicenseStatusEnum, detailDialog?.driverLicense?.status)}, {detailDialog?.driverLicense?.licenseType} sınıfı, {moment(detailDialog?.driverLicense?.issueDate).format("MM.YYYY")}</Typography>
                                </Stack>

                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Burs ve Burslar</Typography>
                                    {detailDialog?.scholarships?.map((scholarship: any, index: number) => (
                                        <Typography fontSize={"small"} key={index}>{moment(scholarship?.receivingDate).format("MM.YYYY")} - {scholarship?.type} - {scholarship?.institution}</Typography>
                                    ))}
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Katıldığı Projeler</Typography>
                                    {detailDialog?.participatedProjects?.map((project: any, index: number) => (
                                        <Typography fontSize={"small"} key={index}>{project?.name} - {project?.institution} - {project?.level} - {moment(project?.date).format("MM.YYYY")} - {project?.details}</Typography>
                                    ))}
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Hobileri</Typography>
                                    {detailDialog?.hobbies?.map((hobby: any, index: number) => (
                                        <Typography fontSize={"small"} key={index}>{hobby}</Typography>
                                    ))}
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between" gap={3}>
                            <Button
                                onClick={() => setDetailDialog(false)}
                                variant='outlined'
                                color="primary"
                            >
                                Kapat
                            </Button>


                        </Stack>

                    </Stack>
                </DialogContent> */}
            </Dialog>


        </>
    )
}
