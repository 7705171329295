import { HasId } from "interfaces/HasId";
import HasPagination from "interfaces/HasPagination";
import IConversion from "interfaces/user/conversionDomain/conversion/IConversion";

export interface IOperationConversionResponse extends IConversion { }

export interface IOperationConversionVariables extends HasId, HasPagination { }

export const operation_conversion = (
  variables: IOperationConversionVariables
) => {
  const data = JSON.stringify({
    query: `
      query operation_conversion($id: ID!) {
        operation_conversion(id: $id) {
          _id
          name
          surname
          sex
          age
          companyName
          phone
          email
          field
          jobTitle
          totalWorkTimeInMonths
          conversionTest {
            question {
              _id
            }
            answer {
              byRange
              byString
            }
          }
          conversionRequestForm {
            _id
            companyName
            authorizedPersonName
            authorizedPersonSurname
            authorizedPersonTitle
            authorizedPersonPhoneNumber
            authorizedPersonEmail
            city
            district
            numberOfEmployees
            sector
            website
            createdAt
            updatedAt
            createdBy {
              _id
            }
            updateBy {
              _id
            }
          }
          createdBy {
            _id
          }
          createdAt
          updatedAt
        }
      }
        `,
    variables: variables,
  });

  return data;
};
