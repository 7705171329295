export interface IHrAdvisorDiscoverFormResponse {
  _id: string;
  position?: string;
  workingType?: string;
  estimatedStartDate?: string;
  gender?: string;
  ageInterval?: string;
  numberOfEmployee?: string;
  shiftNumber?: string;
  disabledStatus?: string;
  workDescription?: string;
  desiredFeatures?: string;
  wage?: string;
  premium?: string;
  bonus?: string;
  travelExpenses?: string;
  mealExpenses?: string;
  education?: string;
  foreignLanguage?: string;
  workerMeetingDate?: string;
  serviceRoute?: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: any;
  updateBy: {
    _id: string
  }
}

export interface IHrAdvisorDiscoverFormVariables {
  discoverFormId: string;
}

export const hrAdvisor_discoverForm = (
  variables: IHrAdvisorDiscoverFormVariables
) => {
  const data = JSON.stringify({
    query: `
      query hrAdvisor_discoverForm($discoverFormId: ID!) {
        hrAdvisor_discoverForm(discoverFormId: $discoverFormId) {
          _id
          position
          workingType
          estimatedStartDate
          gender
          ageInterval
          numberOfEmployee
          shiftNumber
          disabledStatus
          workDescription
          desiredFeatures
          wage
          premium
          bonus
          travelExpenses
          mealExpenses
          education
          foreignLanguage
          workerMeetingDate
          serviceRoute
          createdAt
          updatedAt
          createdBy {
                  _id
                  companyInformation {
                    companyName
                    email
                  }
                  contact {
                    email
                  }
                  personalInformation {
                    name
                    surname
                  }
                  role
          }
          updateBy {
            _id
          }
        }
      }
        `,
    variables: variables
  });

  return data;
};
