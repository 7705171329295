

export const specialistRoutes = {
    dashboard: {
        home: "/",
        companies: "/companies",
        reminders: "/reminders",
        creditRequests: "/credit-requests",
        companyUpdate: "/company-update",
        conversions: "/conversions",
        conversioncreate: "/conversion-create",
        conversionRequests: "/conversion-requests",
        visitorsForms: '/visitors-forms',
        visitorsFormCreate: '/visitors-form-create',

        advertisements: "/advertisements",
        // ozgeleceks: "/ozgeleceks",
        // references: "/references",
        // createUser: "/create-User",
        // conversions: "/conversions",

        notpad: "/notpad",
        myAccount: "/myaccount",
        helpCenter: "/help-center",
        settings: "/settings",
    }
}