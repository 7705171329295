import IOrder from "interfaces/payment/order/IOrder";

export type IAccounterOrderResponse = IOrder | null

export interface IAccounterOrderVariables {
  orderId: string
}

export const accounter_order = (variables: IAccounterOrderVariables) => {

  const data = JSON.stringify({
    query: `
    query accounter_order($orderId: ID!) {
      accounter_order(orderId: $orderId) {
        _id
        createdAt
        orderStatus
        payment {
          paymentStatus
          data
          error
        }
        product {
          _id
          name
          type
          listUnitPrice
          targetRoles
          unitPrice
          amount
          price
          discount
          currency
          createdAt
          updatedAt
        }
        price {
          netTotal
          currency
        }
        user {
          _id
        }
        addresses {
          billingAddress {
            fullName
            companyName
            phoneNumber
            email
            country
            city
            district
            address1
            address2
            postcode
            taxIdentificationNumber
            taxOffice
          }
        }
        createdBy {
          _id
          role
          displayName
          displayImageUrl
        }
        errorData {
          code
          name
          message
          detail
        }
        updatedAt
        updatedBy {
          _id
          role
          displayName
          displayImageUrl
        }
      }
    }
    `,
    variables: variables
  })

  return data

}