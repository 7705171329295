import { IVisitForm } from "interfaces/user/visitForm/IVisitForm";

export interface IManagementVisitFormResponse extends IVisitForm {}

export interface IManagementVisitFormVariables {
  visitFormId: string;
}

/**
 * @roles admin, specialist
 */
export const management_visitForm = (
  variables: IManagementVisitFormVariables
) => {
  const data = JSON.stringify({
    query: `
      query management_visitForm($visitFormId: ID!) {
        management_visitForm(visitFormId: $visitFormId) {
          _id
          status
          companyName
          authorizedPersonName
          authorizedPersonSurname
          authorizedPersonTitle
          authorizedPersonPhoneNumber
          authorizedPersonEmail
          city
          district
          numberOfEmployees
          sector
          website
          relatedCompany {
            _id
            companyInformation {
              companyName
              phoneNumber
              email
              city
              district
              address
              profileImage
              sector
              companyAddress
              foundationYear
              numberOfEmployees
              website
              linkedin
              instagram
              facebook
              companyBrief
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          createdBy {
            _id
          }
          updateBy {
            _id
          }
        }
      }
        `,
    variables: variables,
  });

  return data;
};
