import HasFilter from "interfaces/HasFilter";
import HasPagination from "interfaces/HasPagination";
import IPaginatedListResult from "interfaces/IPaginatedListResult";
import IConversion from "interfaces/user/conversionDomain/conversion/IConversion";
import { IFilterConversion } from "interfaces/user/conversionDomain/conversion/IFilterConversion";

export interface IOperationConversionsItem {
  _id: string
  name: string
  surname: string
  sex: string
  age: string
  companyName: string
  phone: string
  email: string
  field: string
  jobTitle: string
  totalWorkTimeInMonths: string
  conversionTest: {
    question: {
      _id: string
    }
    answer: {
      byRange: string
      byString: string
    }
  }
  conversionRequestForm: {
    _id: string
    companyName: string
    authorizedPersonName: string
    authorizedPersonSurname: string
    authorizedPersonTitle: string
    authorizedPersonPhoneNumber: string
    authorizedPersonEmail: string
    city: string
    district: string
    numberOfEmployees: string
    sector: string
    website: string
    createdAt: string
    updatedAt: string
    createdBy: {
      _id: string
    }
    updateBy: {
      _id: string
    }
  }
  createdBy: {
    _id: string
    displayImageUrl: string
    displayName: string
    role: string
  }
  createdAt: string
  updatedAt: string
}

export interface IOperationConversionsResponse {
  count: number;
  data: Array<IOperationConversionsItem>
}

export interface IOperationConversionsVariables extends HasPagination, HasFilter<IFilterConversion> { }

export const operation_conversions = (variables: IOperationConversionsVariables) => {

  const data = JSON.stringify({
    query: `
    query operation_conversions($pagination: Pagination, $filter: FilterConversionInput) {
      operation_conversions(pagination: $pagination, filter: $filter) {
          count
          data {
            _id
            name
            surname
            sex
            age
            companyName
            phone
            email
            field
            jobTitle
            totalWorkTimeInMonths
            conversionTest {
              question {
                _id
              }
              answer {
                byRange
                byString
              }
            }
            conversionRequestForm {
              _id
              companyName
              authorizedPersonName
              authorizedPersonSurname
              authorizedPersonTitle
              authorizedPersonPhoneNumber
              authorizedPersonEmail
              city
              district
              numberOfEmployees
              sector
              website
              createdAt
              updatedAt
              createdBy {
                _id
                # displayImageUrl
                # displayName
                # role
              }
              updateBy {
                _id
              }
            }
            createdBy {
              _id
            }
            createdAt
            updatedAt
          }
        }
      }
    `,
    variables: variables,
  });

  return data;
};
