

export const hrAdvisorRoutes = {
    dashboard: {
        home: "/",
        reminders: "/reminders",
        candidates: "/candidates",
        companies: "/companies",
        discover: "/discover",

        notpad: "/notpad",
        myAccount: "/myaccount",
        helpCenter: "/help-center",
        settings: "/settings",
    }
}