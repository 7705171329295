

export const specialist_reviseAdvertisement = ({ advertisementId }: { advertisementId: string }) => {

    const data = JSON.stringify({
        query: `
        mutation specialist_reviseAdvertisement($advertisementId: ID!) {
            specialist_reviseAdvertisement(advertisementId: $advertisementId) {
              success
            }
          }
        `,
        variables: {
            "advertisementId": advertisementId
        }
    })

    return data

}