import { ICompany } from "interfaces/user/user/company/ICompany";

export type IHrAdvisorCompanyResponse = ICompany | null

export interface IHrAdvisorCompanyVariables {
  companyId: string;
}

export const hrAdvisor_company = (variables: IHrAdvisorCompanyVariables) => {

  const data = JSON.stringify({
    query: `
      query hrAdvisor_company($companyId: ID!) {
        hrAdvisor_company(companyId: $companyId) {
          _id
          personalInformation {
            name
            surname
            phoneNumber
            description
          }
          companyInformation {
            companyName
            phoneNumber
            email
            city
            district
            address
            taxOffice
            taxNumber
            profileImage
            sector
            companyAddress
            foundationYear
            numberOfEmployees
            website
            linkedin
            instagram
            facebook
            companyBrief
            companyDocuments {
              imzaSirkuleri
              vergiLevhasi
            }
          }
          createdAt
          updatedAt
        }
      }
        `,
    variables: variables
  });

  return data;
};
