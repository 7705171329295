import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { ArrowBack, Edit } from "@mui/icons-material"
import { enqueueSnackbar } from "notistack";
import { Form, Formik, useFormikContext } from 'formik';
import { Box, Card, Grid, IconButton, Stack, Typography } from "@mui/material";

import { NumberOfEmployeesList } from "types";
import { CandidateAdvertisementDetailImage, CompanyImage, User } from "assets";
import { FormikDatePicker, FormikSelect, FormikTextField, FormikTextFieldPhone } from "components";
import { useGetCitiesQuery, useLazyGetUserProfileImagePresignedUrlQuery, useAppDispatch } from "context";
import { ISpecialistUpdateCompanyVariables, useGetSpecialistCompanyQuery, useSpecialistUpdateCompanyMutation } from "../context";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup'
import { specialistRoutes } from "../routes";
import { compressImage, findImageContentType, saveImageAndGetUrl } from "utils";
import { url } from "inspector";



export const CompanyUpdatePage = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const { data: companyInfo } = useGetSpecialistCompanyQuery({ companyId: id ?? "" })
    const [updateCompany, { isLoading: createLoading }] = useSpecialistUpdateCompanyMutation()
    const [profileImage, setProfileImage] = useState<any>(null)
    const [getPresignUrl] = useLazyGetUserProfileImagePresignedUrlQuery()

    const validationSchema = yup.object().shape({
        updateCompanyInput: yup.object().shape({
            companyInformation: yup.object().shape({
                // personalName: yup.string().nullable().required("Ad zorunludur"),
                // personalSurname: yup.string().nullable().required("Soyad zorunludur"),

                companyName: yup.string().nullable().required("Firma adı zorunludur"),

                phoneNumber: yup.string().nullable().required("Telefon numarası zorunludur"),
                email: yup.string().required("Email adresi zorunludur").email("Geçerli bir email adresi giriniz"),
                city: yup.string().nullable().required("Şehir zorunludur"),
                district: yup.string().nullable(),
                taxOffice: yup.string().nullable(),
                taxNumber: yup.string().nullable(),
                profileImage: yup.string().nullable(),
                sector: yup.string().nullable(),
                companyAddress: yup.string().nullable(),
                foundationYear: yup.string().nullable(),
                numberOfEmployees: yup.string().nullable(),
                website: yup.string().nullable().matches(/^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-]*)*$/, "Geçerli bir web sitesi adresi giriniz"),
                linkedin: yup.string().nullable().matches(/linkedin.com/, "Geçerli bir linkedin adresi giriniz"),
                instagram: yup.string().nullable().matches(/instagram.com/, "Geçerli bir instagram adresi giriniz"),
                facebook: yup.string().nullable().matches(/facebook.com/, "Geçerli bir facebook adresi giriniz"),
                companyBrief: yup.string().nullable(),
            })
        }),
    })

    const initialValues: ISpecialistUpdateCompanyVariables = {
        companyId: id ?? "",
        updateCompanyInput: {
            // personalInformation: {
            //     name: companyInfo?.personalInformation?.name ?? "",
            //     description: companyInfo?.personalInformation?.description ?? "",
            //     phoneNumber: companyInfo?.personalInformation?.phoneNumber ?? "",
            //     surname: companyInfo?.personalInformation?.surname ?? "",
            // },
            companyInformation: {
                companyName: companyInfo?.companyInformation?.companyName ?? "",
                companyAddress: companyInfo?.companyInformation?.companyAddress ?? "",
                // address: companyInfo?.companyInformation?.address ?? "",
                city: companyInfo?.companyInformation?.city ?? "",
                district: companyInfo?.companyInformation?.district ?? "",
                companyBrief: companyInfo?.companyInformation?.companyBrief ?? "",
                phoneNumber: companyInfo?.companyInformation?.phoneNumber ?? "",
                email: companyInfo?.companyInformation?.email ?? "",

                foundationYear: companyInfo?.companyInformation?.foundationYear ?? "",
                sector: companyInfo?.companyInformation?.sector ?? "",
                taxNumber: companyInfo?.companyInformation?.taxNumber ?? "",
                taxOffice: companyInfo?.companyInformation?.taxOffice ?? "",
                numberOfEmployees: companyInfo?.companyInformation?.numberOfEmployees ?? "",

                facebook: companyInfo?.companyInformation?.facebook ?? "",
                instagram: companyInfo?.companyInformation?.instagram ?? "",
                linkedin: companyInfo?.companyInformation?.linkedin ?? "",
                website: companyInfo?.companyInformation?.website ?? "",


                profileImage: companyInfo?.companyInformation?.profileImage ?? "",

                companyDocuments: {
                    imzaSirkuleri: companyInfo?.companyInformation?.companyDocuments?.imzaSirkuleri ?? "",
                    vergiLevhasi: companyInfo?.companyInformation?.companyDocuments?.vergiLevhasi ?? ""
                },

            }

        }

    }


    const handleSubmit = async (values: ISpecialistUpdateCompanyVariables) => {

        try {

            let url;

            // if (typeof values?.personalInformation?.profileImageUrl === "object") {
            //     const contentType = findImageContentType(values?.personalInformation?.profileImageUrl)
            //     const presigneUrl = await getPresignUrl({ contentType: contentType })
            //     const isSaved = await saveImageAndGetUrl({
            //         file: values?.personalInformation?.profileImageUrl,
            //         presignUrl: presigneUrl?.data?.profileImage?.presignedUploadUrl ?? ""
            //     })
            //     url = presigneUrl?.data?.profileImage?.url
            //     console.log({ isSaved, presigneUrl });
            // }

            if (profileImage) {
                const contentType = findImageContentType(profileImage)
                const presignedUrl = await getPresignUrl({contentType: contentType })
                const isSaved = await saveImageAndGetUrl({
                    file: profileImage,
                    presignUrl: presignedUrl?.data?.profileImage?.presignedUploadUrl ?? ""
                })
                url = presignedUrl?.data?.profileImage?.url
                console.log({ isSaved, presignedUrl });
                

            }


            const response: any = await updateCompany({
                ...values,
                updateCompanyInput: {
                    ...values.updateCompanyInput, companyInformation: {
                        ...values.updateCompanyInput?.companyInformation,
                        profileImage: url ? url : values.updateCompanyInput?.companyInformation?.profileImage
                    }
                }
            })

            console.log({ response });

            if (response?.data) {
                enqueueSnackbar("Bilgiler Güncellendi!")
            } else {
                enqueueSnackbar("Güncelleme Yapılamadı!", { variant: "error" })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }

    }

    // console.log({ initialValues });

    return (
        <Stack>

            <Stack direction="row" gap={1} alignItems="center">
                <IconButton onClick={() => navigate(specialistRoutes.dashboard.companies)}>
                    <ArrowBack />
                </IconButton>

                <Typography variant="h1" sx={{ my: 3 }}>
                    Şirket Güncelle
                </Typography>
            </Stack>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values, actions) => {
                    console.log({ values });
                    handleSubmit(values)
                }}
            >
                {
                    ({ handleSubmit, errors }) => {
                        console.log({ errors });
                        return (
                            <Form>


                                <CompanyOnboardingForm profileImage={profileImage} setProfileImage={setProfileImage} />

                                <Stack direction="row" justifyContent="end">
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        sx={{ my: 3 }}
                                        disabled={createLoading}
                                        loading={createLoading}
                                        onClick={() => handleSubmit()}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Stack>


                            </Form>
                        )
                    }
                }
            </Formik>

        </Stack>
    )
}




const CompanyOnboardingForm = ({ profileImage, setProfileImage }: { profileImage: string, setProfileImage: any }) => {

    const { data: citiesData } = useGetCitiesQuery({ countryName: "Turkey" })
    const { values, handleSubmit, setFieldValue } = useFormikContext<ISpecialistUpdateCompanyVariables>()

    console.log(profileImage)

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
            }}
        >


            <Card sx={{ width: '100%', }}>

                <Box>
                    <Box
                        sx={{
                            backgroundImage: `url(${CandidateAdvertisementDetailImage})`,
                            height: '200px',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                        }}
                    />

                    <Box
                        sx={{
                            width: 120,
                            height: 120,
                            borderRadius: '50%',
                            border: '7px solid',
                            borderColor: '#fff',
                            bgcolor: '#f1f1f1',
                            marginTop: '-60px',
                            marginLeft: '20px',
                            position: 'relative',
                            background: `url(companyImage})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    >
                        <Box
                            component={"img"}
                            src={
                                profileImage
                                    ? URL.createObjectURL(new Blob([profileImage]))
                                    : values?.updateCompanyInput?.companyInformation?.profileImage ?
                                        values?.updateCompanyInput?.companyInformation?.profileImage
                                        : CompanyImage
                            }
                            sx={{
                                bgcolor: 'white',
                                overflow: 'hidden',
                                borderRadius: '50%',
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                        />
                        <Box
                            component="label"
                            htmlFor="profile_image_input"
                            sx={{
                                width: 25,
                                height: 25,
                                p: 2,
                                borderRadius: '50%',
                                border: '1px solid purple',
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                bgcolor: 'secondary.light',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',

                                '&:hover': {
                                    transform: 'scale(1.05)'
                                },
                                '&:active': {
                                    transform: 'scale(1)'
                                },
                            }}
                        >
                            <Edit />
                        </Box>
                        <Box
                            component="input"
                            type="file"
                            accept="image/*"
                            sx={{ display: 'none' }}
                            id="profile_image_input"
                            // value={values?.personalInformation?.profileImageUrl}
                            onChange={async (event: any) => {
                                // console.log({ event });
                                // handleSetProfileImage(e)
                                // setFieldValue("personelInformation.profileImageUrl", e?.target?.files?.[0])

                                const file = (event.target as HTMLInputElement).files?.[0];
                                const compressedImage = file && await compressImage(file, 0.5)
                                setProfileImage(compressedImage)
                                // setProfileImage(compressedImage)

                            }}
                        />
                    </Box>



                </Box>

                <Grid container spacing={3} sx={{ p: 2, mt: 1 }}>

                    {/* <Grid item xs={12} sm={6}>
                        <FormikTextField upperCase name="personalName" label="Yetkili Adı" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField upperCase name="personalSurname" label="Yetkili Soyadı" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField name="email" label="Email" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextFieldPhone name="phoneNumber" label="Telefon Numarası" />
                    </Grid> */}

                    <Grid item xs={12} sm={6}>
                        <FormikTextField upperCase name="updateCompanyInput.companyInformation.companyName" label="Şirket Adı" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField upperCase name="updateCompanyInput.companyInformation.sector" label="Sektör" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField name="updateCompanyInput.companyInformation.taxOffice" label="Vergi Dairesi" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField name="updateCompanyInput.companyInformation.taxNumber" label="Vergi Numarası" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikDatePicker name="updateCompanyInput.companyInformation.foundationYear" label="Kuruluş Yılı" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikSelect name="updateCompanyInput.companyInformation.numberOfEmployees" label="Çalışan Sayısı" list={NumberOfEmployeesList} />
                    </Grid>

                    <Grid item xs={12} >
                        <FormikTextField
                            upperCase
                            multiline
                            rows={4}
                            label="Şirket Hakkında"
                            name="updateCompanyInput.companyInformation.companyBrief"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikSelect
                            name="updateCompanyInput.companyInformation.city"
                            label="İl"
                            list={citiesData?.data ?? []}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField upperCase name="updateCompanyInput.companyInformation.district" label="İlçe" />
                    </Grid>

                    <Grid item xs={12} >
                        <FormikTextField
                            upperCase
                            multiline
                            rows={4}
                            label="Şirket Adresi"
                            name="updateCompanyInput.companyInformation.companyAddress"
                        />
                    </Grid>

                </Grid>
            </Card >



            <Card sx={{ width: '100%', p: 2 }}>
                <Grid container spacing={3} >
                    <Grid item xs={12} sm={6}>
                        <FormikTextField name="updateCompanyInput.companyInformation.facebook" label="Facebook" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField name="updateCompanyInput.companyInformation.instagram" label="Instagram" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField name="updateCompanyInput.companyInformation.linkedin" label="Linkedin" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormikTextField name="updateCompanyInput.companyInformation.website" label="Website" />
                    </Grid>
                </Grid>
            </Card>

        </Box >
    )
}