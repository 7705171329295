

export interface IAdminReferencesItem {
  _id: string;
  createdAt: string;
  createdBy: {
    _id: string;
  }
  referenceExplanation: string;
  referenceInformation: {
    email: string;
    jobTitle: string;
    name: string;
    phoneNumber: string;
    relationWithCandidate: string;
    surname: string;
  }
  referenceStatus: string;
  referenceTests: {
    answer: string;
    question: {
      _id: string;
    }
  }
  updatedAt: string;
}


export interface IAdminReferencesResponse {
  count: number;
  data: Array<IAdminReferencesItem>
}

export interface IAdminReferencesVariables {
  filter?: {
    candidateId: string,
    referenceStatus: string,
  },

  pagination: {
    page: number,
    pageSize: number,
    sort?: string
  }
}



export const admin_references = (variables: IAdminReferencesVariables) => {

  const data = JSON.stringify({
    query: `
    query Admin_references($filter: FilterReferenceInputForAdmin, $pagination: Pagination) {
      admin_references(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          createdAt
          createdBy {
            _id
          }
          referenceExplanation
          referenceInformation {
            email
            jobTitle
            name
            phoneNumber
            relationWithCandidate
            surname
          }
          referenceStatus
          referenceTests {
            answer
            question {
              _id
            }
          }
          updatedAt
        }
      }
    }
    `,
    variables: {
      "filter": {
        "candidateId": variables?.filter?.candidateId ?? null,
        "referenceStatus": variables?.filter?.referenceStatus ?? null,
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
        "sort": variables?.pagination?.sort ?? null
      }
    }
  })

  return data
}