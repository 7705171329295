import HasFilter from "interfaces/HasFilter"
import HasPagination from "interfaces/HasPagination"
import IPaginatedListResult from "interfaces/IPaginatedListResult"
import { IFilterVisitForm } from "interfaces/user/visitForm/IFilterVisitForm"
import { IVisitForm } from "interfaces/user/visitForm/IVisitForm"


export interface IManagementVisitFormsResponse extends IPaginatedListResult<IVisitForm> {}


export interface IManagementVisitFormsVariables extends HasPagination,
HasFilter<IFilterVisitForm> {}

/**
 * @roles admin, specialist
 */
export const management_visitForms = (variables: IManagementVisitFormsVariables) => {


  const data = JSON.stringify({
    query: `
      query management_visitForms($filter: FilterVisitFormInput, $pagination: Pagination) {
        management_visitForms(filter: $filter, pagination: $pagination) {
          count
          data {
            _id
            status
            companyName
            authorizedPersonName
            authorizedPersonSurname
            authorizedPersonTitle
            authorizedPersonPhoneNumber
            authorizedPersonEmail
            city
            district
            numberOfEmployees
            sector
            website
            relatedCompany {
              _id
              companyInformation {
                companyName
                phoneNumber
                email
                city
                district
                address
                profileImage
                sector
                companyAddress
                foundationYear
                numberOfEmployees
                website
                linkedin
                instagram
                facebook
                companyBrief
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            createdBy {
              _id
            }
            updateBy {
              _id
            }
          }
        }
      }
        `,
    variables: variables
  })

  return data
}