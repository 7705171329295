import { Card, Grid, IconButton, Stack, TableContainer, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material'
import { FormikSelect, FormikTextField } from 'components'
import { Formik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'


import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import { BASE_URL, getAccessToken } from 'utils'
import moment from 'moment'
import { getNameOfEnum } from 'types'
import { Role } from 'types/_role'

// admin
// candidate
// company
// operation
// callCenter
// specialist
// accounter
// hrAdvisor

const rolesList = [
    { value: "operation", name: "Operasyon Uzmanı" },
    { value: "callCenter", name: "Danışma" },
    { value: "specialist", name: "Backoffice" },
    { value: "accounter", name: "Muhasebe Finans" },
    { value: "hrAdvisor", name: "HR Danışman" },
]

const validationSchema = yup.object().shape({
    role: yup.string().required("Gerekli"),
    // password: yup.string().required("Gerekli"),
    email: yup.string().email("Geçerli bir email giriniz").required("Gerekli")
})

const initialValues = {
    role: "",
    email: "",
}




export const CreateUserPage = () => {


    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState<any[]>([])
    const [usersCount, setUsersCount] = useState(0)

    const fetchData = async () => {
        try {

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${BASE_URL}/auth/usersRegisteredByAdmin`,
                headers: {
                    'Authorization': 'Bearer ' + getAccessToken(),
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
            };

            const response = await axios.request(config)
            console.log({ response });
            setUsers(response.data?.data?.data)
            setUsersCount(response.data?.data?.count)
        } catch (error: any) {
            console.log({ error });
        }
    }

    const handleSubmit = async (values: any) => {
        setLoading(true)
        try {

            let data = JSON.stringify({
                "email": values?.email ?? "",
                "password": values?.password ?? "",
                "role": values?.role ?? "",
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${BASE_URL}/auth/auth/admin_register`,
                headers: {
                    'Authorization': 'Bearer ' + getAccessToken(),
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                data: data
            };

            const response = await axios.request(config)
            console.log({ response });

            if (response.data) {
                enqueueSnackbar("Kullanıcı oluşturuldu!")
                fetchData()
            } else {
                enqueueSnackbar("Kullanıcı oluşturulamadı!", { variant: "warning" })
            }


        } catch (error: any) {
            console.log({ error });

            if (error?.response?.data?.errors) {
                error?.response?.data?.errors?.forEach((item: any) => {
                    if (item?.msg === "Email already in use") {
                        enqueueSnackbar("Email kullanılmaktadır, başka bir email kullanınız!", { variant: 'error' })
                    }
                })
            } else {
                enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
            }
        }
        setLoading(false)
    }

    const handlePassive = async (id: string) => {

        console.log(id)

        try {

            let data = JSON.stringify({
                "userId": id
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${BASE_URL}/auth/users/disable`,
                headers: {
                    'Authorization': 'Bearer ' + getAccessToken(),
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                data: data
            };

            const response = await axios.request(config)
            console.log({ response });

            if (response.data) {
                enqueueSnackbar("Kullanıcı pasif yapıldı!")
                fetchData()
            } else {
                enqueueSnackbar("Kullanıcı pasif yapılamadı!", { variant: "warning" })
            }


        } catch (error: any) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
        }
    }

    const handleActive = async (id: string) => {

        console.log(id)

        try {

            let data = JSON.stringify({
                "userId": id
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${BASE_URL}/auth/users/enable`,
                headers: {
                    'Authorization': 'Bearer ' + getAccessToken(),
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                data: data
            };

            const response = await axios.request(config)
            console.log({ response });

            if (response.data) {
                enqueueSnackbar("Kullanıcı aktif yapıldı!")
                fetchData()
            } else {
                enqueueSnackbar("Kullanıcı aktif yapılamadı!", { variant: "warning" })
            }


        }
        catch (error: any) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
        }
    }





    useEffect(() => {
        fetchData()
    }, [])


    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Kullanıcı Oluştur
            </Typography>


            <Card sx={{ p: 3 }}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        handleSubmit(values)
                    }}
                >
                    {
                        ({ values, handleSubmit, isValid }) => {

                            return (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <FormikTextField
                                            name="email"
                                            label="Email"
                                            type='email'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormikSelect
                                            name="role"
                                            label="Kullanıcı Tipi"
                                            list={rolesList}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} sm={6}>
                                        <FormikTextField

                                            type={passwordIsVisible ? "text" : "password"}
                                            name='password'
                                            label="Şifre"

                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => setPasswordIsVisible(prev => !prev)}>
                                                        {
                                                            passwordIsVisible ? <VisibilityIcon /> : <VisibilityOffIcon />
                                                        }
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </Grid> */}

                                    <Grid item xs={12} >
                                        <LoadingButton
                                            variant='contained'
                                            color="primary"
                                            disabled={loading || !isValid}
                                            loading={loading}
                                            onClick={() => handleSubmit()}
                                        >
                                            Oluştur
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            )
                        }
                    }
                </Formik>

            </Card>

            <Card sx={{ p: 3 }}>

                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Adı Soyadı</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Rol</TableCell>
                                <TableCell>Kayıt Tarihi</TableCell>
                                <TableCell>Durum</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                users.map((user, index) => (
                                    <TableRow
                                        key={index}
                                    >
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{(user?.name ?? "-") + " " + (user?.surname ?? "-")}</TableCell>
                                        <TableCell>{user?.email}</TableCell>
                                        <TableCell>{getNameOfEnum(Role, user?.role)}</TableCell>
                                        <TableCell>{moment(user?.createdAt).format("DD.MM.YYYY")}</TableCell>
                                        <TableCell>
                                            <Stack direction={"row"} spacing={1} alignItems='center'>
                                                <strong> {user?.status === "active" ? "Aktif" : user?.status === "pending" ? "Beklemede" : "Pasif"}</strong>
                                                {user?.status === "active" ? <Button onClick={() => handlePassive(user?.userId)} size='small' variant='outlined' color='error'>Pasif Yap</Button> :
                                                    user?.status === "passive" ? <Button onClick={() => handleActive(user?.userId)} size='small' variant='outlined' color='success'>Aktif Yap</Button> : null

                                                }
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>


            </Card>



        </Stack>
    )
}
