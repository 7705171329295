import { useState } from 'react'
import { Card, Chip, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from '@mui/material'

import {
    IAdminReferencesItem,
    IAdminReferencesVariables,
    useGetAdminReferencesQuery,
} from '../context'

import { enumFunctions } from 'utils'
import { CandidateReferenceStatusEnums } from 'types'
import { CustomTableHeader, ICustomTableHeadCell, TableEmptyData, TableLoading } from 'components'






export const ReferencesPage = () => {

    const [variables, setVariables] = useState<IAdminReferencesVariables>({
        pagination: {
            page: 1,
            pageSize: 10
        }
    })

    const { data: references, isLoading } = useGetAdminReferencesQuery(variables)


    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }



    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Referanslar
            </Typography>


            <AdminReferencesTable
                handleChangePage={handleChangePage}
                handleChangePageSize={handleChangePageSize}
                data={references}
                pagination={variables?.pagination}
                loading={isLoading}
            />

        </Stack>
    )
}



const AdminReferencesTable = ({ data, loading, pagination, handleChangePageSize, handleChangePage }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Referans Adı',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Meslek',
        },
        {
            id: 'phone',
            numeric: false,
            disablePadding: false,
            label: 'Telefon',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },
    ];


    return (
        <Card sx={{ p: 3 }}>

            <TableContainer>
                <Table>

                    <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IAdminReferencesItem, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ cursor: 'pointer' }}
                                            >

                                                <TableCell align='center'>
                                                    {index + 1 + ((pagination?.page - 1) * pagination?.pageSize)}
                                                </TableCell>

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Typography>
                                                        {row?.referenceInformation?.name} &nbsp;
                                                        {row?.referenceInformation?.surname}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align="left">{row?.referenceInformation?.email}</TableCell>
                                                <TableCell align="left">{row?.referenceInformation?.jobTitle}</TableCell>
                                                <TableCell align="left">{row?.referenceInformation?.phoneNumber}</TableCell>

                                                <TableCell align="left">
                                                    <Chip
                                                        label={enumFunctions.findAndDisplayName(CandidateReferenceStatusEnums, row?.referenceStatus)}
                                                        color={
                                                            row?.referenceStatus === "pending" ? "warning"
                                                                : row?.referenceStatus === "informationApproved" ? "info"
                                                                    : row?.referenceStatus === "informationRejected" ? "error"
                                                                        : row?.referenceStatus === "referenceApproved" ? "success"
                                                                            : row?.referenceStatus === "referenceRejected" ? "error"
                                                                                : "info"
                                                        }
                                                        variant="outlined"
                                                        style={{ borderRadius: 100 }}
                                                    />
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells?.length} text="Bu alanda hiç bir şirket bulunamadı" />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                count={data?.count ?? 0} // toplam eleman sayısı
                rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                labelRowsPerPage="Sayfa başına gösterim"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePageSize}
            />

        </Card>
    )
} 