import { Box, Card, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Formik, useFormikContext } from 'formik'

import { FormikDatePicker, FormikSelect, FormikSelectCountry, FormikTextField } from 'components'
import { ISelfUpdateUserVariables, useGetCitiesQuery, useGetCountriesQuery, useGetSelfUserQuery, useSelfUpdateUserMutation } from 'context/user'
import { useSelfUserInfoFormValidation } from 'libs'
import { CandidateAdvertisementDetailImage, User } from 'assets'
import { Edit } from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { compressImage, findImageContentType, saveImageAndGetUrl } from 'utils'
import { useLazyGetUserProfileImagePresignedUrlQuery } from 'context'

 

export const UserAccountSection = () => {

    const { data: userInfo } = useGetSelfUserQuery({})
    const [updateUser, { isLoading }] = useSelfUpdateUserMutation({})
    const { initialValues, validationSchema } = useSelfUserInfoFormValidation({ values: userInfo })
    const [getPresignUrl] = useLazyGetUserProfileImagePresignedUrlQuery()

    const handleUpdateUser = async (values: ISelfUpdateUserVariables) => {

        try {

            let url;

            if (typeof values?.personalInformation?.profileImageUrl === "object") {
                const contentType = findImageContentType(values?.personalInformation?.profileImageUrl)
                const presigneUrl = await getPresignUrl({ contentType: contentType })
                const isSaved = await saveImageAndGetUrl({
                    file: values?.personalInformation?.profileImageUrl,
                    presignUrl: presigneUrl?.data?.profileImage?.presignedUploadUrl ?? ""
                })
                url = presigneUrl?.data?.profileImage?.url
                console.log({ isSaved, presigneUrl });
            }


            const response: any = await updateUser({
                ...values,
                personalInformation: {
                    ...values?.personalInformation,
                    profileImageUrl: url
                }
            })
            console.log({ response });

            if (response?.data?._id) {
                enqueueSnackbar("Başarılı bir şekilde güncellendi.")
            } else {
                enqueueSnackbar("Güncelleme yapılamadı!", { variant: 'warning' })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
        }

    }

    // const handleSetProfileImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    //     console.log({ event });
    //     const file = (event.target as HTMLInputElement).files?.[0];
    //     const compressedImage = file && await compressImage(file, 0.5)
    //     console.log({ file, compressedImage });
    //     setProfileImage(compressedImage)
    // }


    return (
        <Card>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: ISelfUpdateUserVariables) => {
                    console.log({ values });
                    handleUpdateUser(values)
                }}
            >
                {
                    ({ values, setFieldValue }) => {
                        return (
                            <>
                                <Box>
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${CandidateAdvertisementDetailImage})`,
                                            height: '200px',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            width: 120,
                                            height: 120,
                                            borderRadius: '50%',
                                            border: '7px solid',
                                            borderColor: '#fff',
                                            bgcolor: 'secondary.main',
                                            marginTop: '-60px',
                                            marginLeft: '20px',
                                            position: 'relative',
                                        }}
                                    >

                                        <Box
                                            component={"img"}
                                            src={
                                                values?.personalInformation?.profileImageUrl
                                                    ? (typeof (values?.personalInformation?.profileImageUrl) !== "string"
                                                        ? URL.createObjectURL(values?.personalInformation?.profileImageUrl)
                                                        : values?.personalInformation?.profileImageUrl)
                                                    : User
                                            }
                                            sx={{
                                                bgcolor: 'secondary.main',
                                                overflow: 'hidden',
                                                borderRadius: '50%',
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />


                                        <Box
                                            component="label"
                                            htmlFor="profile_image_input"
                                            sx={{
                                                width: 25,
                                                height: 25,
                                                p: 2,
                                                borderRadius: '50%',
                                                border: '1px solid purple',
                                                position: 'absolute',
                                                bottom: 0,
                                                right: 0,
                                                bgcolor: 'secondary.light',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',

                                                '&:hover': {
                                                    transform: 'scale(1.05)'
                                                },
                                                '&:active': {
                                                    transform: 'scale(1)'
                                                },
                                            }}
                                        >
                                            <Edit />
                                        </Box>

                                        <Box
                                            component="input"
                                            type="file"
                                            accept="image/*"
                                            sx={{ display: 'none' }}
                                            id="profile_image_input"
                                            // value={values?.personalInformation?.profileImageUrl}
                                            onChange={async (event: any) => {
                                                // console.log({ event });
                                                // handleSetProfileImage(e)
                                                // setFieldValue("personelInformation.profileImageUrl", e?.target?.files?.[0])

                                                const file = (event.target as HTMLInputElement).files?.[0];
                                                const compressedImage = file && await compressImage(file, 0.5)
                                                console.log({ file, compressedImage });
                                                setFieldValue("personalInformation.profileImageUrl", compressedImage)
                                                // setProfileImage(compressedImage)

                                            }}
                                        />
                                    </Box>
                                </Box>

                                <UserAccountForm isLoading={isLoading} />

                            </>
                        )
                    }
                }
            </Formik>
        </Card>
    )
}


const UserAccountForm = ({ isLoading }: any) => {

    const { values, handleSubmit, setFieldValue } = useFormikContext<ISelfUpdateUserVariables>()

    const { data: countriesData, } = useGetCountriesQuery({})
    const { data: citiesData } = useGetCitiesQuery(
        { countryName: values?.personalInformation?.country?.name ?? values?.personalInformation?.country },
        { skip: !values?.personalInformation?.country }
    )

    console.log({ values });


    return (
        <Grid container spacing={3} sx={{ p: 3 }}>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name="personalInformation.name"
                    label="İsim"
                    upperCase
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name="personalInformation.surname"
                    label="Soyisim"
                    upperCase
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name="personalInformation.phoneNumber"
                    label="Telefon Numarası"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikDatePicker
                    name="personalInformation.birthDate"
                    label="Doğum Tarihi"
                />
            </Grid>



            <Grid item xs={12} sm={6}>
                <FormikSelectCountry
                    name="personalInformation.country"
                    label="Ülke"
                    list={countriesData?.data ?? []}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikSelect
                    name="personalInformation.city"
                    label="İl"
                    list={citiesData?.data ?? []}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name="personalInformation.district"
                    label="İlçe"
                    upperCase
                />
            </Grid>

            <Grid item xs={12}>
                <FormikTextField
                    multiline
                    minRows={4}
                    name="personalInformation.description"
                    label="Hakkımda"
                    upperCase
                />
            </Grid>

            {/* <Grid item xs={12} >
                <FormikTextField
                    multiline
                    minRows={4}
                    name="personalInformation.description"
                    label="Address"
                />
            </Grid> */}

            <Grid item xs={12} >
                <LoadingButton
                    onClick={() => handleSubmit()}
                    variant="contained"
                    color='primary'
                    loading={isLoading}
                >
                    Güncelle
                </LoadingButton>
            </Grid>

        </Grid>
    )
}
