import axios from "axios";
import Compressor from "compressorjs"
import { getAccessToken } from "./_axios";



export const dmsConfig = (data: string) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/dms/graphql`,
    headers: {
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data
});

export const dmsQueries = {

    profileImagePresignedUrls: async ({ contentType }: { contentType: string }) => {

        const data = JSON.stringify({
            query: `
                query UserPresignedUrls($contentType: ImageContentType!) {
                    userPresignedUrls(contentType: $contentType) {
                        profileImage {
                            presignedThumbnailUpload
                            presignedUploadUrl
                            url
                        }
                    }
                }
              `,
            variables: {
                "contentType": contentType ?? null,
            }
        })


        try {
            const res = await axios(dmsConfig(data))
            return res?.data?.data?.userPresignedUrls.profileImage
        } catch (err) {
            return err
        }

    },

    backgroundImagePresignedUrls: async ({ contentType }: { contentType: string }) => {

        const data = JSON.stringify({
            query: `
                query UserPresignedUrls($contentType: ImageContentType!) {
                    userPresignedUrls(contentType: $contentType) {
                        backgroundImage {
                            presignedThumbnailUpload
                            presignedUploadUrl
                            url
                        }
                    }
                }
              `,
            variables: {
                "contentType": contentType ?? null,
            }
        })


        try {
            const res = await axios(dmsConfig(data))
            return res?.data?.data?.userPresignedUrls.backgroundImage
        } catch (err) {
            return err
        }

    },

    blogPostPresignedUrls: async ({ contentType }: { contentType: string }) => {

        const data = JSON.stringify({
            query: `
                query BlogPostPresignedUrls($contentType: ImageContentType!) {
                    blogPostPresignedUrls(contentType: $contentType) {
                        contentImage {
                            presignedThumbnailUpload
                            presignedUploadUrl
                            url
                        }
                    }
                }
            `,
            variables: {
                "contentType": contentType ?? null,
            }
        })


        try {
            const res = await axios(dmsConfig(data))
            return res?.data?.data?.blogPostPresignedUrls.contentImage
        } catch (err) {
            return err
        }

    },

}


export const findImageContentType = (file: { type: string; }) => {

    if (file?.type === "image/png") return "pngImage"
    return "jpegImage"

}

export const compressImage = (file: File | Blob, quality: any) => {

    return new Promise((resolve, reject) => {

        new Compressor(
            file, {
            quality: quality ?? 0.6,
            success(result: any) {
                resolve(result)
            },
            error(e: any) {
                reject(e)
            },
        })

    })

}

export const saveImageAndGetUrl = async (
    {
        presignUrl,
        file,
        // getPresignUrl,
        // comppressRate
    }: {
        presignUrl: string,
        file: File | Blob | any,
        // getPresignUrl?: (e: any) => any,
        // comppressRate?: any,
    }
) => {

    try {

        // const contentType = findImageContentType(file)

        // const url = await getPresignUrl({ contentType })
        // const comppressedImage = await compressImage(file, comppressRate)

        const config = {
            method: 'PUT',
            maxBodyLength: Infinity,
            url: presignUrl,// url?.presignedUploadUrl,
            headers: {
                'x-amz-acl': 'public-read',
                'Content-Type': 'video/*'
            },
            data: file // comppressRate ? comppressedImage : file
        };

        const response = await axios(config)
        return response // url?.url

    } catch (error) {
        return error
    }


}