

export interface IUserCompanyDocumentDownloadPresignedUrlResponse { }
export interface IUserCompanyDocumentDownloadPresignedUrlVariables {
    companyId: string,
    documentType: "imzaSirkuleri" | "vergiLevhasi"

}


export const user_companyDocumentDownloadPresignedUrl = (variables: IUserCompanyDocumentDownloadPresignedUrlVariables) => {

    const data = JSON.stringify({
        query: `
        query Query($companyId: ID!, $documentType: CompanyDocument!) {
            user_companyDocumentDownloadPresignedUrl(companyId: $companyId, documentType: $documentType)
        }
        `,
        variables: variables
    })


    return data
}