import * as yup from 'yup'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { enqueueSnackbar } from 'notistack'
import { Button, CircularProgress, Dialog, DialogContent, IconButton, Stack, TextField, Typography } from '@mui/material'

import { FormikDatePicker, FormikTextField, Iconify, MuiTransition } from 'components'
import { useUserCreateReminderMutation, useUserRemoveReminderMutation, useUserUpdateReminderMutation } from 'context'
import { useGetOperationSendCreditRequestQuery, useLazyGetOperationSendCreditRequestQuery, useLazyGetOperationSendCreditRequestsQuery, useOperationCancelSendCreditRequestMutation, useOperationCreateSendCreditRequestMutation } from 'apps/operation'



interface ICreditRequestSectionProps {
    user?: any,
    credit?: any,
    isDelete?: boolean,
    sentFreeCredit?: (e?: any) => any
}


export const CreditRequestSection = ({ user, credit, isDelete = false, sentFreeCredit }: ICreditRequestSectionProps) => {

    const [notDialog, setNoteDialog] = useState(false)
    const [removeReason, setRemoveReason] = useState<any>(false)


    const [createCreditRequest, { isLoading: createCreditRequestLoading }] = useOperationCreateSendCreditRequestMutation()
    const [removeCreditRequest, { isLoading: removeCreditRequestLoading }] = useOperationCancelSendCreditRequestMutation()


    const validationSchema = yup.object().shape({
        creditAmount: yup.string().required(""),
        price: yup.string().required(""),
    })

    const initialValues = {
        creditAmount: "",
        price: "",
    }

    const handleSubmit = async (values?: any) => {
        try {
            if (!!credit) {
                const removeResponse: any = await removeCreditRequest({
                    id: credit?._id,
                    statusChangeReason: removeReason
                })
                console.log({ removeResponse });
                if (removeResponse?.data) {
                    enqueueSnackbar("Güncellendi!")
                    setNoteDialog(false)
                } else {
                    enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
                }
            } else {
                const createResponse: any = await createCreditRequest({
                    input: {
                        creditAmount: Number(values?.creditAmount),
                        price: Number(values?.price),
                        receiver: {
                            _id: user?._id
                        }
                    }
                })
                console.log({ createResponse });
                if (createResponse?.data) {
                    enqueueSnackbar("Oluşturuldu!")
                    setNoteDialog(false)
                } else {
                    enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
                }
            }



        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }



    return (
        <>

            <Stack direction="row" spacing={1}>

                <IconButton
                    sx={{ bgcolor: credit && credit?.status !== "pending" ? "gray" : 'secondary.light' }}
                    onClick={() => setNoteDialog(true)}
                    disabled={credit && credit?.status !== "pending"}
                    title="Kredi İsteği"
                >
                    <Iconify icon={!!credit ? "mdi:credit-card-remove" : "majesticons:creditcard-plus"} sx={{ color: credit && credit?.status !== "pending" ? "gray" : 'primary.main' }} />
                </IconButton>


                {/* {
                    isDelete && (
                        <>

                            <IconButton
                                sx={{ bgcolor: 'success.light' }}
                                onClick={() => setRemoveDialog("finish")}
                                title="Kredi isteğini iptal et."
                            >
                                <Iconify icon="mdi:success-bold" sx={{ color: 'success.main' }} />
                            </IconButton>

                            <IconButton
                                sx={{ bgcolor: 'error.light' }}
                                onClick={() => setRemoveDialog("delete")}
                            >
                                <Iconify icon="material-symbols-light:delete-outline" sx={{ color: 'error.main' }} />
                            </IconButton>

                        </>
                    )
                } */}

            </Stack>


            <Dialog
                open={!!notDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setNoteDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    {
                        // !getCreditRequestLoading ? (
                        !!credit ? (
                            <Stack
                                py={3}
                                spacing={3}
                                minWidth={{ xs: "100%", sm: '400px' }}
                            >

                                <Typography variant='h3'>
                                    İptal et
                                </Typography>

                                <TextField
                                    multiline
                                    minRows={3}
                                    label="İptal sebebi"
                                    value={typeof (removeReason) === "string" ? removeReason : ""}
                                    onChange={(e) => setRemoveReason(e?.target?.value)}
                                />

                                <Stack direction="row" justifyContent="end" gap={3}>
                                    <Button
                                        onClick={() => setNoteDialog(false)}
                                        variant='outlined'
                                        color="primary"
                                    >
                                        Kapat
                                    </Button>

                                    <LoadingButton
                                        variant='contained'
                                        color="primary"
                                        onClick={() => handleSubmit()}
                                        loading={createCreditRequestLoading}
                                    >
                                        İptal Et
                                    </LoadingButton>
                                </Stack>

                            </Stack>
                        ) : (
                            <Formik
                                validationSchema={validationSchema}
                                initialValues={initialValues}
                                onSubmit={(values) => {
                                    handleSubmit(values)
                                }}
                            >
                                {
                                    ({ handleSubmit, errors }) => {

                                        return (
                                            <Stack
                                                spacing={3}
                                                py={3}
                                                minWidth={{ xs: "100%", sm: '400px' }}
                                            >

                                                <Typography variant='h3'>
                                                    Kredi Talebi
                                                </Typography>


                                                <FormikTextField
                                                    type="number"
                                                    name='price'
                                                    label='Ücret'
                                                />
                                                <FormikTextField
                                                    type="number"
                                                    name='creditAmount'
                                                    label='Kredi Miktarı'
                                                />


                                                <Stack direction="row" justifyContent="end" gap={3}>
                                                    <Button
                                                        onClick={() => setNoteDialog(false)}
                                                        variant='outlined'
                                                        color="primary"
                                                    >
                                                        Kapat
                                                    </Button>

                                                    <LoadingButton
                                                        variant='contained'
                                                        color="primary"
                                                        onClick={() => handleSubmit()}
                                                        loading={createCreditRequestLoading}
                                                    >
                                                        Oluştur
                                                    </LoadingButton>
                                                </Stack>

                                            </Stack>
                                        )
                                    }
                                }
                            </Formik>
                        )
                        // ) : (
                        //     <CircularProgress color="primary" />
                        // )
                    }
                </DialogContent>
            </Dialog>

        </>
    )
}
