import { CircularProgress, Stack, Typography } from '@mui/material'
import { Iconify } from 'components/iconify'
import React from 'react'


export interface IShowCountCardProps {
    iconfyIcon: any
    text: string
    count: number | undefined
    loading?: boolean
}
export const ShowCountCard = ({ iconfyIcon, text, count = 0, loading }: IShowCountCardProps) => {

    return (
        <Stack
            direction={{ xs: 'column-reverse', sm: "row" }}
            alignItems={{ xs: 'center' }}
            justifyContent="space-between"
            // gap={5}
            sx={{
                border: '1px solid #ccc',
                // whiteSpace: { sm: 'nowrap' },
                // p: 3,
                // pr: '250px',
                gap: 3,
                position: 'relative',
                overflow: 'hidden',
                // height: "150px"
            }}
        >

            <Stack p={3}>
                <Typography
                    sx={{
                        fontSize: "20px",
                        color: '#666',
                        fontWeight: "bold",
                    }}
                >
                    {text}
                </Typography>

                {
                    loading ? (
                        <CircularProgress />
                    ) : (
                        <Typography
                            sx={{
                                fontSize: "50px",
                                color: 'primary.main'
                            }}
                        >
                            {count}
                        </Typography>
                    )
                }
            </Stack>

            <Iconify
                icon={iconfyIcon}
                sx={{
                    p: 0,
                    // bgcolor: 'secondary.light',
                    color: 'secondary.light',
                    width: "150px",
                    flex: '0 0  150px',
                    // height: "200px",
                    height: "100%",
                    position: { sm: 'absolute' },
                    bottom: -40,
                    right: 40,
                }}
            />

        </Stack>
    )
}
