import { LoadingButton } from '@mui/lab'
import { Card, Grid, Stack } from '@mui/material'
import { IManagementUpdateVisitFormVariables, useLazyGetManagementVisitFormQuery, useManagementUpdateVisitFormMutation } from 'apps/management'
import { ISpecialistCreateVisitFormVariables, useSpecialistCreateVisitFormMutation } from 'apps/specialist'
import { FormikSelect, FormikTextField, FormikTextFieldPhone } from 'components'
import { useGetCitiesQuery } from 'context'
import { Formik, useFormikContext } from 'formik'
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { VisitFormStatusList } from 'types'
import { enumFunctions } from 'utils'
import * as yup from 'yup'

enum VisitCompanyNumberOfEmployees {
    upTo20 = "Up to 20",
    between10_50 = "Between 20-50",
    between51_100 = "Between 51-100",
    between101_250 = "Between 101-250",
    // between251_999 = "Between 251-999",
    greaterThan251 = "Greater Than 251",
}


export const VisitorFormCreateAndUpdate = ({ companies }: any) => {

    const { id } = useParams()
    const [getVisitForm, { data: visitform }] = useLazyGetManagementVisitFormQuery()


    useEffect(() => {
        if (id) {
            getVisitForm({ visitFormId: id })
        }
    }, [getVisitForm, id])



    const validationSchema = yup.object().shape({
        visitFormInput: yup.object().shape({
            authorizedPersonEmail: yup.string().required("Gerekli"),
            authorizedPersonName: yup.string().required("Gerekli"),
            authorizedPersonPhoneNumber: yup.string().required("Gerekli"),
            authorizedPersonSurname: yup.string().required("Gerekli"),
            authorizedPersonTitle: yup.string().required("Gerekli"),
            city: yup.string().required("Gerekli"),
            companyName: yup.string().required("Gerekli"),
            district: yup.string().required("Gerekli"),
            numberOfEmployees: yup.string().required("Gerekli"),
            relatedCompany: yup.object().shape({
                _id: yup.string().required("Gerekli")
            }),
            sector: yup.string().required("Gerekli"),
            status: yup.string().required("Gerekli"),
            website: yup.string().required("Gerekli")
        })
    })

    const initialValues: ISpecialistCreateVisitFormVariables = {

        visitFormInput: {
            authorizedPersonEmail: visitform?.authorizedPersonEmail ?? "",
            authorizedPersonName: visitform?.authorizedPersonName ?? "",
            authorizedPersonPhoneNumber: visitform?.authorizedPersonPhoneNumber ?? "",
            authorizedPersonSurname: visitform?.authorizedPersonSurname ?? "",
            authorizedPersonTitle: visitform?.authorizedPersonTitle ?? "",
            city: visitform?.city ?? "",
            companyName: visitform?.companyName ?? "",
            district: visitform?.district ?? "",
            numberOfEmployees: visitform?.numberOfEmployees ?? "",
            relatedCompany: {
                _id: visitform?.relatedCompany?._id ?? ""
            },
            sector: visitform?.sector ?? "",
            status: visitform?.status ?? "",
            website: visitform?.website ?? ""
        }
    }



    const [updateVisitForm, { isLoading: updateLoading }] = useManagementUpdateVisitFormMutation()
    const [specialistCreateVisitForm, { isLoading: createLoading }] = useSpecialistCreateVisitFormMutation()

    const handleCreate = async (values: ISpecialistCreateVisitFormVariables) => {
        try {
            const response: any = await specialistCreateVisitForm(values)
            console.log({ response });

            if (response?.data) {
                enqueueSnackbar("Başarılı")
            } else {
                enqueueSnackbar("Başarılı olunamadı", { variant: "warning" })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }

    const handleUpdate = async (values: IManagementUpdateVisitFormVariables) => {
        try {

            const response: any = await updateVisitForm({
                ...values,
                visitFormId: id ?? ""
            })

            console.log({ response });

            if (response?.data) {
                enqueueSnackbar("Başarılı")
            } else {
                enqueueSnackbar("Başarılı olunamadı", { variant: "warning" })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }

    const handleSubmit = (values: any) => {
        if (!id) {
            handleCreate(values)
        } else {
            handleUpdate(values)
        }
    }


    const loading = updateLoading || createLoading

    return (
        <Card sx={{ p: 3 }}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                    console.log({ values });
                    handleSubmit(values)
                }}
            >
                {
                    ({ handleSubmit, errors, values }) => {
                        // console.log({ errors, values });
                        return (
                            <Stack spacing={3}>
                                <VisitorFormContainer companies={companies} />

                                <Stack direction="row" justifyContent="end">
                                    <LoadingButton
                                        loading={loading}
                                        disabled={loading}
                                        onClick={() => handleSubmit()}
                                        variant='contained'
                                        color="primary"
                                    >
                                        {id ? "Güncellen" : "Kaydet"}
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        )
                    }
                }
            </Formik>
        </Card>
    )
}


const VisitorFormContainer = ({ companies }: any) => {

    const { data: cities } = useGetCitiesQuery({ countryName: 'Turkey' })
    const { handleSubmit } = useFormikContext()

    return (
        <Grid container spacing={3}>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name='visitFormInput.authorizedPersonName'
                    label='İsim'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name='visitFormInput.authorizedPersonSurname'
                    label='Soyisim'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name='visitFormInput.authorizedPersonEmail'
                    label='Email'
                />
            </Grid>



            <Grid item xs={12} sm={6}>
                <FormikTextFieldPhone
                    name='visitFormInput.authorizedPersonPhoneNumber'
                    label='Telefon'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikSelect
                    name='visitFormInput.city'
                    label='Şehir'
                    list={cities?.data ?? []}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name='visitFormInput.district'
                    label='İlçe'
                />
            </Grid>



            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name='visitFormInput.authorizedPersonTitle'
                    label='Ünvan'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name='visitFormInput.companyName'
                    label='Şirket İsmi'
                />
            </Grid>



            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name='visitFormInput.sector'
                    label='Sektör'
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name='visitFormInput.website'
                    label='Websitesi'
                />
            </Grid>



            <Grid item xs={12} sm={6}>
                <FormikSelect
                    name='visitFormInput.relatedCompany._id'
                    label='Şirket'
                    list={companies ?? []}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikSelect
                    name='visitFormInput.numberOfEmployees'
                    label='Çalışan Sayısı'
                    list={enumFunctions.objectlist(VisitCompanyNumberOfEmployees)}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikSelect
                    name='visitFormInput.status'
                    label='Durumu'
                    list={VisitFormStatusList}
                />
            </Grid>



        </Grid>
    )
}
