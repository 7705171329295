import { ExitToApp } from "@mui/icons-material"
import { Route, Routes, useNavigate, Navigate } from "react-router-dom"

import { callCenterRoutes } from "./routes"
import { DashboardLayout } from "layouts"
import { logoutReducer, useAppDispatch, useGetSelfUserQuery } from "context"
import { IBottomMenuListItem, IMenuListItem, IProfileBoxUser, Iconify } from "components"

import HomePage from "../pages/HomePage"
import { Badge } from "@mui/material"
import { HelpCenterPage, InformationRequestsPage, MyAccountPage, NotepadPage, SettingsPage } from "../pages"



export const CallCenterRoutes = () => {


    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { data: userInfo } = useGetSelfUserQuery({})


    const user: IProfileBoxUser | null = {
        fullName: (userInfo?.personalInformation?.name || userInfo?.personalInformation?.surname)
            ? userInfo?.personalInformation?.name + " " + userInfo?.personalInformation?.surname
            : userInfo?.contact?.email ?? "",
        profileImageUrl: userInfo?.personalInformation?.profileImageUrl ?? "",
        role: userInfo?.role ?? ""
    }


    const menuList: Array<IMenuListItem> = [
        {
            items: [
                {
                    title: 'Panel',
                    path: callCenterRoutes.dashboard.home,
                    icon: <Iconify icon="iconamoon:home" />,
                    // info: <Badge badgeContent={`${4}`} color="primary" />,
                },
                {
                    title: 'Bilgi Talepleri',
                    path: callCenterRoutes.dashboard.informationRequests,
                    icon: <Iconify icon="ic:baseline-list-alt" />
                },


                // {
                //     title: 'İlanlar',
                //     path: callCenterRoutes.dashboard.advertisements,
                //     icon: <Iconify icon="ic:baseline-list-alt" />
                // },
                // {
                //     title: 'Özgelecekler',
                //     path: callCenterRoutes.dashboard.ozgeleceks,
                //     icon: <Iconify icon="pepicons-pencil:cv" />
                // },
                // {
                //     title: 'Referanslar',
                //     path: callCenterRoutes.dashboard.references,
                //     icon: <Iconify icon="ooui:references-ltr" />
                // },
                // {
                //     title: 'Dönüşümler',
                //     path: callCenterRoutes.dashboard.conversions,
                //     icon: <Iconify icon="streamline:recycle-1-solid" />
                // },
                // {
                //     title: 'Kullanıcı Oluştur',
                //     path: callCenterRoutes.dashboard.createUser,
                //     icon: <Iconify icon="mingcute:user-add-line" />
                // },
            ]
        },
        {
            subheader: 'Ayarlar',
            items: [
                {
                    title: 'Ayarlar',
                    path: callCenterRoutes.dashboard.settings,
                    icon: <Iconify icon="solar:settings-bold-duotone" />
                },
                {
                    title: 'Notepad',
                    path: callCenterRoutes.dashboard.notpad,
                    icon: <Iconify icon="fluent:notepad-edit-16-regular" />
                },
            ]
        }
    ]

    const bottomMenuList: Array<IBottomMenuListItem> = [
        {
            func: (e) => navigate(callCenterRoutes.dashboard.myAccount),
            icon: <Iconify icon="uil:setting" width={24} sx={{ marginRight: 1 }} />,
            title: "Hesap Bilgileri"
        },
        {
            func: (e) => dispatch(logoutReducer("")),
            icon: <ExitToApp sx={{ marginRight: 1 }} />,
            title: "Çıkış"
        },
    ]

    return (
        <Routes>
            <Route path="" element={<DashboardLayout menuList={menuList} user={user} bottomMenuList={bottomMenuList} />}>
                <Route path={callCenterRoutes.dashboard.home} element={<HomePage />} />
                <Route path={callCenterRoutes.dashboard.informationRequests} element={<InformationRequestsPage />} />

                <Route path={callCenterRoutes.dashboard.notpad} element={<NotepadPage />} />
                <Route path={callCenterRoutes.dashboard.settings} element={<SettingsPage />} />
                <Route path={callCenterRoutes.dashboard.helpCenter} element={<HelpCenterPage />} />
                <Route path={callCenterRoutes.dashboard.myAccount} element={<MyAccountPage />} />

                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    )
}