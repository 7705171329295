import {
    Card,
    Chip,
    Stack,
    Table,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Tabs,
    Tab,
} from '@mui/material'
import moment from 'moment';
import { useState } from 'react';

import { CreateReminderSection } from 'sections';
import { IUserRemindersItem, IUserRemindersVariables, useGetUserRemindersQuery } from 'context';
import { CustomTableHeader, ICustomTableHeadCell, TableEmptyData, TableLoading } from 'components'


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const RemindersTable = () => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Kullanıcı Adı / Resmi',
        },
        {
            id: 'desctiption',
            numeric: false,
            disablePadding: false,
            label: 'Açıklama',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Tarihi',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Taglar',
        },
        // {
        //     id: 'status',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Durumu',
        // },

        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Aksiyonlar',
        },
    ];

    const [variables, setVariables] = useState<IUserRemindersVariables>({
        pagination: {
            page: 1,
            pageSize: 10,
        },
        filter: {
            status: "inProgress"
        }
    })

    const { data, isLoading: loading } = useGetUserRemindersQuery(variables)


    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }


    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        // setValue(newValue);
        console.log({ newValue });
        setVariables((prevState) => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                status: newValue ?? "inProgress"
            }
        }))
    };


    return (
        <Card sx={{ p: 3 }}>

            <Stack sx={{ pb: 3 }}>
                <Tabs value={variables?.filter?.status} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Aktif" {...a11yProps(0)} value="inProgress" />
                    <Tab label="Zamanı Gelmemiş" {...a11yProps(1)} value="scheduled" />
                    <Tab label="Zamanı Geçmiş" {...a11yProps(2)} value="expired" />
                    <Tab label="Tamamlanmış" {...a11yProps(2)} value="completed" />
                </Tabs>
            </Stack>

            <TableContainer>
                <Table>

                    <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IUserRemindersItem, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        const userInfo = JSON.parse(row?.content ?? "{}")

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ cursor: 'pointer' }}
                                            >

                                                <TableCell align='center'>
                                                    {index + 1 + ((variables?.pagination?.page - 1) * variables?.pagination?.pageSize)}
                                                </TableCell>

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={userInfo?.displayImageUrl}
                                                        >
                                                            {userInfo?.displayName?.slice(0, 1)}
                                                        </Avatar>
                                                        <Stack direction="column">
                                                            <Typography>
                                                                {userInfo?.displayName}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {row?.description?.slice(0, 20)}
                                                </TableCell>


                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {moment(row?.remindDate).format("DD MMM YYYY")}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Stack direction="row" gap="5px">
                                                        {
                                                            row?.tags?.map((tag, index) => {
                                                                return (<Chip label={tag} key={index} variant='outlined' color='primary' />)
                                                            })
                                                        }
                                                    </Stack>
                                                </TableCell>


                                                {/* <TableCell align="left">
                                                    <Chip
                                                        label={enumFunctions.findAndDisplayName(ReminderStatusEnum, row?.status)}
                                                        color={
                                                            row?.status === "completed" ? "info"
                                                                : row?.status === "expired" ? "error"
                                                                    : row?.status === "ongoing" ? "success"
                                                                        : "info"
                                                        }
                                                        variant="outlined"
                                                        style={{ borderRadius: 100 }}
                                                    />
                                                </TableCell> */}

                                                <TableCell align="left" >
                                                    <CreateReminderSection user={row} isUpdate isDone={row?.status !== "completed"} isDelete />
                                                </TableCell>



                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells?.length} text='Hiçbir hatırlatıcı bulunamadı!' />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                count={data?.count ?? 0} // toplam eleman sayısı
                rowsPerPage={variables?.pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                page={(variables?.pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                labelRowsPerPage="Sayfa başına gösterim"
                onPageChange={(event: any, page) => handleChangePage(event, page)}
                onRowsPerPageChange={handleChangePageSize}
            />

        </Card>
    )
}