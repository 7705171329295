

export const accountRoutes = {
    dashboard: {
        home: "/",
        companies: "/companies",
        reminders: "/reminders",
        orders: "/orders",
        creditRequests: "/credit-requests",
        // ozgeleceks: "/ozgeleceks",
        // references: "/references",
        // createUser: "/create-User",
        // conversions: "/conversions",

        notpad: "/notpad",
        myAccount: "/myaccount",
        helpCenter: "/help-center",
        settings: "/settings",
    }
}