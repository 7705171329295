import { IOrderStatistics } from "interfaces/payment/order/IOrderStatistics";


export interface IManagementOrderStatisticsResponse {

  thisMonth: {
    creditAmount: string
    orderCount: string
    salesAmount: string
    uniqueUserCount: string
  }
  thisWeek: {
    orderCount: string
    creditAmount: string
    salesAmount: string
    uniqueUserCount: string
  }
  today: {
    creditAmount: string
    orderCount: string
    salesAmount: string
    uniqueUserCount: string
  }

}


export interface IManagementOrderStatisticsVariables {

}


/**
 * @roles admin, specialist
 */
export const management_orderStatistics = (variables: IManagementOrderStatisticsVariables) => {


  const data = JSON.stringify({
    query: `
      query management_orderStatistics {
        management_orderStatistics {
          thisMonth {
            creditAmount
            salesAmount
            orderCount
            uniqueUserCount
          }
          today {
            creditAmount
            salesAmount
            orderCount
            uniqueUserCount
          }
          thisWeek {
            creditAmount
            salesAmount
            orderCount
            uniqueUserCount
          }
        }
      }
        `,
    variables: variables
  })

  return data
}