import { IOperationCompaniesItem } from "./_operation_companies";

export type IOperationCompanyResponse = IOperationCompaniesItem | null

export interface IOperationCompanyVariables {
  companyId: string
}

export const operation_company = (variables: IOperationCompanyVariables) => {

  const data = JSON.stringify({
    query: `
      query operation_company($companyId: ID!) {
        operation_company(companyId: $companyId) {
          _id
          personalInformation {
            name
            surname
            phoneNumber
            description
          }
          contact {
            phoneNumber
            phoneNumberVerified
            email
            emailVerified
          }
          companyInformation {
            companyName
            phoneNumber
            email
            city
            district
            address
            taxOffice
            taxNumber
            profileImage
            sector
            companyAddress
            foundationYear
            numberOfEmployees
            website
            linkedin
            instagram
            facebook
            companyBrief
            companyDocuments {
              imzaSirkuleri
              vergiLevhasi
            }
          }
          companyOnboardingStatus
          createdAt
          updatedAt
        }
      }
        `,
    variables: variables
  });

  return data;
};
