import * as yup from "yup";
import { useEffect, useState } from "react";
import { ISelfUpdateUserVariables, ISelfUserResponse } from "context";



// const selfUserFormValidationContext = createContext(null)

interface IUseSelfUserInfoFormValidationProps {
    values: ISelfUserResponse | undefined
}


export const useSelfUserInfoFormValidation = ({ values }: IUseSelfUserInfoFormValidationProps) => {


    const [defaultValues, setDefaultValues] = useState(values);

    useEffect(() => {
        setDefaultValues(values)
    }, [values])


    const validationSchema = yup.object().shape({})


    const initialValues: ISelfUpdateUserVariables = {
        contact: {
            email: defaultValues?.contact?.email ?? "",
            emailVerified: defaultValues?.contact?.emailVerified ?? false,
            phoneNumber: defaultValues?.contact?.phoneNumber ?? "",
            phoneNumberVerified: defaultValues?.contact?.phoneNumberVerified ?? false,
        },
        personalInformation: {
            backgroundImageUrl: defaultValues?.personalInformation?.backgroundImageUrl ?? "",
            birthDate: defaultValues?.personalInformation?.birthDate ?? "",
            city: defaultValues?.personalInformation?.city ?? "",
            country: defaultValues?.personalInformation?.country ?? "",
            description: defaultValues?.personalInformation?.description ?? "",
            district: defaultValues?.personalInformation?.district ?? "",
            name: defaultValues?.personalInformation?.name ?? "",
            phoneNumber: defaultValues?.personalInformation?.phoneNumber ?? "",
            profileImageUrl: defaultValues?.personalInformation?.profileImageUrl ?? "",
            surname: defaultValues?.personalInformation?.surname ?? ""
        }
    }

    return ({
        validationSchema,
        initialValues,
        defaultValues,
        setDefaultValues
    })

}