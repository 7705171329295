import { Box, Button, Chip, Divider, IconButton, LinearProgress, Stack, Typography } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import { enumFunctions } from 'utils';
// import { ApplicationStatusEnums, OzgelecekFieldEnum, PlaceOfWorkEnums, PositionLevelEnums, WorkTypeEnums } from 'types';
// import { ICandidateApplicationsItem } from 'context';
import moment from 'moment';


interface ICandidateApplicationCardProps {
    application: any // ICandidateApplicationsItem,
    onReject: () => void,
    onClick: () => void
}

export const CandidateApplicationCard = ({ application, onReject, onClick }: ICandidateApplicationCardProps) => {


    return (
        <Stack
            direction={{ xs: 'column', sm: "row" }}
            spacing={{ xs: 3, sm: 2 }}
            onClick={onClick}
            sx={{
                border: '1px solid #d6ddeb',
                p: 3,
                backgroundColor: '#fff',
                transition: 'all 0.3s ease',
                cursor: 'pointer',
                ":hover": {
                    boxShadow: '0 0 10px 0 #d6ddeb',
                },
            }}
        >

            <Box
                component="img"
                src={application?.advertisement?.createdBy?.companyInformation?.profileImage ?? 'https://cdn-icons-png.flaticon.com/512/5650/5650380.png'}
                sx={{
                    width: '60px',
                    height: '60px',
                }}
            />

            <Stack direction="column" spacing={1} flex={1} sx={{ textTransform: 'capitalize' }}>
                <Typography variant='subtitle1' color={'primary.darker'}>
                    {application?.advertisement?.title} - ({application?.advertisement?.createdBy?.companyInformation?.companyName})
                </Typography>

                <Typography variant='body1'>
                    <span style={{ color: '#000', fontWeight: 'bold' }}>
                        {/* {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, application?.advertisement?.field)} */}
                    </span> ·&nbsp;
                    {application?.advertisement?.createdBy?.companyInformation?.city}
                </Typography>

                {/* <Typography variant='body2'>
                    {application?.advertisement?.description.slice(0, 30)}
                </Typography> */}





                <Stack direction="row" spacing={1} mt={1} flexWrap="wrap" alignItems="center">



                    <Chip label={application?.advertisement?.department} color="primary" variant="outlined" sx={{ borderRadius: '100px', px: '15px' }} />
                    <Chip label={application?.advertisement?.createdBy?.companyInformation?.sector} color="success" variant="outlined" style={{ borderRadius: 100 }} />

                    <Divider orientation="vertical" variant="fullWidth" flexItem />

                    <Typography variant='body2' color={"GrayText"}>
                        {/* <span>Oluşturulma Tarihi : </span> {moment(application?.advertisement?.createdAt).format("DD MMM YYYY")} */}
                        <span>Başlama Tarihi : </span> {moment(application?.advertisement?.startDate).format("DD MMM YYYY")}
                    </Typography>
                </Stack>

                {/* <Typography variant='body2' color={"GrayText"} mt={1}>
                    İlan Tarihi: {moment(application?.advertisement?.createdAt).format('DD MMM YYYY')}
                </Typography> */}
            </Stack>


            <Stack direction="column" justifyContent="space-between">

                <Chip
                    // label={application?.status === "unsuitable" ? "Görüntülendi" : enumFunctions.findAndDisplayName(ApplicationStatusEnums, application?.status)}
                    variant='filled'
                    style={{ borderRadius: 100 }}
                    // sx={{ color: 'primary.main' }}
                    color={
                        application?.status === "pending" ? "warning"
                            : application?.status === "viewed" ? "info"
                                : application?.status === "suitable" ? "success"
                                    : application?.status === "unsuitable" ? "info"
                                        : application?.status === "pendingAsBlacklisted" ? "error"
                                            : "default"
                    }
                />

                <Stack
                    direction="column"
                    spacing={1}
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mb={1}
                    sx={{ '&>*': { flex: 1 } }}
                >
                    <Button color='error' variant='outlined' sx={{ py: 1, px: 2 }}
                        onClick={(e) => {
                            e.stopPropagation()
                            onReject()
                        }}
                    >
                        <ThumbDownAltRoundedIcon fontSize='small' sx={{ mr: 1 }} /> İptal Et
                    </Button>
                </Stack>

                {/* {application?.advertisement?.quota <= 5 && <LinearProgress variant="determinate" value={(4 / 10) * 100} />}
                {application?.advertisement?.quota > 5 && application?.advertisement?.quota <= 10 && <LinearProgress variant="determinate" value={(5 / 10) * 100} />}
                {application?.advertisement?.quota > 10 && application?.advertisement?.quota <= 20 && <LinearProgress variant="determinate" value={(6 / 10) * 100} />}
                {application?.advertisement?.quota > 20 && application?.advertisement?.quota <= 30 && <LinearProgress variant="determinate" value={(7 / 10) * 100} />}
                {application?.advertisement?.quota > 30 && application?.advertisement?.quota <= 50 && <LinearProgress variant="determinate" value={(8 / 10) * 100} />}
                {application?.advertisement?.quota > 50 && <LinearProgress variant="determinate" value={(9 / 10) * 100} />} */}





                {/* <Typography fontWeight={'bold'} >
                    Kontenjan: {application?.advertisement?.quota}
                </Typography> */}

            </Stack>

        </Stack>
    )
}
