import { CircularProgress, Stack, TableCell, TableRow } from '@mui/material'


interface ITableLoadingProps {
    colspan: number
}


export const TableLoading = ({ colspan }: ITableLoadingProps) => {
    return (
        <TableRow>
            <TableCell colSpan={colspan ?? 6}>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    py={5}
                    fontSize="32px"
                >
                    <CircularProgress color='primary' />
                </Stack>
            </TableCell>
        </TableRow>
    )
}
