import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL, getAccessToken } from 'utils'


export const blogApi = createApi({
    reducerPath: 'blogApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/blog/graphql`,
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + getAccessToken(),
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },

    }),

    tagTypes: [
        "management_blogPosts",
        "management_blogPost"
    ],

    endpoints: (builder) => ({}),

})
