import ProfileBox, { IBottomMenuListItem, IProfileBoxUser } from './ProfileBox'
import { Stack, Box } from '@mui/material'
import { Logo, NavbarPattern } from "assets";
import { NavSectionVertical } from "minimal/components/nav-section";


export interface IMenuListItem {
    subheader?: string;
    items: Array<{
        title: string,
        path: string,
        icon: any,
        info?: any
    }>
}
interface INavDefaultProps {
    menuList: Array<IMenuListItem>;
    user: IProfileBoxUser | null,
    bottomMenuList: Array<IBottomMenuListItem>
};

export const NavDefault = ({ menuList, user, bottomMenuList }: INavDefaultProps) => {

    return (
        <Stack
            sx={{
                width: '280px',
                flexShrink: 0,
                height: '100%',
                backgroundSize: 'cover',
                backgroundColor: '#F8F8FD',
                borderRight: '2px solid #dedede',
                backgroundImage: `url(${NavbarPattern})`,
                overflow: 'auto',
            }}
        >

            <Box sx={{ flex: 1 }}>

                <Box sx={{ mb: 2, mt: 4, ml: 4 }}>
                    <img width={"80%"} src={Logo} alt="InternIF Logo" />
                </Box>

                <NavSectionVertical
                    sx={{ display: 'flex', flex: 1, mt: 5 }}
                    data={menuList}
                    config={{
                        currentRole: 'candidate',
                    }}
                />

            </Box>

            <ProfileBox user={user} botomMenuList={bottomMenuList} />

        </Stack>
    )

}