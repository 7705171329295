import IBaseResult from "interfaces/IBaseResult";
import { IDefaultInputSendCreditRequestStatusChange } from "interfaces/payment/finance/ISendCreditRequest";
export interface IOperationCancelSendCreditRequestVariables
  extends IDefaultInputSendCreditRequestStatusChange { }

export interface IOperationCancelSendCreditRequestResponse
  extends IBaseResult { }

export const operation_cancelSendCreditRequest = (
  variables: IOperationCancelSendCreditRequestVariables
) => {

  console.log({ variables });
  const data = JSON.stringify({
    query: `
          mutation operation_cancelSendCreditRequest($id: ID!, $statusChangeReason: String) {
            operation_cancelSendCreditRequest(id: $id, statusChangeReason: $statusChangeReason) {
              success
            }
          }
        `,
    variables: variables,
  });

  return data;
};
