import {
    Card,
    Chip,
    Stack,
    Table,
    Avatar,
    TableRow,
    TableBody,
    Button,
    Dialog,

    IconButton,
    DialogContent,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    CircularProgress,
} from '@mui/material'


import { useEffect, useState } from 'react'
import { enumFunctions } from 'utils'
import { AdvertisementStatusEnum, ReminderStatusEnum, SendCreditRequestStatusEnum } from 'types'
import { CreateReminderSection, CreditRequestSection, UserNoteToUserSection } from 'sections';
import { CustomTableHeader, FormikTextField, ICustomTableHeadCell, Iconify, MuiTransition, TableEmptyData, TableLoading } from 'components'
import { IUserRemindersItem, IUserRemindersVariables, useGetUserRemindersQuery } from 'context';
import moment from 'moment';
import { ICallCenterInformationFormsItem, ICallCenterInformationFormsVariables, useCallCenterUpdateInformationFormMutation, useCallCenter_createNoteToInformationFormMutation, useGetCallCenterInformationFormsQuery, useLazyGetCallCenterNotesToInformationFormQuery } from '../context';
// import { IOperationSendCreditRequestsResponse, IOperationSendCreditRequestsVariables, useGetOperationSendCreditRequestsQuery } from '../context';

import * as yup from 'yup'
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';

import WhatshotOutlinedIcon from '@mui/icons-material/WhatshotOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

import { NumberOfEmployeeEnum } from 'types'






export const InformationRequestsPage = () => {

    const [variables, setVariables] = useState<ICallCenterInformationFormsVariables>({
        pagination: {
            page: 1,
            pageSize: 10,
        }
    })

    const { data, isLoading } = useGetCallCenterInformationFormsQuery(variables)


    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }


    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Bilgi Talepleri
            </Typography>

            <InformationRequestsTable
                data={data}
                pagination={variables?.pagination}
                loading={isLoading}
                handleChangePageSize={handleChangePageSize}
                handleChangePage={handleChangePage}
            />

        </Stack>
    )
}



const InformationRequestsTable = ({ data, pagination, loading, handleChangePageSize, handleChangePage }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Ad / Soyad',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            id: 'phone',
            numeric: false,
            disablePadding: false,
            label: 'Telefon',
        },
        {
            id: 'date',
            numeric: false,
            disablePadding: false,
            label: 'Tarih',
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Aksiyonlar',
        },
    ];


    const [requestNoteDialog, setRequestNoteDialog] = useState<any>(null)
    const [requestUpdateDialog, setRequestUpdateDialog] = useState<any>(null)

    const [getCallCenterNote, { isLoading: callCenterNoteLoading, data: callCenterNote }] = useLazyGetCallCenterNotesToInformationFormQuery()
    useEffect(() => {
        if (requestNoteDialog) {
            getCallCenterNote({ noteInput: { targetId: requestNoteDialog?._id } })
        }
    }, [getCallCenterNote, requestNoteDialog])

    const [updateRequest, { isLoading: updateLoading }] = useCallCenterUpdateInformationFormMutation()
    const [createNote, { isLoading: createNoteLoading }] = useCallCenter_createNoteToInformationFormMutation()


    const handleUpdateRequest = async (values: any) => {
        try {
            const response: any = await updateRequest({
                informationFormId: requestUpdateDialog?._id,
                input: {
                    email: values?.email ?? "",
                    name: values?.name ?? "",
                    phoneNumber: values?.phoneNumber ?? "",
                    surname: values?.surname ?? "",
                }
            })

            if (response?.data) {
                enqueueSnackbar("Başarılı!")
                setRequestUpdateDialog(null)
            } else {
                enqueueSnackbar("Oluşturulamadı!", { variant: 'warning' })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
        }
    }

    const handleCreateNote = async (values: any) => {
        try {
            const response: any = await createNote({
                noteInput: {
                    content: values?.content ?? "",
                    targetId: requestNoteDialog?._id,
                    title: requestNoteDialog?.title ?? "title"
                }
            })

            if (response?.data) {
                enqueueSnackbar("Başarılı!")
                setRequestNoteDialog(null)
            } else {
                enqueueSnackbar("Oluşturulamadı!", { variant: 'warning' })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
        }
    }


    return (
        <>
            <Card sx={{ p: 3 }}>

                <TableContainer>
                    <Table>

                        <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                        <TableBody>
                            {
                                !loading ? (
                                    data?.count ? (
                                        data?.data?.map((row: ICallCenterInformationFormsItem, index: number) => {

                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    key={row?._id ?? index}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        '& > *': {
                                                            whiteSpace: "nowrap"
                                                        },
                                                    }}
                                                >

                                                    <TableCell align='center'>
                                                        {index + 1 + ((pagination?.page - 1) * pagination?.pageSize)}
                                                    </TableCell>

                                                    <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                        <Typography>
                                                            {row?.name} {row?.surname}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="left" >
                                                        {row?.email}
                                                    </TableCell>

                                                    <TableCell align="left" >
                                                        {row?.phoneNumber}
                                                    </TableCell>

                                                    <TableCell align="left" >
                                                        {moment(row?.createdAt).format("DD MMM YYYY")}
                                                    </TableCell>

                                                    <TableCell align="left" >
                                                        <Stack direction="row" spacing={1}>
                                                            <IconButton
                                                                sx={{
                                                                    // bgcolor: 'secondary.light',
                                                                    bgcolor: '#ccc',
                                                                }}
                                                                onClick={() => setRequestNoteDialog(row)}
                                                            >
                                                                <Iconify icon="clarity:note-solid" />
                                                            </IconButton>

                                                            <IconButton
                                                                sx={{ bgcolor: 'secondary.light' }}
                                                                onClick={() => setRequestUpdateDialog(row)}
                                                            >
                                                                <Iconify icon="ph:eye-fill" sx={{ color: 'primary.main' }} />
                                                            </IconButton>
                                                        </Stack>                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableEmptyData colspan={headCells.length} text="Hiç bir bilgi talebi bulunamadı!" />
                                    )
                                ) : (
                                    <TableLoading colspan={headCells.length} />
                                )
                            }
                        </TableBody>

                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                    count={data?.count ?? 0} // toplam eleman sayısı
                    rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                    page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                    labelRowsPerPage="Sayfa başına gösterim"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangePageSize}
                />

            </Card>


            {/* ********************* create note dialog ******************* */}
            <Dialog
                open={!!requestNoteDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setRequestNoteDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Formik
                        validationSchema={yup.object().shape({
                            content: yup.string().required("Gerekli")
                        })}
                        enableReinitialize
                        initialValues={{
                            content: callCenterNote?.data?.[0]?.content ? callCenterNote?.data?.[0]?.content : ""
                        }}
                        onSubmit={(values) => {
                            console.log({ values });
                            handleCreateNote(values)
                        }}
                    >
                        {
                            ({ handleSubmit, errors }) => {
                                console.log({ errors });
                                return (
                                    <Stack
                                        spacing={3}
                                        py={3}
                                        minWidth={{ xs: "100%", sm: '400px' }}
                                    >

                                        <Typography variant='h3'>
                                            Not Ekle
                                        </Typography>

                                        {
                                            callCenterNoteLoading ? (
                                                <CircularProgress />
                                            ) : (
                                                <FormikTextField
                                                    multiline
                                                    minRows={4}
                                                    name='content'
                                                    label='Not Ekle'
                                                />
                                            )
                                        }


                                        <Stack direction="row" justifyContent="end" gap={3}>
                                            <Button
                                                onClick={() => setRequestNoteDialog(false)}
                                                variant='outlined'
                                                color="primary"
                                            >
                                                Kapat
                                            </Button>

                                            <LoadingButton
                                                variant='contained'
                                                color="primary"
                                                type='submit'
                                                onClick={() => handleSubmit()}
                                            // loading={sentFreeCreditLoading}
                                            >
                                                Gönder
                                            </LoadingButton>
                                        </Stack>

                                    </Stack>
                                )
                            }
                        }
                    </Formik>
                </DialogContent>
            </Dialog>


            {/* ********************* update request dialog ******************* */}
            <Dialog
                open={!!requestUpdateDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setRequestUpdateDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Formik
                        validationSchema={yup.object().shape({
                            email: yup.string().email().required("Gerekli"),
                            name: yup.string().required("Gerekli"),
                            phoneNumber: yup.string().required("Gerekli"),
                            surname: yup.string().required("Gerekli"),
                        })}
                        enableReinitialize
                        initialValues={{
                            email: requestUpdateDialog?.email ?? "",
                            name: requestUpdateDialog?.name ?? "",
                            phoneNumber: requestUpdateDialog?.phoneNumber ?? "",
                            surname: requestUpdateDialog?.surname ?? "",
                        }}
                        onSubmit={(values) => {
                            console.log({ values });
                            handleUpdateRequest(values)
                        }}
                    >
                        {
                            ({ handleSubmit, errors }) => {
                                console.log({ errors });
                                return (
                                    <Stack
                                        spacing={3}
                                        py={3}
                                        minWidth={{ xs: "100%", sm: '400px' }}
                                    >

                                        <Typography variant='h3'>
                                            Ücretsiz Kredi Gönder
                                        </Typography>

                                        {/* <FormikTextField
                                            type="number"
                                            name='price'
                                            label='Ücret'
                                        /> */}

                                        <FormikTextField
                                            name='name'
                                            label='İsim'
                                        />

                                        <FormikTextField
                                            name='surname'
                                            label='Soy İsim'
                                        />

                                        <FormikTextField
                                            name='email'
                                            label='Email'
                                        />

                                        <FormikTextField
                                            name='phoneNumber'
                                            label='Telefon'
                                        />


                                        <Stack direction="row" justifyContent="end" gap={3}>
                                            <Button
                                                onClick={() => setRequestUpdateDialog(false)}
                                                variant='outlined'
                                                color="primary"
                                            >
                                                Kapat
                                            </Button>

                                            <LoadingButton
                                                variant='contained'
                                                color="primary"
                                                onClick={() => handleSubmit()}
                                            // loading={sentFreeCreditLoading}
                                            >
                                                Güncelle
                                            </LoadingButton>
                                        </Stack>

                                    </Stack>
                                )
                            }
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    )
}
