import { CompanyConversionNumberOfEmployeesEnum } from "types";

export interface ISpecialistConversionRequestFormsItem {
  _id: string;
  authorizedPersonEmail: string;
  authorizedPersonName: string;
  authorizedPersonPhoneNumber: string;
  authorizedPersonSurname: string;
  authorizedPersonTitle: string;
  city: string;
  companyName: string;
  createdAt: Date;
  createdBy: {
    _id: string;
  };
  district: string;
  numberOfEmployees: CompanyConversionNumberOfEmployeesEnum;
  sector: string;
  updateBy: {
    _id: string;
  };
  updatedAt: Date;
  website: string;
}

export interface ISpecialistConversionRequestFormsResponse {
  count: number;
  data: Array<ISpecialistConversionRequestFormsItem>
}

export interface ISpecialistConversionRequestFormsVariables {
  conversionRequestFormId?: string;
  filter?: {
    authorizedPersonEmail?: string;
    authorizedPersonName?: string;
    authorizedPersonPhoneNumber?: string;
    authorizedPersonSurname?: string;
    authorizedPersonTitle?: string;
    city?: string;
    companyName?: string;
    district?: string;
    numberOfEmployees?: CompanyConversionNumberOfEmployeesEnum;
    sector?: string;
    website?: string;
  };
  pagination?: {
    page?: number;
    pageSize?: number;
    sort?: string;
  };
}

export const specialist_conversionRequestForms = (
  variables: ISpecialistConversionRequestFormsVariables
) => {
  const data = JSON.stringify({
    query: `
        query Specialist_conversionRequestForms {
          specialist_conversionRequestForms {
            count
            data {
              _id
              companyName
              authorizedPersonName
              authorizedPersonSurname
              authorizedPersonTitle
              authorizedPersonPhoneNumber
              authorizedPersonEmail
              city
              district
              numberOfEmployees
              sector
              website
              createdAt
              updatedAt
              createdBy {
                _id
              }
              updateBy {
                _id
              }
            }
          }
        }
        `,
    variables: {
      conversionRequestFormId: variables.conversionRequestFormId,
      filter: {
        authorizedPersonEmail: variables?.filter?.authorizedPersonEmail,
        authorizedPersonName: variables?.filter?.authorizedPersonName,
        authorizedPersonPhoneNumber:
          variables?.filter?.authorizedPersonPhoneNumber,
        authorizedPersonSurname: variables?.filter?.authorizedPersonSurname,
        authorizedPersonTitle: variables?.filter?.authorizedPersonTitle,
        city: variables?.filter?.city,
        companyName: variables?.filter?.companyName,
        district: variables?.filter?.district,
        numberOfEmployees: variables?.filter?.numberOfEmployees,
        sector: variables?.filter?.sector,
        website: variables?.filter?.website,
      },
      pagination: {
        page: variables?.pagination?.page,
        pageSize: variables?.pagination?.pageSize,
        sort: variables?.pagination?.sort,
      },
    },
  });

  return data;
};
