

export type ICompanyOzgeleceksResponse = Array<{
  _id: string
  candidate: {
    _id: string
  }
  field: string
  testResults: {
    areaTestScore: string
    motivationTestScore: string
    areaTestResults: {
      question: {
        _id: string
      }
      answer: string
    }
    motivationTestResults: {
      answer: string
    }
  }
  ozgelecekTest: {
    first: {
      video: string
      text: string
    }
    second: {
      video: string
      text: string
    }
  }
  references: {
    _id: string
    referenceInformation: {
      name: string
      surname: string
      phoneNumber: string
      relationWithCandidate: string
      jobTitle: string
      email: string
    }
    referenceStatus: string
  }
  createdAt: string
  updatedAt: string
}>;

export interface ICompanyOzgeleceksVariables {
  candidateId: string;
}

export const company_ozgeleceks = (variables: ICompanyOzgeleceksVariables) => {


  const data = JSON.stringify({
    query: `
      query company_ozgeleceks($candidateId: ID!) {
        company_ozgeleceks(candidateId: $candidateId) {
          _id
          candidate {
            _id
          }
          field
          testResults {
            areaTestScore
            motivationTestScore
            areaTestResults {
              question {
                _id
              }
              answer
            }
            motivationTestResults {
              answer
            }
          }
          ozgelecekTest {
            first {
              video
              text
            }
            second {
              video
              text
            }
          }
        references {
          _id
          referenceInformation {
            name
            surname
            phoneNumber
            relationWithCandidate
            jobTitle
            email
          }
          referenceStatus
        }
          createdAt
          updatedAt
        }
      }
          `,
    variables: variables

  })

  if (!variables?.candidateId) {
    return null
  } else {

    return data
  }

}