import { InformationFormTargetRole } from "enums/user/informationForm/InformationFormTargetRole";

export interface ICallCenterUpdateInformationFormResponse {
  _id: string;
}

export interface ICallCenterUpdateInformationFormVariables {
  informationFormId: string;
  input: {
    name?: string;
    surname?: string;
    email?: string;
    phoneNumber?: string;
    targetRole?: InformationFormTargetRole
  };
}

export const callCenter_updateInformationForm = (
  variables: ICallCenterUpdateInformationFormVariables
) => {
  const data = JSON.stringify({
    query: `
        mutation callCenter_updateInformationForm($informationFormId: ID!, $input: UpdateInformationFormInput!) {
          callCenter_updateInformationForm(informationFormId: $informationFormId, informationFormInput: $input) {
            _id
          }
        }
        `,
    variables: variables
  });

  return data;
};
