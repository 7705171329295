import { useFormikContext } from 'formik'
import { Box, MenuItem, StandardTextFieldProps, TextField } from '@mui/material'


interface IFormikSelect extends StandardTextFieldProps {
    list: any[],
    name: string
}

export const FormikSelectCountry = ({ list, name, label, ...rest }: IFormikSelect) => {

    const { getFieldProps, getFieldMeta } = useFormikContext<IFormikSelect | any>()

    const error = !!(getFieldMeta(name).touched && getFieldMeta(name).error)
    const helperText = !!getFieldMeta(name).touched && getFieldMeta(name).error && `${getFieldMeta(name).error}`


    return (
        <TextField
            disabled={!list}
            select
            label={label}
            {...getFieldProps(name)}
            {...rest}
            fullWidth
            sx={{ height: '100%' }}
            defaultValue={getFieldProps(name).value}
            value={list.length ? getFieldProps(name).value : ""}
            error={error}
            helperText={helperText}
        >
            {
                list?.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                        <img
                            loading="lazy"
                            width="20"
                            height="20"
                            srcSet={`https://flagcdn.com/w80/${option.code.toLowerCase()}.png 4x`}
                            src={`https://flagcdn.com/w80/${option.code.toLowerCase()}.png`}
                            alt=""
                            style={{
                                borderRadius: '50%',
                                objectFit: 'cover',
                                marginRight: 2,
                            }}
                        />
                        {option?.name}
                    </MenuItem>
                ))
            }
        </TextField>
    )
}
