

export interface ICallCenterCreateNoteToInformationFormResponse {
    _id: string;
}

export interface ICallCenterCreateNoteToInformationFormVariables {
    noteInput: {
        content: string,
        targetId: string,
        title: string
    }
}


export const callCenter_createNoteToInformationForm = (variables: ICallCenterCreateNoteToInformationFormVariables) => {

    const data = JSON.stringify({
        query: `
        mutation CallCenter_createNoteToInformationForm($noteInput: NoteToUserInput!) {
            callCenter_createNoteToInformationForm(noteInput: $noteInput) {
              _id
            }
          }
        `,
        variables: variables
    })

    return data

}