import IBaseResult from "interfaces/IBaseResult";

export interface ISpecialistSendFreeCreditVariables {
  receiverIds: string[],
  creditAmountPerReceiver: number
}

export interface ISpecialistSendFreeCreditResponse
  extends IBaseResult {}

export const specialist_sendFreeCredits = (
  variables: ISpecialistSendFreeCreditVariables
) => {
  const data = JSON.stringify({
    query: `
        mutation specialist_sendFreeCredits($receiverIds: [ID!]!, $creditAmountPerReceiver: Int!) {
          specialist_sendFreeCredits(receiverIds: $receiverIds, creditAmountPerReceiver: $creditAmountPerReceiver) {
            success
          }
        }
        `,
    variables: variables,
  });

  return data;
};
