import HasFilter from "interfaces/HasFilter";
import HasPagination from "interfaces/HasPagination";
import IPaginatedListResult from "interfaces/IPaginatedListResult";
import { IFilterOrder } from "interfaces/payment/order/IFilterOrder";
import IOrder from "interfaces/payment/order/IOrder";


export interface IAccounterOrdersItem {

  _id: string
  createdAt: string
  orderStatus: string
  payment: {
    paymentStatus: string
    data: string
    error: string
  }
  product: {
    _id: string
    name: string
    type: string
    listUnitPrice: string
    targetRoles: string
    unitPrice: string
    amount: string
    price: string
    discount: string
    currency: string
    createdAt: string
    updatedAt: string
  }
  price: {
    netTotal: string
    currency: string
  }
  user: {
    _id: string
  }
  addresses: {
    billingAddress: {
      fullName: string
      companyName: string
      phoneNumber: string
      email: string
      country: string
      city: string
      district: string
      address1: string
      address2: string
      postcode: string
      taxIdentificationNumber: string
      taxOffice: string
    }
  }
  createdBy: {
    _id: string
    role: string
    displayName: string
    displayImageUrl: string
  }
  errorData: {
    code: string
    name: string
    message: string
    detail: string
  }
  updatedAt: string
  updatedBy: {
    _id: string
    role: string
    displayName: string
    displayImageUrl: string
  }

  parasutSalesInvoice: {
    id: string
    previewUrl: string
    printUrl: string
  }

}

export interface IAccounterOrdersResponse extends IPaginatedListResult<IAccounterOrdersItem> { }

export interface IAccounterOrdersVariables extends HasFilter<IFilterOrder>, HasPagination { }

export const accounter_orders = (variables: IAccounterOrdersVariables) => {

  const data = JSON.stringify({
    query: `
      query accounter_orders($filter: FilterOrderInput, $pagination: Pagination) {
        accounter_orders(filter: $filter, pagination: $pagination) {
          count
          data {

            parasutSalesInvoice {
              id
              previewUrl
              printUrl
            }

            _id
            createdAt
            orderStatus
            payment {
              paymentStatus
              data
              error
            }
            product {
              _id
              name
              type
              listUnitPrice
              targetRoles
              unitPrice
              amount
              price
              discount
              currency
              createdAt
              updatedAt
            }
            price {
              netTotal
              currency
            }
            user {
              _id
            }
            addresses {
              billingAddress {
                fullName
                companyName
                phoneNumber
                email
                country
                city
                district
                address1
                address2
                postcode
                taxIdentificationNumber
                taxOffice
              }
            }
            createdBy {
              _id
              role
              displayName
              displayImageUrl
            }
            errorData {
              code
              name
              message
              detail
            }
            updatedAt
            updatedBy {
              _id
              role
              displayName
              displayImageUrl
            }
          }
        }
      }
    `,
    variables: variables
  })

  return data

}