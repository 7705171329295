import IBaseResult from "interfaces/IBaseResult";

export interface IAccounterCreateSalesInvoiceInParachuteForOrderVariables {
  orderId: string;
}

export interface IAccounterCreateSalesInvoiceInParachuteForOrderResponse
  extends IBaseResult {}

export const accounter_createSalesInvoiceInParachuteForOrder = (
  variables: IAccounterCreateSalesInvoiceInParachuteForOrderVariables
) => {
  const data = JSON.stringify({
    query: `
      mutation accounter_createSalesInvoiceInParachuteForOrder($orderId: String!) {
        accounter_createSalesInvoiceInParachuteForOrder(orderId: $orderId) {
          success
        }
      }
    `,
    variables: variables,
  });

  return data;
};
