import IBaseResult from "interfaces/IBaseResult";



export interface IManagementUpdateBlogPostVariables {
  blogPostId: string,
  updateBlogPostInput: {
    title: string,
    content: string,
    readingTime?: string,
    imageUrl?: string,
    targetCategories: Array<string>,
    categories: Array<string>,
    author?: string,
    description?: string,
    shareImageUrl?: string,
    language: string
  }
}


export interface IManagementUpdateBlogPostResponse extends IBaseResult { }

export const management_updateBlogPost = (
  variables: IManagementUpdateBlogPostVariables
) => {
  const data = JSON.stringify({
    query: `mutation Management_updateBlogPost($blogPostId: ID!, $updateBlogPostInput: UpdateBlogPostInput!) {
      management_updateBlogPost(blogPostId: $blogPostId, updateBlogPostInput: $updateBlogPostInput) {
        _id
      }
    }`,
    variables: variables
  });

  return data;
};
