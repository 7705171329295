import { Stack } from '@mui/material'
import { UserNoteToSelfSection } from 'sections'


export const NotepadPage = () => {

    return (
        <Stack spacing={3}>
            <UserNoteToSelfSection />
        </Stack>
    )
}
