import { AccountingRoutes } from "apps/accounting";
import { AdminRoutes } from "apps/admin/routes/AdminRoutes";
import { AuthRoutes } from "apps/auth";
import { CallCenterRoutes } from "apps/call-center";
import { HrAdvisorRoutes } from "apps/hr-advisor";
import { OperationRoutes } from "apps/operation";
import { SpecialistRoutes } from "apps/specialist";
import { IAuthState, useAppSelector } from "context";
import { BrowserRouter } from "react-router-dom";





export const MainRouter = () => {

    const {
        userType,
    }: IAuthState = useAppSelector(state => state?.auth)

    // const userType: any = "call-center"

    const renderUserTypeRouter = () => {


        switch (userType) {
            case "admin":
                return <AdminRoutes />
            case "callCenter":
                return <CallCenterRoutes />
            case "hrAdvisor":
                return <HrAdvisorRoutes />
            case "operation":
                return <OperationRoutes />
            case "specialist":
                return <SpecialistRoutes />
            case "accounter":
                return <AccountingRoutes />

            default:
                return <AuthRoutes />
        }
    }

    return (
        <BrowserRouter>
            {renderUserTypeRouter()}
        </BrowserRouter>
    )
}
