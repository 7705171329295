import { IUpdateDiscoverForm } from "interfaces/user/discoverForm/IDiscoverForm";

export interface IHrAdvisorUpdateDiscoverFormResponse {
  _id: string;
}

export interface IHrAdvisorUpdateDiscoverFormVariables {
  discoverFormId: string;
  input: IUpdateDiscoverForm;
}

export const hrAdvisor_updateDiscoverForm = (
  variables: IHrAdvisorUpdateDiscoverFormVariables
) => {
  const data = JSON.stringify({
    query: `
        mutation hrAdvisor_updateDiscoverForm($discoverFormId: ID!, $input: UpdateDiscoverFormInput!) {
          hrAdvisor_updateDiscoverForm(discoverFormId: $discoverFormId, discoverFormInput: $input) {
            _id
          }
        }
        `,
    variables: variables
  });

  return data;
};
