


export interface ISelfUpdateUserResponse {
  _id: string
}

export interface ISelfUpdateUserVariables {

  contact: {
    email: string
    emailVerified: boolean
    phoneNumber: string
    phoneNumberVerified: boolean
  }
  personalInformation: {
    backgroundImageUrl: string
    birthDate: string
    city: string
    country: string | any
    description: string
    district: string
    name: string
    phoneNumber: string
    profileImageUrl: string | any
    surname: string
  }
}

export const self_updateUser = (variables: ISelfUpdateUserVariables) => {

  const data = JSON.stringify({
    query: `
        mutation Self_updateUser($updateUserInput: UpdateUserInput!) {
          self_updateUser(updateUserInput: $updateUserInput) {
            _id
          }
        }
        `,
    variables: {
      "updateUserInput": {
        "contact": {
          "email": variables?.contact?.email ?? null,
          "emailVerified": variables?.contact?.emailVerified ?? null,
          "phoneNumber": variables?.contact?.phoneNumber ?? null,
          "phoneNumberVerified": variables?.contact?.phoneNumberVerified ?? null
        },
        "personalInformation": {
          "backgroundImageUrl": variables?.personalInformation?.backgroundImageUrl ?? null,
          "birthDate": variables?.personalInformation?.birthDate ?? null,
          "city": variables?.personalInformation?.city ?? null,
          "country": variables?.personalInformation?.country ?? null,
          "description": variables?.personalInformation?.description ?? null,
          "district": variables?.personalInformation?.district ?? null,
          "name": variables?.personalInformation?.name ?? null,
          "phoneNumber": variables?.personalInformation?.phoneNumber ?? null,
          "profileImageUrl": variables?.personalInformation?.profileImageUrl ?? null,
          "surname": variables?.personalInformation?.surname ?? null
        }
      }
    }
  })

  return data

}