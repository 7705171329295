import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, Stack, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, TableHead, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material'
import { TipTapComponent } from 'components'
import { useGetUserNotesToSelfQuery, useUserCreateNoteToSelfMutation, useUserUpdateNoteMutation, useUserRemoveNoteMutation } from 'context'
import moment from 'moment'
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'

export const UserNoteToSelfSection = () => {


    const [noteId, setNoteId] = useState<any>("")

    const [updateNote, { isLoading: updateLoading }] = useUserUpdateNoteMutation()
    const [createNoteToSelf, { isLoading: createLoading }] = useUserCreateNoteToSelfMutation()
    const { data: userNotes, isLoading, isError } = useGetUserNotesToSelfQuery({})
    const [removeNote] = useUserRemoveNoteMutation()


    const handleSubmit = async ({ title, content, noteId }: { title: string, content: string, noteId: any }) => {
        try {
            if (typeof noteId === "string") {
                const updateResponse: any = await updateNote({
                    noteId: noteId ?? "",
                    noteUpdateInput: {
                        title: title,
                        content: content !== "<p> </p>" ? content : "",
                    }
                })
                console.log({ updateResponse });
                if (updateResponse?.data) {
                    enqueueSnackbar("Güncellendi!")
                    setNoteId("")
                } else {
                    enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
                }
            } else {
                const createResponse: any = await createNoteToSelf({
                    noteInput: {
                        title: title,
                        content: content !== "<p> </p>" ? content : "",
                    }
                })
                console.log({ createResponse });
                if (createResponse?.data) {
                    enqueueSnackbar("Oluşturuldu!")
                    setNoteId("")
                } else {
                    enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
                }
            }



        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }

    const handleRemoveNote = async ({ noteId }: { noteId: string }) => {
        try {
            const removeResponse: any = await removeNote({
                noteId: noteId
            })
            console.log({ removeResponse });
            if (removeResponse?.data) {
                enqueueSnackbar("Not silindi!")
            } else {
                enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }


    return (
        <Stack spacing={3}>

            <Stack direction="row" justifyContent="space-between" alignItems="center">

                <Typography variant='h1'>
                    Notlarım
                </Typography>

                <LoadingButton
                    loading={updateLoading || createLoading}
                    disabled={updateLoading || createLoading}
                    onClick={() => setNoteId(true)}
                    variant='contained'
                    color="primary"
                >
                    Not Ekle
                </LoadingButton>
            </Stack>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                #
                            </TableCell>
                            <TableCell>
                                Başlık
                            </TableCell>
                            <TableCell>
                                Güncellenme Tarihi
                            </TableCell>
                            <TableCell>
                                İşlemler
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userNotes?.data ? userNotes?.data?.length > 0 ?
                            userNotes?.data?.map((note, index) => (
                                <TableRow key={note._id}>
                                    <TableCell>
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>
                                        {note.title}
                                    </TableCell>
                                    <TableCell>
                                        {moment(note.updatedAt).format("DD/MM/YYYY HH:mm")}
                                    </TableCell>
                                    <TableCell>
                                        <LoadingButton
                                            loading={updateLoading || createLoading}
                                            disabled={updateLoading || createLoading}
                                            onClick={() => setNoteId(note)}
                                            variant='contained'
                                            color="primary"
                                        >
                                            Güncelle
                                        </LoadingButton>
                                        <LoadingButton
                                            sx={{ ml: 1 }}
                                            loading={updateLoading || createLoading}
                                            disabled={updateLoading || createLoading}
                                            onClick={() => handleRemoveNote({
                                                noteId: note._id
                                            })}
                                            variant='contained'
                                            color="secondary"
                                        >
                                            Sil
                                        </LoadingButton>
                                    </TableCell>
                                </TableRow>
                            )) :
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Typography textAlign={"center"}>
                                        Henüz bir notunuz bulunmamaktadır.
                                    </Typography>
                                </TableCell>
                            </TableRow> :
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Typography textAlign={"center"}>
                                        Henüz bir notunuz bulunmamaktadır.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <UserNoteDetail noteId={noteId ?? true} setNoteId={setNoteId} isLoading={isLoading} handleSubmit={handleSubmit} />


        </Stack>
    )
}



const UserNoteDetail = ({ isLoading, handleSubmit, noteId, setNoteId }: { isLoading?: any, handleSubmit: any, noteId: any, setNoteId: any }) => {

    const [content, setContent] = useState<any>(false)
    const [title, setTitle] = useState<any>("")
    const [editorKey, setEditorKey] = useState<any>(0)

    useEffect(() => {
        if (typeof noteId === "boolean") {
            setTitle("")
            setContent("")
            setEditorKey((prevKey: any) => prevKey + 1);
        } else {
            setTitle(noteId?.title ?? "")
            setContent(noteId?.content ?? "")
            setEditorKey((prevKey: any) => prevKey + 1);
        }
    }, [noteId])


    return (
        <Dialog open={noteId} onClose={() => setNoteId("")}>
            <DialogTitle>
                Not Detayı
            </DialogTitle>
            <DialogContent>
                <Stack spacing={3}>

                    <Box>

                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <TextField
                                    fullWidth
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    label="Başlık"
                                    variant="outlined"
                                    margin="normal"

                                />
                                <TipTapComponent
                                    // content={userNotes?.data?.[0]?.content}
                                    key={editorKey}
                                    value={content}
                                    setValue={(e: string) => setContent(e)}
                                />
                            </>
                        )
                        }

                    </Box>

                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" >
                    <LoadingButton
                        onClick={() => setNoteId("")}
                        variant='outlined'
                        color="primary"
                    >
                        Kapat
                    </LoadingButton>
                    <LoadingButton
                        sx={{ ml: 1 }}
                        disabled={!title || !content}
                        onClick={() => handleSubmit({ title, content, noteId: noteId?._id })}
                        variant='contained'
                        color="primary"
                    >
                        Kaydet
                    </LoadingButton>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}



// export const UserNoteToSelfSection = () => {


//     const [content, setContent] = useState<any>(false)
//     const [title, setTitle] = useState<any>("")

//     const [updateNote, { isLoading: updateLoading }] = useUserUpdateNoteMutation()
//     const [createNoteToSelf, { isLoading: createLoading }] = useUserCreateNoteToSelfMutation()
//     const { data: userNotes, isLoading, isError } = useGetUserNotesToSelfQuery({})

//     useEffect(() => {
//         console.log({ userNotes });
//         if (userNotes?.data) {
//             setTitle(userNotes?.data?.[0]?.title ?? "")
//             setContent(userNotes?.data?.[0]?.content ?? "")
//         }
//     }, [userNotes])


//     const handleSubmit = async () => {
//         try {
//             if (!!userNotes?.data?.[0]) {
//                 const updateResponse: any = await updateNote({
//                     noteId: userNotes?.data?.[0]?._id ?? "",
//                     noteUpdateInput: {
//                         title: title,
//                         content: content !== "<p> </p>" ? content : null,
//                     }
//                 })
//                 console.log({ updateResponse });
//                 if (updateResponse?.data) {
//                     enqueueSnackbar("Güncellendi!")
//                 } else {
//                     enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
//                 }
//             } else {
//                 const createResponse: any = await createNoteToSelf({
//                     noteInput: {
//                         title: title,
//                         content: content !== "<p> </p>" ? content : null,
//                     }
//                 })
//                 console.log({ createResponse });
//                 if (createResponse?.data) {
//                     enqueueSnackbar("Oluşturuldu!")
//                 } else {
//                     enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
//                 }
//             }



//         } catch (error) {
//             console.log({ error });
//             enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
//         }
//     }

//     console.log(typeof content, content);

//     return (
//         <Stack spacing={3}>

//             <Typography variant='h1'>
//                 Notlarım
//             </Typography>

//             <Box>
//                 {
//                     ((typeof content === "boolean") || isLoading) ? (
//                         <CircularProgress />
//                     ) : (
//                         <>
//                             <TextField
//                                 fullWidth
//                                 value={title}
//                                 onChange={(e) => setTitle(e.target.value)}
//                                 label="Başlık"
//                                 variant="outlined"
//                                 margin="normal"

//                             />
//                             <TipTapComponent
//                                 // content={userNotes?.data?.[0]?.content}
//                                 value={content}
//                                 setValue={(e: string) => setContent(e)}
//                             />
//                         </>
//                     )
//                 }
//             </Box>

//             <Stack direction="row" >
//                 <LoadingButton
//                     loading={updateLoading || createLoading}
//                     disabled={updateLoading || createLoading}
//                     onClick={handleSubmit}
//                     variant='contained'
//                     color="primary"
//                 >
//                     Kaydet
//                 </LoadingButton>
//             </Stack>

//         </Stack>
//     )
// }
