import IEntity, { IInsertEntity } from "interfaces/IEntity";

  export interface IOperationCreateSendCreditRequestVariables {
    input: {
      creditAmount: number;
      receiver: IInsertEntity;
      price: number;
    };
  }
  export interface IOperationCreateSendCreditRequestResponse
    extends IEntity {}

export const operation_createSendCreditRequest = (variables: IOperationCreateSendCreditRequestVariables) => {

    const data = JSON.stringify({
        query: `
          mutation operation_createSendCreditRequest($input: SendCreditRequestInput!) {
            operation_createSendCreditRequest(input: $input) {
              _id
            }
          }
        `,
        variables: variables
    })

    return data
}
