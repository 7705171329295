import { ExitToApp } from "@mui/icons-material"
import { Route, Routes, useNavigate } from "react-router-dom"

import { adminRoutes } from "./routes"
import { DashboardLayout } from "layouts"
import { logoutReducer, useAppDispatch, useGetSelfUserQuery, useGetUserRemindersQuery } from "context"
import { IBottomMenuListItem, IMenuListItem, IProfileBoxUser, Iconify } from "components"
import {
    AdvertisementsPage,
    CompaniesPage,
    ConversionRequestsPage,
    // ConversionsPage,
    CreateUserPage,
    HelpCenterPage,
    HomePage,
    MyAccountPage,
    NotepadPage,
    OrdersPage,
    OzgeleceksPage,
    ReferencesPage,
    RemindersPage,
    SettingsPage,
    CandidatesPage,
    BlogPage
} from "../pages"
import { Badge } from "@mui/material"
import { CreateBlogPage } from "../pages/CreateBlogPage"
import { UpdateBlogPage } from "../pages/UpdateBlogPage"





export const AdminRoutes = () => {


    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { data: userInfo } = useGetSelfUserQuery({})
    const { data: reminders } = useGetUserRemindersQuery({ pagination: { page: 0, pageSize: 0 }, filter: { status: "inProgress" } })

    const user: IProfileBoxUser | null = {
        fullName: (userInfo?.personalInformation?.name || userInfo?.personalInformation?.surname)
            ? userInfo?.personalInformation?.name + " " + userInfo?.personalInformation?.surname
            : userInfo?.contact?.email ?? "",
        profileImageUrl: userInfo?.personalInformation?.profileImageUrl ?? "",
        role: userInfo?.role ?? ""
    }


    const menuList: Array<IMenuListItem> = [
        {
            items: [
                {
                    title: 'Panel',
                    path: adminRoutes.dashboard.home,
                    icon: <Iconify icon="iconamoon:home" />
                },
                {
                    title: 'Şirketler',
                    path: adminRoutes.dashboard.companies,
                    icon: <Iconify icon="mdi:company" />
                },
                {
                    title: 'Adaylar',
                    path: adminRoutes.dashboard.candidates,
                    icon: <Iconify icon="formkit:people" />
                },
                {
                    title: 'Siparişler',
                    path: adminRoutes.dashboard.orders,
                    icon: <Iconify icon="icon-park-outline:transaction-order" />
                },
                {
                    title: 'İlanlar',
                    path: adminRoutes.dashboard.advertisements,
                    icon: <Iconify icon="ic:baseline-list-alt" />
                },
                {
                    title: 'Özgelecekler',
                    path: adminRoutes.dashboard.ozgeleceks,
                    icon: <Iconify icon="pepicons-pencil:cv" />
                },
                {
                    title: 'Referanslar',
                    path: adminRoutes.dashboard.references,
                    icon: <Iconify icon="ooui:references-ltr" />
                },
                // {
                //     title: 'Dönüşümler',
                //     path: adminRoutes.dashboard.conversions,
                //     icon: <Iconify icon="streamline:recycle-1-solid" />
                // },
                {
                    title: 'Dönüşüm Talepleri',
                    path: adminRoutes.dashboard.conversionRequests,
                    icon: <Iconify icon="icon-park-outline:list-fail" />
                },
                {
                    title: 'Kullanıcı Oluştur',
                    path: adminRoutes.dashboard.createUser,
                    icon: <Iconify icon="mingcute:user-add-line" />
                },
                {
                    title: 'Hatırlatıcılar',
                    path: adminRoutes.dashboard.reminders,
                    icon: <Iconify icon="carbon:reminder" />,
                    info: <Badge badgeContent={reminders?.count} color="primary" />
                },
                {
                    title: 'Blog',
                    path: adminRoutes.dashboard.blog,
                    icon: <Iconify icon="bx:bxs-book" />
                }
            ]
        },
        {
            subheader: 'Ayarlar',
            items: [
                {
                    title: 'Ayarlar',
                    path: adminRoutes.dashboard.settings,
                    icon: <Iconify icon="solar:settings-bold-duotone" />
                },
                {
                    title: 'Notepad',
                    path: adminRoutes.dashboard.notpad,
                    icon: <Iconify icon="fluent:notepad-edit-16-regular" />
                },
            ]
        }
    ]

    const bottomMenuList: Array<IBottomMenuListItem> = [
        {
            func: (e) => navigate(adminRoutes.dashboard.myAccount),
            icon: <Iconify icon="uil:setting" width={24} sx={{ marginRight: 1 }} />,
            title: "Hesap Bilgileri"
        },
        {
            func: (e) => dispatch(logoutReducer("")),
            icon: <ExitToApp sx={{ marginRight: 1 }} />,
            title: "Çıkış"
        },
    ]

    return (
        <Routes>
            <Route path="" element={<DashboardLayout menuList={menuList} user={user} bottomMenuList={bottomMenuList} />}>
                <Route path={adminRoutes.dashboard.home} element={<HomePage />} />
                <Route path={adminRoutes.dashboard.companies} element={<CompaniesPage />} />
                <Route path={adminRoutes.dashboard.advertisements} element={<AdvertisementsPage />} />
                <Route path={adminRoutes.dashboard.ozgeleceks} element={<OzgeleceksPage />} />
                <Route path={adminRoutes.dashboard.references} element={<ReferencesPage />} />
                {/* <Route path={adminRoutes.dashboard.conversions} element={<ConversionsPage />} /> */}
                <Route path={adminRoutes.dashboard.conversionRequests} element={<ConversionRequestsPage />} />
                <Route path={adminRoutes.dashboard.createUser} element={<CreateUserPage />} />
                <Route path={adminRoutes.dashboard.reminders} element={<RemindersPage />} />
                <Route path={adminRoutes.dashboard.orders} element={<OrdersPage />} />
                <Route path={adminRoutes.dashboard.candidates} element={<CandidatesPage />} />
                <Route path={adminRoutes.dashboard.blog} element={<BlogPage />} />
                <Route path={adminRoutes.dashboard.createBlog} element={<CreateBlogPage />} />
                <Route path={adminRoutes.dashboard.updateBlog} element={<UpdateBlogPage />} />

                <Route path={adminRoutes.dashboard.notpad} element={<NotepadPage />} />
                <Route path={adminRoutes.dashboard.settings} element={<SettingsPage />} />
                <Route path={adminRoutes.dashboard.helpCenter} element={<HelpCenterPage />} />
                <Route path={adminRoutes.dashboard.myAccount} element={<MyAccountPage />} />

                <Route path="*" element={<HomePage />} />
            </Route>
        </Routes>
    )
}