
import { IManagementOrderStatisticsResponse, IManagementOrderStatisticsVariables, management_orderStatistics } from "./queries"
import { paymentApi } from "context/payment";
import { IManagementUsersResponse, IManagementUsersVariables, management_users } from "./queries/_management_users";


const managementPaymentApi = paymentApi.injectEndpoints({
  endpoints: (builder) => ({

    // ####################  Queries #################
    getManagementUsers: builder.query<IManagementUsersResponse, IManagementUsersVariables>({
      query: (variables: IManagementUsersVariables) => {
        return ({
          url: "",
          body: management_users(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.management_users
      },
      providesTags: ["management_users"]
    }),

    getManagementStatistics: builder.query<IManagementOrderStatisticsResponse, IManagementOrderStatisticsVariables>({
      query: (variables: IManagementOrderStatisticsVariables) => {

        return ({
          url: "",
          body: management_orderStatistics(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.management_orderStatistics
      },
      providesTags: ["management_statistics"]
    }),

    // ####################  Mutations #################
  }),

  overrideExisting: false,
});

export const {
  // ####################  Queries #################
  useGetManagementStatisticsQuery,
  useLazyGetManagementStatisticsQuery,

  useGetManagementUsersQuery,
  useLazyGetManagementUsersQuery,

  // ####################  Mutations #################

} = managementPaymentApi

