
import { useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { Button, CircularProgress, Dialog, DialogContent, IconButton, Stack, TextField, Typography } from '@mui/material'

import { Iconify, MuiTransition } from 'components'
import { useLazyGetUserNotesToUserQuery, useUserCreateNoteToUserMutation, useUserUpdateNoteMutation } from 'context';




export const UserNoteToUserSection = ({ targetId }: { targetId: string }) => {

    const [notDialog, setNoteDialog] = useState(false)
    const [content, setContent] = useState("")

    const [updateNote] = useUserUpdateNoteMutation()
    const [createNoteToUser] = useUserCreateNoteToUserMutation()
    const [getUserNotes, { data: userNotes, isFetching }] = useLazyGetUserNotesToUserQuery()


    useEffect(() => {
        if (notDialog) {
            getUserNotes({ filter: { targetId } })
        }
    }, [getUserNotes, notDialog, targetId])

    useEffect(() => {
        setContent(userNotes?.data?.[0]?.content ?? "")
    }, [userNotes?.data])


    const handleSubmit = async () => {
        try {
            if (!!userNotes?.data?.[0]) {
                const updateResponse: any = await updateNote({
                    noteId: userNotes?.data?.[0]?._id ?? "",
                    noteUpdateInput: {
                        title: userNotes?.data?.[0]?.title ?? "",
                        content: content,
                    }
                })
                console.log({ updateResponse });
                if (updateResponse?.data) {
                    enqueueSnackbar("Güncellendi!")
                    setNoteDialog(false)
                } else {
                    enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
                }
            } else {
                const createResponse: any = await createNoteToUser({
                    noteInput: {
                        title: "advertisement_" + targetId,
                        content: content,
                        targetId: targetId
                    }
                })
                console.log({ createResponse });
                if (createResponse?.data) {
                    enqueueSnackbar("Oluşturuldu!")
                    setNoteDialog(false)
                } else {
                    enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
                }
            }



        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }

    return (
        <>

            <IconButton sx={{ bgcolor: '#ccc' }} onClick={() => setNoteDialog(true)} title='Not Yaz'>
                <Iconify icon="clarity:note-solid" />
            </IconButton>


            <Dialog
                open={!!notDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setNoteDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Stack
                        spacing={3}
                        py={3}
                        minWidth={{ xs: "100%", sm: '400px' }}
                    >

                        {
                            !isFetching ? (
                                <>
                                    <Typography variant='h3'>
                                        Not Ekle
                                    </Typography>

                                    <TextField
                                        multiline
                                        minRows={4}
                                        value={content}
                                        onChange={(e) => setContent(e.target.value)}
                                    />

                                    <Stack direction="row" justifyContent="end" gap={3}>
                                        <Button
                                            onClick={() => setNoteDialog(false)}
                                            variant='outlined'
                                            color="primary"
                                        >
                                            Kapat
                                        </Button>

                                        <Button
                                            variant='contained'
                                            color="primary"
                                            onClick={handleSubmit}
                                        >
                                            Oluştur
                                        </Button>
                                    </Stack>

                                </>
                            ) : (
                                <CircularProgress color="primary" sx={{ m: 'auto' }} />
                            )
                        }

                    </Stack>
                </DialogContent>
            </Dialog>

        </>
    )
}