import {
    Card,
    Link,
    Grid,
    Chip,
    Table,
    Stack,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom';

import { enumFunctions } from 'utils'
import { useGetSelfUserQuery } from 'context'
import { adminRoutes } from '../routes/routes';
import { CandidateReferenceStatusEnums, OzgelecekFieldEnum } from 'types'
import { CustomTableHeader, DashboardPanelCard, ICustomTableHeadCell, ShowCountCard, TableEmptyData, TableLoading, } from 'components'

import {
    IAdminCompaniesItem,
    IAdminOzgeleceksItem,
    IAdminReferencesItem,
    useGetAdminCompaniesQuery,
    useGetAdminOzgeleceksQuery,
    useGetAdminReferencesQuery,
} from '../context'
import { useGetManagementStatisticsQuery } from 'apps/management';




export const HomePage = () => {

    const { data: userInfo } = useGetSelfUserQuery("")
    const { data: companies, isLoading: companiesLoading } = useGetAdminCompaniesQuery({ pagination: { page: 1, pageSize: 3, sort: null } })
    const { data: ozgeleceks, isLoading: ozgeleceksLoading } = useGetAdminOzgeleceksQuery({ pagination: { page: 1, pageSize: 3 } })
    const { data: orderStatistics, isLoading: orderStatisticsLoading } = useGetManagementStatisticsQuery({ pagination: { page: 1, pageSize: 3 } })




    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Hoşgeldin, {userInfo?.personalInformation?.name} {userInfo?.personalInformation?.surname}!
            </Typography>

            <Grid container spacing={3}>

                <Grid item xs={12} lg={6}>
                    <ShowCountCard
                        text='Toplam Şirket Sayısı'
                        iconfyIcon="mdi:company"
                        count={companies?.count}
                        loading={companiesLoading}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <ShowCountCard
                        text='Toplam Özgelecek Sayısı'
                        iconfyIcon="pepicons-pencil:cv"
                        count={ozgeleceks?.count}
                        loading={ozgeleceksLoading}
                    />
                </Grid>

                <Grid item xs={12} lg={4}>
                    <DashboardPanelCard
                        title='Aylık'
                        list={[
                            { name: "Yeni Kullanıcı Sayısı", value: orderStatistics?.thisMonth?.uniqueUserCount ?? "" },
                            { name: "Sipariş Sayısı", value: orderStatistics?.thisMonth?.orderCount ?? "" },
                            { name: "Satış Tutarı", value: orderStatistics?.thisMonth?.salesAmount ?? "" },
                        ]}
                        loading={orderStatisticsLoading}
                    />
                </Grid>

                <Grid item xs={12} lg={4}>
                    <DashboardPanelCard
                        title='Haftalık'
                        list={[
                            { name: "Yeni Kullanıcı Sayısı", value: orderStatistics?.thisWeek?.uniqueUserCount ?? "" },
                            { name: "Sipariş Sayısı", value: orderStatistics?.thisWeek?.orderCount ?? "" },
                            { name: "Satış Tutarı", value: orderStatistics?.thisWeek?.salesAmount ?? "" },
                        ]}
                        loading={orderStatisticsLoading}
                    />
                </Grid>

                <Grid item xs={12} lg={4}>
                    <DashboardPanelCard
                        title='Günlük'
                        list={[
                            { name: "Yeni Kullanıcı Sayısı", value: orderStatistics?.today?.uniqueUserCount ?? "" },
                            { name: "Sipariş Sayısı", value: orderStatistics?.today?.orderCount ?? "" },
                            { name: "Satış Tutarı", value: orderStatistics?.today?.salesAmount ?? "" },
                        ]}
                        loading={orderStatisticsLoading}
                    />
                </Grid>

            </Grid>

        </Stack>
    )
}



const CompaniesTable = ({ data, loading }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Şirket Adı / Logosu',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Şehir / Bölge',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Vergi No',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Vergi Dairesi',
        },
    ];


    return (
        <Card sx={{ p: 3, height: '100%' }}>

            <Stack direction="row" justifyContent="space-between" mb={3}>
                <Typography variant='h5'>
                    Son Şirketler
                </Typography>

                <Link to={adminRoutes.dashboard.companies} component={RouterLink}>
                    Hepsini Gör
                </Link>
            </Stack>

            <TableContainer>
                <Table>

                    <CustomTableHeader
                        headCells={headCells}
                        numSelected={0}
                        onSelectAllClick={() => null}
                        rowCount={data?.data?.length ?? 0}
                    />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IAdminCompaniesItem, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ cursor: 'pointer' }}
                                            >

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={row?.companyInformation?.profileImage}
                                                        >
                                                            {row?.companyInformation?.companyName?.slice(0, 1)}
                                                        </Avatar>
                                                        <Typography>
                                                            {row?.companyInformation?.companyName}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="left">
                                                    {row?.companyInformation?.city} - {row?.companyInformation?.district}
                                                </TableCell>

                                                <TableCell align="left">{row?.companyInformation?.taxNumber}</TableCell>

                                                <TableCell align="left">{row?.companyInformation?.taxOffice}</TableCell>

                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells?.length} text="Bu alanda hiç bir şirket bulunamadı" />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </Card>
    )
}


const AdvertisementsTable = ({ data, loading }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Şirket Adı / Logosu',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'İlan Adı',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Alan',
        },
        // {
        //     id: 'status',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Durumu',
        // },
    ];

    return (
        <Card sx={{ p: 3, height: '100%' }}>

            <Stack direction="row" justifyContent="space-between" mb={3}>
                <Typography variant='h5'>
                    Son İlanlar
                </Typography>

                <Link to={adminRoutes.dashboard.advertisements} component={RouterLink}>
                    Hepsini Gör
                </Link>
            </Stack>

            <TableContainer>
                <Table>

                    <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: any, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{
                                                    cursor: 'pointer',
                                                    '& *': {
                                                        whiteSpace: 'nowrap'
                                                    },
                                                }}
                                            >

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={row?.createdBy?.companyInformation?.profileImage}
                                                        >
                                                            {row?.createdBy?.companyInformation?.companyName?.slice(0, 1)}
                                                        </Avatar>

                                                        <Typography>
                                                            {row?.createdBy?.companyInformation?.companyName} &nbsp;
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="left">{row?.title}</TableCell>

                                                <TableCell align="left">
                                                    {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, row?.field)}
                                                </TableCell>

                                                {/* <TableCell align="left">
                                                    <Chip
                                                        label={enumFunctions.findAndDisplayName(AdvertisementStatusEnum, row?.status)}
                                                        color={
                                                            row?.status === "onboardingFirstCompleted" ? "info"
                                                                : row?.status === "onboardingSecondCompleted" ? "info"
                                                                    : row?.status === "onboardingThirdCompleted" ? "info"
                                                                        : row?.status === "onboardingForthCompleted" ? "info"
                                                                            : row?.status === "pendingApproval" ? "secondary"
                                                                                : row?.status === "approved" ? "success"
                                                                                    : row?.status === "revision" ? "warning"
                                                                                        : row?.status === "rejected" ? "error"
                                                                                            : "info"
                                                        }
                                                        variant="outlined"
                                                        style={{ borderRadius: 100 }}
                                                    />
                                                </TableCell> */}

                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells?.length} text="Bu alanda hiç bir başvuru bulunamadı" />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </Card>
    )
}


const OzgeleceksTable = ({ data, loading }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Aday Adı / Resmi',
        },

        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Alan',
        },

        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Adresi',
        },

        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },
    ];


    return (
        <Card sx={{ p: 3, height: '100%' }}>

            <Stack direction="row" justifyContent="space-between" mb={3}>
                <Typography variant='h5'>
                    Son Özgelecekler
                </Typography>

                <Link to={adminRoutes.dashboard.ozgeleceks} component={RouterLink}>
                    Hepsini Gör
                </Link>
            </Stack>

            <TableContainer>
                <Table>

                    <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IAdminOzgeleceksItem, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ cursor: 'pointer' }}
                                            >

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={row?.candidate?.personalInformation?.profileImageUrl}
                                                        >
                                                            {row?.candidate?.personalInformation?.name?.slice(0, 1)}
                                                        </Avatar>
                                                        <Stack direction="column">
                                                            <Typography>
                                                                {row?.candidate?.personalInformation?.name} &nbsp;
                                                                {row?.candidate?.personalInformation?.surname}
                                                            </Typography>
                                                            <Typography
                                                                variant='body2'
                                                                sx={{
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    display: 'block',
                                                                    maxWidth: '250px',
                                                                }}
                                                            >
                                                                {row?.candidate?.personalInformation?.description} &nbsp;
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="left">
                                                    {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, row?.field)}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {row?.candidate?.personalInformation?.city}
                                                </TableCell>


                                                <TableCell align="left">
                                                    <Chip
                                                        label={row?.candidate?.candidateOnboardingStatus === "completed" ? "Tamamlandı" : "Tamamlanmadı"}
                                                        color={
                                                            row?.candidate?.candidateOnboardingStatus === "completed" ? "success"
                                                                : row?.candidate?.candidateOnboardingStatus === "notCompleted" ? "warning"
                                                                    : "info"
                                                        }
                                                        variant="outlined"
                                                        style={{ borderRadius: 100 }}
                                                    />
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells?.length} text="Bu alanda hiç bir başvuru bulunamadı" />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>

        </Card>
    )
}


const ReferencesTable = ({ data, loading }: any) => {

    const headCells: ICustomTableHeadCell[] = [

        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Referans Adı',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Meslek',
        },
        {
            id: 'phone',
            numeric: false,
            disablePadding: false,
            label: 'Telefon',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },

    ];


    return (
        <Card sx={{ p: 3, height: '100%' }}>

            <Stack direction="row" justifyContent="space-between" mb={3}>
                <Typography variant='h5'>
                    Son Referanslar
                </Typography>

                <Link to={adminRoutes.dashboard.references} component={RouterLink}>
                    Hepsini Gör
                </Link>
            </Stack>

            <TableContainer>
                <Table>

                    <CustomTableHeader
                        headCells={headCells}
                        rowCount={data?.data?.length ?? 0}
                    />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IAdminReferencesItem, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ cursor: 'pointer' }}
                                            >



                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Typography>
                                                        {row?.referenceInformation?.name} &nbsp;
                                                        {row?.referenceInformation?.surname}
                                                    </Typography>
                                                </TableCell>



                                                <TableCell align="left">{row?.referenceInformation?.email}</TableCell>
                                                <TableCell align="left">{row?.referenceInformation?.jobTitle}</TableCell>
                                                <TableCell align="left">{row?.referenceInformation?.phoneNumber}</TableCell>

                                                <TableCell align="left">
                                                    <Chip
                                                        label={enumFunctions.findAndDisplayName(CandidateReferenceStatusEnums, row?.referenceStatus)}
                                                        color={
                                                            row?.referenceStatus === "pending" ? "warning"
                                                                : row?.referenceStatus === "informationApproved" ? "info"
                                                                    : row?.referenceStatus === "informationRejected" ? "error"
                                                                        : row?.referenceStatus === "referenceApproved" ? "success"
                                                                            : row?.referenceStatus === "referenceRejected" ? "error"
                                                                                : "info"
                                                        }
                                                        variant="outlined"
                                                        style={{ borderRadius: 100 }}
                                                    />
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells?.length} text="Bu alanda hiç bir şirket bulunamadı" />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>

        </Card>
    )
}