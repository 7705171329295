
export interface IUserCreateNoteToSelfResponse {
    _id: string;
}

export interface IUserCreateNoteToSelfVariables {
    noteInput: {
        content: string,
        title: string,
    }
}

export const user_createNoteToSelf = (variables: IUserCreateNoteToSelfVariables) => {
    console.log({ variables });

    const data = JSON.stringify({
        query: `
        mutation User_createNoteToSelf($noteInput: NoteToSelfInput!) {
            user_createNoteToSelf(noteInput: $noteInput) {
              _id
            }
          }
        `,
        variables: {
            "noteInput": {
                "content": variables?.noteInput?.content ?? null,
                "title": variables?.noteInput?.title ?? null,
            }
        }

    })

    return data

}