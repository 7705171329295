





export interface IUserNotesToSelfItem {
    _id: string
    content: string
    createdAt: string
    targetType: string
    title: string
    updatedAt: string
}

export interface IUserNotesToSelfResponse {
    count: number
    data: Array<IUserNotesToSelfItem>
}


export interface IUserNotesToSelfVariables {
    pagination?: {
        page: number,
        pageSize: number,
        sort?: string
    }
}


export const user_notesToSelf = (variables: IUserNotesToSelfVariables) => {

    const data = JSON.stringify({
        query: `
        query User_notesToSelf($pagination: Pagination) {
            user_notesToSelf(pagination: $pagination) {
              count
              data {
                _id
                content
                createdAt
                targetType
                title
                updatedAt
              }
            }
          }     
        `,
        variables: {
            "pagination": {
                "page": null,
                "pageSize": null,
                "sort": null
            }
        }
    })

    return data

}