import { CompanyConversionNumberOfEmployeesEnum } from "types";

export interface ISpecialistUpdateCompanyResponse {
  _id: string;
}

export interface ISpecialistUpdateCompanyVariables {
  companyId: string,
  updateCompanyInput?: {
    companyInformation?: {
      address?: string,
      city?: string,
      companyAddress?: string,
      companyBrief?: string,
      companyDocuments?: {
        imzaSirkuleri: string,
        vergiLevhasi: string
      },
      companyName?: string,
      district?: string,
      email?: string,
      facebook?: string,
      foundationYear?: string,
      instagram?: string,
      linkedin?: string,
      numberOfEmployees?: string,
      phoneNumber?: string,
      profileImage?: string,
      sector?: string,
      taxNumber?: string,
      taxOffice?: string,
      website?: string
    },
    personalInformation?: {
      description?: string,
      name?: string,
      phoneNumber?: string,
      surname?: string
    }
  }
}

export const specialist_updateCompany = (
  variables: ISpecialistUpdateCompanyVariables
) => {
  const data = JSON.stringify({
    query: `
        mutation Specialist_updateCompany($companyId: ID!, $updateCompanyInput: UpdateCompanyInput!) {
          specialist_updateCompany(companyId: $companyId, updateCompanyInput: $updateCompanyInput) {
            _id
          }
        }
        `,
    variables:  {
      companyId: variables.companyId,
      updateCompanyInput: {
        companyInformation: {
          address: variables?.updateCompanyInput?.companyInformation?.address,
          city: variables?.updateCompanyInput?.companyInformation?.city,
          companyAddress: variables?.updateCompanyInput?.companyInformation?.companyAddress,
          companyBrief: variables?.updateCompanyInput?.companyInformation?.companyBrief,
          companyDocuments: {
            imzaSirkuleri: variables?.updateCompanyInput?.companyInformation?.companyDocuments?.imzaSirkuleri,
            vergiLevhasi: variables?.updateCompanyInput?.companyInformation?.companyDocuments?.vergiLevhasi
          },
          companyName: variables?.updateCompanyInput?.companyInformation?.companyName,
          district: variables?.updateCompanyInput?.companyInformation?.district,
          email: variables?.updateCompanyInput?.companyInformation?.email,
          facebook: variables?.updateCompanyInput?.companyInformation?.facebook,
          foundationYear: variables?.updateCompanyInput?.companyInformation?.foundationYear,
          instagram: variables?.updateCompanyInput?.companyInformation?.instagram,
          linkedin: variables?.updateCompanyInput?.companyInformation?.linkedin,
          numberOfEmployees: variables?.updateCompanyInput?.companyInformation?.numberOfEmployees,
          phoneNumber: variables?.updateCompanyInput?.companyInformation?.phoneNumber,
          profileImage: variables?.updateCompanyInput?.companyInformation?.profileImage,
          sector: variables?.updateCompanyInput?.companyInformation?.sector,
          taxNumber: variables?.updateCompanyInput?.companyInformation?.taxNumber,
          taxOffice: variables?.updateCompanyInput?.companyInformation?.taxOffice,
          website: variables?.updateCompanyInput?.companyInformation?.website
        },
        personalInformation: {
          description: variables?.updateCompanyInput?.personalInformation?.description,
          name: variables?.updateCompanyInput?.personalInformation?.name,
          phoneNumber: variables?.updateCompanyInput?.personalInformation?.phoneNumber,
          surname: variables?.updateCompanyInput?.personalInformation?.surname
        }
      }
    },
  });

  return data;
};
