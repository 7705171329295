import axios from 'axios';
import * as Yup from 'yup';
// import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, Typography, InputAdornment } from '@mui/material';

import { routes } from 'routes';
import { loginReducer, useAppDispatch } from 'context';
import { useBoolean } from 'minimal/hooks/use-boolean';
import { RHFTextField, FormProvider, Iconify } from 'components';



const LoginSchema = Yup.object().shape({
  email: Yup.string().required('Email gerekli!').email('Geçerli bir email adresi giriniz!'),
  password: Yup.string()
    // .matches(/(?=.*[!'^+%.,/()&=?\-_*])/, "En az bir özel karakter içermeli!")
    // .matches(/(?=.*[a-z])/, "En az bir küçük harf içermeli!")
    // .matches(/(?=.*[A-Z])/, "En az bir büyük harf içermeli!")
    // .matches(/(?=.*[0-9])/, "En az bir rakam içermeli!")
    .min(4, "En az 4 karakter olmalı!")
    .required("Gerekli"),
});


export const LoginContainer = () => {

  const password = useBoolean()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()


  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'all',
  });

  const { handleSubmit, formState: { isSubmitting } } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/auth/auth/login`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(data)
      };

      const response = await axios.request(config)

      console.log({ response });

      if (response?.data?.isSuccess) {
        enqueueSnackbar("Giriş başarılı!", { variant: "success" })
        dispatch(loginReducer(response.data))
      } else {
        enqueueSnackbar("Email onayı gerekiyor!", {
          variant: "info"
        })
      }


    } catch (error: any) {
      console.log({ error }, 'errorrrrr');

      if (error?.response?.data?.message === "RegistrationConfirmationIsRequired") {
        enqueueSnackbar("Email onayı gerekiyor!", {
          variant: "warning"
        })
        setTimeout(() => {
          navigate(routes.auth.registerVerfiy)
        }, 1000)
      } else if (error?.response?.data?.message === "UserIsPassive") {
        enqueueSnackbar("Kullanıcı pasif durumda! Yönetici ile iletişime geçiniz.", {
          variant: "error"
        })
      } else if (error?.response?.data?.devMessage.startsWith("User not found with email:")) {
        enqueueSnackbar("Kullanıcı bulunamadı!", { variant: "warning" });
      } else if (error?.response?.data?.devMessage === "Invalid Password") {
        enqueueSnackbar("Yanlış şifre", { variant: "info" });
      } else {
        enqueueSnackbar("Birşeyler yanlış gitti!", {
          variant: "warning"
        })
      }

    }
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h1" textAlign={"center"}>Giriş Yap</Typography>
      <Stack direction="row" spacing={0.5}>
      </Stack>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      <RHFTextField name="email" label="Email adresi" />

      <RHFTextField
        name="password"
        label="Şifre"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Link
        component={RouterLink}
        to={routes.auth.forgotPassword}
        variant="body2"
        color="inherit"
        underline="always"
        sx={{ alignSelf: 'flex-end' }}
      >
        Şifremi Unuttum?
      </Link>

      <LoadingButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Giriş Yap
      </LoadingButton>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}
      {renderForm}
    </FormProvider>
  );
}
