


export interface IManagementUserResponse {
  _id: string
  contact: {
    email: string
    emailVerified: boolean
    phoneNumber: string
    phoneNumberVerified: boolean
  }
  createdAt: string
  personalInformation: {
    backgroundImageUrl: string
    birthDate: string
    city: string
    country: string
    description: string
    district: string
    name: string
    phoneNumber: string
    profileImageUrl: string
    surname: string
  }
  role: string
  settings: {
    defaultLanguage: string
    acceptedPolicies: Array<{
      name: string
      policy: {
        _id: string
      }
      version: string
    }>
  }
  updatedAt: string
}

export interface IManagementUserVariables { }

export const management_user = (variables: IManagementUserVariables) => {

  const data = JSON.stringify({
    query: `
        query management_user($userId: ID!) {
          management_user(userId: $userId) {
              _id
              contact {
                email
                emailVerified
                phoneNumber
                phoneNumberVerified
              }
              createdAt
              personalInformation {
                backgroundImageUrl
                birthDate
                city
                country
                description
                district
                name
                phoneNumber
                profileImageUrl
                surname
              }
              role
              settings {
                defaultLanguage
                acceptedPolicies {
                  name
                  policy {
                    _id
                  }
                  version
                }
              }
              updatedAt
            }
          }
        `,
    variables: {}
  })

  return data

}