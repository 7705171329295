import { Stack, Typography } from "@mui/material"
import { UserAccountSection } from "sections"

export const MyAccountPage = () => {

    return (
        <Stack spacing={3}>
            <Typography variant="h1">
                Kişisel Bilgiler
            </Typography>

            <UserAccountSection />
        </Stack>
    )
}
