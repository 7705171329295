
export interface IUserRemoveNoteResponse {
    success: boolean;
}

export interface IUserRemoveNoteVariables {
    noteId: string,
}

export const user_removeNote = (variables: IUserRemoveNoteVariables) => {

    console.log({ variables });
    const data = JSON.stringify({
        query: `mutation User_removeNote($noteId: ID!) {
            user_removeNote(noteId: $noteId) {
              success
            }
          }`,
        variables: {
            "noteId": variables?.noteId ?? null
        }

    })

    return data

}