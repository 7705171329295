

export interface ISpecialistRejectCompanyResponse {
    success: boolean
  }
  
  export interface ISpecialistRejectCompanyVariables {
    companyId: string
  }

export const specialist_rejectCompany = (variables: ISpecialistRejectCompanyVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Specialist_rejectCompany($companyId: ID!) {
          specialist_rejectCompany(companyId: $companyId) {
            success
          }
        }
          `,
        variables: {
            "companyId": variables.companyId
        }
    })

    return data

}