import {
    Card,
    Chip,
    Stack,
    Table,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
} from '@mui/material'
import moment from 'moment';
import { useState } from 'react'


import { enumFunctions } from 'utils'
import { CreditRequestSection } from 'sections';
import { SendCreditRequestStatusEnum } from 'types'
import { CustomTableHeader, ICustomTableHeadCell, TableEmptyData, TableLoading } from 'components'
import { IOperationSendCreditRequestsVariables, useGetOperationSendCreditRequestsQuery } from '../context';



export const CreditRequestsPage = () => {

    const [variables, setVariables] = useState<IOperationSendCreditRequestsVariables>({
        pagination: {
            page: 1,
            pageSize: 10,
        }
    })

    const { data, isLoading } = useGetOperationSendCreditRequestsQuery(variables)


    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }

    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Kredi İstekleri
            </Typography>

            <OperationRemindersTable
                data={data}
                pagination={variables?.pagination}
                loading={isLoading}
                handleChangePageSize={handleChangePageSize}
                handleChangePage={handleChangePage}
            />

        </Stack>
    )
}


const OperationRemindersTable = ({ data, pagination, loading, handleChangePageSize, handleChangePage }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Şirket Adı / Logosu',
        },
        {
            id: 'amount',
            numeric: false,
            disablePadding: false,
            label: 'Miktarı',
        },
        {
            id: 'desctiption',
            numeric: false,
            disablePadding: false,
            label: 'Ücreti',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Tarihi',
        },
        // {
        //     id: 'field',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Taglar',
        // },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },

        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Aksiyonlar',
        },
    ];


    return (
        <Card sx={{ p: 3 }}>

            <TableContainer>
                <Table>

                    <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: any, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ cursor: 'pointer' }}
                                            >

                                                <TableCell align='center'>
                                                    {index + 1 + ((pagination?.page - 1) * pagination?.pageSize)}
                                                </TableCell>

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={row?.receiver?.displayImageUrl}
                                                        >
                                                            {row?.receiver?.displayName?.slice(0, 1)}
                                                        </Avatar>
                                                        <Stack direction="column">
                                                            <Typography>
                                                                {row?.receiver?.displayName}
                                                            </Typography>
                                                            <Typography variant='body2'>
                                                                {row?.receiver?.role}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {row?.creditAmount}
                                                </TableCell>

                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {row?.price} {row?.currency}
                                                </TableCell>


                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {moment(row?.remindDate).format("DD MMM YYYY")}
                                                </TableCell>



                                                <TableCell align="left">
                                                    <Chip
                                                        label={enumFunctions.findAndDisplayName(SendCreditRequestStatusEnum, row?.status)}
                                                        color={
                                                            row?.status === "approved" ? "success"
                                                                : row?.status === "cancelled" ? "error"
                                                                    : row?.status === "pending" ? "warning"
                                                                        : row?.status === "rejected" ? "error"
                                                                            : "info"
                                                        }
                                                        variant="outlined"
                                                        style={{ borderRadius: 100 }}
                                                    />
                                                </TableCell>

                                                <TableCell align="left" >
                                                    <CreditRequestSection credit={row} />
                                                </TableCell>



                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData text="Hiç bir kredi talebi yok" colspan={headCells?.length} />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                count={data?.count ?? 0} // toplam eleman sayısı
                rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                labelRowsPerPage="Sayfa başına gösterim"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePageSize}
            />

        </Card>
    )
}
