import {
    Card,
    Chip,
    Stack,
    Table,
    Avatar,
    Button,
    Dialog,
    TableRow,
    TableBody,
    TableCell,
    TextField,
    Typography,
    IconButton,
    DialogContent,
    TableContainer,
    TablePagination,
    CircularProgress,
} from '@mui/material'

import moment from 'moment';
import { useState } from 'react'
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';

import { enumFunctions } from 'utils'
import { SendCreditRequestStatusEnum } from 'types'
import { CustomTableHeader, ICustomTableHeadCell, Iconify, MuiTransition, TableEmptyData, TableLoading } from 'components'
import { IAccounterSendCreditRequestsVariables, useAccounterApproveSendCreditRequestMutation, useAccounterRejectSendCreditRequestMutation, useGetAccounterSendCreditRequestsQuery } from '../context';
// import { ISpecialistSendCreditRequestsVariables, useGetSpecialistSendCreditRequestsQuery, useSpecialistApproveSendCreditRequestMutation, useSpecialistRejectSendCreditRequestMutation } from '../context';




export const CreditRequestsPage = () => {

    const [variables, setVariables] = useState<IAccounterSendCreditRequestsVariables>({
        pagination: {
            page: 1,
            pageSize: 10,
        }
    })

    const { data: creditrequests, isLoading: creditrequestsLoading } = useGetAccounterSendCreditRequestsQuery(variables)

    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }



    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Kredi İstekleri
            </Typography>

            <OperationRemindersTable
                data={creditrequests}
                pagination={variables?.pagination}
                loading={creditrequestsLoading}
                handleChangePageSize={handleChangePageSize}
                handleChangePage={handleChangePage}
            />

        </Stack>
    )
}


const OperationRemindersTable = ({ data, pagination, loading, handleChangePageSize, handleChangePage }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },

        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Operator Adı / Logosu',
        },

        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Şirket Adı / Logosu',
        },

        {
            id: 'desctiption',
            numeric: false,
            disablePadding: false,
            label: 'Miktarı',
        },
        {
            id: 'desctiption',
            numeric: false,
            disablePadding: false,
            label: 'Ücreti',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Tarihi',
        },
        // {
        //     id: 'field',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Taglar',
        // },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },

        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Aksiyonlar',
        },
    ];


    return (
        <Card sx={{ p: 3 }}>

            <TableContainer>
                <Table>

                    <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: any, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        // const userInfo = JSON.parse(row?.content ?? "{}")

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ cursor: 'pointer' }}
                                            >

                                                <TableCell align='center'>
                                                    {index + 1 + ((pagination?.page - 1) * pagination?.pageSize)}
                                                </TableCell>



                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={row?.sender?.displayImageUrl}
                                                        >
                                                            {row?.sender?.displayName?.slice(0, 1)}
                                                        </Avatar>
                                                        <Stack direction="column">
                                                            <Typography>
                                                                {row?.sender?.displayName}
                                                            </Typography>
                                                            {/* <Typography variant='body2'>
                                                                {row?.sender?.role}
                                                            </Typography> */}
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={row?.receiver?.displayImageUrl}
                                                        >
                                                            {row?.receiver?.displayName?.slice(0, 1)}
                                                        </Avatar>
                                                        <Stack direction="column">
                                                            <Typography>
                                                                {row?.receiver?.displayName}
                                                            </Typography>
                                                            {/* <Typography variant='body2'>
                                                                {row?.receiver?.role}
                                                            </Typography> */}
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>


                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {row?.creditAmount}
                                                </TableCell>

                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {row?.price} {row?.currency}
                                                </TableCell>


                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {moment(row?.remindDate).format("DD MMM YYYY")}
                                                </TableCell>



                                                <TableCell align="left">
                                                    <Chip
                                                        label={enumFunctions.findAndDisplayName(SendCreditRequestStatusEnum, row?.status)}
                                                        color={
                                                            row?.status === "approved" ? "success"
                                                                : row?.status === "cancelled" ? "error"
                                                                    : row?.status === "pending" ? "warning"
                                                                        : row?.status === "rejected" ? "error"
                                                                            : "info"
                                                        }
                                                        variant="outlined"
                                                        style={{ borderRadius: 100 }}
                                                    />
                                                </TableCell>

                                                <TableCell align="left" >
                                                    <SpecialCreditActionsSection user={row} />
                                                </TableCell>



                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData text='Hiçbir kredi isteği bulunamadı!' colspan={headCells?.length} />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                count={data?.count ?? 0} // toplam eleman sayısı
                rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                labelRowsPerPage="Sayfa başına gösterim"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePageSize}
            />

        </Card>
    )
}



interface ISpecialCreditActionsSectionProps {
    user: any,
}


const SpecialCreditActionsSection = ({ user }: ISpecialCreditActionsSectionProps) => {


    const [actionReason, setActionReason] = useState("")
    const [actionDialog, setActionDialog] = useState<"approve" | "reject" | boolean>(false)

    const [approveCredit, { isLoading: approveCreditLoading }] = useAccounterApproveSendCreditRequestMutation()
    const [rejectCredit, { isLoading: rejectCreditLoading }] = useAccounterRejectSendCreditRequestMutation()


    const handleSubmit = async () => {
        try {
            if (actionDialog === "approve") {
                const approveResponse: any = await approveCredit({
                    id: user?._id,
                    statusChangeReason: actionReason
                })
                console.log({ approveResponse });

                if (approveResponse?.data) {
                    enqueueSnackbar("Onaylandı!")
                    setActionDialog(false)
                } else {
                    enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
                }
            } else {

                const rejectResponse: any = await rejectCredit({
                    id: user?._id,
                    statusChangeReason: actionReason
                })
                console.log({ rejectResponse });

                if (rejectResponse?.data) {
                    enqueueSnackbar("Onaylandı!")
                    setActionDialog(false)
                } else {
                    enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
                }
            }



        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }

    // const generalLoading = approveCreditLoading || rejectCreditLoading

    return (
        <>

            <Stack direction="row" spacing={1}>

                <IconButton
                    sx={{ bgcolor: user?.status !== "pending" ? 'gray' : 'secondary.light' }}
                    onClick={() => setActionDialog("approve")}
                    disabled={user?.status !== "pending"}
                >
                    <Iconify icon="mdi:success-bold" sx={{ color: user?.status !== "pending" ? 'gray' : 'suprimaryccess.main' }} />
                </IconButton>

                <IconButton
                    sx={{ bgcolor: user?.status !== "pending" ? 'gray' : 'secondary.light' }}
                    onClick={() => setActionDialog("reject")}
                    disabled={user?.status !== "pending"}
                >
                    <Iconify icon="material-symbols:delete" sx={{ color: user?.status !== "pending" ? 'gray' : 'primary.main' }} />
                </IconButton>

            </Stack>


            <Dialog
                open={!!actionDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setActionDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Stack
                        py={3}
                        spacing={3}
                        minWidth={{ xs: "100%", sm: '400px' }}
                    >

                        <Typography variant='h3'>
                            Teklifi  {actionDialog === "approve" ? "Onayla" : "Reddet"}
                        </Typography>

                        <TextField
                            multiline
                            minRows={3}
                            label={actionDialog === "approve" ? "Onaylama Sebebi" : "Reddetme Sebebi"}
                            value={actionReason}
                            onChange={(e) => setActionReason(e?.target?.value)}
                        />

                        <Stack direction="row" justifyContent="end" gap={3}>
                            <Button
                                onClick={() => setActionDialog(false)}
                                variant='outlined'
                                color="primary"
                            >
                                Kapat
                            </Button>

                            <LoadingButton
                                variant='contained'
                                color="primary"
                                onClick={() => handleSubmit()}
                            // loading={generalLoading}
                            // disabled={generalLoading || user?.status !== "pending"}
                            >
                                {actionDialog === "approve" ? "Onayla" : "Reddet"}
                            </LoadingButton>
                        </Stack>


                    </Stack>
                </DialogContent>
            </Dialog>


            {/* <Dialog
                open={!!removeDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setRemoveDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    {
                        user ? (
                            <Stack spacing={3} my={3}>
                                <Typography variant='h3'>
                                    {removeDialog === "finish" ? "Hatırlatıcı Tamamla" : "Hatırlatıcı Sil"}
                                </Typography>

                                <Typography >
                                    {
                                        removeDialog === "finish"
                                            ? "Hatırlatıcının yapıldığına emin misiniz?"
                                            : "Hatırlatıcıyı silmek istediğinize emin misiniz?"
                                    }
                                </Typography>

                                <Stack direction="row" justifyContent="end" gap={3}>
                                    <Button
                                        onClick={() => setRemoveDialog(false)}
                                        variant='outlined'
                                        color="primary"
                                    >
                                        Kapat
                                    </Button>

                                    <LoadingButton
                                        variant='contained'
                                        color="primary"
                                    // onClick={() => handleRemove()}
                                    // loading={removeLoading}
                                    >
                                        {removeDialog === "finish" ? "Tamamla" : "Sil"}
                                    </LoadingButton>
                                </Stack>

                            </Stack>
                        ) : (
                            <CircularProgress color="primary" />
                        )
                    }
                </DialogContent>
            </Dialog> */}

        </>
    )
}