import { IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { specialistRoutes } from '../routes'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import { VisitorFormCreateAndUpdate } from 'sections'
import { useGetSpecialistCompaniesQuery } from '../context'

export const VisitorFormCreatePage = () => {

    const navigate = useNavigate()
    const { data: companies } = useGetSpecialistCompaniesQuery({ pagination: {} })

    return (
        <Stack spacing={3}>

            <Stack direction="row" gap={1} alignItems="center">

                <IconButton onClick={() => navigate(specialistRoutes.dashboard.visitorsForms)} >
                    <ArrowBack />
                </IconButton>

                <Typography variant='h1'>
                    Ziyaretçi Formu Oluştur
                </Typography>

            </Stack>

            <VisitorFormCreateAndUpdate
                companies={
                    companies?.data?.map(item => ({
                        name: item?.companyInformation?.companyName ?? item?._id,
                        value: item?._id
                    })) ?? []
                }
            />


        </Stack>
    )
}
