import {
    Card,
    Chip,
    Grid,
    Link,
    Stack,
    Table,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    CircularProgress,
} from '@mui/material'
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

import { enumFunctions } from 'utils';
import { ReminderStatusEnum } from 'types';
import { operationRoutes } from '../routes';
import { IUserRemindersItem, useGetSelfUserQuery } from 'context';
import { CustomTableHeader, ICustomTableHeadCell, ShowCountCard } from 'components'
import { IOperationCompaniesItem, useGetOperationCompaniesQuery, useGetOperationSendCreditRequestsQuery } from '../context';



export const HomePage = () => {

    const { data: userInfo } = useGetSelfUserQuery({})
    const { data: companies, isLoading: companiesLoading } = useGetOperationCompaniesQuery({ pagination: { page: 1, pageSize: 3, sort: null } })
    const { data: creditRequests, isLoading: creditRequestsLoading } = useGetOperationSendCreditRequestsQuery({ pagination: { page: 1, pageSize: 3 } })


    return (
        <Grid container spacing={3}>

            <Grid item xs={12}>
                <Typography variant='h3'>
                    Hoşgeldin, {userInfo?.personalInformation?.name ?? ""} {userInfo?.personalInformation?.surname ?? ""}
                </Typography>
            </Grid>

            <Grid item xs={12} lg={6}>
                <ShowCountCard
                    text='Toplam Şirket Sayısı'
                    iconfyIcon="ion:business-sharp"
                    count={companies?.count}
                    loading={companiesLoading}
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <ShowCountCard
                    text='Toplam Kredi İsteği Sayısı'
                    iconfyIcon="wpf:bank-cards"
                    count={creditRequests?.count}
                    loading={creditRequestsLoading}
                />
            </Grid>

        </Grid>
    )
}




const OperationCompaniesTable = ({ data, loading }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Şirket Adı / Logosu',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Şehir / Bölge',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Vergi No',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Vergi Dairesi',
        },

    ];

    return (
        <Card sx={{ p: 3, height: '100%' }}>

            <Stack direction="row" justifyContent="space-between" mb={3}>
                <Typography variant='h5'>
                    Son Şirketler
                </Typography>

                <Link to={operationRoutes.dashboard.companies} component={RouterLink}>
                    Hepsini Gör
                </Link>
            </Stack>

            <TableContainer>
                <Table>

                    <CustomTableHeader
                        headCells={headCells}
                        numSelected={0}
                        onSelectAllClick={() => null}
                        rowCount={data?.data?.length ?? 0}
                    />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IOperationCompaniesItem, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ cursor: 'pointer' }}
                                            >

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={row?.companyInformation?.profileImage}
                                                        >
                                                            {row?.companyInformation?.companyName?.slice(0, 1)}
                                                        </Avatar>
                                                        <Stack direction="column">
                                                            <Typography>
                                                                {row?.companyInformation?.companyName}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="left">
                                                    {row?.companyInformation?.city} - {row?.companyInformation?.district}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {row?.companyInformation?.taxNumber}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {row?.companyInformation?.taxOffice}
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <Stack
                                                justifyContent="center"
                                                alignItems="center"
                                                py={5}
                                                fontSize="32px"
                                            >
                                                Bu alanda hiçbir şirket yok!.
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <Stack
                                            justifyContent="center"
                                            alignItems="center"
                                            py={5}
                                            fontSize="32px"
                                        >
                                            <CircularProgress color='primary' />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>

        </Card>
    )
}


const OperationRemindersTable = ({ data, loading }: any) => {

    const headCells: ICustomTableHeadCell[] = [

        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Şirket Adı / Logosu',
        },
        {
            id: 'desctiption',
            numeric: false,
            disablePadding: false,
            label: 'Açıklama',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Tarihi',
        },
        // {
        //     id: 'field',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Taglar',
        // },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },

    ];


    return (
        <Card sx={{ p: 3, height: '100%' }}>

            <Stack direction="row" justifyContent="space-between" mb={3}>
                <Typography variant='h5'>
                    Son Hatırlatmalar
                </Typography>

                <Link to={operationRoutes.dashboard.reminders} component={RouterLink}>
                    Hepsini Gör
                </Link>
            </Stack>

            <TableContainer>
                <Table>

                    <CustomTableHeader
                        headCells={headCells}
                        numSelected={0}
                        onSelectAllClick={() => null}
                        rowCount={data?.data?.length ?? 0}
                    />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IUserRemindersItem, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        const userInfo = JSON.parse(row?.content)

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ cursor: 'pointer' }}
                                            >

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={userInfo?.profileImage}
                                                        >
                                                            {userInfo?.companyName?.slice(0, 1)}
                                                        </Avatar>
                                                        <Stack direction="column">
                                                            <Typography>
                                                                {userInfo?.companyName}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {row?.description?.slice(0, 20)}
                                                </TableCell>


                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {moment(row?.remindDate).format("DD MMM YYYY")}
                                                </TableCell>

                                                {/* <TableCell align="left">
                                                    <Stack direction="row" gap="5px">
                                                        {
                                                            row?.tags?.map((tag, index) => {
                                                                return (<Chip label={tag} key={index} variant='outlined' color='primary' />)
                                                            })
                                                        }
                                                    </Stack>
                                                </TableCell> */}

                                                <TableCell align="left">
                                                    <Chip
                                                        label={enumFunctions.findAndDisplayName(ReminderStatusEnum, row?.status)}
                                                        color={
                                                            row?.status === "completed" ? "info"
                                                                : row?.status === "expired" ? "error"
                                                                    : row?.status === "ongoing" ? "success"
                                                                        : "info"
                                                        }
                                                        variant="outlined"
                                                        style={{ borderRadius: 100 }}
                                                    />
                                                    {/* {row?.status} */}
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <Stack
                                                justifyContent="center"
                                                alignItems="center"
                                                py={5}
                                                fontSize="32px"
                                            >
                                                Hiçbir hatırlatıcı bulunamadı!.
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <Stack
                                            justifyContent="center"
                                            alignItems="center"
                                            py={5}
                                            fontSize="32px"
                                        >
                                            <CircularProgress color='primary' />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>

        </Card>
    )
}
