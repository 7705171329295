import { CreateReminderSection, RemindersTable } from 'sections';
import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from '@mui/material'
import { CustomTableHeader, ICustomTableHeadCell, TableEmptyData, TableLoading } from 'components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { adminRoutes } from 'apps/admin/routes/routes';
import { IAnonymousBlogPostsItem, IAnonymousBlogPostsVariables, useGetAnonymoustBlogPostsQuery, useManagementRemoveBlogPostMutation } from '../context/blog';
import { enqueueSnackbar } from 'notistack';

export const BlogPage = () => {

    const navigate = useNavigate()
    const [selectedBlog, setSelectedBlog] = useState<IAnonymousBlogPostsItem | null>(null)
    const [deleteBlog, { isSuccess, isError, isLoading, data: dataRemove, error }] = useManagementRemoveBlogPostMutation()

    const [variables, setVariables] = useState<IAnonymousBlogPostsVariables>({
        pagination: {
            page: 1,
            pageSize: 10,
        },
        filter: {
            targetCategory: null,
            category: null,
            language: null
        }
    })
    const { data, isLoading: loading, refetch } = useGetAnonymoustBlogPostsQuery(variables)

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Başlık',
        },
        {
            id: 'categories',
            numeric: false,
            disablePadding: false,
            label: 'Kategori'
        },
        {
            id: 'targetCategories',
            numeric: false,
            disablePadding: false,
            label: 'Hedef Kitle'
        },
        {
            id: 'createdAt',
            numeric: false,
            disablePadding: false,
            label: 'Oluşturulma Tarihi',
        },

        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Aksiyonlar',
        },
    ];

    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }

    const handleCreate = () => {
        navigate(adminRoutes.dashboard.createBlog)
    }

    const handleDeleteBlog = () => {
        deleteBlog({ blogPostId: selectedBlog?._id ?? "" })
    }

    useEffect(() => {
        if (isSuccess) {
            setVariables((prevState) => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    page: 1
                }
            }))
            setSelectedBlog(null)
            enqueueSnackbar("Blog başarıyla silindi", { variant: "success" })
        }
        if (isError) {
            // todo
            // enqueueSnackbar("Blog silinirken bir hata oluştu", { variant: "error" })

            enqueueSnackbar("Blog başarıyla silindi", { variant: "success" })
            setSelectedBlog(null)
            refetch()
        }
    }, [isSuccess, isError])

    return (
        <Stack spacing={3}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='h1'>
                    Blog
                </Typography>

                <Button onClick={handleCreate} variant='contained' color="primary">
                    Blog Oluştur
                </Button>
            </Stack>

            <TableContainer>
                <Table>

                    <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IAnonymousBlogPostsItem, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ cursor: 'pointer' }}
                                            >

                                                <TableCell>
                                                    {index + 1 + ((variables?.pagination?.page - 1) * variables?.pagination?.pageSize)}
                                                </TableCell>

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Typography variant='body1'>
                                                        {row?.title}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {row?.categories?.join(", ")}
                                                </TableCell>


                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {row?.targetCategories?.join(", ").replace("jobSeeker", "İş Arayan").replace("worker", "Çalışan").replace("company", "Şirket").replace("universityStudent", "Üniversite Öğrencisi")}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {moment(row?.createdAt).format("DD.MM.YYYY")}
                                                </TableCell>


                                                <TableCell align="left" >
                                                    <Stack direction='row' spacing={1}>
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            onClick={() => navigate(adminRoutes.dashboard.updateBlog.replace(":id", row?.slug ?? ""))}
                                                        >
                                                            Düzenle
                                                        </Button>
                                                        <Button
                                                            variant='contained'
                                                            color='error'
                                                            onClick={() => setSelectedBlog(row)}
                                                        >
                                                            Sil
                                                        </Button>
                                                    </Stack>
                                                </TableCell>



                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells?.length} text='Hiçbir hatırlatıcı bulunamadı!' />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                count={data?.count ?? 0} // toplam eleman sayısı
                rowsPerPage={variables?.pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                page={(variables?.pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                labelRowsPerPage="Sayfa başına gösterim"
                onPageChange={(event: any, page) => handleChangePage(event, page)}
                onRowsPerPageChange={handleChangePageSize}
            />

            <Dialog open={!!selectedBlog} onClose={() => setSelectedBlog(null)}>
                <DialogTitle>
                    Blog Sil
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography variant='body1'>
                            {selectedBlog?.title} başlıklı blogu silmek istediğinize emin misiniz?
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSelectedBlog(null)} color="primary">
                        İptal
                    </Button>
                    <Button variant='contained' onClick={handleDeleteBlog} color="primary">
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}



