import {
    Card,
    Link,
    Grid,
    Chip,
    Table,
    Stack,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
} from '@mui/material'
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

import { accountRoutes } from '../routes';
import { useGetSelfUserQuery } from 'context'
import { useGetManagementStatisticsQuery } from 'apps/management';
import { IAccounterCompaniesItem, useGetAccounterCompaniesQuery, useGetAccounterOrdersQuery } from '../context';
import { CustomTableHeader, DashboardPanelCard, ICustomTableHeadCell, ShowCountCard, TableEmptyData, TableLoading, } from 'components'



export const HomePage = () => {

    const { data: userInfo } = useGetSelfUserQuery("")
    const { data: orders, isLoading: ordersLoading } = useGetAccounterOrdersQuery({ pagination: { page: 1, pageSize: 3 } })
    const { data: companies, isLoading: companiesLoading } = useGetAccounterCompaniesQuery({ pagination: { page: 1, pageSize: 3, sort: null } })
    const { data: orderStatistics, isLoading: orderStatisticsLoading } = useGetManagementStatisticsQuery({ pagination: { page: 1, pageSize: 3 } })

    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Hoşgeldin, {userInfo?.personalInformation?.name} {userInfo?.personalInformation?.surname}!
            </Typography>

            <Grid container spacing={3}>

                <Grid item xs={12} lg={6}>
                    <ShowCountCard
                        text='Toplam Şirket Sayısı'
                        iconfyIcon="mdi:company"
                        count={companies?.count}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <ShowCountCard
                        text='Toplam Sipariş Sayısı'
                        iconfyIcon="icon-park-outline:transaction-order"
                        count={orders?.count}
                    />
                </Grid>

                <Grid item xs={12} lg={4}>
                    <DashboardPanelCard
                        title='Aylık'
                        list={[
                            { name: "Yeni Kullanıcı Sayısı", value: orderStatistics?.thisMonth?.uniqueUserCount ?? "" },
                            { name: "Sipariş Sayısı", value: orderStatistics?.thisMonth?.orderCount ?? "" },
                            { name: "Satış Tutarı", value: orderStatistics?.thisMonth?.salesAmount ?? "" },
                        ]}
                        loading={orderStatisticsLoading}
                    />
                </Grid>

                <Grid item xs={12} lg={4}>
                    <DashboardPanelCard
                        title='Haftalık'
                        list={[
                            { name: "Yeni Kullanıcı Sayısı", value: orderStatistics?.thisWeek?.uniqueUserCount ?? "" },
                            { name: "Sipariş Sayısı", value: orderStatistics?.thisWeek?.orderCount ?? "" },
                            { name: "Satış Tutarı", value: orderStatistics?.thisWeek?.salesAmount ?? "" },
                        ]}
                        loading={orderStatisticsLoading}
                    />
                </Grid>

                <Grid item xs={12} lg={4}>
                    <DashboardPanelCard
                        title='Günlük'
                        list={[
                            { name: "Yeni Kullanıcı Sayısı", value: orderStatistics?.today?.uniqueUserCount ?? "" },
                            { name: "Sipariş Sayısı", value: orderStatistics?.today?.orderCount ?? "" },
                            { name: "Satış Tutarı", value: orderStatistics?.today?.salesAmount ?? "" },
                        ]}
                        loading={orderStatisticsLoading}
                    />
                </Grid>


            </Grid>


        </Stack>
    )
}



const CompaniesTable = ({ data, loading }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Şirket Adı / Logosu',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Şehir / Bölge',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Vergi No',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Vergi Dairesi',
        },
    ];


    return (
        <Card sx={{ p: 3, height: '100%' }}>

            <Stack direction="row" justifyContent="space-between" mb={3}>
                <Typography variant='h5'>
                    Son Şirketler
                </Typography>

                <Link to={accountRoutes.dashboard.companies} component={RouterLink}>
                    Hepsini Gör
                </Link>
            </Stack>

            <TableContainer>
                <Table>

                    <CustomTableHeader
                        headCells={headCells}
                        numSelected={0}
                        onSelectAllClick={() => null}
                        rowCount={data?.data?.length ?? 0}
                    />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IAccounterCompaniesItem, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ cursor: 'pointer' }}
                                            >

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={row?.companyInformation?.profileImage}
                                                        >
                                                            {row?.companyInformation?.companyName?.slice(0, 1)}
                                                        </Avatar>
                                                        <Typography>
                                                            {row?.companyInformation?.companyName}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="left">
                                                    {row?.companyInformation?.city} - {row?.companyInformation?.district}
                                                </TableCell>

                                                <TableCell align="left">{row?.companyInformation?.taxNumber}</TableCell>

                                                <TableCell align="left">{row?.companyInformation?.taxOffice}</TableCell>

                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells?.length} text="Bu alanda hiç bir şirket bulunamadı" />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </Card>
    )
}


const OrdersTable = ({ data, loading }: any) => {

    const headCells: ICustomTableHeadCell[] = [

        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Sipariş Sahibi Adı / Resmi',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Paket İsmi',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Miktarı - Fiyatı',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Tarihi',
        },

    ];


    return (
        <Card sx={{ p: 3, height: '100%' }}>

            <Stack direction="row" justifyContent="space-between" mb={3}>
                <Typography variant='h5'>
                    Son İlanlar
                </Typography>

                <Link to={accountRoutes.dashboard.orders} component={RouterLink}>
                    Hepsini Gör
                </Link>
            </Stack>

            <TableContainer>
                <Table>

                    <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: any, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{
                                                    cursor: 'pointer',
                                                    '& *': {
                                                        whiteSpace: 'nowrap'
                                                    },
                                                }}
                                            >

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={row?.createdBy?.displayImageUrl}
                                                        >
                                                            {row?.createdBy?.displayName?.slice(0, 1)}
                                                        </Avatar>
                                                        <Stack direction="column">
                                                            <Typography>
                                                                {row?.createdBy?.displayName}
                                                            </Typography>
                                                            <Typography variant='body2'>
                                                                {row?.createdBy?.role}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="left">
                                                    {row?.product?.name}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {row?.product?.amount} - {row?.product?.price} {row?.product?.currency}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {moment(row?.createdAt).format('DD MMMM YYYY')}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells?.length} text="Bu alanda hiç bir başvuru bulunamadı" />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>
        </Card>
    )
}
