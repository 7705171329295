import { IReference } from "interfaces/user/reference/IReference"


export interface IAdminOzgeleceksItem {
  _id: string
  candidate: {
    _id: string
    candidateOnboardingStatus: string
    personalInformation: {
      profileImageUrl: string
      name: string
      surname: string
      city: string
      description: string
    }

  }
  createdAt: string
  field: string
  ozgelecekTest: {
    second: {
      text: string
      video: string
    }
    first: {
      text: string
      video: string
    }
  }
  references?: IReference[]
  testResults: {
    motivationTestScore: string
    motivationTestResults: {
      question: {
        _id: string
      }
      answer: string
    }
    areaTestScore: string
    areaTestResults: {
      answer: string
      question: {
        _id: string
      }
    }
  }
  updatedAt: string
}


export interface IAdminOzgeleceksResponse {
  count: number;
  data: Array<IAdminOzgeleceksItem>
}

export interface IAdminOzgeleceksVariables {
  filter?: {
    candidateId: string,
    field: string
  },

  pagination: {
    page: number,
    pageSize: number,
    sort?: string
  }
}



export const admin_ozgeleceks = (variables: IAdminOzgeleceksVariables) => {

  const data = JSON.stringify({
    query: `
        query Admin_ozgeleceks($filter: FilterOzgelecekForAdmin, $pagination: Pagination) {
            admin_ozgeleceks(filter: $filter, pagination: $pagination) {
              count
              data {
                _id
                candidate {
                  candidateOnboardingStatus
                  personalInformation {
                    profileImageUrl
                    name
                    surname
                    city
                    description
                  }
                }
                createdAt
                field
                ozgelecekTest {
                  second {
                    text
                    video
                  }
                  first {
                    text
                    video
                  }
                }
                references {
                  _id
                  referenceInformation {
                    name
                    surname
                    email
                    phoneNumber
                    jobTitle
                    relationWithCandidate
                  }
                  referenceStatus
                  referenceExplanation
                  referenceTests {
                    answer
                  }
                  createdBy {
                    _id
                  }
                  createdAt
                  updatedAt
                }
                testResults {
                  # motivationTestScore
                  motivationTestResults {
                    question {
                      _id
                    }
                    answer
                  }
                  # areaTestScore
                  areaTestResults {
                    answer
                    question {
                      _id
                    }
                  }
                }
                updatedAt
              }
            }
          }
        `,
    variables: {
      "filter": {
        "candidateId": variables?.filter?.candidateId ?? null,
        "field": variables?.filter?.field ?? null
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
        "sort": variables?.pagination?.sort ?? null
      }
    }
  })

  return data
}