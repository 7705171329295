import { CompanyConversionNumberOfEmployeesEnum } from "types";

export interface ISpecialistUpdateConversionRequestFormResponse {
  _id: string;
}

export interface ISpecialistUpdateConversionRequestFormVariables {
  conversionRequestFormId: string;
  conversionRequestFormInput: {
    authorizedPersonEmail: string;
    authorizedPersonName: string;
    authorizedPersonPhoneNumber: string;
    authorizedPersonSurname: string;
    authorizedPersonTitle: string;
    city: string;
    companyName: string;
    district: string;
    numberOfEmployees: CompanyConversionNumberOfEmployeesEnum;
    sector: string;
    website: string;
  };
}

export const specialist_updateConversionRequestForm = (
  variables: ISpecialistUpdateConversionRequestFormVariables
) => {
  const data = JSON.stringify({
    query: `
        mutation Specialist_updateConversionRequestForm($conversionRequestFormId: ID!, $conversionRequestFormInput: UpdateConversionRequestFormInput!) {
            specialist_updateConversionRequestForm(conversionRequestFormId: $conversionRequestFormId, conversionRequestFormInput: $conversionRequestFormInput) {
              _id
            }
          }
        `,
    variables: {
      conversionRequestFormId: variables?.conversionRequestFormId,
      conversionRequestFormInput: {
        authorizedPersonEmail:
          variables.conversionRequestFormInput.authorizedPersonEmail,
        authorizedPersonName:
          variables.conversionRequestFormInput.authorizedPersonName,
        authorizedPersonPhoneNumber:
          variables.conversionRequestFormInput.authorizedPersonPhoneNumber,
        authorizedPersonSurname:
          variables.conversionRequestFormInput.authorizedPersonSurname,
        authorizedPersonTitle:
          variables.conversionRequestFormInput.authorizedPersonTitle,
        city: variables.conversionRequestFormInput.city,
        companyName: variables.conversionRequestFormInput.companyName,
        district: variables.conversionRequestFormInput.district,
        numberOfEmployees:
          variables.conversionRequestFormInput.numberOfEmployees,
        sector: variables.conversionRequestFormInput.sector,
        website: variables.conversionRequestFormInput.website,
      },
    },
  });

  return data;
};
