import { ReminderStatusEnum } from "types"






export interface IUserRemindersItem {
    _id: string
    content: string
    remindDate: string
    status: string
    tags: Array<string>
    description: string
    createdBy: {
        displayImageUrl: string
        displayName: string
    }
}

export interface IUserRemindersResponse {
    count: number
    data: Array<IUserRemindersItem>
}


export interface IUserRemindersVariables {
    pagination: {
        page: number,
        pageSize: number,
        sort?: string
    },
    filter?: {
        content?: string,
        description?: string,
        priority?: "high" | "medium" | "low",
        remindDate?: string,
        status?: "scheduled" | "inProgress" | "expired" | "completed" | "cancelled",
        tags?: string,
        target?: {
            _id?: string
        },
        targetType?: "user" | "organization"
    },
}


export const user_reminders = (variables: IUserRemindersVariables) => {

    const data = JSON.stringify({
        query: `
        query Data($filter: FilterReminderInput, $pagination: Pagination) {
            user_reminders(filter: $filter, pagination: $pagination) {
              data {
                _id
                content
                remindDate
                status
                tags
                description
                createdBy {
                    displayImageUrl
                    displayName
                  }
              }
              count
            }
          }
        `,
        variables: {
            "filter": {
                "content": null,
                "description": null,
                "priority": null,
                "remindDate": null,
                "status": variables?.filter?.status ?? null,
                "tags": null,
                "target": null,
                "targetType": null
            },
            "pagination": {
                "page": variables?.pagination?.page ?? null,
                "pageSize": variables?.pagination?.pageSize ?? null,
                "sort": variables?.pagination?.sort ?? null
            }
        }

    })

    return data

}