import { HasId } from "interfaces/HasId";
import { ISendCreditRequest } from "interfaces/payment/finance/ISendCreditRequest";

export interface IAccounterSendCreditRequestVariables extends HasId {}

export interface IAccounterSendCreditRequestResponse
  extends ISendCreditRequest {}

export const accounter_sendCreditRequest = (variables: IAccounterSendCreditRequestVariables) => {

    const data = JSON.stringify({
        query: `
          query accounter_sendCreditRequest($id: ID!) {
            accounter_sendCreditRequest(id: $id) {
              _id
              status
              creditAmount
              statusChangeReason
              price
              currency
              createdAt
              updatedAt
            }
          }
        `,
        variables: variables
    })

    return data
}
