export interface IAccounterCompaniesItem {
  _id: string
  personalInformation: {
    name: string
    surname: string
    phoneNumber: string
    description: string
  }
  contact: {
    phoneNumber: string
    phoneNumberVerified: string
    email: string
    emailVerified: string
  }
  companyInformation: {
    companyName: string
    phoneNumber: string
    email: string
    city: string
    district: string
    address: string
    taxOffice: string
    taxNumber: string
    profileImage: string
    sector: string
    companyAddress: string
    foundationYear: string
    numberOfEmployees: string
    website: string
    linkedin: string
    instagram: string
    facebook: string
    companyBrief: string
    companyDocuments: {
      imzaSirkuleri: string
      vergiLevhasi: string
    }
  }
  companyOnboardingStatus: string
  createdAt: string
  updatedAt: string
}

export interface IAccounterCompaniesResponse {
  count: number;
  data: Array<IAccounterCompaniesItem>;
}

export interface IAccounterCompaniesVariables {
  filter?: {
    city?: string;
    companyCity?: string;
    companyDistrict?: string;
    companyName?: string;
    companySector?: string;
    country?: string;
    name?: string;
    surname?: string;
  };
  pagination?: {
    page?: number;
    pageSize?: number;
    sort?: string | null;
  };
}

export const accounter_companies = (variables: IAccounterCompaniesVariables) => {

  const data = JSON.stringify({
    query: `
      query accounter_companies($filter: FilterCompanyInput, $pagination: Pagination) {
        accounter_companies(filter: $filter, pagination: $pagination) {
          count
          data {
            _id
            personalInformation {
              name
              surname
              phoneNumber
              description
            }
            contact {
              phoneNumber
              phoneNumberVerified
              email
              emailVerified
            }
            companyInformation {
              companyName
              phoneNumber
              email
              city
              district
              address
              taxOffice
              taxNumber
              profileImage
              sector
              companyAddress
              foundationYear
              numberOfEmployees
              website
              linkedin
              instagram
              facebook
              companyBrief
              companyDocuments {
                imzaSirkuleri
                vergiLevhasi
              }
            }
            companyOnboardingStatus
            createdAt
            updatedAt
          }
        }
      }
        `,
    variables: {
      filter: {
        city: variables?.filter?.city,
        companyCity: variables?.filter?.companyCity,
        companyDistrict: variables?.filter?.companyDistrict,
        companyName: variables?.filter?.companyName,
        companySector: variables?.filter?.companySector,
        country: variables?.filter?.country,
        name: variables?.filter?.name,
        surname: variables?.filter?.surname,
      },
      pagination: {
        page: variables?.pagination?.page,
        pageSize: variables?.pagination?.pageSize,
        sort: variables?.pagination?.sort,
      },
    },
  });

  return data;
};
