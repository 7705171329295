import axios from 'axios';
import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Link, Stack, IconButton, Typography, InputAdornment, Box } from '@mui/material';

import { useBoolean } from 'minimal/hooks/use-boolean';
import { Iconify, RHFTextField, FormProvider } from 'components';
import { routes } from 'routes';


const RegisterFormSchema = Yup.object().shape({
  email: Yup.string().required('Gerekli').email('Geçerli bir email adresi giriniz'),
  password: Yup.string()
    // .matches(/^(?=.*[a-z])/, 'En az 1 küçük karakter olmalı')
    // .matches(/^(?=.*[A-Z])/, 'En az bir büyük karakter olmalı')
    // .matches(/^(?=.*[0-9])/, 'En az bir numara içermeli')
    // .matches(/^(?=.*[!@#%&?.,+%-_])/, 'En az bir özel karakter içermeli')
    // .matches(/(?=.*[!'^+%.,/()&=?\-_*])/, "En az bir özel karakter içermeli!")
    .min(4, "En az 4 karakter içermeli")
    .required('Gerekli'),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), "null"], 'Şifreler eşleşmeli!'),
});



export const RegisterContainer = () => {

  const password = useBoolean();
  const navigate = useNavigate()
  const [role, setRole] = useState("")

  const methods = useForm({
    resolver: yupResolver(RegisterFormSchema),
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
    },
    mode: 'all',
  });


  const { handleSubmit, formState: { isSubmitting } } = methods;

  const onSubmit = handleSubmit(async (data) => {

    try {

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/auth/auth/register`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ ...data, role })
      };

      const response = await axios.request(config)

      console.log(response?.data)

      if (response?.data?.isSuccess) {
        enqueueSnackbar("Kayıt başarılı! Giriş yapmak için e-mail onaylama gerekiyor!")
        navigate(routes.auth.registerSuccessfull)
      } else {
        enqueueSnackbar("Başka bir email deneyiniz ya da daha sonra tekrar deneyiniz!", {
          variant: 'error'
        })
      }

    } catch (error: any) {

      console.error(error);
      if (error?.response?.data?.message) {
        error?.response?.data?.errors?.forEach((item: any) => {
          if (item.msg === "Email already in use") {
            enqueueSnackbar("Bu email kullanılmaktadır!", {
              variant: 'error'
            })
          } else {
            enqueueSnackbar("Birşeyler yanlış gitti!", {
              variant: 'error'
            })
          }
        })
      } else {
        enqueueSnackbar("Birşeyler yanlış gitti!", {
          variant: 'error'
        })
      }


    }



  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
      <Typography variant="h4">Yeni bir hesap oluştur</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> Zaten hesabın var mı? </Typography>

        <Link to={routes.auth.login} component={RouterLink} variant="subtitle2">
          Giriş Yap
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{ color: 'text.secondary', mt: 2.5, typography: 'caption', textAlign: 'center' }}
    >
      <Link underline="always" color="text.primary">
        Kullanım Koşulları
      </Link>
      {' ve '}
      <Link underline="always" color="text.primary">
        Gizlilik Politikası'nı
      </Link>
      {'Kabul ediyorum. '}
      .
    </Typography>
  );

  const renderForm = (
    <Stack spacing={2.5}>

      <RHFTextField name="email" label="Email adresi" />

      <RHFTextField
        name="password"
        label="Şifre"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <RHFTextField
        name="passwordConfirm"
        label="Şifre onayla"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Kayıt Ol
      </LoadingButton>
    </Stack>
  );

  const renderRole = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        // height: '100%',
        justifyContent: 'center',
      }}
    >

      {
        ["company", "candidate"].map(item => {

          return (
            <Box
              key={item}
              onClick={() => setRole(item)}
              sx={{
                borderRadius: "18px",
                border: "1px solid #ccc",
                p: 3,
                color: "#999",
                display: 'flex',
                alignItems: "center",
                justifyContent: "center",
                transitionDuration: "300ms",
                cursor: "pointer",

                '&:hover': {
                  border: "1px solid transparent",
                  boxShadow: '1px 1px 10px 0px #ccc',
                  color: "#666",
                },

                '&:active': {
                  cursor: "grabbing",
                }

              }}
            >
              {item}
            </Box>
          )
        })
      }

    </Box>
  )


  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}
      {role ? renderForm : renderRole}
      {renderTerms}
    </FormProvider>
  );
}
