
import {
  anonymous_blogPosts, IAnonymousBlogPostsResponse, IAnonymousBlogPostsVariables,
  anonymous_blogPost, IAnonymousBlogPostResponse, IAnonymousBlogPostVariables
} from "./queries"
import {
  IManagementCreateBlogPostResponse, IManagementCreateBlogPostVariables, management_createBlogPost,
  IManagementRemoveBlogPostResponse, IManagementRemoveBlogPostVariables, management_removeBlogPost,
  IManagementUpdateBlogPostResponse, IManagementUpdateBlogPostVariables, management_updateBlogPost


} from "./mutations";
import { blogApi } from "context/blog";

const adminBlogApi = blogApi.injectEndpoints({
  endpoints: (builder) => ({


    // ####################  Queries #################

    getAnonymoustBlogPosts: builder.query<IAnonymousBlogPostsResponse, IAnonymousBlogPostsVariables>({
      query: (variables: IAnonymousBlogPostsVariables) => {
        return ({
          url: "",
          body: anonymous_blogPosts(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.anonymous_blogPosts
      },
      providesTags: ["management_blogPosts", "management_blogPost"]
    }),

    getAnonymoustBlogPost: builder.query<IAnonymousBlogPostResponse, IAnonymousBlogPostVariables>({
      query: (variables: IAnonymousBlogPostVariables) => {
        return ({
          url: "",
          body: anonymous_blogPost(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.anonymous_blogPost
      },
      providesTags: ["management_blogPost"]
    }),


    // ####################  Mutations #################

    managementCreateBlogPost: builder.mutation<IManagementCreateBlogPostResponse, IManagementCreateBlogPostVariables>({
      query: (variables: IManagementCreateBlogPostVariables) => {
        return ({
          url: "",
          body: management_createBlogPost(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.admin_sendFreeCredits
      },
      invalidatesTags: ["management_blogPosts", "management_blogPost"]
    }),

    managementRemoveBlogPost: builder.mutation<IManagementRemoveBlogPostResponse, IManagementRemoveBlogPostVariables>({
      query: (variables: IManagementRemoveBlogPostVariables) => {
        return ({
          url: "",
          body: management_removeBlogPost(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.admin_sendFreeCredits
      },
      invalidatesTags: ["management_blogPosts", "management_blogPost"]
    }),

    managementUpdateBlogPost: builder.mutation<IManagementUpdateBlogPostResponse, IManagementUpdateBlogPostVariables>({
      query: (variables: IManagementUpdateBlogPostVariables) => {
        return ({
          url: "",
          body: management_updateBlogPost(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.admin_sendFreeCredits
      },
      invalidatesTags: ["management_blogPosts", "management_blogPost"]
    }),





  }),

  overrideExisting: false,
});

export const {
  // ####################  Queries #################
  useGetAnonymoustBlogPostsQuery, useLazyGetAnonymoustBlogPostsQuery,
  useGetAnonymoustBlogPostQuery, useLazyGetAnonymoustBlogPostQuery,


  // ####################  Mutations #################
  useManagementCreateBlogPostMutation,
  useManagementRemoveBlogPostMutation,
  useManagementUpdateBlogPostMutation,


} = adminBlogApi

