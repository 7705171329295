





export interface IUserNotesToUserItem {
    _id: string
    content: string
    createdAt: string
    targetType: string
    title: string
    updatedAt: string
}

export interface IUserNotesToUserResponse {
    count: number
    data: Array<IUserNotesToUserItem>
}


export interface IUserNotesToUserVariables {
    filter: {
        targetId: string
    },
    pagination?: {
        page: number,
        pageSize: number,
        sort?: string
    }
}


export const user_notesToUser = (variables: IUserNotesToUserVariables) => {

    const data = JSON.stringify({
        query: `
        query User_notesToUser($filter: FilterNoteInput, $pagination: Pagination) {
            user_notesToUser(filter: $filter, pagination: $pagination) {
              count
              data {
                _id
                content
                createdAt
                targetType
                title
                updatedAt
              }
            }
          }      
        `,
        variables: {
            "filter": {
                "targetId": variables?.filter?.targetId ?? null
            },
            "pagination": {
                "page": null,
                "pageSize": null,
                "sort": null
            }
        }
    })

    return data

}