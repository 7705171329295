
export interface IUserCreateReminderResponse {
    _id: string;
}

export interface IUserCreateReminderVariables {
    input: {
        content?: string,
        // title: string,
        targetId: string

        remindDate: string,
        tags: Array<string>,

        targetType: string,
        priority: string,
        description?: string // mesaj
    }
}

export const user_createReminder = (variables: IUserCreateReminderVariables) => {

    const data = JSON.stringify({
        query: `
        mutation User_createReminder($input: ReminderInput!) {
            user_createReminder(input: $input) {
              _id
            }
          }
        `,
        variables: {
            "input": {
                "content": variables?.input?.content ?? "", // user bilgileri, isim soyisim/companyName
                // "title": variables?.input?.title ?? null,
                "target": {
                    "_id": variables?.input?.targetId ?? null
                },


                "remindDate": variables?.input?.remindDate ?? null,
                "tags": variables?.input?.tags ?? null,

                "targetType": variables?.input?.targetType ?? null,
                "priority": variables?.input?.priority ?? "medium",
                "description": variables?.input?.description ?? null // mesaj

            }
        }

    })

    return data

}