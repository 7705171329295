import { Badge } from "@mui/material"
import { ExitToApp } from "@mui/icons-material"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"

import {
    HomePage,
    NotepadPage,
    SettingsPage,
    MyAccountPage,
    RemindersPage,
    HelpCenterPage,
    CandidatesPage,
    CompaniesPage,
    DiscoverPage,
} from "../pages"

import { DashboardLayout } from "layouts"
import { hrAdvisorRoutes } from "./routes"
import { IBottomMenuListItem, IMenuListItem, IProfileBoxUser, Iconify } from "components"
import { logoutReducer, useAppDispatch, useGetSelfUserQuery, useGetUserRemindersQuery } from "context"



export const HrAdvisorRoutes = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { data: userInfo } = useGetSelfUserQuery({})

    const { data: reminders } = useGetUserRemindersQuery({ pagination: { page: 0, pageSize: 0 }, filter: { status: "inProgress" } })


    const user: IProfileBoxUser | null = {
        fullName: (userInfo?.personalInformation?.name || userInfo?.personalInformation?.surname)
            ? userInfo?.personalInformation?.name + " " + userInfo?.personalInformation?.surname
            : userInfo?.contact?.email ?? "",
        profileImageUrl: userInfo?.personalInformation?.profileImageUrl ?? "",
        role: userInfo?.role ?? ""
    }


    const menuList: Array<IMenuListItem> = [
        {
            items: [
                {
                    title: 'Panel',
                    path: hrAdvisorRoutes.dashboard.home,
                    icon: <Iconify icon="iconamoon:home" />
                },
                {
                    title: 'Şirketler',
                    path: hrAdvisorRoutes.dashboard.companies,
                    icon: <Iconify icon="mdi:company" />
                },
                {
                    title: 'Adaylar',
                    path: hrAdvisorRoutes.dashboard.candidates,
                    icon: <Iconify icon="formkit:people" />
                },
                {
                    title: 'Özgelecek Keşif',
                    path: hrAdvisorRoutes.dashboard.discover,
                    icon: <Iconify icon="fluent:people-community-20-regular" />
                },
                {
                    title: 'Hatırlatıcılar',
                    path: hrAdvisorRoutes.dashboard.reminders,
                    icon: <Iconify icon="ic:baseline-list-alt" />,
                    info: <Badge badgeContent={reminders?.count} color="primary" />
                },
            ]
        },
        {
            subheader: 'Ayarlar',
            items: [
                {
                    title: 'Ayarlar',
                    path: hrAdvisorRoutes.dashboard.settings,
                    icon: <Iconify icon="solar:settings-bold-duotone" />
                },
                {
                    title: 'Notepad',
                    path: hrAdvisorRoutes.dashboard.notpad,
                    icon: <Iconify icon="fluent:notepad-edit-16-regular" />
                },
            ]
        }
    ]

    const bottomMenuList: Array<IBottomMenuListItem> = [
        {
            func: (e) => navigate(hrAdvisorRoutes.dashboard.myAccount),
            icon: <Iconify icon="uil:setting" width={24} sx={{ marginRight: 1 }} />,
            title: "Hesap Bilgileri"
        },
        {
            func: (e) => dispatch(logoutReducer("")),
            icon: <ExitToApp sx={{ marginRight: 1 }} />,
            title: "Çıkış"
        },
    ]

    return (
        <Routes>
            <Route path="" element={<DashboardLayout menuList={menuList} user={user} bottomMenuList={bottomMenuList} />}>
                <Route path="/" element={<HomePage />} />
                <Route path={hrAdvisorRoutes.dashboard.reminders} element={<RemindersPage />} />
                <Route path={hrAdvisorRoutes.dashboard.companies} element={<CompaniesPage />} />
                <Route path={hrAdvisorRoutes.dashboard.candidates} element={<CandidatesPage />} />
                <Route path={hrAdvisorRoutes.dashboard.discover} element={<DiscoverPage />} />

                <Route path={hrAdvisorRoutes.dashboard.notpad} element={<NotepadPage />} />
                <Route path={hrAdvisorRoutes.dashboard.settings} element={<SettingsPage />} />
                <Route path={hrAdvisorRoutes.dashboard.helpCenter} element={<HelpCenterPage />} />
                <Route path={hrAdvisorRoutes.dashboard.myAccount} element={<MyAccountPage />} />

                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    )
}