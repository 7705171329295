
export interface IUserUpdateNoteResponse {
    _id: string;
}

export interface IUserUpdateNoteVariables {
    noteId: string,
    noteUpdateInput: {
        title: string,
        content: string
    }
}

export const user_updateNote = (variables: IUserUpdateNoteVariables) => {

    console.log({ variables });
    const data = JSON.stringify({
        query: `
        mutation User_updateNote($noteId: ID!, $noteUpdateInput: UpdateNoteInput!) {
            user_updateNote(noteId: $noteId, noteUpdateInput: $noteUpdateInput) {
              _id
            }
          }
        `,
        variables: {
            "noteId": variables?.noteId ?? null,
            "noteUpdateInput": {
                "title": variables?.noteUpdateInput?.title ?? null,
                "content": variables?.noteUpdateInput?.content ?? null
            }
        }

    })

    return data

}