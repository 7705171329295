import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import { Formik, useFormikContext } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { Box, Button, Card, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Slider, Stack, TextField, Typography } from '@mui/material'

import { operationRoutes } from '../routes'
import { OzgelecekFieldsList, SexsList } from 'types'
import { useGetAnonymousConversionQuestionsQuery } from 'context'
import { FormikSelect, FormikTextField, FormikTextFieldPhone, Iconify } from 'components'
// import { ISpecialistCreateConversionVariables, useGetSpecialistConversionRequestFormsQuery, useSpecialistCreateConversionMutation } from '../context'
import { IOperationCreateConversionVariables, useOperationCreateConversionMutation, useGetOperationConversionQuery, useGetOperationConversionsQuery } from '../context'
import { enqueueSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'



export const ConversionCreatePage = () => {

    const navigate = useNavigate()

    const validationSchema = yup.object().shape({
        name: yup.string().required("Gerekli!"),
        surname: yup.string().required("Gerekli!"),
        sex: yup.string().required("Gerekli!"),
        age: yup.string().required("Gerekli!"),
        companyName: yup.string().required("Gerekli!"),
        phone: yup.string().required("Gerekli!"),
        field: yup.string().required("Gerekli!"),
        jobTitle: yup.string().required("Gerekli!"),
        totalWorkTimeInMonths: yup.string().required("Gerekli!"),

        conversionTest: yup.array().min(1, "Soruları tamamlayınız!"),

        conversionRequestForm: yup.object().shape({
            _id: yup.string().required("Gerekli!")
        }),
    })

    const initialValues: IOperationCreateConversionVariables = {
        email: "",
        name: "",
        surname: "",
        sex: "",
        age: "",
        companyName: "",
        phone: "",
        field: "",
        jobTitle: "",
        totalWorkTimeInMonths: "",
        conversionTest: [],
        conversionRequestForm: {
            _id: ""
        },
    }


    return (
        <Stack spacing={3}>

            <Stack direction="row" gap={1} alignItems="center">
                <IconButton onClick={() => navigate(operationRoutes.dashboard.conversions)} >
                    <ArrowBack />
                </IconButton>

                <Typography variant='h1'>
                    Dönüşüm Oluştur
                </Typography>
            </Stack>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {

                }}
            >
                <ConversionCreateFormContainer />
            </Formik>

        </Stack>
    )
}

interface IFormState {
    formIndex: number,
    questionIndex: number,
}

const ConversionCreateFormContainer = () => {

    const [formState, setFormState] = useState<IFormState>({
        formIndex: 1,
        questionIndex: 1,
    })

    const renderForm = () => {

        switch (formState.formIndex) {
            case 1:
                return <ConversionCreateForm1 setFormState={setFormState} />
            case 2:
                return <ConversionCreateForm2 formState={formState} setFormState={setFormState} questionsType="forConversion" />
            case 3:
                return <ConversionCreateForm2 formState={formState} setFormState={setFormState} questionsType="forProductivityAndSatisfaction" />

            default:
                return null
        }

    }


    return (
        <Card sx={{ p: 3, }} >
            {renderForm()}
        </Card>
    )
}


// ----------------- ConversionCreateForm1 ----------------------------
const ConversionCreateForm1 = ({ setFormState }: any) => {

    const { values, setFieldValue } = useFormikContext<IOperationCreateConversionVariables>()
    // const { data: conversionRequestForms } = useGetOperationConversionsQuery({ pagination: {} })


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h5'>
                    Kişisel Bilgiler
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikSelect
                    name="field"
                    label="Alan"
                    list={OzgelecekFieldsList}
                />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
                <FormikSelect
                    name="conversionRequestForm._id"
                    label="Dönüşüm Talebi Formu"
                    list={
                        conversionRequestForms?.data
                            ? conversionRequestForms?.data?.map(item => ({ value: item?._id, name: item?.authorizedPersonName + " " + item?.authorizedPersonSurname }))
                            : []
                    }
                />
            </Grid> */}

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name='name'
                    label="İsmi"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name='surname'
                    label="Soyismi"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    type='number'
                    name='age'
                    label="Yaş"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name='companyName'
                    label="Şirket İsmi"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name='email'
                    label="Email"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextField
                    name='jobTitle'
                    label="Mesleği"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikTextFieldPhone
                    name='phone'
                    label="Telefon"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormikSelect
                    name="sex"
                    label="Cinsiyet"
                    list={SexsList}
                />
            </Grid>
            <Grid item xs={12} >
                <WorkExperienceInMonthComponent
                    value={values?.totalWorkTimeInMonths ?? ""}
                    setValue={(value: number) => setFieldValue("totalWorkTimeInMonths", value)}
                />
            </Grid>

            <Grid item xs={12} component={Stack} justifyContent="end" >
                <Button
                    color='primary'
                    variant='contained'
                    onClick={() => setFormState((prevState: any) => ({ ...prevState, formIndex: prevState?.formIndex + 1 }))}
                >
                    İleri
                </Button>
            </Grid>


        </Grid>
    )
}

export const WorkExperienceInMonthComponent = ({ value, setValue }: any) => {

    const [data, setData] = useState({
        month: 0,
        year: 0
    })

    const setDefaultValue = useCallback(() => {
        const year = Math.floor(value / 12)
        const month = value % 12
        setData({ month, year })
    }, [])

    useEffect(() => {
        setDefaultValue()
    }, [setDefaultValue])


    const handleDataChange = useCallback(() => {
        const newValue = data.year * 12 + data.month
        setValue(newValue)
    }, [])

    useEffect(() => {
        handleDataChange()
    }, [handleDataChange])


    return (
        <Stack direction={{ xs: 'column', sm: 'column' }} gap={2}>

            <Typography variant="body2" sx={{ whiteSpace: { sm: 'nowrap' } }}>
                Kaç yıl çalışma deneyiminiz var?
            </Typography>

            <Stack direction="row" gap={3} width={{ xs: '100%' }}>
                <FormControl fullWidth>
                    <InputLabel id="candidata_year_input">Yıl</InputLabel>
                    <Select
                        label="Yıl"
                        labelId="candidata_year_input"
                        defaultValue={0}
                        value={data.year}
                        onChange={(e: any) => setData((prevState: any) => ({ ...prevState, year: e.target.value }))}
                    >
                        {
                            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => {
                                return (<MenuItem key={item} value={item}>{item === 0 ? item + " Yıl" : item + " Yıl"}{item === 10 && "+"}</MenuItem>)
                            })
                        }
                    </Select>
                </FormControl>


                <FormControl fullWidth>
                    <InputLabel id="candidata_month_input">Ay</InputLabel>
                    <Select
                        label="Ay"
                        labelId="candidata_month_input"
                        defaultValue={0}
                        value={data.month}
                        onChange={(e: any) => setData((prevState: any) => ({ ...prevState, month: e.target.value }))}
                    >
                        {
                            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => {
                                return (<MenuItem key={item} value={item}>{item === 0 ? item + " Ay" : item + " Ay"}</MenuItem>)
                            })
                        }
                    </Select>
                </FormControl>
            </Stack>

        </Stack>
    )
}



// ----------------- ConversionCreateForm2 ----------------------------
const ConversionCreateForm2 = ({ formState, setFormState, questionsType = "forConversion" }: any) => {

    const { data: questions } = useGetAnonymousConversionQuestionsQuery({
        // filter: {
        //     type: questionsType
        // }
    })
    const { values, setFieldValue } = useFormikContext<IOperationCreateConversionVariables>()
    const [range, setRange] = useState(0)
    const [text, setText] = useState("")

    const question = questions?.data?.[formState?.questionIndex - 1]

    const [answerType, setAnswerType] = useState<"byRange" | "byString" | null>(null)

    const handleNextQuestion = () => {
        setFieldValue("conversionTest", [
            ...values.conversionTest,
            { answer: (answerType === "byRange" ? { "byRange": range } : { "byString": text }) }
        ])
        setFormState((prevState: IFormState) => ({ ...prevState, questionIndex: prevState.questionIndex + 1 }))
        setText("")
        setRange(0)
        setAnswerType(null)
    }

    // console.log({ values });

    const [createConversion, { isLoading: createConversionLoading }] = useOperationCreateConversionMutation()

    const handlCreateConversion = async () => {
        try {
            const response: any = await createConversion({
                ...values
            })
            console.log({ response });

            if (response?.data) {
                enqueueSnackbar("Başarılı")
            } else {
                enqueueSnackbar("Başarılı olamadı", { variant: "warning" })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış giti!", { variant: "error" })
        }
    }


    return (
        <Stack>
            {
                !!question ? (
                    <Stack spacing={3}>
                        <Typography variant='h6' height={60} color="GrayText">
                            {formState?.questionIndex} ) {question?.content}
                        </Typography>

                        <Stack>
                            {
                                answerType ? (
                                    <Stack>
                                        {
                                            answerType === "byRange" ? (
                                                <Stack>
                                                    <Slider
                                                        value={range}
                                                        onChange={(e, value) => setRange(value as number)}
                                                    />
                                                </Stack>
                                            ) : (
                                                <Stack>
                                                    <TextField
                                                        multiline
                                                        minRows={3}
                                                        label="Cevap"
                                                        value={text}
                                                        onChange={(e) => setText(e.target.value)}
                                                    />
                                                </Stack>
                                            )
                                        }
                                    </Stack>
                                ) : (
                                    <Stack direction="row" justifyContent="center" gap={3}>
                                        <Button
                                            onClick={() => setAnswerType("byRange")}
                                            variant='outlined'
                                            color='primary'
                                        >
                                            Sayı
                                        </Button>

                                        <Button
                                            onClick={() => setAnswerType("byString")}
                                            variant='outlined'
                                            color='primary'
                                        >
                                            Metin
                                        </Button>
                                    </Stack>
                                )
                            }
                        </Stack>

                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant='h6' color="GrayText">
                                {formState?.questionIndex} / {questions?.count}
                            </Typography>

                            <Button
                                disabled={!range && !text}
                                onClick={handleNextQuestion}
                                variant='contained'
                                color='primary'
                            >
                                İleri
                            </Button>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack spacing={3} alignItems="center">
                        <Typography>
                            Testi bitirdiniz, sonuçları kaydetmek için butona tıklayınız!
                        </Typography>

                        <LoadingButton
                            onClick={handlCreateConversion}
                            variant='contained'
                            color='primary'
                            loading={createConversionLoading}
                        >
                            Kaydet
                        </LoadingButton>
                    </Stack>
                )
            }
        </Stack>
    )
}