import IBaseResult from "interfaces/IBaseResult";



export interface IManagementCreateBlogPostVariables {
  blogPostInput: {
    title: string,
    categories: Array<string>,
    targetCategories: [
      "jobSeeker",
      "worker",
      "company",
      "universityStudent"
    ]
    readingTime: string,
    language: string,
    content: string,
    description: string,
    imageUrl: string
  }
}


export interface IManagementCreateBlogPostResponse extends IBaseResult { }

export const management_createBlogPost = (
  variables: IManagementCreateBlogPostVariables
) => {
  const data = JSON.stringify({
    query: `mutation Management_createBlogPost($blogPostInput: BlogPostInput!) {
      management_createBlogPost(blogPostInput: $blogPostInput) {
        _id
      }
    }`,
    variables: {
      blogPostInput: {
        title: variables.blogPostInput.title,
        categories: variables.blogPostInput.categories,
        targetCategories: variables.blogPostInput.targetCategories,
        readingTime: variables.blogPostInput.readingTime,
        language: variables.blogPostInput.language,
        content: variables.blogPostInput.content,
        description: variables.blogPostInput.description,
        imageUrl: variables.blogPostInput.imageUrl
      }
    }
  });

  return data;
};
