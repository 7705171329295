

import { paymentApi } from "context/payment";
import { IAccounterApproveSendCreditRequestResponse, IAccounterApproveSendCreditRequestVariables, IAccounterCreateSalesInvoiceInParachuteForOrderResponse, IAccounterCreateSalesInvoiceInParachuteForOrderVariables, IAccounterRejectSendCreditRequestResponse, IAccounterRejectSendCreditRequestVariables, IAccounterSendCreditResponse, IAccounterSendCreditVariables, accounter_approveSendCreditRequest, accounter_createSalesInvoiceInParachuteForOrder, accounter_rejectSendCreditRequest, accounter_sendCredits } from "./mutations";
import { IAccounterOrderResponse, IAccounterOrderVariables, IAccounterOrdersResponse, IAccounterOrdersVariables, accounter_order, accounter_orders } from "./queries";
import { IAccounterSendCreditRequestResponse, IAccounterSendCreditRequestVariables, accounter_sendCreditRequest } from "./queries/_accounter_sendCreditRequest";
import { IAccounterSendCreditRequestsResponse, IAccounterSendCreditRequestsVariables, accounter_sendCreditRequests } from "./queries/_accounter_sendCreditRequests";

const accounterPaymentApi = paymentApi.injectEndpoints({
    endpoints: (builder) => ({

        // #################### User Queries #################
        getAccounterOrders: builder.query<IAccounterOrdersResponse, IAccounterOrdersVariables>({
            query: (variables: IAccounterOrdersVariables) => {
                return ({
                    url: "",
                    body: accounter_orders(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.accounter_orders
            },
            providesTags: ["accounter_orders"]
        }),

        getAccounterOrder: builder.query<IAccounterOrderResponse, IAccounterOrderVariables>({
            query: (variables: IAccounterOrderVariables) => {
                return ({
                    url: "",
                    body: accounter_order(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.accounter_order
            },
            providesTags: ["accounter_order"]
        }),

        getAccounterSendCreditRequests: builder.query<IAccounterSendCreditRequestsResponse, IAccounterSendCreditRequestsVariables>({
            query: (variables: IAccounterSendCreditRequestsVariables) => {
                return ({
                    url: "",
                    body: accounter_sendCreditRequests(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.accounter_sendCreditRequests
            },
            providesTags: ["accounter_sendCreditRequests"]
        }),

        getAccounterSendCreditRequest: builder.query<IAccounterSendCreditRequestResponse, IAccounterSendCreditRequestVariables>({
            query: (variables: IAccounterSendCreditRequestVariables) => {
                return ({
                    url: "",
                    body: accounter_sendCreditRequest(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.accounter_sendCreditRequest
            },
            providesTags: ["accounter_sendCreditRequest"]
        }),




        // #################### User Mutations #################
        accounterCreateSalesInvoiceInParachuteForOrder: builder.mutation<IAccounterCreateSalesInvoiceInParachuteForOrderResponse, IAccounterCreateSalesInvoiceInParachuteForOrderVariables>({
            query: (variables: IAccounterCreateSalesInvoiceInParachuteForOrderVariables) => {
                return ({
                    url: "",
                    body: accounter_createSalesInvoiceInParachuteForOrder(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.accounter_createSalesInvoiceInParachuteForOrder
            },
            invalidatesTags: ["accounter_orders"]
        }),

        accounterSendCredit: builder.mutation<IAccounterSendCreditResponse, IAccounterSendCreditVariables>({
            query: (variables: IAccounterSendCreditVariables) => {
                return ({
                    url: "",
                    body: accounter_sendCredits(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.accounter_sendCredits
            },
            invalidatesTags: ["accounter_sendCreditRequests"]
        }),

        accounterRejectSendCreditRequest: builder.mutation<IAccounterRejectSendCreditRequestResponse, IAccounterRejectSendCreditRequestVariables>({
            query: (variables: IAccounterRejectSendCreditRequestVariables) => {
                return ({
                    url: "",
                    body: accounter_rejectSendCreditRequest(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.accounter_rejectSendCreditRequest
            },
            invalidatesTags: ["accounter_sendCreditRequest", "accounter_sendCreditRequests"]
        }),

        accounterApproveSendCreditRequest: builder.mutation<IAccounterApproveSendCreditRequestResponse, IAccounterApproveSendCreditRequestVariables>({
            query: (variables: IAccounterApproveSendCreditRequestVariables) => {
                return ({
                    url: "",
                    body: accounter_approveSendCreditRequest(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.accounter_approveSendCreditRequest
            },
            invalidatesTags: ["accounter_sendCreditRequest", "accounter_sendCreditRequests"]
        }),

    }),
})


export const {
    // ########## queries ###############
    useGetAccounterOrdersQuery,
    useLazyGetAccounterOrdersQuery,
    useGetAccounterOrderQuery,
    useLazyGetAccounterOrderQuery,

    useGetAccounterSendCreditRequestQuery,
    useLazyGetAccounterSendCreditRequestQuery,

    useGetAccounterSendCreditRequestsQuery,
    useLazyGetAccounterSendCreditRequestsQuery,


    // ########## mutation ###############
    useAccounterSendCreditMutation,
    useAccounterCreateSalesInvoiceInParachuteForOrderMutation,

    useAccounterRejectSendCreditRequestMutation,
    useAccounterApproveSendCreditRequestMutation,


} = accounterPaymentApi