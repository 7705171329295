import { userApi } from "context/user";
import {
  IOperationCompaniesResponse,
  IOperationCompaniesVariables,
  IOperationCompanyResponse,
  IOperationCompanyVariables,
  operation_companies,
  operation_company,
} from "./queries";
import { IOperationCreateConversionResponse, IOperationCreateConversionVariables, IOperationUpdateConversionResponse, IOperationUpdateConversionVariables, operation_createConversion, operation_updateConversion } from "./mutations";
import { IOperationConversionResponse, IOperationConversionVariables, operation_conversion } from "./queries/_operation_conversion";
import { IOperationConversionsResponse, IOperationConversionsVariables, operation_conversions } from "./queries/_operation_conversions";

const operationUserApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    // ####################  Queries #################
    getOperationCompany: builder.query<IOperationCompanyResponse, IOperationCompanyVariables>({
      query: (variables: IOperationCompanyVariables) => {
        return {
          url: "",
          body: operation_company(variables),
        };
      },
      transformResponse: (response: any) => {
        return response.data.operation_company;
      },
      providesTags: ["operation_company"],
    }),
      
    getOperationCompanies: builder.query<IOperationCompaniesResponse, IOperationCompaniesVariables>({
      query: (variables: IOperationCompaniesVariables) => {
        return {
          url: "",
          body: operation_companies(variables),
        };
      },
      transformResponse: (response: any) => {
        return response.data.operation_companies;
      },
      providesTags: ["operation_companies"],
    }),

    getOperationConversions: builder.query<IOperationConversionsResponse, IOperationConversionsVariables>({
      query: (variables: IOperationConversionsVariables) => {
        return ({
          url: "",
          body: operation_conversions(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.operation_conversions
      },
      providesTags: ["operation_conversions"]
    }),

    getOperationConversion: builder.query<IOperationConversionResponse, IOperationConversionVariables>({
      query: (variables: IOperationConversionVariables) => {
        return ({
          url: "",
          body: operation_conversion(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.conversion_conversion
      },
      providesTags: ["operation_conversion"]
    }),

    // ####################  Mutations #################
    operationCreateConversion: builder.mutation<IOperationCreateConversionResponse, IOperationCreateConversionVariables>({
      query: (variables: IOperationCreateConversionVariables) => {
        return {
          url: "",
          body: operation_createConversion(variables),
        };
      },
      transformResponse: (response: any) => {
        return response?.data?.operation_createConversion;
      },
      invalidatesTags: ["operation_conversion", "operation_conversions"],
    }),

    operationUpdateConversion: builder.mutation<IOperationUpdateConversionResponse, IOperationUpdateConversionVariables>({
      query: (variables: IOperationUpdateConversionVariables) => {
        return {
          url: "",
          body: operation_updateConversion(variables),
        };
      },
      transformResponse: (response: any) => {
        return response?.data?.operation_updateConversion;
      },
      invalidatesTags: ["operation_conversion", "operation_conversions"],
    }),
  }),

  overrideExisting: false,
});

export const {
  // ####################  Queries #################
  useGetOperationCompanyQuery,
  useLazyGetOperationCompanyQuery,
  
  useGetOperationCompaniesQuery,
  useLazyGetOperationCompaniesQuery,

  useGetOperationConversionsQuery,
  useLazyGetOperationConversionsQuery,

  useGetOperationConversionQuery,
  useLazyGetOperationConversionQuery,

  // ####################  Mutations #################
  useOperationCreateConversionMutation,
  useOperationUpdateConversionMutation,
  
} = operationUserApi;
