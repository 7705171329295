
import { userApi } from "context/user"
import { IManagementAdvertisementsResponse, IManagementAdvertisementsVariables, IManagementUserResponse, IManagementUserVariables, IManagementVisitFormResponse, IManagementVisitFormVariables, IManagementVisitFormsResponse, IManagementVisitFormsVariables, management_advertisements, management_user, management_visitForm, management_visitForms } from "./queries"
import { IManagementUpdateVisitFormResponse, IManagementUpdateVisitFormVariables, management_updateVisitForm } from "./mutations";


const managementUserApi = userApi.injectEndpoints({
  endpoints: (builder) => ({


    // ####################  Queries #################
    
    getManagementAdvertisements: builder.query<IManagementAdvertisementsResponse, IManagementAdvertisementsVariables>({
      query: (variables: IManagementAdvertisementsVariables) => {
        return ({
          url: "",
          body: management_advertisements(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.management_advertisements
      },
      providesTags: ["management_advertisements"]
    }),
    getManagementVisitForms: builder.query<IManagementVisitFormsResponse, IManagementVisitFormsVariables>({
      query: (variables: IManagementVisitFormsVariables) => {
        return ({
          url: "",
          body: management_visitForms(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.management_visitForms
      },
      providesTags: ["management_visitForms"]
    }),
    getManagementVisitForm: builder.query<IManagementVisitFormResponse, IManagementVisitFormVariables>({
      query: (variables: IManagementVisitFormVariables) => {
        return ({
          url: "",
          body: management_visitForm(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.management_visitForm
      },
      providesTags: ["management_visitForm"]
    }),
    getManagementUser: builder.query<IManagementUserResponse, IManagementUserVariables>({
      query: (variables: IManagementUserVariables) => {
        return ({
          url: "",
          body: management_user(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.management_user
      },
      providesTags: ["management_user"]
    }),

    // ####################  Mutations #################

    managementUpdateVisitForm: builder.mutation<IManagementUpdateVisitFormResponse, IManagementUpdateVisitFormVariables>({
      query: (variables: IManagementUpdateVisitFormVariables) => {
        return ({
          url: "",
          body: management_updateVisitForm(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.management_updateVisitForm
      },
      invalidatesTags: ["management_visitForm", "management_visitForms"]
    }),
  }),

  overrideExisting: false,
});

export const {
  // ####################  Queries #################
  useGetManagementAdvertisementsQuery,
  useLazyGetManagementAdvertisementsQuery,

  useGetManagementVisitFormQuery,
  useLazyGetManagementVisitFormQuery,
  useGetManagementVisitFormsQuery,
  useLazyGetManagementVisitFormsQuery,

  useGetManagementUserQuery,
  useLazyGetManagementUserQuery,

  // ####################  Mutations #################
  useManagementUpdateVisitFormMutation
  
} = managementUserApi

