


export const specialist_changeCompanyVisibilityForHrAdvisor = ({ companyId }: { companyId: string }) => {

    const data = JSON.stringify({
        query: `
        mutation specialist_changeCompanyVisibilityForHrAdvisor($companyId: ID!) {
          specialist_changeCompanyVisibilityForHrAdvisor(companyId: $companyId) {
            success
          }
        }
        `,
        variables: {
            "companyId": companyId
        }
    })

    return data

}