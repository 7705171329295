import {
    Box,
    Link,
    Grid,
    Card,
    Chip,
    Table,
    Stack,
    Button,
    Avatar,
    Dialog,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TextField,
    IconButton,
    DialogContent,
    TableContainer,
    TablePagination,
    CircularProgress,
    Divider,
} from '@mui/material'
import moment from 'moment';
import React, { useState } from 'react'

import { enumFunctions } from 'utils'
import { NumberOfEmployeeEnum, OrderStatusEnum } from 'types'
import { CreateReminderSection, UserNoteToUserSection } from 'sections';
import { IAccounterOrdersItem, IAccounterOrdersVariables, useAccounterCreateSalesInvoiceInParachuteForOrderMutation, useGetAccounterOrdersQuery } from 'apps/accounting'
import { CustomTableHeader, ICustomTableHeadCell, Iconify, MuiTransition, TableEmptyData, TableLoading, } from 'components'

import WhatshotOutlinedIcon from '@mui/icons-material/WhatshotOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { enqueueSnackbar } from 'notistack';





export const OrdersPage = () => {

    const [variables, setVariables] = useState<IAccounterOrdersVariables>({
        pagination: {
            page: 1,
            pageSize: 10,
        }
    })

    const { data: orders, isLoading } = useGetAccounterOrdersQuery(variables)

    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }

    // const handleSearchByName = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setVariables((prevState) => ({
    //         ...prevState,
    //         filter: {
    //             ...prevState.filter,
    //             companyName: e?.target?.value
    //         }
    //     }))
    // }


    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Siparişler
            </Typography>

            <OrdersTable
                data={orders}
                pagination={variables?.pagination}
                loading={isLoading}
                handleChangePageSize={handleChangePageSize}
                handleChangePage={handleChangePage}
            />

        </Stack>
    )
}


const OrdersTable = ({ data, pagination, loading, handleChangePageSize, handleChangePage, companyName, handleSearchByName }: any) => {


    const [invoiceDetailsDialog, setInvoiceDetailsDialog] = useState<IAccounterOrdersItem | null>(null)

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Sipariş Sahibi Adı',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Paket İsmi',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Miktarı - Fiyatı',
        },
        {
            id: 'date',
            numeric: false,
            disablePadding: false,
            label: 'Tarihi',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Aksiyonlar',
        },
    ];

    // const [createInvoice] = useAccounterCreateSalesInvoiceInParachuteForOrderMutation()

    // const handleCreateInvoice = async (row: IAccounterOrdersItem) => {
    //     try {

    //         const response: any = await createInvoice({ orderId: row?._id })

    //         if (response?.data) {
    //             enqueueSnackbar("Tamamlandı")
    //             // setNoteDialog(false)
    //         } else {
    //             enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
    //         }

    //     } catch (error) {
    //         console.log({ error });
    //         enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
    //     }
    // }

    const handleDownloadInvoice = async (row: IAccounterOrdersItem) => {
        try {
            // const response: any = await getDownloadPresignedUrls({ companyId: detailDialog?._id, documentType: "vergiLevhasi" })
            // console.log({ response });
            if (row?.parasutSalesInvoice?.printUrl) {
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.download = "name";
                a.href = row?.parasutSalesInvoice?.printUrl
                a.target = "_blank"
                a.click();

                // navigate(response?.data)
                enqueueSnackbar("Başarılı indirildi")
            } else {
                enqueueSnackbar("Başarılı olunamadı!", { variant: "warning" })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!")
        }
    }


    const DetailItem = ({ name, value }: any) => {

        return (
            <Grid item xs={12} sm={6}>
                <Stack direction="row" spacing={1}>
                    <Typography fontWeight="bold" >
                        {name} :
                    </Typography>
                    <Typography variant='body2' >
                        {value}
                    </Typography>
                </Stack>
            </Grid>
        )
    }


    return (
        <>

            <Card sx={{ p: 3 }}>

                <TableContainer>
                    <Table>

                        <CustomTableHeader
                            headCells={headCells}
                            numSelected={0}
                            onSelectAllClick={() => null}
                            rowCount={data?.data?.length ?? 0}
                        />

                        <TableBody>
                            {
                                !loading ? (
                                    data?.count ? (
                                        data?.data?.map((row: IAccounterOrdersItem, index: number) => {

                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    key={row?._id ?? index}
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        '& > *': {
                                                            whiteSpace: 'nowrap'
                                                        }
                                                    }}
                                                >

                                                    <TableCell align='center'>
                                                        {index + 1 + ((pagination?.page - 1) * pagination?.pageSize)}
                                                    </TableCell>

                                                    <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            {/* <Avatar
                                                                sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                                src={row?.createdBy?.displayImageUrl}
                                                            >
                                                                {row?.createdBy?.displayName?.slice(0, 1)}
                                                            </Avatar> */}
                                                            <Stack direction="column">
                                                                <Typography>
                                                                    {row?.createdBy?.displayName}
                                                                </Typography>
                                                                {/* <Typography variant='body2'>
                                                                    {row?.createdBy?.role}
                                                                </Typography> */}
                                                            </Stack>
                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {row?.product?.name}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {row?.product?.amount} - {row?.product?.price} {row?.product?.currency}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {moment(row?.createdAt).format('DD MMMM YYYY')}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        <Chip
                                                            label={enumFunctions.findAndDisplayName(OrderStatusEnum, row?.orderStatus)}
                                                            color={
                                                                row?.orderStatus === "completed" ? "success"

                                                                    : row?.orderStatus === "paymentCancelled" ? "error"
                                                                        : row?.orderStatus === "paymentFailed" ? "error"

                                                                            : row?.orderStatus === "paymentNotInitiated" ? "warning"
                                                                                : row?.orderStatus === "paymentPartiallyReturned" ? "warning"
                                                                                    : row?.orderStatus === "paymentPending" ? "warning"

                                                                                        : row?.orderStatus === "paymentConvertedToSale" ? "primary"
                                                                                            : row?.orderStatus === "paymentReturned" ? "primary"
                                                                                                : row?.orderStatus === "returned" ? "primary"
                                                                                                    : "info"
                                                            }
                                                            variant="outlined"
                                                            style={{ borderRadius: 100 }}
                                                        />
                                                    </TableCell>

                                                    <TableCell align="left" >
                                                        <Stack direction="row" spacing={1}>

                                                            <IconButton
                                                                sx={{ bgcolor: 'secondary.light' }}
                                                                onClick={() => setInvoiceDetailsDialog(row)}
                                                            >
                                                                <Iconify icon="ph:eye-fill" sx={{ color: 'primary.main' }} />
                                                            </IconButton>


                                                            {

                                                                row?.orderStatus === "completed" ? (
                                                                    !row?.parasutSalesInvoice?.id ? (
                                                                        <>

                                                                            {/* <IconButton
                                                                            sx={{ bgcolor: 'secondary.light' }}
                                                                            onClick={() => handleCreateInvoice(row)}
                                                                        >
                                                                            <Iconify icon="mdi:invoice-text-plus" sx={{ color: 'primary.main' }} />
                                                                        </IconButton> */}

                                                                        </>
                                                                    ) : (
                                                                        <IconButton
                                                                            sx={{ bgcolor: 'secondary.light' }}
                                                                            onClick={() => handleDownloadInvoice(row)}
                                                                        >
                                                                            <Iconify icon="ic:round-download" sx={{ color: 'primary.main' }} />
                                                                        </IconButton>
                                                                    )
                                                                ) : (
                                                                    <>
                                                                    </>
                                                                )

                                                            }

                                                        </Stack>
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableEmptyData colspan={headCells.length} text="Hiç bir şirket bulunamadı!" />
                                    )
                                ) : (
                                    <TableLoading colspan={headCells.length} />
                                )
                            }
                        </TableBody>

                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                    count={data?.count ?? 0} // toplam eleman sayısı
                    rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                    page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                    labelRowsPerPage="Sayfa başına gösterim"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangePageSize}
                />

            </Card>

            <Dialog
                open={!!invoiceDetailsDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setInvoiceDetailsDialog(null)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Stack spacing={3} py={3} >

                        {
                            true ? (
                                <Stack spacing={3}>
                                    {/* <Typography variant='h3'>
                                        Fatura Detayı
                                    </Typography> */}

                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant='h5' color="primary">
                                                Ürün Bilgileri
                                            </Typography>
                                        </Grid>

                                        <DetailItem
                                            name="Ürün"
                                            value={invoiceDetailsDialog?.product?.name}
                                        />

                                        <DetailItem
                                            name="Miktarı"
                                            value={invoiceDetailsDialog?.product?.amount}
                                        />

                                        <DetailItem
                                            name="Fiyatı"
                                            value={invoiceDetailsDialog?.product?.price + ' ' + invoiceDetailsDialog?.product?.currency}
                                        />

                                        <DetailItem
                                            name="İndirim"
                                            value={invoiceDetailsDialog?.product?.discount}
                                        />

                                    </Grid>

                                    <Divider />

                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant='h5' color="primary">
                                                Fatura Sahibi Bilgileri
                                            </Typography>
                                        </Grid>

                                        <DetailItem
                                            name="Şehir"
                                            value={invoiceDetailsDialog?.addresses?.billingAddress?.city}
                                        />

                                        <DetailItem
                                            name="Adres"
                                            value={invoiceDetailsDialog?.addresses?.billingAddress?.address1}
                                        />

                                        <DetailItem
                                            name="Şirket Adı"
                                            value={invoiceDetailsDialog?.addresses?.billingAddress?.companyName}
                                        />

                                        <DetailItem
                                            name="Adı"
                                            value={invoiceDetailsDialog?.addresses?.billingAddress?.fullName}
                                        />

                                        <DetailItem
                                            name="Vergi Numarası"
                                            value={invoiceDetailsDialog?.addresses?.billingAddress?.taxIdentificationNumber}
                                        />

                                        <DetailItem
                                            name="Vergi Kurumu"
                                            value={invoiceDetailsDialog?.addresses?.billingAddress?.taxOffice}
                                        />

                                        <DetailItem
                                            name="Email"
                                            value={invoiceDetailsDialog?.addresses?.billingAddress?.email}
                                        />

                                    </Grid>



                                    <Stack direction="row" justifyContent="end" gap={3}>
                                        <Button
                                            onClick={() => setInvoiceDetailsDialog(null)}
                                            variant='outlined'
                                            color="primary"
                                        >
                                            Kapat
                                        </Button>
                                    </Stack>
                                </Stack>
                            ) : (
                                <CircularProgress color="primary" />
                            )
                        }

                    </Stack>
                </DialogContent>
            </Dialog>

        </>
    )
}