import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL, getAccessToken } from 'utils'


export const paymentApi = createApi({
    reducerPath: 'paymentApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/payment/graphql`,
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + getAccessToken(),
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },

    }),

    tagTypes: [
        "accounter_order",
        "accounter_orders",

        "operation_sendCreditRequest",
        "operation_sendCreditRequests",

        "accounter_sendCreditRequest",
        "accounter_sendCreditRequests",

        "specialist_sendCreditRequest",
        "specialist_sendCreditRequests",

        "management_statistics",

        "management_users"
    ],

    endpoints: (builder) => ({}),

})
