import IBaseResult from "interfaces/IBaseResult";

export interface IAccounterSendCreditVariables {
  receiverIds: string[],
  creditAmountPerReceiver: number
}

export interface IAccounterSendCreditResponse
  extends IBaseResult {}

export const accounter_sendCredits = (
  variables: IAccounterSendCreditVariables
) => {
  const data = JSON.stringify({
    query: `
      mutation accounter_sendCredits($receiverIds: [ID!]!, $creditAmountPerReceiver: Int!) {
        accounter_sendCredits(receiverIds: $receiverIds, creditAmountPerReceiver: $creditAmountPerReceiver) {
          success
        }
      }
        `,
    variables: variables,
  });

  return data;
};
