import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { VisitorsFormTable } from 'sections'
import { specialistRoutes } from '../routes'

export const VisitorFormsPage = () => {

    const navigate = useNavigate()


    return (
        <Stack spacing={3}>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant='h1'>
                    Ziyaretçi Formları
                </Typography>

                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => navigate(specialistRoutes.dashboard.visitorsFormCreate)}
                >
                    Oluştur
                </Button>
            </Stack>

            <VisitorsFormTable />

        </Stack>
    )
}
