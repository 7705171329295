import IBaseResult from "interfaces/IBaseResult";
import { IManagementUpdateVisitForm } from "interfaces/user/visitForm/IVisitForm.graphqlRequest";

export interface IManagementUpdateVisitFormResponse extends IBaseResult {}

export interface IManagementUpdateVisitFormVariables {
  visitFormId: string,
  visitFormInput: IManagementUpdateVisitForm
}

/**
 * @roles admin, specialist
 */
export const management_updateVisitForm = (
  variables: IManagementUpdateVisitFormVariables
) => {
  const data = JSON.stringify({
    query: `
      mutation management_updateVisitForm($visitFormId: ID!, $visitFormInput: UpdateVisitFormInput!) {
        management_updateVisitForm(visitFormId: $visitFormId, visitFormInput: $visitFormInput) {
          success
        }
      }
        `,
    variables: variables,
  });

  return data;
};
