import React, { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { TextField, StandardTextFieldProps } from '@mui/material';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';

interface IFormikTextField extends StandardTextFieldProps {
    name: string,
    upperCase?: boolean
}

// Custom function to sanitize links
const customSanitizeLink = () => {
    const Link = Quill.import('formats/link');
    const originalSanitize = Link.sanitize;

    Link.sanitize = function (url: any) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'http://' + url;
        }
        return originalSanitize.call(this, url);
    };
};

export const FormikQuill = ({ name = "", upperCase = false, ...rest }: IFormikTextField) => {
    const { getFieldProps, getFieldMeta } = useFormikContext<IFormikTextField | any>();
    const quillRef = useRef<ReactQuill | null>(null);

    const error = !!(getFieldMeta(name).touched && getFieldMeta(name).error);
    const helperText = !!getFieldMeta(name).touched && getFieldMeta(name).error && `${getFieldMeta(name).error}`;

    useEffect(() => {
        customSanitizeLink();
        if (quillRef.current) {
            const quillEditor = quillRef.current.getEditor();
            quillEditor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                if (delta.ops) {
                    delta.ops.forEach(op => {
                        if (op.insert && typeof op.insert === 'string' && op.insert.match(/https?:\/\/[^\s]+/)) {
                            if (!op.insert.startsWith('http://') && !op.insert.startsWith('https://')) {
                                op.insert = 'http://' + op.insert;
                            }
                        }
                    });
                }
                return delta;
            });
        }
    }, []);

    return (
        <ReactQuill
            ref={quillRef}
            placeholder='İçerik giriniz...'
            theme="snow"
            value={getFieldProps(name).value}
            onChange={(value: any) => {
                getFieldProps(name).onChange({
                    target: {
                        name: name,
                        value: value
                    }
                });
            }}
            style={{ height: "360px", marginBottom: "40px" }}
        />
    );
};
