import HasTimeStamp from "interfaces/HasTimeStamp";
import IEntity from "interfaces/IEntity";
import { IParasutCustomer } from "interfaces/payment/parasut/parasutCustomer/interface.parasutCustomer";
import { IUserDisplayable } from "interfaces/preview/IUserDisplayable";
import { IBillingInfo } from "interfaces/user/user/IBillingInfo";


interface IManagementUsersItem extends IUserDisplayable, IEntity, HasTimeStamp, IParasutCustomer {
  credit: {
    credit: {
      total: number,
    }
  }
  registeredBy?: IEntity;
  billingInfo?: IBillingInfo;
}

export type IManagementUsersResponse = Array<IManagementUsersItem>

export interface IManagementUsersVariables {
  userIds: string[]
}


/**
 * @roles admin, accounter, operation, specialist
 */
export const management_users = (variables: IManagementUsersVariables) => {


  const data = JSON.stringify({
    query: `
    query management_users($userIds: [ID!]!) {
      management_users(userIds: $userIds) {
        _id
        role
        displayName
        displayImageUrl
        credit {
          credit {
            total
          }
        }
        billingInfo {
          buyer {
            name
            surname
            identityNumber
            city
            country
            email
            gsmNumber
            ip
            registrationAddress
            zipCode
            registrationDate
            lastLoginDate
          }
          billingAddress {
            fullName
            companyName
            phoneNumber
            email
            country
            city
            district
            address1
            address2
            postcode
            taxIdentificationNumber
            taxOffice
          }
        }
        parasutCustomerId
        registeredBy {
          _id
        }
        createdBy {
          _id
    
        }
        updatedBy {
          _id
        }
        createdAt
        updatedAt
      }
    }
        `,
    variables: variables
  })

  return data
}