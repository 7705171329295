import { IAccounterCompaniesItem } from "./_accounter_companies";

export type IAccounterCompanyResponse = IAccounterCompaniesItem | null

export interface IAccounterCompanyVariables {
  companyId: string
}

export const accounter_company = (variables: IAccounterCompanyVariables) => {

  const data = JSON.stringify({
    query: `
      query accounter_company($companyId: ID!) {
        accounter_company(companyId: $companyId) {
          _id
          personalInformation {
            name
            surname
            phoneNumber
            description
          }
          contact {
            phoneNumber
            phoneNumberVerified
            email
            emailVerified
          }
          companyInformation {
            companyName
            phoneNumber
            email
            city
            district
            address
            taxOffice
            taxNumber
            profileImage
            sector
            companyAddress
            foundationYear
            numberOfEmployees
            website
            linkedin
            instagram
            facebook
            companyBrief
            companyDocuments {
              imzaSirkuleri
              vergiLevhasi
            }
          }
          companyOnboardingStatus
          createdAt
          updatedAt
        }
      }
        `,
    variables: variables
  });

  return data;
};
