import { Stack, Typography } from '@mui/material'
import React from 'react'
import { VisitorsFormTable } from 'sections'
import { operationRoutes } from '../routes'

export const VisitorFormsPage = () => {
    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Ziyaretçi Formları
            </Typography>

            <VisitorsFormTable isUpdate={operationRoutes.dashboard.visitorsFormUpdate} />

        </Stack>
    )
}
