

export const operationRoutes = {
    dashboard: {
        home: "/",
        companies: "/companies",
        reminders: "/reminders",
        creditRequests: "/credit-requests",
        visitorsForms: '/visitors-forms',
        visitorsFormUpdate: '/visitors-form-update',
        // advertisements: "/advertisements",
        // ozgeleceks: "/ozgeleceks",
        // references: "/references",
        // createUser: "/create-User",
        conversions: "/conversions",
        conversioncreate: "/conversions/create",

        notpad: "/notpad",
        myAccount: "/myaccount",
        helpCenter: "/help-center",
        settings: "/settings",
    }
}