import { userApi } from "context/user";
import {
  ISpecialistCompaniesResponse,
  ISpecialistCompaniesVariables,
  specialist_companies,
  ISpecialistConversionRequestFormResponse,
  ISpecialistConversionRequestFormVariables,
  specialist_conversionRequestForm,
  ISpecialistConversionRequestFormsResponse,
  ISpecialistConversionRequestFormsVariables,
  specialist_conversionRequestForms,
  ISpecialistCompanyVariables,
  specialist_company,
  ISpecialistCompanyResponse,
} from "./queries";
import {
  ISpecialistUpdateConversionRequestFormResponse,
  ISpecialistUpdateConversionRequestFormVariables,
  specialist_updateConversionRequestForm,
  ISpecialistApproveCompanyResponse,
  ISpecialistApproveCompanyVariables,
  specialist_approveCompany,
  ISpecialistRejectCompanyResponse,
  ISpecialistRejectCompanyVariables,
  specialist_rejectCompany,
  ISpecialistUpdateCompanyResponse,
  ISpecialistUpdateCompanyVariables,
  specialist_updateCompany,
  specialist_approveAdvertisement,
  specialist_rejectAdvertisement,
  specialist_reviseAdvertisement,
  ISpecialistCreateVisitFormResponse,
  specialist_createVisitForm,
  ISpecialistCreateVisitFormVariables,
  specialist_changeCompanyVisibilityForHrAdvisor,
} from "./mutations";

const specialistUserApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    // ####################  Queries #################
    getSpecialistCompany: builder.query<ISpecialistCompanyResponse, ISpecialistCompanyVariables>({
      query: (variables: ISpecialistCompanyVariables) => {
        return {
          url: "",
          body: specialist_company(variables),
        };
      },
      transformResponse: (response: any) => {
        return response.data.specialist_company;
      },
      providesTags: ["specialist_company"],
    }),

    getSpecialistCompanies: builder.query<ISpecialistCompaniesResponse, ISpecialistCompaniesVariables>({
      query: (variables: ISpecialistCompaniesVariables) => {
        return {
          url: "",
          body: specialist_companies(variables),
        };
      },
      transformResponse: (response: any) => {
        return response.data.specialist_companies;
      },
      providesTags: ["specialist_companies"],
    }),

    getSpecialistConversionRequestForm: builder.query<ISpecialistConversionRequestFormResponse, ISpecialistConversionRequestFormVariables>({
      query: (variables: ISpecialistConversionRequestFormVariables) => {
        return {
          url: "",
          body: specialist_conversionRequestForm(variables),
        };
      },
      transformResponse: (response: any) => {
        return response.data.specialist_conversionRequestForm;
      },
      providesTags: ["specialist_conversionRequestForm"],
    }),

    getSpecialistConversionRequestForms: builder.query<ISpecialistConversionRequestFormsResponse, ISpecialistConversionRequestFormsVariables>({
      query: (variables: ISpecialistConversionRequestFormsVariables) => {
        return {
          url: "",
          body: specialist_conversionRequestForms(variables),
        };
      },
      transformResponse: (response: any) => {
        return response.data.specialist_conversionRequestForms;
      },
      providesTags: ["specialist_conversionRequestForms"],
    }),

    // ####################  Mutations #################

    specialistUpdateConversionRequestForm: builder.mutation<ISpecialistUpdateConversionRequestFormResponse, ISpecialistUpdateConversionRequestFormVariables>({
      query: (variables: ISpecialistUpdateConversionRequestFormVariables) => {
        return {
          url: "",
          body: specialist_updateConversionRequestForm(variables),
        };
      },
      transformResponse: (response: any) => {
        return response?.data;
      },
      invalidatesTags: ["specialist_conversionRequestForms"],
    }),

    specialistApproveCompany: builder.mutation<ISpecialistApproveCompanyResponse, ISpecialistApproveCompanyVariables>({
      query: (variables: ISpecialistApproveCompanyVariables) => {
        return {
          url: "",
          body: specialist_approveCompany(variables),
        };
      },
      transformResponse: (response: any) => {
        return response?.data;
      },
      invalidatesTags: ["specialist_companies"],
    }),


    specialistRejectCompany: builder.mutation<ISpecialistRejectCompanyResponse, ISpecialistRejectCompanyVariables>({
      query: (variables: ISpecialistRejectCompanyVariables) => {
        return {
          url: "",
          body: specialist_rejectCompany(variables),
        };
      },
      transformResponse: (response: any) => {
        return response?.data;
      },
      invalidatesTags: ["specialist_companies"],
    }),


    specialistUpdateCompany: builder.mutation<ISpecialistUpdateCompanyResponse, ISpecialistUpdateCompanyVariables>({
      query: (variables: ISpecialistUpdateCompanyVariables) => {
        return {
          url: "",
          body: specialist_updateCompany(variables),
        };
      },
      transformResponse: (response: any) => {
        return response?.data;
      },
      invalidatesTags: ["specialist_companies"],
    }),

    specialistApproveAdvertisement: builder.mutation<any, { advertisementId: string }>({
      query: ({ advertisementId }) => {
        return ({
          url: "",
          body: specialist_approveAdvertisement({ advertisementId })
        })
      },
      transformResponse: (response: any) => {
        return response?.data
      },
      invalidatesTags: ["management_advertisements"]
    }),

    specialistRejectAdvertisement: builder.mutation<any, { advertisementId: string }>({
      query: ({ advertisementId }) => {
        return ({
          url: "",
          body: specialist_rejectAdvertisement({ advertisementId })
        })
      },
      transformResponse: (response: any) => {
        return response?.data
      },
      invalidatesTags: ["management_advertisements"]
    }),

    specialistReviseAdvertisement: builder.mutation<any, { advertisementId: string }>({
      query: ({ advertisementId }) => {
        return ({
          url: "",
          body: specialist_reviseAdvertisement({ advertisementId })
        })
      },
      transformResponse: (response: any) => {
        return response?.data
      },
      invalidatesTags: ["management_advertisements"]
    }),
    
    specialistCreateVisitForm: builder.mutation<ISpecialistCreateVisitFormResponse, ISpecialistCreateVisitFormVariables>({
      query: (variables: ISpecialistCreateVisitFormVariables) => {
        return ({
          url: "",
          body: specialist_createVisitForm(variables)
        })
      },
      transformResponse: (response: any) => {
        return response?.data
      },
      invalidatesTags: ["management_visitForms"]
    }),
    specialistChangeCompanyVisibilityForHrAdvisor: builder.mutation<any, { companyId: string }>({
      query: ({ companyId }) => {
        return ({
          url: "",
          body: specialist_changeCompanyVisibilityForHrAdvisor({ companyId })
        })
      },
      transformResponse: (response: any) => {
        return response?.data
      },
      invalidatesTags: ["specialist_company", "specialist_companies"]
    }),
  }),

  overrideExisting: false,
});

export const {
  // ####################  Queries #################
  useGetSpecialistCompanyQuery,
  useLazyGetSpecialistCompanyQuery,

  useGetSpecialistCompaniesQuery,
  useLazyGetSpecialistCompaniesQuery,

  useGetSpecialistConversionRequestFormQuery,
  useLazyGetSpecialistConversionRequestFormQuery,

  useGetSpecialistConversionRequestFormsQuery,
  useLazyGetSpecialistConversionRequestFormsQuery,

  // ####################  Mutations #################
  useSpecialistUpdateConversionRequestFormMutation,
  useSpecialistApproveCompanyMutation,
  useSpecialistRejectCompanyMutation,
  useSpecialistUpdateCompanyMutation,
  useSpecialistApproveAdvertisementMutation,
  useSpecialistRejectAdvertisementMutation,
  useSpecialistReviseAdvertisementMutation,
  useSpecialistCreateVisitFormMutation,
  useSpecialistChangeCompanyVisibilityForHrAdvisorMutation

} = specialistUserApi;
