import IBaseResult from "interfaces/IBaseResult";
import { IDefaultInputSendCreditRequestStatusChange } from "interfaces/payment/finance/ISendCreditRequest";

export interface IAccounterRejectSendCreditRequestVariables
  extends IDefaultInputSendCreditRequestStatusChange {}

export interface IAccounterRejectSendCreditRequestResponse
  extends IBaseResult {}

export const accounter_rejectSendCreditRequest = (
  variables: IAccounterRejectSendCreditRequestVariables
) => {
  const data = JSON.stringify({
    query: `
          mutation accounter_rejectSendCreditRequest($id: ID!, $statusChangeReason: String) {
            accounter_rejectSendCreditRequest(id: $id, statusChangeReason: $statusChangeReason) {
              success
            }
          }
        `,
    variables: variables,
  });

  return data;
};
