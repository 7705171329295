import { Grid, Stack, Typography } from '@mui/material'
import { ShowCountCard } from 'components'
import React from 'react'
import { useGetCallCenterInformationFormsQuery } from '../context'

const HomePage = () => {

    const { data: informationForms, isLoading: informationFormsLoading } = useGetCallCenterInformationFormsQuery({ pagination: { page: 1, pageSize: 0 } })

    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Home
            </Typography>

            <Grid container>
                <Grid item xs={12} md={6}>
                    <ShowCountCard
                        text='Toplam Bilgi Talebi Sayısı'
                        iconfyIcon="icon-park-outline:transaction-order"
                        count={informationForms?.count}
                        loading={informationFormsLoading}
                    />
                </Grid>
            </Grid>

        </Stack>
    )
}

export default HomePage