

export const callCenterRoutes = {
    dashboard: {
        home: "/",
        informationRequests: "/information-requests",

        // advertisements: "/advertisements",
        // ozgeleceks: "/ozgeleceks",
        // references: "/references",
        // createUser: "/create-User",
        // conversions: "/conversions",

        notpad: "/notpad",
        myAccount: "/myaccount",
        helpCenter: "/help-center",
        settings: "/settings",
    }
}