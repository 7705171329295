import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Link, Stack, InputAdornment, IconButton } from '@mui/material';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';

import { PasswordIcon } from 'assets';
import { RHFTextField, Iconify, FormProvider, RHFCode } from 'components';
import { routes } from 'routes';
import { useBoolean } from 'minimal/hooks/use-boolean';





// ----------------------------------------------------------------------
const ForgotPasswordSchema = Yup.object().shape({
    code: Yup.string().min(6, 'Code must be at least 6 characters').required('Code is required'),
    password: Yup.string()
        .matches(/^(?=.*[a-z])/, 'En az 1 küçük karakter olmalı')
        .matches(/^(?=.*[A-Z])/, 'En az bir büyük karakter olmalı')
        .matches(/^(?=.*[0-9])/, 'En az bir numara içermeli')
        .matches(/^(?=.*[!@#%&?.,+%-_])/, 'En az bir özel karakter içermeli')
        .min(8, "En az 8 karakter içermeli")
        .required('Gerekli'),
    confirmPassword: Yup.string()
        .required('Gerekli')
        .oneOf([Yup.ref('password')], 'Şifreler eşleşmeli!'),
});


export const ConfirmAccountAndCreatePasswordContainer = () => {

    const password = useBoolean();
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const reset = searchParams.get("reset");


    const methods = useForm({
        resolver: yupResolver(ForgotPasswordSchema),
        defaultValues: {
            code: '',
            password: '',
            confirmPassword: '',
        },
    });

    const { handleSubmit, formState: { isSubmitting } } = methods;

    const onSubmit = handleSubmit(async (data) => {

        try {

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_URL}/auth/auth/confirmRegistrationByUser`,
                headers: {
                    'x-auth-token': '',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    code: data.code,
                    password: data.password,
                    token,
                    resetPasswordToken: reset,
                })
            };

            console.log({ config });
            const response = await axios.request(config)

            console.log(response.data)


            if (response?.data?.isSuccess) {
                // sessionStorage.setItem('email-recovery', data.email);
                enqueueSnackbar("Başarılı bir şekilde gönderilmiştir!")
                setTimeout(() => {
                    navigate(routes.auth.forgotPasswordSuccessfull)
                }, 1000)
            } else {
                enqueueSnackbar("Kod gönderilemedi!", {
                    variant: "info"
                })
            }

        } catch (error: any) {
            console.error(error)

            if (error?.response?.data?.message === "EmailIsNotRegistered") {
                enqueueSnackbar("Birşeyler yanlış gitti!", {
                    variant: 'error'
                })
            } else {
                enqueueSnackbar("Birşeyler yanlış gitti!", {
                    variant: 'error'
                })
            }
        }
    });


    const renderHead = (
        <>
            <PasswordIcon sx={{ height: 96 }} />

            <Stack spacing={1} sx={{ my: 5 }}>
                <Typography variant="h3">Hesabınızı Aktif Hale Getirin</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Emailinize gelen kodu giriniz ve yeni bir şifre belirleyerek hesabınızı aktif hale getiriniz.
                </Typography>
            </Stack>
        </>
    );

    const renderForm = (
        <Stack spacing={3} alignItems="center">
            <RHFCode name="code" />

            <RHFTextField
                name="password"
                label="Şifre"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <RHFTextField
                name="confirmPassword"
                label="şifreyi onayla"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                loading={isSubmitting}
            >
                Gönder
            </LoadingButton>

            <Link
                component={RouterLink}
                to={routes.auth.login}
                color="inherit"
                variant="subtitle2"
                sx={{
                    alignItems: 'center',
                    display: 'inline-flex',
                }}
            >
                <Iconify icon="eva:arrow-ios-back-fill" width={16} />
                Geri dön
            </Link>
        </Stack>
    );


    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderHead}
            {renderForm}
        </FormProvider>
    );

}
