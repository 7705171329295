import { IManagementVisitFormsVariables, useGetManagementVisitFormsQuery } from 'apps/management'
import React, { useState } from 'react'

import {
    Card,
    Chip,
    Table,
    Stack,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    CircularProgress,
    TextField,
    Dialog,
    IconButton,
    DialogContent,
    Button,
    Box,
    Link,
    Grid,
} from '@mui/material'
import * as yup from 'yup'

import { enumFunctions } from 'utils'
import { AdvertisementStatusEnum, CompanyOnboardingStatusEnum, NumberOfEmployeeEnum, VisitFormStatusEnum } from 'types'
import { CreateReminderSection, CreditRequestSection, UserNoteToUserSection } from 'sections';
import { CustomTableHeader, FormikTextField, ICustomTableHeadCell, Iconify, MuiTransition, TableEmptyData, TableLoading, } from 'components'
// import { ISpecialistCompaniesItem, ISpecialistCompaniesVariables, useGetSpecialistCompaniesQuery, useSpecialistApproveCompanyMutation, useSpecialistRejectCompanyMutation, useSpecialistSendFreeCreditMutation } from 'context'


import WhatshotOutlinedIcon from '@mui/icons-material/WhatshotOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// import { specialistRoutes } from '../routes';
import { LoadingButton } from '@mui/lab';
import { useLazyGetUserCompanyDocumentDownloadPresignedUrlQuery } from 'context';
import { Formik } from 'formik';

import { IVisitForm } from "interfaces/user/visitForm/IVisitForm"



interface IVisitorsFormTableProps {
    isUpdate?: string
}

export const VisitorsFormTable = ({ isUpdate }: IVisitorsFormTableProps) => {

    const navigate = useNavigate()
    const [variables, setVariables] = useState<IManagementVisitFormsVariables>({
        pagination: {
            page: 1,
            pageSize: 10,
        }
    })

    const { data: visitorForms, isLoading: visitorFormsLoading } = useGetManagementVisitFormsQuery(variables)

    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }

    const [detailDialog, setDetailDialog] = useState<IVisitForm | null>(null)
    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Adı - Soyadı',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },

        {
            id: 'taxOffice',
            numeric: false,
            disablePadding: false,
            label: 'Şirket İsmi',
        },

        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },

        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Aksiyonlar',
        },
    ];


    const HeaderItem = ({ icon, name, value }: { icon?: any, name: string, value: any }) => {
        return (
            <>
                {
                    value && (
                        <Grid item xs={12} sm={6}>
                            <Stack direction="row" spacing={2} alignItems="center">

                                {
                                    icon && (
                                        <Box
                                            sx={{
                                                border: '1px solid #d6ddeb',
                                                borderRadius: '50%',
                                                flexShrink: '0',
                                                width: '50px',
                                                height: '50px',
                                                p: 1,

                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'primary.main',

                                                '&>*': {
                                                    width: '100%',
                                                    height: '100%',
                                                }
                                            }}
                                        >
                                            {icon}
                                        </Box>
                                    )
                                }

                                <Stack direction="row" gap={1} sx={{ '&>*': { whiteSpace: 'nowrap' } }}>
                                    <Typography variant='subtitle2' >
                                        {name} :
                                    </Typography>
                                    <Typography variant='body2'>
                                        {value}
                                    </Typography>
                                </Stack>

                            </Stack>
                        </Grid>
                    )
                }
            </>
        )
    }


    return (
        <>

            <Card sx={{ p: 3 }}>

                <TableContainer>
                    <Table>

                        <CustomTableHeader headCells={headCells} rowCount={visitorForms?.data?.length ?? 0} />

                        <TableBody>
                            {
                                !visitorFormsLoading ? (
                                    visitorForms?.count ? (
                                        visitorForms?.data?.map((row: IVisitForm, index: number) => {

                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    key={index}
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    sx={{ cursor: 'pointer' }}
                                                >

                                                    <TableCell align='center'>
                                                        {index + 1 + (((variables.pagination?.page ?? 1) - 1) * (variables?.pagination?.pageSize ?? 0))}
                                                    </TableCell>

                                                    <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                        <Typography>
                                                            {row?.authorizedPersonName} {row?.authorizedPersonSurname}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {row?.authorizedPersonEmail}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {row?.companyName}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        <Chip
                                                            label={enumFunctions.findAndDisplayName(VisitFormStatusEnum, row?.status)}
                                                            color={
                                                                row?.status === "accepted" ? "success"
                                                                    : row?.status === "rejected" ? "error"
                                                                        : row?.status === "cancelled" ? "default"
                                                                            : row?.status === "pending" ? "warning"
                                                                                : row?.status === "visited" ? "success"
                                                                                    : "info"
                                                            }
                                                            variant="outlined"
                                                            style={{ borderRadius: 100 }}
                                                        />
                                                    </TableCell>

                                                    <TableCell align="left" >
                                                        <Stack direction="row" spacing={1}>
                                                            {/* <UserNoteToUserSection targetId={row?._id} /> */}

                                                            <IconButton
                                                                sx={{ bgcolor: 'secondary.light' }}
                                                                onClick={() => setDetailDialog(row)}
                                                            >
                                                                <Iconify icon="ph:eye" sx={{ color: 'primary.main' }} />
                                                            </IconButton>

                                                            {
                                                                isUpdate && (
                                                                    <IconButton
                                                                        sx={{ bgcolor: 'secondary.light' }}
                                                                        onClick={() => navigate(isUpdate + "/" + row?._id)}
                                                                    >
                                                                        <Iconify icon="ph:note-pencil" sx={{ color: 'primary.main' }} />
                                                                    </IconButton>
                                                                )
                                                            }


                                                        </Stack>
                                                    </TableCell>



                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableEmptyData colspan={headCells.length} text="Hiç bir şirket bulunamadı!" />
                                    )
                                ) : (
                                    <TableLoading colspan={headCells.length} />
                                )
                            }
                        </TableBody>

                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                    count={visitorForms?.count ?? 0} // toplam eleman sayısı
                    rowsPerPage={variables.pagination?.pageSize ?? 1} // sayfadaki eleman sayısı
                    page={(variables.pagination?.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                    labelRowsPerPage="Sayfa başına gösterim"
                    onPageChange={(e: any, value) => handleChangePage(e, value)}
                    onRowsPerPageChange={handleChangePageSize}
                />

            </Card>

            <Dialog
                open={!!detailDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setDetailDialog(null)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Stack spacing={3} py={3} >

                        <Typography variant='h3'>
                            Ziyaret Detayı
                        </Typography>

                        <Grid container spacing={3}>


                            <HeaderItem
                                name="İsim"
                                value={detailDialog?.authorizedPersonName}
                            />

                            <HeaderItem
                                name="Soyisim"
                                value={detailDialog?.authorizedPersonSurname}
                            />



                            <HeaderItem
                                name="Personel Sayısı"
                                value={enumFunctions.findAndDisplayName(NumberOfEmployeeEnum, (detailDialog?.numberOfEmployees ?? "")?.toString())}
                            />

                            <HeaderItem
                                name="Email"
                                value={detailDialog?.authorizedPersonEmail}
                            />






                            <HeaderItem
                                name="Telefon No"
                                value={detailDialog?.authorizedPersonPhoneNumber}
                            />

                            <HeaderItem
                                name="Şehir"
                                value={detailDialog?.city}
                            />
                            <HeaderItem
                                name="İlçe"
                                value={detailDialog?.district}
                            />

                            <HeaderItem
                                name="Ünvan"
                                value={detailDialog?.authorizedPersonTitle}
                            />



                            <HeaderItem
                                name="Şirket İsmi"
                                value={detailDialog?.companyName}
                            />
                            <HeaderItem
                                name="Sektör"
                                value={detailDialog?.sector}
                            />
                            <HeaderItem
                                name="Kuruluş Yılı"
                                value={moment(detailDialog?.createdAt).format('DD MMMM YYYY')}
                            />

                            <HeaderItem
                                name="Statüsü"
                                value={enumFunctions.findAndDisplayName(VisitFormStatusEnum, detailDialog?.status ?? "")}
                            />

                            <HeaderItem
                                name="Websitesi"
                                value={detailDialog?.website}
                            />

                        </Grid>

                        <Stack direction={{ xs: 'column-reverse', sm: "row" }} justifyContent="space-between" gap={3}>
                            <Button
                                onClick={() => setDetailDialog(null)}
                                variant='outlined'
                                color="primary"
                            >
                                Kapat
                            </Button>
                        </Stack>

                    </Stack>
                </DialogContent>
            </Dialog>

        </>
    )
}
