import { Navigate, Route, Routes } from "react-router-dom"
import { ConfirmAccountAndCreatePasswordPage, ForgotPasswordPage, ForgotPasswordSuccessfullPage, LoginPage, RegisterVerifyPage, ResetPasswordPage } from "../pages"
import { routes } from "routes"



export const AuthRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path={routes.auth.login} element={<LoginPage />} />

      <Route path={routes.auth.forgotPassword} element={<ForgotPasswordPage />} />
      <Route path={routes.auth.forgotPasswordSuccessfull} element={<ForgotPasswordSuccessfullPage />} />

      <Route path={routes.auth.resetPassword} element={<ResetPasswordPage />} />

      <Route path={routes.auth.registerVerfiy} element={<RegisterVerifyPage />} />
      <Route path={routes.auth.confirmAccountAndCreatePassword} element={<ConfirmAccountAndCreatePasswordPage />} />


      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
