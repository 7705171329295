

export interface ISpecialistApproveCompanyResponse {
    success: boolean
  }
  
  export interface ISpecialistApproveCompanyVariables {
    companyId: string
  }

export const specialist_approveCompany = (variables: ISpecialistApproveCompanyVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Specialist_approveCompany($companyId: ID!) {
            specialist_approveCompany(companyId: $companyId) {
              success
            }
          }
          `,
        variables: {
            "companyId": variables.companyId
        }
    })

    return data

}