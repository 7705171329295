import IBaseResult from "interfaces/IBaseResult";



export interface IManagementRemoveBlogPostVariables {
  blogPostId: string
}


export interface IManagementRemoveBlogPostResponse extends IBaseResult { }

export const management_removeBlogPost = (
  variables: IManagementRemoveBlogPostVariables
) => {
  const data = JSON.stringify({
    query: `mutation Management_removeBlogPost($blogPostId: ID!) {
      management_removeBlogPost(blogPostId: $blogPostId) {
        success
      }
    }`,
    variables: {
      blogPostId: variables.blogPostId
    }
  });

  return data;
};
