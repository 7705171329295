

import { paymentApi } from "context/payment";
import { IOperationCancelSendCreditRequestResponse, IOperationCancelSendCreditRequestVariables, IOperationCreateSendCreditRequestResponse, IOperationCreateSendCreditRequestVariables, IOperationSendFreeCreditResponse, IOperationSendFreeCreditVariables, operation_cancelSendCreditRequest, operation_createSendCreditRequest, operation_sendFreeCredits } from "./mutations";
import { IOperationSendCreditRequestsResponse, IOperationSendCreditRequestsVariables, operation_sendCreditRequests, IOperationSendCreditRequestResponse, IOperationSendCreditRequestVariables, operation_sendCreditRequest } from "./queries";

const operationPaymentApi = paymentApi.injectEndpoints({
    endpoints: (builder) => ({

        // #################### User Queries #################
        getOperationSendCreditRequests: builder.query<IOperationSendCreditRequestsResponse, IOperationSendCreditRequestsVariables>({
            query: (variables: IOperationSendCreditRequestsVariables) => {
                return ({
                    url: "",
                    body: operation_sendCreditRequests(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.operation_sendCreditRequests
            },
            providesTags: ["operation_sendCreditRequests"]
        }),
        getOperationSendCreditRequest: builder.query<IOperationSendCreditRequestResponse, IOperationSendCreditRequestVariables>({
            query: (variables: IOperationSendCreditRequestVariables) => {
                return ({
                    url: "",
                    body: operation_sendCreditRequest(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.operation_sendCreditRequest
            },
            providesTags: ["operation_sendCreditRequest"]
        }),


        // #################### User Mutations #################
        operationSendFreeCredit: builder.mutation<IOperationSendFreeCreditResponse, IOperationSendFreeCreditVariables>({
            query: (variables: IOperationSendFreeCreditVariables) => {
                return ({
                    url: "",
                    body: operation_sendFreeCredits(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.operation_sendFreeCredits
            }
        }),
        operationCreateSendCreditRequest: builder.mutation<IOperationCreateSendCreditRequestResponse, IOperationCreateSendCreditRequestVariables>({
            query: (variables: IOperationCreateSendCreditRequestVariables) => {
                return ({
                    url: "",
                    body: operation_createSendCreditRequest(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.operation_createSendCreditRequest
            },
            invalidatesTags: ["operation_sendCreditRequest", "operation_sendCreditRequests"]
        }),

        operationCancelSendCreditRequest: builder.mutation<IOperationCancelSendCreditRequestResponse, IOperationCancelSendCreditRequestVariables>({
            query: (variables: IOperationCancelSendCreditRequestVariables) => {
                console.log({ variables });
                return ({
                    url: "",
                    body: operation_cancelSendCreditRequest(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.operation_cancelSendCreditRequest
            },
            invalidatesTags: ["operation_sendCreditRequest", "operation_sendCreditRequests"]
        }),
    }),
})


export const {
    // ########## queries ###############

    useGetOperationSendCreditRequestQuery,
    useLazyGetOperationSendCreditRequestQuery,

    useGetOperationSendCreditRequestsQuery,
    useLazyGetOperationSendCreditRequestsQuery,

    // ########## mutation ###############
    useOperationSendFreeCreditMutation,
    useOperationCreateSendCreditRequestMutation,
    useOperationCancelSendCreditRequestMutation

} = operationPaymentApi