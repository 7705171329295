

export interface IManagementAdvertisementsItem {
  _id: string
  title: string
  status: string
  productType: string
  extraProducts: string
  startDate: string
  quota: string
  quotaVisibleToApplicant: string
  field: string
  department: string
  language: string
  description: string
  // place:string
  workType: string
  locations: Array<{
    city: string
    districts: string[]
  }>
  positionLevel: string
  requiredAgeInterval: string
  requiredEducationLevel: string
  requiredSkills: string
  requiredLanguages: {
    name: string
    reading: string
    writing: string
    listening: string
  }
  militaryServiceRequired: string
  requiredDriverLicense: string
  companyInfoVisible: string
  images: string
  replyNotification: string
  customReplyNotification: {
    content: string
    subject: string
  }
  createdBy: {
    companyInformation: {
      profileImage: string
      companyName: string
    }
  }
  createdAt: string
  updatedAt: string
}


export interface IManagementAdvertisementsResponse {
  count: number;
  data: Array<IManagementAdvertisementsItem>
}

export interface IManagementAdvertisementsVariables {
  filter?: {
    filter?: {
      cities: string[],
      createdById: string,
      department: string,
      description: string,
      districts: string[],
      extraProduct: string,
      field: string,
      language: string,
      militaryServiceRequired: string,
      place: string,
      positionLevel: string,
      productType: string,
      requiredAgeInterval: string,
      requiredDriverLicense: string,
      requiredEducationLevel: string,
      // requiredLanguages: [ ],
      requiredSkills: string,
      startDate: string,
      status: string,
      workType: string
    },
    search?: {
      cities: string[],
      department: string,
      description: string,
      districts: string[],
      title: string,
    }
  },

  pagination: {
    page: number,
    pageSize: number,
    sort?: string
  }
}


/**
 * @roles admin, specialist
 */
export const management_advertisements = (variables: IManagementAdvertisementsVariables) => {


  const data = JSON.stringify({
    query: `
    query management_advertisements($pagination: PaginationWithAdvertisementSort, $filter: FilterAdvertisementOptionForManagement) {
      management_advertisements(pagination: $pagination, filter: $filter) {
        count 
        data {
          _id
          title
          status
          productType
          extraProducts
          startDate
          quota
          quotaVisibleToApplicant
          field
          department
          language
          description
          place
          workType
          locations {
            city
            districts
          }
          positionLevel
          requiredAgeInterval
          requiredEducationLevel
          requiredSkills
          requiredLanguages {
            name
            reading
            writing
            listening
          }
          militaryServiceRequired
          requiredDriverLicense
          companyInfoVisible
          images
          # replyNotification
          # customReplyNotification {
            #   content
            #   subject
            # }
          createdBy {
            companyInformation {
              profileImage
              companyName
            }
          }
          createdAt
          updatedAt
        }
      }
    }
        `,
    variables: {
      "filter": {
        "filter": {
          "cities": null,
          "createdById": null,
          "department": null,
          "description": null,
          "districts": null,
          "extraProducts": null,
          "field": null,
          "language": null,
          "militaryServiceRequired": null,
          "place": null,
          "positionLevel": null,
          "productType": null,
          "requiredAgeInterval": null,
          "requiredDriverLicense": null,
          "requiredEducationLevel": null,
          "requiredLanguages": [
            {
              "name": null
            }
          ],
          "requiredSkills": null,
          "startDate": null,
          "status": null,
          "title": null,
          "workType": null
        },
        "search": {
          "cities": null,
          "department": null,
          "description": null,
          "districts": null,
          "title": null
        }
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
        "sort": variables?.pagination?.sort ?? "updatedAtDesc"
      }
    }
  })

  return data
}