import IBaseResult from "interfaces/IBaseResult";

export interface IOperationSendFreeCreditVariables {
  receiverIds: string[],
  creditAmountPerReceiver: number
}

export interface IOperationSendFreeCreditResponse
  extends IBaseResult {}

export const operation_sendFreeCredits = (
  variables: IOperationSendFreeCreditVariables
) => {
  const data = JSON.stringify({
    query: `
        mutation operation_sendFreeCredits($receiverIds: [ID!]!, $creditAmountPerReceiver: Int!) {
          operation_sendFreeCredits(receiverIds: $receiverIds, creditAmountPerReceiver: $creditAmountPerReceiver) {
            success
          }
        }
        `,
    variables: variables,
  });

  return data;
};
