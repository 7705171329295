export interface IAnonymousBlogPostsItem {
  _id: string;
  title: string;
  slug: string;
  content: string;
  readingTime: string;
  imageUrl: string;
  targetCategories: Array<string>;
  categories: Array<string>;
  author: string;
  shareImageUrl: string;
  description: string;
  language: string;
  createdAt: string;
  updatedAt: string;
}

export interface IAnonymousBlogPostsResponse {
  count: number;
  data: Array<IAnonymousBlogPostsItem>;
}

export interface IAnonymousBlogPostsVariables {
  pagination: {
    page: number;
    pageSize: number;
  },
  filter: {
    targetCategory: null,
    category: null,
    language: null
  }
}

export const anonymous_blogPosts = (
  variables: IAnonymousBlogPostsVariables
) => {
  const data = JSON.stringify({
    query: `query Anonymous_blogPosts($pagination: Pagination, $filter: FilterBlogPost) {
      anonymous_blogPosts(pagination: $pagination, filter: $filter) {
        data {
          _id
          title
          slug
          content
          readingTime
          imageUrl
          targetCategories
          categories
          author
          shareImageUrl
          description
          language
          createdAt
          updatedAt
        }
        count
      }
    }`,
    variables: variables
  });

  return data;
};
