

import { paymentApi } from "context/payment";
import { IAdminSendFreeCreditResponse, IAdminSendFreeCreditVariables, admin_sendFreeCredits } from "./mutations";

const adminPaymentApi = paymentApi.injectEndpoints({
    endpoints: (builder) => ({

        // #################### User Queries #################


        // #################### User Mutations #################
        adminSendFreeCredit: builder.mutation<IAdminSendFreeCreditResponse, IAdminSendFreeCreditVariables>({
            query: (variables: IAdminSendFreeCreditVariables) => {
                return ({
                    url: "",
                    body: admin_sendFreeCredits(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.admin_sendFreeCredits
            },
            invalidatesTags: ["management_users"]
        }),
    }),
})


export const {
    // ########## queries ###############


    // ########## mutation ###############
    useAdminSendFreeCreditMutation,
} = adminPaymentApi