
export interface IUserCreateNoteToUserResponse {
    _id: string;
}

export interface IUserCreateNoteToUserVariables {
    noteInput: {
        content: string,
        title: string,
        targetId: string
    }
}

export const user_createNoteToUser = (variables: IUserCreateNoteToUserVariables) => {

    const data = JSON.stringify({
        query: `
        mutation User_createNoteToUser($noteInput: NoteToUserInput!) {
            user_createNoteToUser(noteInput: $noteInput) {
              _id
            }
          }
        `,
        variables: {
            "noteInput": {
                "content": variables?.noteInput?.content ?? null,
                "title": variables?.noteInput?.title ?? null,
                "targetId": variables?.noteInput?.targetId ?? null
            }
        }

    })

    return data

}