export interface IAdminCompaniesItem {
  _id: string
  personalInformation: {
    name: string
    surname: string
    phoneNumber: string
    description: string
  }
  contact: {
    phoneNumber: string
    phoneNumberVerified: string
    email: string
    emailVerified: string
  }
  companyInformation: {
    companyName: string
    phoneNumber: string
    email: string
    city: string
    district: string
    address: string
    taxOffice: string
    taxNumber: string
    profileImage: string
    sector: string
    companyAddress: string
    foundationYear: string
    numberOfEmployees: string
    website: string
    linkedin: string
    instagram: string
    facebook: string
    companyBrief: string
    companyDocuments: {
      imzaSirkuleri: string
      vergiLevhasi: string
    }
  }
  companyOnboardingStatus: string
  createdAt: string
  updatedAt: string
}
export interface IAdminCompaniesResponse {
  count: number;
  data: Array<IAdminCompaniesItem>;
}

export interface IAdminCompaniesVariables {
  filter?: {
    city?: string;
    companyCity?: string;
    companyDistrict?: string;
    companyName?: string;
    companySector?: string;
    numberOfEmployees?: string | null;
    country?: string;
    name?: string;
    surname?: string;
  };
  pagination: {
    page: number;
    pageSize: number;
    sort: string | null;
  };
}

export const admin_companies = (
  variables: IAdminCompaniesVariables
) => {
  const data = JSON.stringify({
    query: `
          query admin_companies($filter: FilterCompanyInput, $pagination: Pagination) {
            admin_companies(filter: $filter, pagination: $pagination) {
                 count
              data {
                _id
                personalInformation {
                  name
                  surname
                  phoneNumber
                  description
                }
                contact {
                  phoneNumber
                  phoneNumberVerified
                  email
                  emailVerified
                }
                companyInformation {
                  companyName
                  phoneNumber
                  email
                  city
                  district
                  address
                  taxOffice
                  taxNumber
                  profileImage
                  sector
                  companyAddress
                  foundationYear
                  numberOfEmployees
                  website
                  linkedin
                  instagram
                  facebook
                  companyBrief
                  companyDocuments {
                    imzaSirkuleri
                    vergiLevhasi
                  }
                }
                companyOnboardingStatus
                createdAt
                updatedAt
              }
            }
          }
        `,
    variables: {
      filter: {
        city: variables?.filter?.city ?? null,
        companyCity: variables?.filter?.companyCity ?? null,
        companyDistrict: variables?.filter?.companyDistrict ?? null,
        companyName: variables?.filter?.companyName ?? null,
        companySector: variables?.filter?.companySector ?? null,
        numberOfEmployees: variables?.filter?.numberOfEmployees ?? null,
        country: variables?.filter?.country ?? null,
        name: variables?.filter?.name ?? null,
        surname: variables?.filter?.surname ?? null,
      },
      pagination: {
        page: variables?.pagination?.page ?? null,
        pageSize: variables?.pagination?.pageSize ?? null,
        sort: variables?.pagination?.sort ?? null,
      },
    },
  });

  return data;
};
