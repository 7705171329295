import * as yup from 'yup'
import { Formik } from 'formik'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { enqueueSnackbar } from 'notistack'
import { Button, CircularProgress, Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material'

import { FormikDatePicker, FormikTextField, Iconify, MuiTransition } from 'components'
import { useUserCreateReminderMutation, useUserRemoveReminderMutation, useUserUpdateReminderMutation } from 'context'
import { IOperationCompaniesItem } from 'apps/operation'



interface ICreateReminderSectionProps {
    user: IOperationCompaniesItem | any,
    isUpdate?: boolean,
    isDelete?: boolean,
    isDone?: boolean,
    content?: {
        displayName: string,
        displayImageUrl: string,
    },
    forMe?: boolean
}


export const CreateReminderSection = ({ user, isUpdate = false, isDone = false, isDelete = false, content, forMe }: ICreateReminderSectionProps) => {

    const [notDialog, setNoteDialog] = useState(false)
    const [removeDialog, setRemoveDialog] = useState<any>(false)

    const [createReminder, { isLoading: createLoading }] = useUserCreateReminderMutation()
    const [updateReminder, { isLoading: updateLoading }] = useUserUpdateReminderMutation()
    const [removeReminder, { isLoading: removeLoading }] = useUserRemoveReminderMutation()

    console.log(user)

    const validationSchema = yup.object().shape({
        date: yup.string().required("Gerekli!"),
        tags: yup.string().required("Gerekli!"),
        description: yup.string().required("Gerekli!"),
    })

    const initialValues = {
        date: isUpdate ? new Date(user?.remindDate) : "",
        tags: isUpdate ? user?.tags?.join(" , ") : "",
        description: isUpdate ? user?.description : "",
    }

    const handleSubmit = async (values: any) => {
        try {
            if (isUpdate && !!user) {
                const updateResponse: any = await updateReminder({
                    reminderId: user?._id ?? "",
                    input: {
                        ...user,
                        description: values?.description,
                        remindDate: values?.date,
                        tags: values?.tags?.split(","),
                    }
                })
                console.log({ updateResponse });
                if (updateResponse?.data) {
                    enqueueSnackbar("Güncellendi!")
                    setNoteDialog(false)
                } else {
                    enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
                }
            } else {
                const createResponse: any = await createReminder({
                    input: {
                        description: values?.description,
                        content: content ? JSON.stringify(content) : JSON.stringify({
                            displayName: user?.companyInformation?.companyName,
                            displayImageUrl: user?.companyInformation?.profileImage
                        }),
                        priority: "medium",
                        remindDate: values?.date,
                        tags: values?.tags?.split(","),
                        targetId: user?._id,
                        targetType: "organization"
                    }
                })
                console.log({ createResponse });
                if (createResponse?.data) {
                    enqueueSnackbar("Oluşturuldu!")
                    setNoteDialog(false)
                } else {
                    enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
                }
            }



        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }

    const handleRemove = async () => {
        try {

            const removeResponse: any = await removeReminder({ reminderId: user?._id })
            console.log({ removeResponse });

            if (removeResponse?.data) {
                enqueueSnackbar(removeDialog === "finish" ? "Tamamlandı" : "Silindi")
                setNoteDialog(false)
            } else {
                enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }

    const handleMakeDone = async () => {
        try {
            const updateResponse: any = await updateReminder({
                reminderId: user?._id ?? "",
                input: {
                    ...user,
                    status: "completed"
                }
            })
            console.log({ updateResponse });

            if (updateResponse?.data) {
                enqueueSnackbar("Tamamlandı")
                setNoteDialog(false)
            } else {
                enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }

    return (
        <>

            <Stack direction="row" spacing={1}>

                {!forMe ? <IconButton sx={{ bgcolor: 'secondary.light' }} onClick={() => setNoteDialog(true)} title="Hatırlatıcı Oluştur">
                    <Iconify icon="material-symbols:notification-add-rounded" sx={{ color: 'primary.main' }} />
                </IconButton> :
                    <Button onClick={() => setNoteDialog(true)} variant='contained' color="primary">
                        Hatırlatıcı Oluştur
                    </Button>}


                {
                    isDone && (
                        <>

                            <IconButton
                                sx={{ bgcolor: 'secondary.light' }}
                                onClick={() => handleMakeDone()}
                                title='Hatırlatı Yapıldı'
                            >
                                <Iconify icon="mdi:success-bold" sx={{ color: 'primary.main' }} />
                            </IconButton>

                        </>
                    )
                }

                {
                    isDelete && (
                        <>

                            <IconButton
                                sx={{ bgcolor: 'secondary.light' }}
                                onClick={() => setRemoveDialog("delete")}
                                title='Hatırlatıcı Sil'
                            >
                                <Iconify icon="material-symbols:delete" sx={{ color: 'primary.main' }} />
                            </IconButton>

                        </>
                    )
                }
            </Stack>


            <Dialog
                open={!!notDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setNoteDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    {
                        user ? (
                            <Formik
                                validationSchema={validationSchema}
                                initialValues={initialValues}
                                onSubmit={(values) => {
                                    console.log({ values });
                                    handleSubmit(values)
                                }}
                            >
                                {
                                    ({ values, handleSubmit }) => {

                                        return (
                                            <Stack
                                                spacing={3}
                                                py={3}
                                                minWidth={{ xs: "100%", sm: '400px' }}
                                            >

                                                <Typography variant='h3'>
                                                    Hatırlatıcı {isUpdate ? "Güncelle" : "Oluştur"}
                                                </Typography>

                                                <FormikDatePicker
                                                    minDate={new Date(Date.now())}
                                                    name='date'
                                                    label="Alarm Tarihi"
                                                />
                                                <FormikTextField
                                                    name='tags'
                                                    label='Taglar'
                                                    placeholder='Aralarını virgül ile ayırınız'
                                                />
                                                <FormikTextField
                                                    multiline
                                                    minRows={4}
                                                    name="description"
                                                    label="Açıklama"
                                                />


                                                <Stack direction="row" justifyContent="end" gap={3}>
                                                    <Button
                                                        onClick={() => setNoteDialog(false)}
                                                        variant='outlined'
                                                        color="primary"
                                                    >
                                                        Kapat
                                                    </Button>

                                                    <LoadingButton
                                                        variant='contained'
                                                        color="primary"
                                                        onClick={() => handleSubmit()}
                                                        loading={createLoading || updateLoading}
                                                    >
                                                        {isUpdate ? "Güncelle" : "Oluştur"}
                                                    </LoadingButton>
                                                </Stack>

                                            </Stack>
                                        )
                                    }
                                }
                            </Formik>
                        ) : (
                            <CircularProgress color="primary" />
                        )
                    }
                </DialogContent>
            </Dialog>


            <Dialog
                open={!!removeDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setRemoveDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    {
                        user ? (
                            <Stack spacing={3} my={3}>
                                <Typography variant='h3'>
                                    {removeDialog === "finish" ? "Hatırlatıcı Tamamla" : "Hatırlatıcı Sil"}
                                </Typography>

                                <Typography >
                                    {
                                        removeDialog === "finish"
                                            ? "Hatırlatıcının yapıldığına emin misiniz?"
                                            : "Hatırlatıcıyı silmek istediğinize emin misiniz?"
                                    }
                                </Typography>

                                <Stack direction="row" justifyContent="end" gap={3}>
                                    <Button
                                        onClick={() => setRemoveDialog(false)}
                                        variant='outlined'
                                        color="primary"
                                    >
                                        Kapat
                                    </Button>

                                    <LoadingButton
                                        variant='contained'
                                        color="primary"
                                        onClick={() => handleRemove()}
                                        loading={removeLoading}
                                    >
                                        {removeDialog === "finish" ? "Tamamla" : "Sil"}
                                    </LoadingButton>
                                </Stack>

                            </Stack>
                        ) : (
                            <CircularProgress color="primary" />
                        )
                    }
                </DialogContent>
            </Dialog>

        </>
    )
}
