
import { userApi } from "context/user"
import { IAdminOzgeleceksResponse, IAdminOzgeleceksVariables, admin_ozgeleceks, admin_references, IAdminReferencesResponse, IAdminReferencesVariables, IAdminCompaniesResponse, IAdminCompaniesVariables, IAdminCompanyResponse, IAdminCompanyVariables, admin_companies, admin_company } from "./queries"

const adminUserApi = userApi.injectEndpoints({
  endpoints: (builder) => ({


    // ####################  Queries #################
    getAdminCompany: builder.query<IAdminCompanyResponse, IAdminCompanyVariables>({
      query: (variables: IAdminCompanyVariables) => {
        return {
          url: "",
          body: admin_company(variables),
        };
      },
      transformResponse: (response: any) => {
        return response.data.admin_company;
      },
      providesTags: ["admin_company"],
    }),

    getAdminCompanies: builder.query<IAdminCompaniesResponse, IAdminCompaniesVariables>({
      query: (variables: IAdminCompaniesVariables) => {
        return {
          url: "",
          body: admin_companies(variables),
        };
      },
      transformResponse: (response: any) => {
        return response.data.admin_companies;
      },
      providesTags: ["admin_companies"],
    }),

    getAdminOzgeleceks: builder.query<IAdminOzgeleceksResponse, IAdminOzgeleceksVariables>({
      query: (variables: IAdminOzgeleceksVariables) => {
        return ({
          url: "",
          body: admin_ozgeleceks(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.admin_ozgeleceks
      },
      providesTags: ["admin_ozgeleceks"]
    }),

    getAdminReferences: builder.query<IAdminReferencesResponse, IAdminReferencesVariables>({
      query: (variables: IAdminReferencesVariables) => {
        return ({
          url: "",
          body: admin_references(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.admin_references
      },
      providesTags: ["admin_references"]
    }),





    // ####################  Mutations #################

  }),

  overrideExisting: false,
});

export const {
  // ####################  Queries #################
  useGetAdminCompanyQuery,
  useLazyGetAdminCompanyQuery,

  useGetAdminCompaniesQuery,
  useLazyGetAdminCompaniesQuery,

  useGetAdminOzgeleceksQuery,
  useLazyGetAdminOzgeleceksQuery,

  useGetAdminReferencesQuery,
  useLazyGetAdminReferencesQuery,

  // ####################  Mutations #################

} = adminUserApi

