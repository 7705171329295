


export interface IHrAdvisorCandidatesItem {
    _id: string;
    candidateOnboardingStatus: string;
    certificates: Array<{
        certificateInstitution: string;
        issuedDate: string;
        name: string;
        type: string;
        validUntil: string;
    }>
    computerKnowledgeLevel: string;
    contact: {
        phoneNumberVerified: string;
        phoneNumber: string;
        emailVerified: string;
        email: string;
    }
    createdAt: string;
    driverLicense: {
        status: string;
        licenseType: string;
        issueDate: string;
    }
    education: {
        higherEducation: Array<{
            department: string;
            gpa: string;
            graduationDate: string;
            higherEducationDegree: string;
            name: string;
            startDate: string;
        }>
        highSchool: string;
    }
    hobbies: Array<string>;
    languages: Array<{
        writing: string;
        reading: string;
        name: string;
        listening: string;
    }>
    participatedProjects: Array<{
        name: string;
        level: string;
        institution: string;
        details: string;
        date: string;
    }>
    personalInformation: {
        name: string;
        surname: string;
        birthDate: string;
        country: string;
        city: string;
        district: string;
        nationality: string;
        citizenship: string;
        sex: string;
        gender: string;
        genderDetail: string;
        address: string;
        phoneNumber: string;
        profileImageUrl: string;
        backgroundImageUrl: string;
        description: string;
    }
    programmingLanguages: Array<{
        name: string;
        level: string;
    }>
    scholarships: Array<{
        receivingDate: string;
        type: string;
        institution: string;
    }>
    smoking: string;
    updatedAt: string;
    workExperiencesInMonths: string;
}

export interface IHrAdvisorCandidatesResponse {
    count: number;
    data: Array<IHrAdvisorCandidatesItem>
}

export interface IHrAdvisorCandidatesVariables {
    filter?: {
        candidateOnboardingStatus: null,
        certificates: {
            certificateInstitution: null,
            issuedDate: null,
            name: null,
            type: null,
            validUntil: null
        },
        computerKnowledgeLevel: null,
        driverLicense: {
            issueDate: null,
            licenseType: null,
            status: null
        },
        education: {
            highSchool: null,
            higherEducation: {
                department: null,
                gpa: null,
                graduationDate: null,
                higherEducationDegree: null,
                name: null,
                startDate: null
            }
        },
        hobbies: null,
        languages: {
            name: null
        },
        participatedProjects: {
            name: null,
            level: null,
            institution: null,
            details: null,
            date: null
        },
        personalInformation: {
            citizenship: null,
            city: null,
            country: null,
            description: null,
            district: null,
            gender: null,
            name: null,
            nationality: null,
            sex: null,
            surname: null
        },
        programmingLanguages: {
            name: null,
            level: null
        },
        scholarships: {
            type: null,
            receivingDate: null,
            institution: null
        },
        smoking: null,
        workExperiencesInMonths: null
    },

    pagination: {
        page: number,
        pageSize: number,
        sort?: string
    }
}


export const hrAdvisor_candidates = (variables: IHrAdvisorCandidatesVariables) => {


    const data = JSON.stringify({
        query: `
        query hrAdvisor_candidates($filter: FilterCandidateInput, $pagination: Pagination) {
            hrAdvisor_candidates(filter: $filter, pagination: $pagination) {
              count
              data {
                _id
                candidateOnboardingStatus
                certificates {
                  certificateInstitution
                  issuedDate
                  name
                  type
                  validUntil
                }
                computerKnowledgeLevel
                contact {
                  phoneNumberVerified
                  phoneNumber
                  emailVerified
                  email
                }
                createdAt
                driverLicense {
                  status
                  licenseType
                  issueDate
                }
                # education {
                  # higherEducation {
                    # department
                    # gpa
                    # graduationDate
                    # higherEducationDegree
                    # name
                    # startDate
                  # }
                  # highSchool
                # }
                hobbies
                languages {
                  writing
                  reading
                  name
                  listening
                }
                participatedProjects {
                  name
                  level
                  institution
                  # details
                  date
                }
                personalInformation {
                  name
                  surname
                  birthDate
                  country
                  city
                  district
                  nationality
                  citizenship
                  sex
                  gender
                  genderDetail
                  address
                  phoneNumber
                  profileImageUrl
                  backgroundImageUrl
                  description
                }
                programmingLanguages {
                  name
                  level
                }
                scholarships {
                  receivingDate
                  type
                  institution
                }
                smoking
                updatedAt
                workExperiencesInMonths
              }
            }
          }
        `,
        variables: variables
    })

    return data
}