import { useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { Avatar, Box, Button, Card, Chip, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from '@mui/material'

import {
    IManagementAdvertisementsItem,
    IManagementAdvertisementsVariables,
    useGetManagementAdvertisementsQuery,
} from 'apps/management'

import { enumFunctions } from 'utils'
import { AdvertisementStatusEnum, OzgelecekFieldEnum } from 'types'
import { CustomTableHeader, ICustomTableHeadCell, TableEmptyData, TableLoading } from 'components'



export const AdvertisementsPage = () => {

    const [variables, setVariables] = useState<IManagementAdvertisementsVariables>({
        pagination: {
            page: 1,
            pageSize: 10
        }
    })

    const { data: advertisements, isLoading } = useGetManagementAdvertisementsQuery(variables)


    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }



    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                İlanlar
            </Typography>

            <ManagementAdvertisementsTable
                handleChangePage={handleChangePage}
                handleChangePageSize={handleChangePageSize}
                data={advertisements}
                pagination={variables?.pagination}
                loading={isLoading}
            />

        </Stack>
    )
}


const ManagementAdvertisementsTable = ({ data, pagination, loading, handleChangePageSize, handleChangePage }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Şirket Adı / Logosu',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'İlan Adı',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Alan',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },
        // {
        //     id: 'actions',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Aksiyonlar',
        // },
    ];


    return (
        <Card sx={{ p: 3, overflowX: 'auto' }}>

            <TableContainer>
                <Table>

                    <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IManagementAdvertisementsItem, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{
                                                    cursor: 'pointer',
                                                    '& *': {
                                                        whiteSpace: 'nowrap'
                                                    },
                                                }}
                                            >

                                                <TableCell align='center'>
                                                    {index + 1 + ((pagination?.page - 1) * pagination?.pageSize)}
                                                </TableCell>

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={row?.createdBy?.companyInformation?.profileImage}
                                                        >
                                                            {row?.createdBy?.companyInformation?.companyName?.slice(0, 1)}
                                                        </Avatar>
                                                        <Stack direction="column">
                                                            <Typography>
                                                                {row?.createdBy?.companyInformation?.companyName} &nbsp;
                                                            </Typography>
                                                            {/* <Typography>
                                                                {row?.candidate._id} &nbsp;
                                                            </Typography> */}
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="left">{row?.title}</TableCell>

                                                <TableCell align="left">
                                                    {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, row?.field)}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Chip
                                                        label={enumFunctions.findAndDisplayName(AdvertisementStatusEnum, row?.status)}
                                                        color={
                                                            row?.status === "onboardingFirstCompleted" ? "info"
                                                                : row?.status === "onboardingSecondCompleted" ? "info"
                                                                    : row?.status === "onboardingThirdCompleted" ? "info"
                                                                        : row?.status === "onboardingForthCompleted" ? "info"
                                                                            : row?.status === "pendingApproval" ? "secondary"
                                                                                : row?.status === "approved" ? "success"
                                                                                    : row?.status === "revision" ? "warning"
                                                                                        : row?.status === "rejected" ? "error"
                                                                                            : "info"
                                                        }
                                                        variant="outlined"
                                                        style={{ borderRadius: 100 }}
                                                    />
                                                </TableCell>

                                                {/* <TableCell align="left" >
                                                    <TableItemActions advertisement={row} />
                                                </TableCell> */}

                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells?.length} text="Bu alanda hiç bir başvuru bulunamadı" />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                    count={data?.count ?? 0} // toplam eleman sayısı
                    rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                    page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                    labelRowsPerPage="Sayfa başına gösterim"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangePageSize}
                />

            </TableContainer>
        </Card>


    )
}
