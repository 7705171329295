import { Stack, TableCell, TableRow } from '@mui/material'


interface ITableEmptyDataProps {
    text: string,
    colspan: number
}

export const TableEmptyData = ({ text, colspan }: ITableEmptyDataProps) => {
    return (
        <TableRow>
            <TableCell colSpan={colspan ?? 6}>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    py={5}
                    fontSize="18px"
                >
                    {text ?? "Bu alanda hiçbir veri yok!."}
                </Stack>
            </TableCell>
        </TableRow>
    )
}
