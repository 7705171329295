import { CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react'


export interface IDashboardPanelCardProps {
    title: string
    list: Array<{
        name: string
        value: string
    }>,
    loading: boolean
}


export const DashboardPanelCard = ({ title, list, loading }: IDashboardPanelCardProps) => {
    return (
        <Stack
            sx={{
                border: '1px solid #ccc',
                p: 3,
                // backgroundColor: 'secondary.light',
            }}
        >
            <Typography variant='h2' color="primary">
                {title}
            </Typography>

            <Stack>
                {
                    loading ? (
                        <CircularProgress />
                    ) : (
                        list?.map((item, index) => {

                            return (
                                <Stack key={index} direction="row">
                                    <Typography variant='subtitle1'>
                                        {item?.name} :&nbsp;
                                    </Typography>

                                    <Typography color="primary" variant='subtitle1'>
                                        {item?.value}
                                    </Typography>
                                </Stack>
                            )
                        })
                    )
                }
            </Stack>
        </Stack>
    )
}
