

export const routes = {
    auth: {
        // register: "/register",
        // registerSuccessfull: "/register-successfull",
        // registerVerfiy: "/register-verify",

        // login: "/login",
        // forgotPassword: "/forgot-password",
        // forgotPasswordSuccessfull: "/forgot-password-successfull",
        // resetPassword: "/reset-password",

        register: "/kayit-ol",
        registerSuccessfull: "/kayit-basarili",
        registerVerfiy: "/dogrulama",

        login: "/giris-yap",
        forgotPassword: "/sifremi-unuttum",
        forgotPasswordSuccessfull: "/sifremi-unuttum-basarili",
        resetPassword: "/sifre-sifirlama",


        confirmAccountAndCreatePassword: "/sifre-olustur",
    },
    anonymous: {
        main: "/"
    },
}