

export const adminRoutes = {
    dashboard: {
        home: "/",
        companies: "/companies",
        advertisements: "/advertisements",
        ozgeleceks: "/ozgeleceks",
        references: "/references",
        createUser: "/create-User",
        conversions: "/conversions",
        conversionRequests: "/conversion-requests",
        reminders: "/reminders",
        orders: "/orders",
        candidates: "/candidates",
        blog: "/blog",
        createBlog: "/create-blog",
        updateBlog: "/update-blog/:id",
        notpad: "/notpad",
        myAccount: "/myaccount",
        helpCenter: "/help-center",
        settings: "/settings",
    }
}