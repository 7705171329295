import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { accountRoutes } from "./routes"
import { IBottomMenuListItem, IMenuListItem, IProfileBoxUser, Iconify } from "components"
import { logoutReducer, useAppDispatch, useGetSelfUserQuery, useGetUserRemindersQuery } from "context"
import { ExitToApp } from "@mui/icons-material"
import { DashboardLayout } from "layouts"
import { CompaniesPage, CreditRequestsPage, HelpCenterPage, HomePage, MyAccountPage, NotepadPage, OrdersPage, RemindersPage, SettingsPage } from "../pages"
import { Badge } from "@mui/material"




export const AccountingRoutes = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { data: userInfo } = useGetSelfUserQuery({})
    const { data: reminders } = useGetUserRemindersQuery({ pagination: { page: 0, pageSize: 0 }, filter: { status: "inProgress" } })


    const user: IProfileBoxUser | null = {
        fullName: (userInfo?.personalInformation?.name || userInfo?.personalInformation?.surname)
            ? userInfo?.personalInformation?.name + " " + userInfo?.personalInformation?.surname
            : userInfo?.contact?.email ?? "",
        profileImageUrl: userInfo?.personalInformation?.profileImageUrl ?? "",
        role: userInfo?.role ?? ""
    }


    const menuList: Array<IMenuListItem> = [
        {
            items: [
                {
                    title: 'Panel',
                    path: accountRoutes.dashboard.home,
                    icon: <Iconify icon="iconamoon:home" />
                },
                {
                    title: 'Şirketler',
                    path: accountRoutes.dashboard.companies,
                    icon: <Iconify icon="mdi:company" />
                },
                {
                    title: 'Siparişler',
                    path: accountRoutes.dashboard.orders,
                    icon: <Iconify icon="icon-park-outline:transaction-order" />
                },
                {
                    title: 'Hatırlatıcılar',
                    path: accountRoutes.dashboard.reminders,
                    icon: <Iconify icon="carbon:reminder" />,
                    info: <Badge badgeContent={reminders?.count} color="primary" />
                },
                {
                    title: 'Kredi İstekleri',
                    path: accountRoutes.dashboard.creditRequests,
                    icon: <Iconify icon="wpf:bank-cards" />,
                    // info: <Badge badgeContent={reminders?.count} color="primary" />
                },
            ]
        },
        {
            subheader: 'Ayarlar',
            items: [
                {
                    title: 'Ayarlar',
                    path: accountRoutes.dashboard.settings,
                    icon: <Iconify icon="solar:settings-bold-duotone" />
                },
                {
                    title: 'Notepad',
                    path: accountRoutes.dashboard.notpad,
                    icon: <Iconify icon="fluent:notepad-edit-16-regular" />
                },
            ]
        }
    ]

    const bottomMenuList: Array<IBottomMenuListItem> = [
        {
            func: (e) => navigate(accountRoutes.dashboard.myAccount),
            icon: <Iconify icon="uil:setting" width={24} sx={{ marginRight: 1 }} />,
            title: "Hesap Bilgileri"
        },
        {
            func: (e) => dispatch(logoutReducer("")),
            icon: <ExitToApp sx={{ marginRight: 1 }} />,
            title: "Çıkış"
        },
    ]

    return (
        <Routes>
            <Route path="" element={<DashboardLayout menuList={menuList} user={user} bottomMenuList={bottomMenuList} />}>
                <Route path={accountRoutes.dashboard.home} element={<HomePage />} />
                <Route path={accountRoutes.dashboard.companies} element={<CompaniesPage />} />
                <Route path={accountRoutes.dashboard.orders} element={<OrdersPage />} />
                <Route path={accountRoutes.dashboard.reminders} element={<RemindersPage />} />
                <Route path={accountRoutes.dashboard.creditRequests} element={<CreditRequestsPage />} />


                <Route path={accountRoutes.dashboard.notpad} element={<NotepadPage />} />
                <Route path={accountRoutes.dashboard.settings} element={<SettingsPage />} />
                <Route path={accountRoutes.dashboard.helpCenter} element={<HelpCenterPage />} />
                <Route path={accountRoutes.dashboard.myAccount} element={<MyAccountPage />} />

                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    )
}