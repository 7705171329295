import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Box, Container, Drawer, IconButton, Stack, Toolbar } from '@mui/material'

import { Logo } from 'assets';
import { NotificationDrawer } from 'sections';
import { IBottomMenuListItem, IMenuListItem, IProfileBoxUser, NavDefault } from 'components';


interface IDashboardLayoutProps {
    menuList: Array<IMenuListItem>
    bottomMenuList: Array<IBottomMenuListItem>
    user: IProfileBoxUser | null,
}


export const DashboardLayout = ({ menuList, bottomMenuList, user }: IDashboardLayoutProps) => {


    const [mobileDrawer, setMobileDrawer] = useState(false)


    const renderNav = () => (
        <NavDefault menuList={menuList} user={user} bottomMenuList={bottomMenuList} />
    )


    return (
        <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{
                height: '100%',
            }}
        >

            <Box sx={{ height: '100%', display: { xs: 'none', md: 'block' } }}>
                {renderNav()}
            </Box>

            <AppBar position="relative" sx={{ display: { xs: 'block', md: 'none' }, boxShadow: '1px 1px 5px 0px #ccc', py: 1 }}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >

                    <IconButton
                        onClick={() => setMobileDrawer(true)}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            zIndex: -3,
                        }}
                    >
                        <Box
                            component="img"
                            src={Logo}
                            sx={{ width: '100px' }}
                        />
                    </Stack>

                    {/* <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <NotificationDrawer />
                    </Box> */}

                </Toolbar>
            </AppBar>


            <Drawer
                anchor="left"
                open={mobileDrawer}
                onClose={() => setMobileDrawer(false)}
            >
                {renderNav()}
            </Drawer>


            <Box sx={{ overflow: 'auto', width: '100%', }}>
                <Container sx={{ p: 3, ml: 0, py: 8, pt: { xs: 2, sm: 10 }, position: 'relative' }}>
                    {/* <Box sx={{ position: 'absolute', right: 20, top: 30, display: { xs: 'none', md: 'block' } }}>
                        <NotificationDrawer />
                    </Box> */}

                    <Outlet />
                </Container>
            </Box>

        </Stack>
    )

}
