import { ReminderStatusEnum } from "types";

export interface IUserUpdateReminderResponse {
    _id: string;
}

export interface IUserUpdateReminderVariables {
    reminderId: string
    input: {
        content?: string,
        // title: string,
        targetId: string

        remindDate: string,
        tags: Array<string>,
        status: ReminderStatusEnum

        targetType: string,
        priority: string,
        description?: string // mesaj
    }
}

export const user_updateReminder = (variables: IUserUpdateReminderVariables) => {

    const data = JSON.stringify({
        query: `
        mutation User_updateReminder($reminderId: ID!, $input: UpdateReminderInput!) {
            user_updateReminder(reminderId: $reminderId, input: $input) {
              success
            }
          }
        `,
        variables: {
            "reminderId": variables?.reminderId ?? null,

            "input": {
                "content": variables?.input?.content ?? "", // user bilgileri, isim soyisim/companyName
                "description": variables?.input?.description ?? null, // mesaj
                "priority": variables?.input?.priority ?? "medium",
                "remindDate": variables?.input?.remindDate ?? null,
                "tags": variables?.input?.tags ?? null,
                "status": variables?.input?.status ?? null,
                "targetType": variables?.input?.targetType ?? null,
            }
        }

    })

    return data

}