import { useState } from 'react'
import { Avatar, Button, Card, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from '@mui/material'

import { CustomTableHeader, ICustomTableHeadCell, TableEmptyData, TableLoading } from 'components'
// import { ISpecialistConversionsItem, ISpecialistConversionsVariables, specialistRoutes } from 'apps/specialist'
import { operationRoutes, useGetOperationConversionsQuery } from 'apps/operation'
import { useNavigate } from 'react-router-dom'
import { enumFunctions } from 'utils'
import { OzgelecekFieldEnum } from 'types'
import { IOperationConversionVariables } from '../context/user/queries/_operation_conversion'
import { IOperationConversionsItem } from '../context/user/queries/_operation_conversions'



export const ConversionsPage = () => {

    const navigate = useNavigate()
    const [variables, setVariables] = useState<IOperationConversionVariables>({
        id: '', // Add the 'id' property with an empty string value
        pagination: {
            page: 1,
            pageSize: 10
        }
    })

    const { data: conversions, isLoading } = useGetOperationConversionsQuery(variables)

    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }


    return (
        <Stack spacing={3}>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant='h1'>
                    Dönüşümler
                </Typography>

                <Button
                    onClick={() => navigate(operationRoutes.dashboard.conversioncreate)}
                    color='primary'
                    variant='contained'
                >
                    Oluştur
                </Button>
            </Stack>

            <ConversionsTable
                handleChangePage={handleChangePage}
                handleChangePageSize={handleChangePageSize}
                data={conversions}
                pagination={variables?.pagination}
                loading={isLoading}
            />

        </Stack>
    )
}



const ConversionsTable = ({ data, loading, pagination, handleChangePageSize, handleChangePage }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Adı Soyadı',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Alan',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            id: 'job',
            numeric: false,
            disablePadding: false,
            label: 'Meslek',
        },
        {
            id: 'company',
            numeric: false,
            disablePadding: false,
            label: 'Şirket',
        },
        {
            id: 'phone',
            numeric: false,
            disablePadding: false,
            label: 'Telefon',
        },
        // {
        //     id: 'status',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Durumu',
        // },
        // {
        //     id: 'actions',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Aksiyonlar',
        // },
    ];


    return (
        <Card sx={{ p: 3 }}>

            <TableContainer>
                <Table>

                    <CustomTableHeader
                        headCells={headCells}
                        rowCount={data?.data?.length ?? 0}
                    />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IOperationConversionsItem, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ cursor: 'pointer' }}
                                            >

                                                <TableCell align='center'>
                                                    {index + 1 + ((pagination?.page - 1) * pagination?.pageSize)}
                                                </TableCell>

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Typography>
                                                        {row?.name} {row?.surname}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align="left">
                                                    {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, row?.field)}
                                                </TableCell>

                                                <TableCell align="left">{row?.email}</TableCell>

                                                <TableCell align="left">{row?.jobTitle}</TableCell>

                                                <TableCell align="left">{row?.companyName}</TableCell>

                                                <TableCell align="left">{row?.phone}</TableCell>

                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells?.length} text="Bu alanda hiç bir dönüşüm bulunamadı" />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                count={data?.count ?? 0} // toplam eleman sayısı
                rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                labelRowsPerPage="Sayfa başına gösterim"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePageSize}
            />

        </Card>
    )
}
