import { Helmet } from 'react-helmet-async';

import { CompactLayout } from 'layouts';
import { ConfirmAccountAndCreatePasswordContainer } from './ConfirmAccountAndCreatePasswordContainer';


export const ConfirmAccountAndCreatePasswordPage = () => {

  return (
    <CompactLayout>
      <Helmet>
        <title> Reset Password</title>
      </Helmet>
      <ConfirmAccountAndCreatePasswordContainer />
    </CompactLayout>
  )
}
