export interface IAnonymousCreateInformationFormResponse {
  _id: string;
}

export interface IAnonymousCreateInformationFormVariables {
  input: {
    name: string;
    surname: string;
    email: string;
    phoneNumber?: string;
  };
}

export const anonymous_createInformationForm = (
  variables: IAnonymousCreateInformationFormVariables
) => {
  const data = JSON.stringify({
    query: `
        mutation anonymous_createInformationForm($input: InformationFormInput!)  {
          anonymous_createInformationForm(informationFormInput: $input) {
            _id
          }
        }
        `,
    variables: {
      input: {
        name: variables?.input?.name ?? null,
        surname: variables?.input?.surname ?? null,
        email: variables?.input?.email ?? null,
        phoneNumber: variables?.input?.phoneNumber ?? null,
      },
    },
  });

  return data;
};
