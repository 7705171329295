import { HasId } from "interfaces/HasId";
import { ISendCreditRequest } from "interfaces/payment/finance/ISendCreditRequest";

export interface IOperationSendCreditRequestVariables extends HasId {}

export interface IOperationSendCreditRequestResponse
  extends ISendCreditRequest {}

export const operation_sendCreditRequest = (variables: IOperationSendCreditRequestVariables) => {

    const data = JSON.stringify({
        query: `
          query operation_sendCreditRequest($id: ID!) {
            operation_sendCreditRequest(id: $id) {
              _id
              status
              creditAmount
              statusChangeReason
              price
              currency
              createdAt
              updatedAt
            }
          }
        `,
        variables: variables
    })

    return data
}
