import IEntity, { IInsertEntity } from "interfaces/IEntity";
import { IInsertConversionTestResult } from "interfaces/user/conversionDomain/testResult/IConversionTestResult";
import { OzgelecekFieldEnum, SexEnum } from "types";

export interface IOperationCreateConversionResponse {
  input: {
    email: string;
    name: string;
    surname: string;
    sex: SexEnum;
    age: number;
    companyName: string;
    phone: string;
    field: OzgelecekFieldEnum;
    jobTitle: string;
    totalWorkTimeInMonths: number;
    conversionTest: IInsertConversionTestResult[];
    conversionRequestForm?: IInsertEntity;
  };
}

export interface IOperationCreateConversionVariables {

  field: string,
  conversionRequestForm: {
    _id: string
  },

  age: string,
  companyName: string,

  email: string,
  jobTitle: string,
  name: string,
  surname: string,

  phone: string,
  sex: string,
  totalWorkTimeInMonths: string,

  conversionTest: Array<{
    answer: {
      byRange: string,
      byString: string
    },
    question: {
      _id: string
    }
  }>


}

export const operation_createConversion = (
  variables: IOperationCreateConversionVariables
) => {
  const data = JSON.stringify({
    query: `
      mutation operation_createConversion($input: ConversionInput!) {
        operation_createConversion(input: $input) {
          _id
        }
      }
        `,
    variables: { input: variables },
  });

  return data;
};
