import { IconButton, Stack, Typography } from '@mui/material'
import { VisitorFormCreateAndUpdate } from 'sections'
import { useGetOperationCompaniesQuery } from '../context'
import { ArrowBack } from '@mui/icons-material'
import { operationRoutes } from '../routes'
import { useNavigate } from 'react-router-dom'

export const VisitorFormUpdatePage = () => {

    const navigate = useNavigate()
    const { data: companies } = useGetOperationCompaniesQuery({ pagination: { page: 1, pageSize: null } })

    return (
        <Stack spacing={3}>


            <Stack direction="row" gap={1} alignItems="center">

                <IconButton onClick={() => navigate(operationRoutes.dashboard.visitorsForms)} >
                    <ArrowBack />
                </IconButton>

                <Typography variant='h1'>
                    Ziyaretçi Formu Güncelle
                </Typography>

            </Stack>


            <VisitorFormCreateAndUpdate
                companies={
                    companies?.data?.map(item => ({
                        name: item?.companyInformation?.companyName ?? item?._id,
                        value: item?._id
                    })) ?? []
                }
            />

        </Stack>
    )
}
