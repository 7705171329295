import { CreateReminderSection, RemindersTable } from 'sections';
import { Stack, Typography } from '@mui/material'
import { useGetSelfUserQuery } from 'context';

export const RemindersPage = () => {
    const { data: user } = useGetSelfUserQuery({})


    return (
        <Stack spacing={3}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='h1'>
                    Hatırlatıcılar
                </Typography>

                <CreateReminderSection user={
                    {
                        _id: user?._id,
                        companyInformation: {
                            companyName: (user?.personalInformation?.name || user?.personalInformation?.surname)
                                ? user?.personalInformation?.name + " " + user?.personalInformation?.surname
                                : user?.contact?.email ?? "",
                            profileImage: user?.personalInformation?.profileImageUrl,
                        }
                    }
                }
                    forMe={true}
                />
            </Stack>

            <RemindersTable />
        </Stack>
    )
}



