import { useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import {
    Avatar, Box, Button, Card, Chip, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography,
    Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TextField, CircularProgress, Grid
} from '@mui/material'

import {
    IManagementAdvertisementsItem,
    IManagementAdvertisementsVariables,
    useGetManagementAdvertisementsQuery,

    // useAdminRejectAdvertisementMutation,
    // useAdminReviseAdvertisementMutation,
    // useAdminApproveAdvertisementMutation,
} from 'apps/management'

import { enumFunctions } from 'utils'
import { AdvertisementStatusEnum, HigherEducationDegreeEnum, OzgelecekFieldEnum, PlaceOfWorkEnums, PositionLevelEnums, WorkTypeEnums, getNameOfEnum } from 'types'
import { CustomTableHeader, ICustomTableHeadCell, TableEmptyData, TableLoading } from 'components'
import { useSpecialistApproveAdvertisementMutation, useSpecialistRejectAdvertisementMutation, useSpecialistReviseAdvertisementMutation } from '../context'
import { getNameOfDeclaration } from 'typescript'
import { ProductType } from 'enums/payment/product/ProductType'
import moment from 'moment'
import HTMLReactParser from 'html-react-parser'



export const AdvertisementsPage = () => {

    const [variables, setVariables] = useState<IManagementAdvertisementsVariables>({
        pagination: {
            page: 1,
            pageSize: 10
        }
    })

    const { data: advertisements, isLoading } = useGetManagementAdvertisementsQuery(variables)


    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }



    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                İlanlar
            </Typography>

            <AdminAdvertisementsTable
                handleChangePage={handleChangePage}
                handleChangePageSize={handleChangePageSize}
                data={advertisements}
                pagination={variables?.pagination}
                loading={isLoading}
            />

        </Stack>
    )
}


const AdminAdvertisementsTable = ({ data, pagination, loading, handleChangePageSize, handleChangePage }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Şirket Adı / Logosu',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'İlan Adı',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Alan',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Aksiyonlar',
        },
    ];


    return (
        <Card sx={{ p: 3, overflowX: 'auto' }}>

            <TableContainer>
                <Table>

                    <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IManagementAdvertisementsItem, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{
                                                    cursor: 'pointer',
                                                }}
                                            >

                                                <TableCell align='center'>
                                                    {index + 1 + ((pagination?.page - 1) * pagination?.pageSize)}
                                                </TableCell>

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={row?.createdBy?.companyInformation?.profileImage}
                                                        >
                                                            {row?.createdBy?.companyInformation?.companyName?.slice(0, 1)}
                                                        </Avatar>
                                                        <Stack direction="column">
                                                            <Typography>
                                                                {row?.createdBy?.companyInformation?.companyName} &nbsp;
                                                            </Typography>
                                                            {/* <Typography>
                                                                {row?.candidate._id} &nbsp;
                                                            </Typography> */}
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="left" >
                                                    {row?.title}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, row?.field)}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Chip
                                                        label={enumFunctions.findAndDisplayName(AdvertisementStatusEnum, row?.status)}
                                                        color={
                                                            row?.status === "onboardingFirstCompleted" ? "info"
                                                                : row?.status === "onboardingSecondCompleted" ? "info"
                                                                    : row?.status === "onboardingThirdCompleted" ? "info"
                                                                        : row?.status === "onboardingForthCompleted" ? "info"
                                                                            : row?.status === "pendingApproval" ? "default"
                                                                                : row?.status === "approved" ? "success"
                                                                                    : row?.status === "revision" ? "warning"
                                                                                        : row?.status === "rejected" ? "error"
                                                                                            : "info"
                                                        }
                                                        variant="outlined"
                                                        style={{ borderRadius: 100 }}
                                                    />
                                                </TableCell>

                                                <TableCell align="left" >
                                                    <TableItemActions advertisement={row} />
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells?.length} text="Bu alanda hiç bir başvuru bulunamadı" />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                    count={data?.count ?? 0} // toplam eleman sayısı
                    rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                    page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                    labelRowsPerPage="Sayfa başına gösterim"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangePageSize}
                />

            </TableContainer>
        </Card>


    )
}


interface ITableItemActionsProps {
    advertisement: any,
}


const TableItemActions = ({ advertisement }: ITableItemActionsProps) => {

    const [rejectAdvertisement] = useSpecialistRejectAdvertisementMutation()
    const [reviseAdvertisement] = useSpecialistReviseAdvertisementMutation()
    const [approveAdvertisement] = useSpecialistApproveAdvertisementMutation()
    const [advertisementDetail, setAdvertisementDetail] = useState<any>(null)


    const handleDetail = () => {
        console.log({ advertisement });
        setAdvertisementDetail(advertisement)
    }

    const handleApprove = async () => {

        try {

            const response: any = await approveAdvertisement({ advertisementId: advertisement?._id })
            console.log({ response });

            if (response?.data) {
                enqueueSnackbar("Onaylandı!")
            } else {
                enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }

    }


    const handleReject = async () => {

        try {

            const response: any = await rejectAdvertisement({ advertisementId: advertisement?._id })
            console.log({ response });

            if (response?.data) {
                enqueueSnackbar("Onaylandı!")
            } else {
                enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }

    }


    const handleRevise = async () => {

        try {

            const response: any = await reviseAdvertisement({ advertisementId: advertisement?._id })
            console.log({ response });

            if (response?.data) {
                enqueueSnackbar("Onaylandı!")
            } else {
                enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }

    }



    return (
        <Stack direction="column" alignItems="center" justifyContent="end" spacing={1}>

            <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                // flexWrap="wrap"
                sx={{ '&>*': { flex: 1, whiteSpace: 'nowrap' } }}
            >
                <Button color='info' variant='contained' sx={{ px: 2 }} onClick={handleDetail} size='small'>
                    Gözat
                </Button>
                <Button color='success' sx={{ bgcolor: '#06A03E33', px: 2 }} onClick={handleApprove} size='small'>
                    {/* <ThumbUpAltRoundedIcon />  */}
                    Onayla
                </Button>
            </Stack>
            <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                // flexWrap="wrap"
                sx={{ '&>*': { flex: 1, whiteSpace: 'nowrap' } }}
            >

                <Button color='primary' sx={{ bgcolor: '#5344d233 ', px: 2 }} onClick={handleRevise} size='small'>
                    {/* <DeleteForeverIcon />  */}
                    Revize
                </Button>
                <Button color='error' sx={{ bgcolor: '#C9000033', px: 2 }} onClick={handleReject} size='small'>
                    {/* <RefreshIcon />  */}
                    Reddet
                </Button>

            </Stack>

            {/* <UserNoteToUserSection targetId={advertisement?._id} /> */}

            {/* 

          language
          description
        place
          workType
          city
          district
          positionLevel
          requiredAgeInterval
          requiredEducationLevel
          requiredSkills
          requiredLanguages {
            name
            reading
            writing
            listening
          }
          militaryServiceRequired
          requiredDriverLicense
          companyInfoVisible
          images
          createdAt
          updatedAt */}

            <Dialog open={Boolean(advertisementDetail)} onClose={() => setAdvertisementDetail(null)}>
                <DialogTitle>
                    İlan Detayı
                </DialogTitle>
                <DialogContent sx={{ width: { xs: '100%', sm: "600px" } }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} justifyContent="center" alignItems={"center"}>
                                <Avatar src={advertisementDetail?.createdBy?.companyInformation?.profileImage} sx={{ width: 60, height: 60, objectFit: 'cover', border: '2px solid #ccc' }}></Avatar>
                                <Typography fontWeight={"bold"}>{advertisementDetail?.createdBy?.companyInformation?.companyName}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} display={"flex"} flexDirection={"column"} gap={1}>
                            <Typography><strong>İlan Adı:</strong> {advertisementDetail?.title}</Typography>
                            <Typography><strong>Alan:</strong> {getNameOfEnum(OzgelecekFieldEnum, advertisementDetail?.field)}</Typography>
                            <Typography><strong>Departman:</strong> {advertisementDetail?.department}</Typography>
                            <Typography><strong>İlan Durumu:</strong> {getNameOfEnum(AdvertisementStatusEnum, advertisementDetail?.status)}</Typography>
                            <Typography><strong>Ürün Tipi:</strong> {advertisementDetail?.productType === "day15" ? "15 Günlük" : advertisementDetail?.productType === "day30" ? "30 Günlük" : ""}</Typography>
                            <Typography><strong>Ek Ürünler:</strong> {advertisementDetail?.extraProducts?.map((item: any) => item).join(", ")}</Typography>
                            <Typography><strong>İlan Başlangıç Tarihi:</strong> {moment(advertisementDetail?.startDate).format("DD.MM.YYYY")}</Typography>
                            <Typography><strong>Kontenjan:</strong> {advertisementDetail?.quota}</Typography>
                            <Typography><strong>Kontenjan Görünsün:</strong> {advertisementDetail?.quotaVisibleToApplicant ? "Evet" : "Hayır"}</Typography>
                            <Typography><strong>İş Yeri:</strong> {getNameOfEnum(PlaceOfWorkEnums, advertisementDetail?.place)}</Typography>
                            <Typography><strong>Çalışma Şekli:</strong> {getNameOfEnum(WorkTypeEnums, advertisementDetail?.workType)}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} display={"flex"} flexDirection={"column"} gap={1}>
                            <Typography><strong>Şehir:</strong> {advertisementDetail?.city}</Typography>
                            <Typography><strong>İlçe:</strong> {advertisementDetail?.district}</Typography>
                            <Typography><strong>Pozisyon Seviyesi:</strong> {getNameOfEnum(PositionLevelEnums, advertisementDetail?.positionLevel)}</Typography>
                            {/* { value: 1, name: "16-18" },
                            { value: 2, name: "18-25" },
                            { value: 3, name: "25-30" },
                            { value: 4, name: "30-35" },
                            { value: 5, name: "35-40" },
                            { value: 6, name: "40+" }, */}
                            <Typography><strong>Yaş Aralığı:</strong> {advertisementDetail?.requiredAgeInterval?.map((item: any) => item === 1 ? "16-18" : item === 2 ? "18-25" : item === 3 ? "25-30" : item === 4 ? "30-35" : item === 5 ? "35-40" : item === 6 ? "40+" : "").join(", ")}</Typography>
                            <Typography><strong>Eğitim Seviyesi:</strong> {getNameOfEnum(HigherEducationDegreeEnum, advertisementDetail?.requiredEducationLevel)}</Typography>
                            <Typography><strong>Gereken Beceriler:</strong> {advertisementDetail?.requiredSkills?.map((item: any) => item).join(", ")}</Typography>
                            <Typography><strong>Gereken Diller:</strong> {advertisementDetail?.requiredLanguages?.map((item: any) => item?.name).join(", ")}</Typography>
                            <Typography><strong>Askerlik Durumu:</strong> {advertisementDetail?.militaryServiceRequired ? "Yapıldı" : "Muaf"}</Typography>
                            <Typography><strong>Sürücü Belgesi:</strong> {advertisementDetail?.requiredDriverLicense}</Typography>
                            <Typography><strong>Şirket Bilgisi Görünsün:</strong> {advertisementDetail?.companyInfoVisible ? "Evet" : "Hayır"}</Typography>
                            <Typography><strong>Oluşturulma Tarihi:</strong> {moment(advertisementDetail?.createdAt).format("DD.MM.YYYY HH:mm")}</Typography>
                            <Typography><strong>Güncellenme Tarihi:</strong> {moment(advertisementDetail?.updatedAt).format("DD.MM.YYYY HH:mm")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography><strong>İş Tanımı ve Genel Nitelikler:</strong>   {HTMLReactParser(advertisementDetail?.description ?? "")}</Typography>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAdvertisementDetail(null)} color="primary">
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>

        </Stack>
    )
}