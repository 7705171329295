import { useState } from 'react'
import { Avatar, Card, Chip, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from '@mui/material'

import {
    IAdminOzgeleceksItem,
    IAdminOzgeleceksVariables,
    useGetAdminOzgeleceksQuery,
} from '../context'

import { enumFunctions } from 'utils'
import { OzgelecekFieldEnum } from 'types'
import { CustomTableHeader, ICustomTableHeadCell, TableEmptyData, TableLoading } from 'components'




export const OzgeleceksPage = () => {

    const [variables, setVariables] = useState<IAdminOzgeleceksVariables>({

        pagination: {
            page: 1,
            pageSize: 10
        }
    })

    const { data: advertisements, isLoading } = useGetAdminOzgeleceksQuery(variables)


    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }



    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Özgelecekler
            </Typography>

            <AdminOzgeleceksTable
                handleChangePage={handleChangePage}
                handleChangePageSize={handleChangePageSize}
                data={advertisements}
                pagination={variables?.pagination}
                loading={isLoading}
            />

        </Stack>
    )
}



const AdminOzgeleceksTable = ({ data, pagination, loading, handleChangePageSize, handleChangePage }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Aday Adı / Resmi',
        },

        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Alan',
        },

        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Adresi',
        },

        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },
        // {
        //     id: 'actions',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Aksiyonlar',
        // },
    ];


    return (
        <Card sx={{ p: 3 }}>

            <TableContainer>
                <Table>

                    <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IAdminOzgeleceksItem, index: number) => {

                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                sx={{ cursor: 'pointer' }}
                                            >

                                                <TableCell align='center'>
                                                    {index + 1 + ((pagination?.page - 1) * pagination?.pageSize)}
                                                </TableCell>

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={row?.candidate?.personalInformation?.profileImageUrl}
                                                        >
                                                            {row?.candidate?.personalInformation?.name?.slice(0, 1)}
                                                        </Avatar>
                                                        <Stack direction="column">
                                                            <Typography>
                                                                {row?.candidate?.personalInformation?.name} &nbsp;
                                                                {row?.candidate?.personalInformation?.surname}
                                                            </Typography>
                                                            <Typography
                                                                variant='body2'
                                                                sx={{
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    display: 'block',
                                                                    maxWidth: '250px',
                                                                }}
                                                            >
                                                                {row?.candidate?.personalInformation?.description} &nbsp;
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="left">
                                                    {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, row?.field)}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {row?.candidate?.personalInformation?.city}
                                                </TableCell>


                                                <TableCell align="left">
                                                    <Chip
                                                        label={row?.candidate?.candidateOnboardingStatus === "completed" ? "Tamamlandı" : "Tamamlanmadı"}
                                                        color={
                                                            row?.candidate?.candidateOnboardingStatus === "completed" ? "success"
                                                                : row?.candidate?.candidateOnboardingStatus === "notCompleted" ? "warning"
                                                                    : "info"
                                                        }
                                                        variant="outlined"
                                                        style={{ borderRadius: 100 }}
                                                    />
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells?.length} text="Bu alanda hiç bir özgelecek bulunamadı" />
                                )
                            ) : (
                                <TableLoading colspan={headCells?.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                count={data?.count ?? 0} // toplam eleman sayısı
                rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                labelRowsPerPage="Sayfa başına gösterim"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePageSize}
            />

        </Card>
    )
}