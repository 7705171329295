import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL, getAccessToken } from 'utils'
import {
    ICandidateBackgroundImagePresignedUrlResponse,
    ICandidateBackgroundImagePresignedUrlVariables,


    ICandidateTestVideoDownloadPresignedUrlResponse,


    ICandidateTestVideoDownloadPresignedUrlVariables,


    ICandidateTestVideoUploadPresignedUrlResponse,
    ICandidateTestVideoUploadPresignedUrlVariables,
    ICompanyBackgroundImagePresignedUrlResponse,
    ICompanyBackgroundImagePresignedUrlVariables,
    ICompanyLogoImagePresignedUrlResponse,
    ICompanyLogoImagePresignedUrlVariables,
    IUserCompanyDocumentDownloadPresignedUrlResponse,
    IUserCompanyDocumentDownloadPresignedUrlVariables,
    IUserPresignedUrlsProfileResponse,
    IUserPresignedUrlsProfileVariables,
    candidateBackgroundImagePresignedUrl,

    candidateTestVideoDownloadPresignedUrl,

    candidateTestVideoUploadPresignedUrl,
    companyBackgroundImagePresignedUrl,
    companyLogoImagePresignedUrl,
    userPresignedUrlsProfile,
    user_companyDocumentDownloadPresignedUrl,
} from './queries'


export const dmsApi = createApi({
    reducerPath: 'dmsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/dms/graphql`,
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + getAccessToken(),
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },

    }),

    tagTypes: [

    ],

    endpoints: (builder) => ({

        // #################### Dms Queries #################
        candidateBackgroundImagePresignedUrl: builder.query<ICandidateBackgroundImagePresignedUrlResponse, ICandidateBackgroundImagePresignedUrlVariables>({
            query: (variables: ICandidateBackgroundImagePresignedUrlVariables) => {
                return ({
                    url: "",
                    body: candidateBackgroundImagePresignedUrl(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.userPresignedUrls
            }
        }),
        getUserProfileImagePresignedUrl: builder.query<IUserPresignedUrlsProfileResponse, IUserPresignedUrlsProfileVariables>({
            query: (variables: IUserPresignedUrlsProfileVariables) => {
                return ({
                    url: "",
                    body: userPresignedUrlsProfile(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.userPresignedUrls
            }
        }),
        candidateTestVideoUploadPresignedUrl: builder.query<ICandidateTestVideoUploadPresignedUrlResponse, ICandidateTestVideoUploadPresignedUrlVariables>({
            query: (variables: ICandidateTestVideoUploadPresignedUrlVariables) => {
                return ({
                    url: "",
                    body: candidateTestVideoUploadPresignedUrl(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.candidateTestVideoUploadPresignedUrl
            }
        }),
        companyBackgroundImagePresignedUrl: builder.query<ICompanyBackgroundImagePresignedUrlResponse, ICompanyBackgroundImagePresignedUrlVariables>({
            query: (variables: ICompanyBackgroundImagePresignedUrlVariables) => {
                return ({
                    url: "",
                    body: companyBackgroundImagePresignedUrl(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.organizationPresignedUrls
            }
        }),
        companyLogoImagePresignedUrl: builder.query<ICompanyLogoImagePresignedUrlResponse, ICompanyLogoImagePresignedUrlVariables>({
            query: (variables: ICompanyLogoImagePresignedUrlVariables) => {
                return ({
                    url: "",
                    body: companyLogoImagePresignedUrl(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.organizationPresignedUrls
            }
        }),


        getUserCompanyDocumentDownloadPresignedUrl: builder.query<IUserCompanyDocumentDownloadPresignedUrlResponse, IUserCompanyDocumentDownloadPresignedUrlVariables>({
            query: (variables: IUserCompanyDocumentDownloadPresignedUrlVariables) => {
                return ({
                    url: "",
                    body: user_companyDocumentDownloadPresignedUrl(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.user_companyDocumentDownloadPresignedUrl
            }
        }),

        candidateTestVideoDownloadPresignedUrl: builder.query<ICandidateTestVideoDownloadPresignedUrlResponse, ICandidateTestVideoDownloadPresignedUrlVariables>({
            query: (variables: ICandidateTestVideoDownloadPresignedUrlVariables) => {
                return ({
                    url: "",
                    body: candidateTestVideoDownloadPresignedUrl(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data
            }
        }),






        // #################### Dms Mutations #################
        // companyCreateAdvertisement: builder.mutation<any, TCompanyCreateAdvertisementMutationVariables>({
        //     query: ({ variables }) => {
        //         return ({
        //             url: "",
        //             body: company_createAdvertisement({ variables })
        //         })
        //     },
        //     transformResponse: (response: any) => {
        //         return response
        //     },
        //     invalidatesTags: ["company_advertisement"]
        // }),
    }),

})


export const {
    useCandidateBackgroundImagePresignedUrlQuery,
    useLazyCandidateBackgroundImagePresignedUrlQuery,

    useGetUserProfileImagePresignedUrlQuery,
    useLazyGetUserProfileImagePresignedUrlQuery,

    useCandidateTestVideoUploadPresignedUrlQuery,
    useLazyCandidateTestVideoUploadPresignedUrlQuery,

    useCompanyBackgroundImagePresignedUrlQuery,
    useLazyCompanyBackgroundImagePresignedUrlQuery,

    useCompanyLogoImagePresignedUrlQuery,
    useLazyCompanyLogoImagePresignedUrlQuery,

    useGetUserCompanyDocumentDownloadPresignedUrlQuery,
    useLazyGetUserCompanyDocumentDownloadPresignedUrlQuery,


    useCandidateTestVideoDownloadPresignedUrlQuery,
    useLazyCandidateTestVideoDownloadPresignedUrlQuery,
} = dmsApi