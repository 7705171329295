
import { Card, Button, Grid, Stack, Typography, Box, IconButton } from '@mui/material'
import { useLazyGetUserProfileImagePresignedUrlQuery } from 'context';
import { FormikTextField, FormikAutoComplete, } from 'components';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { FormikQuill } from 'components/formik-form-items/FormikQuill';
import { compressImage, findImageContentType, saveImageAndGetUrl } from 'utils';
import { CandidateAdvertisementDetailImage } from 'assets';
import { useManagementCreateBlogPostMutation } from '../context/blog';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';

const initialValues = {
    title: "",
    categories: [],
    targetCategories: [],
    readingTime: "",
    language: "tr",
    content: "",
    description: "",
    imageUrl: "",
}


const validationSchema = yup.object().shape({
    title: yup.string().required("Başlık zorunludur"),
    categories: yup.array().required("Kategoriler zorunludur"),
    targetCategories: yup.array().required("Hedef Kitle zorunludur"),
    readingTime: yup.string().required("Okuma süresi zorunludur"),
    content: yup.string().required("İçerik zorunludur"),
})

const targetCategories = [
    { value: "jobSeeker", name: "İş Arayanlar" },
    { value: "worker", name: "Çalışanlar" },
    { value: "company", name: "Şirketler" },
    { value: "universityStudent", name: "Üniversite Öğrencileri" }
]

const categories = [
    { value: "İşe Alım", name: "İşe Alım" },
    { value: "İş İlanları ", name: "İş İlanları " },
    { value: "Kariyer Gelişimi", name: "Kariyer Gelişimi" },
    { value: "İşe Alım Trendleri ", name: "İşe Alım Trendleri " },
    { value: "Beceri Temelli İşe Alım ", name: "Beceri Temelli İşe Alım " },
    { value: "Beceri Gelişimi", name: "Beceri Gelişimi" },
    { value: "Eğitim", name: "Eğitim" },
    { value: "Teknoloji", name: "Teknoloji" },
    { value: "Sektörel Çalışan İhtiyaçları", name: "Sektörel Çalışan İhtiyaçları" },
    { value: "Çalışan Bağlılığı", name: "Çalışan Bağlılığı" },
    { value: "Kariyer Rehberi", name: "Kariyer Rehberi" },
    { value: "İşletme Rehberi", name: "İşletme Rehberi" },
    { value: "Başarı Hikayeleri", name: "Başarı Hikayeleri" },
    { value: "Özgelecek İpuçları", name: "Özgelecek İpuçları" },
    { value: "Özgelecek İle İşe Alım ", name: "Özgelecek İle İşe Alım " },
    { value: "Girişimcilik ve İnovasyon", name: "Girişimcilik ve İnovasyon" },
    { value: "Çalışma-Yaşam Dengesi", name: "Çalışma-Yaşam Dengesi" },
    { value: "Mülakat Teknikleri", name: "Mülakat Teknikleri" },
    { value: "Yeni Mezun Rehberi", name: "Yeni Mezun Rehberi" },
    { value: "Yapay Zeka", name: "Yapay Zeka" },
    { value: "Mentorluk", name: "Mentorluk" },
    { value: "Meslekler", name: "Meslekler" },
    { value: "Freelance ve Serbest Çalışma", name: "Freelance ve Serbest Çalışma" },
    { value: "Uzaktan Çalışma", name: "Uzaktan Çalışma" },
    { value: "İnsan Kaynakları Trendleri", name: "İnsan Kaynakları Trendleri" },
    { value: "İş Kanunu ve Mevzuat", name: "İş Kanunu ve Mevzuat" },
    { value: "Liderlik", name: "Liderlik" },
    { value: "Genel", name: "Genel" },
    { value: "Sektör İhtiyaçları", name: "Sektör İhtiyaçları" },
    { value: "Çalışma Modelleri", name: "Çalışma Modelleri" },
]


export const CreateBlogPage = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [compressedImage, setCompressedImage] = useState<any>(null)
    const [createBlogPost, { isSuccess, isError, data, error, isLoading }] = useManagementCreateBlogPostMutation()
    const [getPresignUrl] = useLazyGetUserProfileImagePresignedUrlQuery()

    const handleSubmit = async (values: any) => {
        console.log(values)
        try {
            setLoading(true)
            let url;
            if (compressedImage) {
                const contentType = findImageContentType(compressedImage)
                const presigneUrl = await getPresignUrl({ contentType: contentType })
                const isSaved = await saveImageAndGetUrl({
                    file: compressedImage,
                    presignUrl: presigneUrl?.data?.profileImage?.presignedUploadUrl ?? ""
                })
                url = presigneUrl?.data?.profileImage?.url
                console.log({ isSaved, presigneUrl });
            }

            const response = await createBlogPost({
                blogPostInput: {
                    ...values,
                    imageUrl: url ?? ""
                }
            })

            console.log(response, 'response');

            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar('Blog başarıyla oluşturuldu', { variant: 'success' })
            setTimeout(() => {
               navigate(-1)
            }, 1000)
        }
        if (isError) {
            enqueueSnackbar('Blog oluşturulurken bir hata oluştu', { variant: 'error' })
        }
    }, [isSuccess, isError])

    return (
        <Stack spacing={3}>
            <Stack direction='row' justifyContent='flex-start' alignItems='center' mb={4} gap={1}>
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIos />
                </IconButton>
                <Typography variant='h1'>
                    Blog Oluştur
                </Typography>
            </Stack>

            <Card variant='outlined' sx={{ p: 4 }}>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        handleSubmit(values)
                    }}
                >
                    {
                        ({ values, handleSubmit, isValid, setFieldValue }) => {
                            return (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} >
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} >
                                                <FormikTextField
                                                    name='title'
                                                    label='Başlık'
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <FormikAutoComplete
                                                    name='categories'
                                                    label='Kategoriler'
                                                    list={categories}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <Box display='flex' alignItems='center' justifyContent='center' sx={{ height: '100%', minHeight:"120px", border: 1, borderColor: 'divider', borderStyle: 'dashed', borderRadius: 1, position: 'relative' }}>
                                            <img src={compressedImage ? URL.createObjectURL(compressedImage) : CandidateAdvertisementDetailImage}
                                                alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0, right: 0, zIndex: -1 }} />
                                            <Box p={2} display='flex' gap={1} bgcolor={'#FFFFFFAA'} flexDirection='row' alignItems='center' justifyContent='center' sx={{ zIndex: 1 }}>
                                                <Typography variant='body2'>
                                                    Kapak Resmi
                                                </Typography>

                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    component="label"
                                                    htmlFor="profile_image_input"
                                                >
                                                    Yükle
                                                </Button>
                                                <Box
                                                    component={'input'}
                                                    type='file'
                                                    sx={{ display: 'none' }}
                                                    id='profile_image_input'
                                                    accept="image/*"
                                                    onChange={async (event: any) => {
                                                        const file = (event.target as HTMLInputElement).files?.[0];
                                                        const compressedImage = file && await compressImage(file, 0.8)
                                                        console.log({ file, compressedImage });
                                                        setCompressedImage(compressedImage)

                                                    }}
                                                />
                                            </Box>


                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <FormikAutoComplete
                                            name='targetCategories'
                                            label='Hedef Kitle'
                                            list={targetCategories}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <FormikTextField
                                            name='readingTime'
                                            label='Okuma Süresi'
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormikQuill
                                            name='content'
                                            label='İçerik'
                                            fullWidth
                                        />
                                    </Grid>





                                    <Grid item xs={12} >
                                        <LoadingButton
                                            variant='contained'
                                            color="primary"
                                            disabled={loading || !isValid || isLoading || !compressedImage}
                                            loading={loading}
                                            onClick={() => handleSubmit()}
                                        >
                                            Oluştur
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            )
                        }
                    }
                </Formik>
            </Card>
        </Stack>
    )
}



