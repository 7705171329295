

import { paymentApi } from "context/payment";
import { ISpecialistSendFreeCreditResponse, ISpecialistSendFreeCreditVariables, specialist_sendFreeCredits } from "./mutations";

const specialistPaymentApi = paymentApi.injectEndpoints({
    endpoints: (builder) => ({

        // #################### User Queries #################
        
        // #################### User Mutations #################
        specialistSendFreeCredit: builder.mutation<ISpecialistSendFreeCreditResponse, ISpecialistSendFreeCreditVariables>({
            query: (variables: ISpecialistSendFreeCreditVariables) => {
                return ({
                    url: "",
                    body: specialist_sendFreeCredits(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.specialist_sendFreeCredits
            }
        }),        
    }),
})


export const {
    // ########## queries ###############
    
    // ########## mutation ###############
    useSpecialistSendFreeCreditMutation,

} = specialistPaymentApi