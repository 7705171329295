import { ShowCountCard } from 'components'
import { useGetSelfUserQuery } from 'context'
import { Grid, Stack, Typography } from '@mui/material'
import { useGetHrAdvisorCandidatesQuery, useGetHrAdvisorCompaniesQuery } from '../context'



export const HomePage = () => {

    const { data: userInfo } = useGetSelfUserQuery("")
    const { data: candidates, isLoading: candidatesLoading } = useGetHrAdvisorCandidatesQuery({ pagination: { page: 1, pageSize: 1 } })
    const { data: companies, isLoading: companiesLoading } = useGetHrAdvisorCompaniesQuery({ pagination: { page: 1, pageSize: 1 } })

    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Hoşgeldin, {userInfo?.personalInformation?.name} {userInfo?.personalInformation?.surname}
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <ShowCountCard
                        text='Toplam Aday Sayısı'
                        iconfyIcon="formkit:people"
                        count={candidates?.count}
                        loading={candidatesLoading}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <ShowCountCard
                        text='Yönlendirilen Şirket Sayısı'
                        iconfyIcon="mdi:company"
                        count={companies?.count}
                        loading={companiesLoading}
                    />
                </Grid>
            </Grid>

        </Stack>
    )
}