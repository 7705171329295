import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { userApi } from "./user";
import { dmsApi } from "./dms";
import { paymentApi } from "./payment";
import { notificationApi } from "./notification";
import { blogApi } from "./blog";


const store = configureStore({
    devTools: true,
    reducer: {
        auth: authSlice,

        [userApi.reducerPath]: userApi.reducer,
        [dmsApi.reducerPath]: dmsApi.reducer,
        [paymentApi.reducerPath]: paymentApi.reducer,
        [notificationApi.reducerPath]: notificationApi.reducer,
        [blogApi.reducerPath]: blogApi.reducer,

    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(userApi.middleware)
        .concat(dmsApi.middleware)
        .concat(paymentApi.middleware)
        .concat(notificationApi.middleware)
        .concat(blogApi.middleware)

});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch