import { enumFunctions } from "utils"

export type TUserType = "company" | "candidate" | string
// export type TOnboardingStatus = "idle" | "completed" | "notCompleted"
export type TOnboardingStatus = "idle" | "error" | "completed" | "notCompleted" | "pendingRequest" | "pendingApproval" | "approved" | "rejected"

export type TMicroService = "user"

export enum ExtraProductsEnum {
  doping = "doping",
  emergent = "emergent",
  showcase = "showcase",
}

export enum GendersEnum {
  specified = "specified",
  notSpecified = "Not Specified",
}

export enum GendersEnum2 {
  male = "Male",
  female = "Female",
  other = "Other",
}

export enum ProgrammingLanguageLevelsEnum {
  beginner = "beginner",
  intermediate = "intermediate",
  advance = "advance",
}
export enum DriverLicenseStatusEnum {
  exist = "Var",
  notExist = "Yok",
}

export enum LanguageLevelsEnum {
  beginner = "Beginner",
  intermediate = "Intermediate",
  advance = "Advance",
  native = "Native",
}
export enum ComputerKnowledgeLevelsEnum {
  beginner = "Beginner",
  intermediate = "Intermediate",
  advance = "Advance",
  none = "None",
}
export enum NumberOfEmployeesEnum {
  upTo10 = "Up to 10",
  between10_50 = "Between 10-50",
  between51_100 = "Between 51-100",
  between101_250 = "Between 101-250",
  between251_999 = "Between 251-999",
  greaterThan1000 = "Greater Than 1000",
}

export enum CompanyConversionNumberOfEmployeesEnum {
  upTo20 = "upTo20",
  between20_50 = "between20_50",
  between51_100 = "between51_100",
  between101_250 = "between101_250",
  greaterThan251 = "greaterThan251",
}

export enum AdvertisementStatusEnum {
  onboardingFirstCompleted = "Birinci Aşama",
  onboardingSecondCompleted = "İkinci Aşama",
  onboardingThirdCompleted = "Üçüncü Aşama",
  onboardingForthCompleted = "Dördüncü Aşama",
  pendingApproval = "Onay Bekliyor",
  approved = "Onaylandı",
  rejected = "Reddedildi",
  revision = "Revize istendi",
}


export enum GenderEnum {
  specified = "Belirtmek istiyorum",
  notSpecified = "Belirtmek istemiyorum",
}

export enum SexEnum {
  male = "Erkek",
  female = "Kadın",
  notSpecified = "Diğer"
}

export enum ProgrammingLanguageLevelEnum {
  beginner = "Temel",
  intermediate = "Orta",
  advance = "İleri",
}



export enum LanguageLevelEnum {
  beginner = "Temel",
  intermediate = "Orta",
  advance = "İleri",
  native = "Ana Dil",
}

export enum ComputerKnowledgeLevelEnum {
  beginner = "Temel",
  intermediate = "Orta",
  advance = "İleri",
  none = "Yok",
}

export enum NumberOfEmployeeEnum {
  upTo10 = "10'dan Az",
  between10_50 = "10-50 Arası",
  between51_100 = "51-100 Arası",
  between101_250 = "101-250 Arası",
  between251_999 = "251-999 Arası",
  greaterThan1000 = "1000'den Fazla",
}

export enum HigherEducationDegreeEnum {
  associate = "Ön Lisans",
  bachelor = "Lisans",
  master = "Yüksek Lisans",
  doctoral = "Doktora",
}

export enum OzgelecekFieldEnum {
  arge = "Arge",
  satisVePazarlama = "Satış ve Pazarlama",
  bilgiTeknolojileriVeIT = "Bilgi Teknolojileri ve IT",
  yeniMedyaVeDigitalPazarlama = "Yeni Medya ve Dijital Pazarlama",
  muhasebeVeFinans = "Muhasebe ve Finans",
  egitim = "Eğitim",
  muhendislik = "Muhendislik",
  danisma = "Danışma",
  idariIsler = "İdari İşler",
  insanKaynaklari = "İnsan Kaynakları",
  ithalatVeIhracat = "İthalat ve İhracat",
  turizm = "Turizm",
  gayrimenkul = "Gayrimenkul",
  logistik = "Lojistik",
  sigortacilik = "Sigortacılık",
  uretimVeImalat = "Üretim ve İmalat",
  magazacilik = "Mağazacilik",
  cafeVeRestorant = "Kafe ve Restoran",
  cagriMerkezi = "Çagrı Merkezi",
}

export enum CandidateReferenceStatusEnums {
  pending = "Onay Bekliyor",
  informationApproved = "Doğrulandı",
  informationRejected = "Reddedildi",
  referenceApproved = "Kabul Edildi",
  referenceRejected = "Reddedildi",
}

// export enum LanguageEnums {
//     tr = "Türkçe",
//     en = "İngilizce",
//     fr = "Fransızca",
//     de = "Almanca",
//     it = "İtalyanca",
//     pt = "Portekizce",
//     ru = "Rusça",
//     es = "İspanyolca",
// }




export const getNameOfEnum = (enumObject: any, name: string): string | undefined => {
    return enumObject[name];
};

export enum ConversionQuestionsSortOptionEnum {
  indexInc = "indexInc",
  indexDec = "indexDec",
}

export enum ConversionQuestionTypeEnum {
  forConversion = "forConversion",
  forProductivityAndSatisfaction = "forProductivityAndSatisfaction",
}

export enum ConversionCompanyNumberOfEmployeesEnum {
  upTo20 = "upTo20",
  between20_50 = "between10_50",
  between51_100 = "between51_100",
  between101_250 = "between101_250",
  greaterThan251 = "greaterThan251",
}



export enum SendCreditRequestStatusEnum {
  pending = "Bekleniyor",
  cancelled = "İptal Edildi",
  rejected = "Reddedildi",
  approved = "Onaylandı",
}


export enum CompanyOnboardingStatusEnum {
  pendingOnboarding = "Kayıt Bekleniyor",
  pendingRequest = "İstek Bekleniyor",
  pendingApproval = "Onay Bekleniyor",
  approved = "Onaylandı",
  rejected = "Reddedildi",
}

export enum OzgelecekRolesEnum {
  admin = "Yönetici",
  operation = "Operasyon Uzmanı",
  callCenter = "Çağrı Merkezi",
  specialist = "İletişim Uzmanı",
  accounter = "Muhasebe / Finans",
  hrAdvisor = "İK Danışmanı",
}

// export enum ReminderStatusEnum {
//   ongoing = "Devam Ediyor",
//   expired = "Süresi Geçti",
//   completed = "Devam Ediyor",
// }
export enum ReminderStatusEnum {
  scheduled = "Takvime Eklendi",
  inProgress = "Tarihi Geldi",
  expired = "Tarihi Geçti",
  completed = "Tamamlandı",
  cancelled = "İptal Edildi",
}


export enum OrderStatusEnum {
  paymentNotInitiated = "Ödeme Başlatılamadı",
  paymentPending = "Ödeme Devam Ediyor",
  paymentFailed = "Ödeme Hatalı",
  paymentCancelled = "Ödeme İptal Edildi",
  paymentReturned = "Ödeme Kabul Edilemedi",
  paymentPartiallyReturned = "Ödeme Kısmi Kabul Edilmedi",
  paymentConvertedToSale = "Ödemen Satışa Döndü",
  returned = "Ödeme Geri Çevirildi",
  completed = "Tamamlandı",
}


export enum VisitFormStatusEnum {
  pending = "Devam Ediyor",
  cancelled = "İptal Edildi",
  accepted = "Kabul Edildi",
  rejected = "Reddedildi",
  visited = "Ziyaret Edildi",
}


export const VisitFormStatusList = enumFunctions.objectlist(VisitFormStatusEnum)
export const GendersList = enumFunctions.objectlist(GendersEnum)
export const languageLevelsList = enumFunctions.objectlist(LanguageLevelsEnum)
export const DriverLicenseStatusList = enumFunctions.objectlist(DriverLicenseStatusEnum)
export const ProgrammingLanguageLevelsList = enumFunctions.objectlist(ProgrammingLanguageLevelsEnum)
export const ComputerKnowledgeLevelsList = enumFunctions.objectlist(ComputerKnowledgeLevelsEnum)
export const NumberOfEmployeesList = enumFunctions.objectlist(NumberOfEmployeesEnum)
export const SexsList = enumFunctions.objectlist(SexEnum)
export const HigherEducationDegreesList = enumFunctions.objectlist(HigherEducationDegreeEnum)
export const OzgelecekFieldsList = enumFunctions.objectlist(OzgelecekFieldEnum)



// export const getValueOfEnum = (enumObject: any, value: string): string | undefined => {
//   const enumKeys = Object.keys(enumObject);
//   for (const key of enumKeys) {
//     if (enumObject[key] === value) {
//       return key;
//     }
//   }
//   return undefined;
// };

