

export interface ICallCenterNotesToInformationFormItem {
  content: string
  _id: string
  createdAt: string
  createdBy: {
    _id: string
  }
  target: {
    _id: string
  }
  targetType: string
  title: string
  updatedAt: string
}

export interface ICallCenterNotesToInformationFormResponse {
  count: number;
  data: Array<ICallCenterNotesToInformationFormItem>
}

export interface ICallCenterNotesToInformationFormVariables {
  noteInput: {
    content?: string,
    targetId?: string,
    title?: string
  }
}


export const callCenter_notesToInformationForm = (variables: ICallCenterNotesToInformationFormVariables) => {

  const data = JSON.stringify({
    query: `
        query CallCenter_notesToInformationForm($pagination: Pagination, $filter: FilterNoteInput) {
            callCenter_notesToInformationForm(pagination: $pagination, filter: $filter) {
              data {
                content
                _id
                createdAt
                createdBy {
                  _id
                }
                target {
                  _id
                }
                targetType
                title
                updatedAt
              }
              count
            }
          }
        `,
    variables: variables
  })

  return data

}