
import { userApi } from "context/user"
import { ICompanyOzgeleceksResponse, ICompanyOzgeleceksVariables, company_ozgeleceks } from "./queries";


const companyUserApi = userApi.injectEndpoints({
  endpoints: (builder) => ({


    // ####################  Queries #################
    getCompanyOzgeleceks: builder.query<ICompanyOzgeleceksResponse, ICompanyOzgeleceksVariables>({
      query: (variables: ICompanyOzgeleceksVariables) => {
        return ({
          url: "",
          body: company_ozgeleceks(variables)
        })
      },
      transformResponse: (response: any) => {
        return response.data.company_ozgeleceks
      },
      // providesTags: ["company_ozgeleceks"]
    }),

    // ####################  Mutations #################



  }),

  overrideExisting: false,
});

export const {
  // ####################  Queries #################
  useGetCompanyOzgeleceksQuery,
  useLazyGetCompanyOzgeleceksQuery,

  // ####################  Mutations #################

} = companyUserApi

