import { InformationFormTargetRole } from "enums/user/informationForm/InformationFormTargetRole";

export interface ICallCenterInformationFormResponse {
  _id: string;
  name: string;
  surname: string;
  email: string;
  phoneNumber?: string;
  targetRole?: InformationFormTargetRole;
  createdAt: Date;
  updatedAt: Date;
  createdBy: {
    _id: string
  };
  updateBy: {
    _id: string
  }
}

export interface ICallCenterInformationFormVariables {
  informationFormId: string;
}

export const callCenter_informationForm = (
  variables: ICallCenterInformationFormVariables
) => {
  const data = JSON.stringify({
    query: `
      query callCenter_informationForm($informationFormId: ID!) {
        callCenter_informationForm(informationFormId: $informationFormId) {
          _id
          name
          surname
          email
          phoneNumber
          targetRole
          createdAt
          updatedAt
          createdBy {
            _id
          }
          updateBy {
            _id
          }
        }
      }
        `,
    variables: variables
  });

  return data;
};
