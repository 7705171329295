import { HasId } from "interfaces/HasId";
import { HasInput } from "interfaces/HasInput";
import IBaseResult from "interfaces/IBaseResult";
import { IInsertEntity } from "interfaces/IEntity";
import { OzgelecekFieldEnum, SexEnum } from "types";

export interface IOperationUpdateConversionResponse extends IBaseResult {}

export interface IOperationUpdateConversionVariables
  extends HasId,
    HasInput<{
      email?: string;
      name?: string;
      surname?: string;
      sex?: SexEnum;
      age?: number;
      companyName?: string;
      phone?: string;
      field?: OzgelecekFieldEnum;
      jobTitle?: string;
      totalWorkTimeInMonths?: number;
      conversionRequestForm?: IInsertEntity;
    }> {}

export const operation_updateConversion = (
  variables: IOperationUpdateConversionVariables
) => {
  const data = JSON.stringify({
    query: `
      mutation operation_updateConversion($id: ID!, $input: UpdateConversionInput!) {
        operation_updateConversion(id: $id, input: $input) {
          success
        }
      }
        `,
    variables: variables,
  });

  return data;
};
