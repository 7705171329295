export interface IHrAdvisorDiscoverFormsItem {
  _id: string;
  position?: string;
  workingType?: string;
  estimatedStartDate?: string;
  gender?: string;
  ageInterval?: string;
  numberOfEmployee?: string;
  shiftNumber?: string;
  disabledStatus?: string;
  workDescription?: string;
  desiredFeatures?: string;
  wage?: string;
  premium?: string;
  bonus?: string;
  travelExpenses?: string;
  mealExpenses?: string;
  education?: string;
  foreignLanguage?: string;
  workerMeetingDate?: string;
  serviceRoute?: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: any;
  updateBy: {
    _id: string
  }
}

export interface IHrAdvisorDiscoverFormsResponse {
  count: number,
  data: Array<IHrAdvisorDiscoverFormsItem>
}

export interface IHrAdvisorDiscoverFormsVariables {
  filter?: {
    position?: string;
    workingType?: string;
    estimatedStartDate?: string;
    gender?: string;
    ageInterval?: string;
    numberOfEmployee?: string;
    shiftNumber?: string;
    disabledStatus?: string;
    workDescription?: string;
    desiredFeatures?: string;
    wage?: string;
    premium?: string;
    bonus?: string;
    travelExpenses?: string;
    mealExpenses?: string;
    education?: string;
    foreignLanguage?: string;
    workerMeetingDate?: string;
    serviceRoute?: string;
  };
  pagination?: {
    page?: number,
    pageSize?: number
    sort?: string
  };
}

export const hrAdvisor_discoverForms = (
  variables: IHrAdvisorDiscoverFormsVariables
) => {
  const data = JSON.stringify({
    query: `
    query hrAdvisor_discoverForms($filter: FilterDiscoverFormInput, $pagination: Pagination) {
      hrAdvisor_discoverForms(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          position
          workingType
          estimatedStartDate
          gender
          ageInterval
          numberOfEmployee
          shiftNumber
          disabledStatus
          workDescription
          desiredFeatures
          premium
          bonus
          travelExpenses
          mealExpenses
          education
          workerMeetingDate
          serviceRoute
          createdAt
          updatedAt
          createdBy {
                  _id
                  companyInformation {
                    companyName
                    email
                  }
                  contact {
                    email
                  }
                  personalInformation {
                    name
                    surname
                  }
                  role
          }
          updateBy {
            _id
          }
        }
      }
    }
        `,
    variables: variables
  });

  return data;
};
