import {
    Card,
    Chip,
    Stack,
    Table,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    CircularProgress,
} from '@mui/material'


import { useState } from 'react'
import { enumFunctions } from 'utils'
import { AdvertisementStatusEnum, ReminderStatusEnum } from 'types'
import { CreateReminderSection, RemindersTable, UserNoteToUserSection } from 'sections';
import { CustomTableHeader, ICustomTableHeadCell, TableEmptyData, TableLoading } from 'components'
import { IUserRemindersItem, IUserRemindersVariables, useGetUserRemindersQuery, useGetSelfUserQuery } from 'context';
import moment from 'moment';



export const RemindersPage = () => {
    const { data: user } = useGetSelfUserQuery({})
    const [variables, setVariables] = useState<IUserRemindersVariables>({
        pagination: {
            page: 1,
            pageSize: 10,
        }
    })

    const { data, isLoading } = useGetUserRemindersQuery(variables)


    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }

    return (
        <Stack spacing={3}>

            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='h1'>
                    Hatırlatıcılar
                </Typography>

                <CreateReminderSection user={
                    {
                        _id: user?._id,
                        companyInformation: {
                            companyName: (user?.personalInformation?.name || user?.personalInformation?.surname)
                                ? user?.personalInformation?.name + " " + user?.personalInformation?.surname
                                : user?.contact?.email ?? "",
                            profileImage: user?.personalInformation?.profileImageUrl,
                        }
                    }
                }
                    forMe={true}
                />
            </Stack>
            <RemindersTable />


            {/* <Stack>
                <OperationRemindersTable
                    data={data}
                    pagination={variables?.pagination}
                    loading={isLoading}
                    handleChangePageSize={handleChangePageSize}
                    handleChangePage={handleChangePage}
                // handleSearchByName={handleSearchByName}
                // companyName={variables?.filter?.companyName}
                />
            </Stack> */}
        </Stack>
    )
}


const OperationRemindersTable = ({ data, pagination, loading, handleChangePageSize, handleChangePage }: any) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Şirket Adı / Logosu',
        },
        {
            id: 'desctiption',
            numeric: false,
            disablePadding: false,
            label: 'Açıklama',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Tarihi',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Taglar',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },

        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Aksiyonlar',
        },
    ];

    const [selected, setSelected] = useState<readonly number[]>([]);

    const handleSelectOne = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.checked) {
            const newSelected = data?.data?.map((n: any) => n?._id);
            setSelected(newSelected ?? []);
            return;
        }
        setSelected([]);
    };


    const isSelected = (id: number) => selected?.indexOf(id) !== -1;



    return (
        <Card sx={{ p: 3 }}>

            <TableContainer>
                <Table>

                    <CustomTableHeader
                        headCells={headCells}
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={data?.data?.length ?? 0}
                    />

                    <TableBody>
                        {
                            !loading ? (
                                data?.count ? (
                                    data?.data?.map((row: IUserRemindersItem, index: number) => {

                                        const isItemSelected = isSelected(index);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        const userInfo = JSON.parse(row?.content ?? "{}")


                                        return (
                                            <TableRow
                                                key={row?._id ?? index}
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                selected={isItemSelected}
                                                sx={{ cursor: 'pointer' }}
                                            >

                                                <TableCell align='center'>
                                                    {index + 1 + ((pagination?.page - 1) * pagination?.pageSize)}
                                                </TableCell>

                                                <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Avatar
                                                            sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                            src={userInfo?.profileImage}
                                                        >
                                                            {userInfo?.companyName?.slice(0, 1)}
                                                        </Avatar>
                                                        <Stack direction="column">
                                                            <Typography>
                                                                {userInfo?.companyName}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {row?.description?.slice(0, 20)}
                                                </TableCell>


                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        whiteSpace: "nowrap"
                                                    }}
                                                >
                                                    {moment(row?.remindDate).format("DD MMM YYYY")}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Stack direction="row" gap="5px">
                                                        {
                                                            row?.tags?.map((tag, index) => {
                                                                return (<Chip label={tag} key={index} variant='outlined' color='primary' />)
                                                            })
                                                        }
                                                    </Stack>
                                                </TableCell>


                                                <TableCell align="left">
                                                    <Chip
                                                        label={enumFunctions.findAndDisplayName(ReminderStatusEnum, row?.status)}
                                                        color={
                                                            row?.status === "completed" ? "info"
                                                                : row?.status === "expired" ? "error"
                                                                    : row?.status === "ongoing" ? "success"
                                                                        : "info"
                                                        }
                                                        variant="outlined"
                                                        style={{ borderRadius: 100 }}
                                                    />
                                                </TableCell>

                                                <TableCell align="left" >
                                                    <CreateReminderSection user={row} isUpdate isDelete />
                                                </TableCell>



                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableEmptyData colspan={headCells.length} text="Hiç bir hatırlatıcı bulunamadı!" />
                                )
                            ) : (
                                <TableLoading colspan={headCells.length} />
                            )
                        }
                    </TableBody>

                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                count={data?.count ?? 0} // toplam eleman sayısı
                rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                labelRowsPerPage="Sayfa başına gösterim"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePageSize}
            />

        </Card>
    )
}
