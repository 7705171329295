import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import store from 'context/store';
import { MainRouter } from 'routes';
import ThemeProvider from 'minimal/theme';
import SnackbarProvider from 'minimal/components/snackbar/snackbar-provider';




function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider>
          <SnackbarProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MainRouter />
            </LocalizationProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
