import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { InputAdornment, Typography, IconButton, Stack, Link } from '@mui/material';

import { routes } from 'routes';
import { SentIcon } from 'assets';
import { Iconify } from 'components';
import { useBoolean } from 'minimal/hooks/use-boolean';
import { FormProvider, RHFTextField } from 'components';


// ----------------------------------------------------------------------
const NewPasswordSchema = Yup.object().shape({
  // code: Yup.string().min(6, '6 karakter içermeli!').required('Gerekli'),
  // email: Yup.string().required('Gerekli').email('Geçerli bir email adresi giriniz!'),
  password: Yup.string()
    .matches(/^(?=.*[a-z])/, 'En az 1 küçük karakter olmalı')
    .matches(/^(?=.*[A-Z])/, 'En az bir büyük karakter olmalı')
    .matches(/^(?=.*[0-9])/, 'En az bir numara içermeli')
    .matches(/^(?=.*[!@#%&?.,+%-_])/, 'En az bir özel karakter içermeli')
    .min(8, "En az 8 karakter içermeli")
    .required('Gerekli'),
  confirmPassword: Yup.string()
    .required('Gerekli')
    .oneOf([Yup.ref('password')], 'Şifreler eşleşmeli!'),
});



export const ResetPasswordContainer = () => {

  const password = useBoolean();
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");



  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(NewPasswordSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const { handleSubmit, formState: { isSubmitting } } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/auth/auth/resetpassword`,
        headers: {
          'x-auth-token': '',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          token,
          password: data.password
        })
      };

      const response = await axios.request(config)

      console.log(response.data)

      if (response?.data?.isSuccess) {
        // sessionStorage.removeItem('email-recovery');
        enqueueSnackbar('Şifre değiştirildi!');
        setTimeout(() => {
          navigate(routes.auth.login);
        }, 1000)
      } else {
        enqueueSnackbar('Birşeyler yanlış gitti!', { variant: "info" });
      }

    } catch (error: any) {
      console.error(error);

      if (error?.response?.data?.message === "RequestValidationError") {
        error?.response?.data?.errors?.forEach((item: any) => {
          enqueueSnackbar(item?.msg, { variant: "warning" });
        })
      } else if (error?.response?.data?.message === "PasswordMustBeDifferentFromPrevious") {
        enqueueSnackbar("Önceki şifreden farklı olmalı", { variant: "warning" });
      } else if (error?.response?.data?.message === "invalid token") {
        enqueueSnackbar("Yanlış token, yeni link gönderin!", { variant: "warning" });

      } else {
        enqueueSnackbar('Birşeyler yanlış gitti!', { variant: "error" });
      }
    }
  });



  const renderForm = (
    <Stack spacing={3} alignItems="center">

      <RHFTextField
        name="password"
        label="Şifre"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <RHFTextField
        name="confirmPassword"
        label="şifreyi onayla"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="primary"
        loading={isSubmitting}
      >
        Şifreyi güncelle
      </LoadingButton>

      <Typography variant="body2">
        Kod gelmedi mi? &nbsp;
        <Link variant="subtitle2" component={RouterLink} to={routes.auth.forgotPassword}>Tekrar gönder</Link>
      </Typography>

      <Link
        component={RouterLink}
        to={routes.auth.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          mx: 'auto',
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:chevron-left-fill" width={16} />
        Giriş sayfasına geri dön
      </Link>


    </Stack>
  );

  const renderHead = (
    <>
      <SentIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ my: 5 }}>
        <Typography variant="h3">İstek başarılı bir şekilde gönderilmiştir!</Typography>

        {/* <Typography sx={{ color: 'text.secondary' }} variant='body2'>
          Email hesabınıza 6 haneli onaylama kodu gönderdik.

          <br />
          Lütfen email adresinizi onaylamak için,
          gelen kodu giriniz.
        </Typography> */}
      </Stack>
    </>
  );


  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}
    </FormProvider>
  );
}
