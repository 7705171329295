import { User } from 'assets'
import { useState } from 'react';
import { Menu, MenuItem, Avatar, Box } from '@mui/material'
import { enumFunctions } from 'utils';
import { OzgelecekRolesEnum } from 'types';


export interface IProfileBoxUser {
    fullName: string;
    role: string;
    profileImageUrl: string;
}

export interface IBottomMenuListItem {
    func: (e?: any) => void,
    icon: any,
    title: string
}
export interface ProfileBoxProps {
    user: IProfileBoxUser | null,
    botomMenuList: Array<IBottomMenuListItem>
};

const ProfileBox = ({ user, botomMenuList }: ProfileBoxProps) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }


    return (
        <>

            <Box sx={{ mb: 5, mt: 4, mx: 1, cursor: 'pointer' }} flexDirection="row"
                display="flex" alignItems="center" p={1}
                onClick={handleClick}
            >
                <Avatar sx={{ width: 40, height: 40 }} src={user?.profileImageUrl ?? User} />
                <Box sx={{ ml: 2 }}>
                    <Box sx={{ fontWeight: 600, color: '#222', textTransform: 'capitalize' }}>
                        {user?.fullName ?? ''}
                    </Box>
                    <Box sx={{ fontSize: 12, color: '#666' }}>
                        {enumFunctions.findAndDisplayName(OzgelecekRolesEnum, (user?.role ?? ""))}
                    </Box>
                </Box>

            </Box>

            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                {
                    botomMenuList?.map((menu, index) => {

                        return (
                            <MenuItem onClick={menu?.func} key={index}>
                                {menu?.icon}
                                {menu?.title}
                            </MenuItem>
                        )
                    })
                }

            </Menu>
        </>
    )
}

export default ProfileBox