import { CompanyConversionNumberOfEmployeesEnum } from "types";

export interface ISpecialistConversionRequestFormResponse {
  _id: string;
  authorizedPersonEmail: string;
  authorizedPersonName: string;
  authorizedPersonPhoneNumber: string;
  authorizedPersonSurname: string;
  authorizedPersonTitle: string;
  city: string;
  companyName: string;
  createdAt: Date;
  createdBy: {
    _id: string;
  };
  district: string;
  numberOfEmployees: CompanyConversionNumberOfEmployeesEnum;
  sector: string;
  updateBy: {
    _id: string;
  };
  updatedAt: Date;
  website: string;
}
export interface ISpecialistConversionRequestFormVariables {
  conversionRequestFormId: string;
}

export const specialist_conversionRequestForm = (
  variables: ISpecialistConversionRequestFormVariables
) => {
  const data = JSON.stringify({
    query: `
      query Specialist_conversionRequestForm($conversionRequestFormId: ID!) {
        specialist_conversionRequestForm(conversionRequestFormId: $conversionRequestFormId) {
          _id
          companyName
          authorizedPersonName
          authorizedPersonSurname
          authorizedPersonTitle
          authorizedPersonPhoneNumber
          authorizedPersonEmail
          city
          district
          numberOfEmployees
          sector
          website
          createdAt
          updatedAt
          createdBy {
            _id
          }
          updateBy {
            _id
          }
        }
      }
        `,
    variables: {
      conversionRequestFormId: variables.conversionRequestFormId,
    },
  });

  return data;
};
