import { useEffect } from 'react';
import { useFormikContext } from 'formik'
import { MenuItem, StandardTextFieldProps, TextField } from '@mui/material'


interface IFormikSelect extends StandardTextFieldProps {
    list: any[],
    name: string
}

export const FormikSelect = ({ list, name, label, ...rest }: IFormikSelect) => {

    const { getFieldProps, getFieldMeta } = useFormikContext<IFormikSelect | any>()

    const error = !!(getFieldMeta(name).touched && getFieldMeta(name).error)
    const helperText = !!getFieldMeta(name).touched && getFieldMeta(name).error && `${getFieldMeta(name).error}`

    useEffect(() => {
        if (name === 'personalInformation.gender' && list.length > 0) {
            getFieldProps(name).onChange(list[1].value ?? list[1].name);
        }
        if (name === 'driverLicense.status' && list.length > 0) {
            getFieldProps(name).onChange(list[1].value ?? list[1].name);
        }
    }, [name, list, getFieldProps])


    return (
        <TextField
            disabled={!list}
            select
            label={label}
            {...getFieldProps(name)}
            value={list.length ? (getFieldProps(name)?.value ?? rest?.defaultValue) : (rest?.defaultValue ?? "")}
            fullWidth
            defaultValue={rest?.defaultValue ?? ""}
            error={error}
            helperText={helperText}
            {...rest}
        >
            {
                list.map((option) => (
                    <MenuItem
                        selected={rest?.defaultValue === option?.value}
                        key={option.value ?? option.name}
                        value={option.value ?? option.name}>
                        {option.name}
                    </MenuItem>
                ))
            }
        </TextField>
    )
}
