import { memo } from 'react';
import { useFormikContext } from 'formik'
import { DatePickerProps } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


interface IFormikDatePicker extends DatePickerProps<Date> {
    label: string,
    name: string
}



export const FormikDatePicker = memo(({ label, name, ...rest }: IFormikDatePicker) => {

    const { getFieldProps, getFieldMeta, setFieldValue, setFieldTouched } = useFormikContext<IFormikDatePicker | any>()

    const error = !!(getFieldMeta(name).touched && getFieldMeta(name).error)
    const helperText = !!getFieldMeta(name).touched && getFieldMeta(name).error && `${getFieldMeta(name).error}`

    const value = getFieldProps(name).value

    return (
        <DatePicker
            label={label}
            {...getFieldProps(name)}
            {...rest}

            {...(
                name === "personalInformation.birthDate" &&
                {
                    maxDate: dayjs().subtract(18, 'year').toDate()
                }
            )}
            format="dd.MM.yyyy"
            value={value ? new Date(value) : null}
            onChange={(e) => setFieldValue(name, e)}
            onOpen={() => {
                setFieldTouched(name, true)
            }}
            slotProps={{
                textField: {
                    helperText: helperText,
                    error: error
                },
                openPickerButton: {
                    sx: {
                        position: 'absolute',
                        letf: 0,
                        top: 0,
                        bottom: 0,
                        right: 0,
                        width: '100%',
                        m: 0,
                        borderRadius: 0,
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }
                }
            }}
            sx={{ width: '100%', position: 'relative' }}
        />
    )
})
