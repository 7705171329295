import IBaseResult from "interfaces/IBaseResult";
import IEntity from "interfaces/IEntity";
import { ISpecialistCreateVisitForm } from "interfaces/user/visitForm/IVisitForm.graphqlRequest";

export interface ISpecialistCreateVisitFormResponse extends IEntity { }

export interface ISpecialistCreateVisitFormVariables {
  visitFormInput: ISpecialistCreateVisitForm,
}

/**
 * @roles admin, specialist
 */
export const specialist_createVisitForm = (
  variables: ISpecialistCreateVisitFormVariables
) => {
  const data = JSON.stringify({
    query: `
      mutation specialist_createVisitForm($visitFormInput: VisitFormInput!) {
        specialist_createVisitForm(visitFormInput: $visitFormInput) {
          _id
        }
      }
        `,
    variables: variables,
  });

  return data;
};
