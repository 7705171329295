export interface IAnonymousConversionQuestionsItem {
  _id: string
  content: string
  createdAt: string
  index: string
  type: string
  updatedAt: string
}

export interface IAnonymousConversionQuestionsResponse {
  count: number
  data: Array<IAnonymousConversionQuestionsItem>
}

export interface IAnonymousConversionQuestionsVariables {
  filter?: {
    type: "forConversion" | "forProductivityAndSatisfaction",
    index?: number,
  },
  pagination?: {
    page?: number,
    pageSize?: number,
    sort?: string
  }
}


export const anonymous_conversionQuestions = (variables: IAnonymousConversionQuestionsVariables) => {

  const data = JSON.stringify({
    query: `
        query Anonymous_conversionQuestions($filter: FilterConversionQuestionInputForUser, $pagination: PaginationWithConversionQuestionSort) {
            anonymous_conversionQuestions(filter: $filter, pagination: $pagination) {
              count
              data {
                _id
                content
                createdAt
                index
                type
                updatedAt
              }
            }
          }
        `,
    variables: variables
  })

  return data
}