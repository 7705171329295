import { ExitToApp } from "@mui/icons-material"
import { Route, Routes, useNavigate, Navigate } from "react-router-dom"

import { operationRoutes } from "./routes"
import { DashboardLayout } from "layouts"
import { logoutReducer, useAppDispatch, useGetSelfUserQuery, useGetUserRemindersQuery } from "context"
import { IBottomMenuListItem, IMenuListItem, IProfileBoxUser, Iconify } from "components"
import { CompaniesPage, ConversionCreatePage, ConversionsPage, CreditRequestsPage, HelpCenterPage, HomePage, MyAccountPage, NotepadPage, RemindersPage, SettingsPage, VisitorFormUpdatePage, VisitorFormsPage } from "../pages"
import { Badge } from "@mui/material"



export const OperationRoutes = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { data: userInfo } = useGetSelfUserQuery({})

    const { data: reminders } = useGetUserRemindersQuery({ pagination: { page: 0, pageSize: 0 }, filter: { status: "inProgress" } })


    const user: IProfileBoxUser | null = {
        fullName: (userInfo?.personalInformation?.name || userInfo?.personalInformation?.surname)
            ? userInfo?.personalInformation?.name + " " + userInfo?.personalInformation?.surname
            : userInfo?.contact?.email ?? "",
        profileImageUrl: userInfo?.personalInformation?.profileImageUrl ?? "",
        role: userInfo?.role ?? ""
    }


    const menuList: Array<IMenuListItem> = [
        {
            items: [
                {
                    title: 'Panel',
                    path: operationRoutes.dashboard.home,
                    icon: <Iconify icon="iconamoon:home" />
                },
                {
                    title: 'Şirketler',
                    path: operationRoutes.dashboard.companies,
                    icon: <Iconify icon="ion:business-sharp" />
                },
                {
                    title: 'Hatırlatıcılar',
                    path: operationRoutes.dashboard.reminders,
                    icon: <Iconify icon="carbon:reminder" />,
                    info: <Badge badgeContent={reminders?.count} color="primary" />
                },
                {
                    title: 'Kredi İsteklerim',
                    path: operationRoutes.dashboard.creditRequests,
                    icon: <Iconify icon="wpf:bank-cards" />,
                    // info: <Badge badgeContent={reminders?.count} color="primary" />
                },
                {
                    title: 'Ziyaretçi Formları',
                    path: operationRoutes.dashboard.visitorsForms,
                    icon: <Iconify icon="fluent:form-new-20-regular" />
                },
                // {
                //     title: 'Referanslar',
                //     path: operationRoutes.dashboard.references,
                //     icon: <Iconify icon="ooui:references-ltr" />
                // },
                {
                    title: 'Dönüşümler',
                    path: operationRoutes.dashboard.conversions,
                    icon: <Iconify icon="streamline:recycle-1-solid" />
                },
                // {
                //     title: 'Kullanıcı Oluştur',
                //     path: operationRoutes.dashboard.createUser,
                //     icon: <Iconify icon="mingcute:user-add-line" />
                // },
            ]
        },
        {
            subheader: 'Ayarlar',
            items: [
                {
                    title: 'Ayarlar',
                    path: operationRoutes.dashboard.settings,
                    icon: <Iconify icon="solar:settings-bold-duotone" />
                },
                {
                    title: 'Notepad',
                    path: operationRoutes.dashboard.notpad,
                    icon: <Iconify icon="fluent:notepad-edit-16-regular" />
                },
            ]
        }
    ]

    const bottomMenuList: Array<IBottomMenuListItem> = [
        {
            func: (e) => navigate(operationRoutes.dashboard.myAccount),
            icon: <Iconify icon="uil:setting" width={24} sx={{ marginRight: 1 }} />,
            title: "Hesap Bilgileri"
        },
        {
            func: (e) => dispatch(logoutReducer("")),
            icon: <ExitToApp sx={{ marginRight: 1 }} />,
            title: "Çıkış"
        },
    ]

    return (
        <Routes>
            <Route path="" element={<DashboardLayout menuList={menuList} user={user} bottomMenuList={bottomMenuList} />}>
                <Route path={operationRoutes.dashboard.home} element={<HomePage />} />
                <Route path={operationRoutes.dashboard.companies} element={<CompaniesPage />} />
                <Route path={operationRoutes.dashboard.reminders} element={<RemindersPage />} />
                <Route path={operationRoutes.dashboard.creditRequests} element={<CreditRequestsPage />} />

                <Route path={operationRoutes.dashboard.visitorsForms} element={<VisitorFormsPage />} />
                <Route path={operationRoutes.dashboard.visitorsFormUpdate + "/:id"} element={<VisitorFormUpdatePage />} />
                <Route path={operationRoutes.dashboard.conversions} element={<ConversionsPage />} />
                <Route path={operationRoutes.dashboard.conversioncreate} element={<ConversionCreatePage />} />


                <Route path={operationRoutes.dashboard.notpad} element={<NotepadPage />} />
                <Route path={operationRoutes.dashboard.settings} element={<SettingsPage />} />
                <Route path={operationRoutes.dashboard.helpCenter} element={<HelpCenterPage />} />
                <Route path={operationRoutes.dashboard.myAccount} element={<MyAccountPage />} />

                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    )
}