import React, { useState } from 'react'
import {
    Box,
    Card,
    Grid,
    Link,
    Table,
    Stack,
    Avatar,
    Button,
    Dialog,
    TableRow,
    TableBody,
    TextField,
    TableCell,
    Typography,
    IconButton,
    DialogContent,
    TableContainer,
    TablePagination,
    CircularProgress,
    MenuItem,
} from '@mui/material'

import * as yup from 'yup';
import moment from 'moment';
import { Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';

import { enumFunctions } from 'utils'
import { CompanyConversionNumberOfEmployeesEnum, NumberOfEmployeeEnum, NumberOfEmployeesList, getNameOfEnum } from 'types'
import { CreateReminderSection, CreditRequestSection, UserNoteToUserSection } from 'sections';
import { CustomTableHeader, FormikTextField, ICustomTableHeadCell, Iconify, MuiTransition, TableEmptyData, TableLoading, } from 'components'
import { IOperationCompaniesItem, IOperationCompaniesVariables, useGetOperationCompaniesQuery, useOperationSendFreeCreditMutation } from '../context'

import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import WhatshotOutlinedIcon from '@mui/icons-material/WhatshotOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { useGetCitiesQuery } from 'context';
import { CompanyImage } from 'assets';




export const CompaniesPage = () => {

    const [variables, setVariables] = useState<IOperationCompaniesVariables>({
        filter: {
            companyName: "",
            companyCity: "",
            numberOfEmployees: null
        },
        pagination: {
            page: 1,
            pageSize: 10,
            sort: null
        }
    })

    const { data, isLoading } = useGetOperationCompaniesQuery(variables)

    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }

    const handleSearchByName = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log({ e });
        setVariables((prevState) => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                companyName: e?.target?.value
            }
        }))
    }


    return (
        <Stack spacing={3}>

            <Typography variant='h1'>
                Şirketler
            </Typography>

            <OperationCompaniesTable
                data={data}
                pagination={variables?.pagination}
                loading={isLoading}
                handleChangePageSize={handleChangePageSize}
                handleChangePage={handleChangePage}
                handleSearchByName={handleSearchByName}
                companyName={variables?.filter?.companyName}
                variables={variables}
                setVariables={setVariables}
            />

        </Stack>
    )
}


const OperationCompaniesTable = ({ data, pagination, loading, handleChangePageSize, handleChangePage, companyName, handleSearchByName, variables, setVariables }: any) => {


    const [sentFreeCredit, { isLoading: sentFreeCreditLoading }] = useOperationSendFreeCreditMutation()
    const [freeCreditDialog, setFreeCreditDialog] = useState<any>(false)

    const { data: citiesData } = useGetCitiesQuery({ countryName: "Turkey" })


    const [detailDialog, setDetailDialog] = useState<any>(null)
    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Şirket Adı / Logosu',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Şehir / Bölge',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Vergi No',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Vergi Dairesi',
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Aksiyonlar',
        },
    ];

    const HeaderItem = ({ icon, name, value }: { icon: any, name: string, value: any }) => {
        return (
            <>
                {
                    value && (
                        <Grid item xs={12} sm={6}>
                            <Stack direction="row" spacing={2} alignItems="center">

                                <Box
                                    sx={{
                                        border: '1px solid #d6ddeb',
                                        borderRadius: '50%',
                                        flexShrink: '0',
                                        width: '50px',
                                        height: '50px',
                                        p: 1,

                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'primary.main',

                                        '&>*': {
                                            width: '100%',
                                            height: '100%',
                                        }
                                    }}
                                >
                                    {icon}
                                </Box>

                                <Stack>
                                    <Typography variant='body2'>
                                        {name}
                                    </Typography>
                                    <Typography variant='subtitle2'>
                                        {value}
                                    </Typography>
                                </Stack>

                            </Stack>
                        </Grid>
                    )
                }
            </>
        )
    }

    const handleSentFreeCredit = async (values?: any) => {
        try {

            const freeCreditResponse: any = await sentFreeCredit({ creditAmountPerReceiver: values?.creditAmountPerReceiver, receiverIds: [freeCreditDialog?._id] })
            console.log({ freeCreditResponse });

            if (freeCreditResponse?.data) {
                enqueueSnackbar("Gönderildi!")
                setFreeCreditDialog(false)
            } else {
                enqueueSnackbar("Başarısız olundu!", { variant: "warning" })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }


    return (
        <>
            <Card sx={{ p: 3 }}>

                <Stack direction="row" spacing={2}>

                    <TextField
                        label="Şirket ismine göre ara..."
                        value={companyName}
                        onChange={handleSearchByName}

                        sx={{
                            width: '100%',
                            maxWidth: '600px',
                            mb: 3,
                        }}
                    />

                    <TextField
                        select
                        label="Şehir"
                        fullWidth
                        value={variables.filter?.companyCity ?? ""}
                        onChange={(e) => setVariables({
                            ...variables,
                            filter: {
                                ...variables.filter,
                                companyCity: e.target.value as string
                            }
                        })}
                    >
                        {citiesData?.data?.map((city, index) => (
                            <MenuItem key={index} value={city?.name}>
                                {city?.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        select
                        label="Çalışan Sayısı"
                        fullWidth
                        value={variables.filter?.numberOfEmployees ?? ""}
                        onChange={(e) => setVariables({
                            ...variables,
                            filter: {
                                ...variables.filter,
                                numberOfEmployees: e.target.value as string
                            }
                        })}
                    >
                         {NumberOfEmployeesList?.map((item: any, index: number) => (
                            <MenuItem key={index} value={item?.value}>
                                {getNameOfEnum(NumberOfEmployeeEnum, item?.value)}
                            </MenuItem>
                        ))}

                    </TextField>

                </Stack>

                <TableContainer>
                    <Table>

                        <CustomTableHeader
                            headCells={headCells}
                            numSelected={0}
                            onSelectAllClick={() => null}
                            rowCount={data?.data?.length ?? 0}
                        />

                        <TableBody>
                            {
                                !loading ? (
                                    data?.count ? (
                                        data?.data?.map((row: IOperationCompaniesItem, index: number) => {

                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    key={row?._id ?? index}
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    sx={{ cursor: 'pointer' }}
                                                >

                                                    <TableCell align='center'>
                                                        {index + 1 + ((pagination?.page - 1) * pagination?.pageSize)}
                                                    </TableCell>

                                                    <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <img
                                                                style={{ backgroundColor: "white", border: "1px solid", borderColor: "primary", objectFit: 'contain', width: '40px', height: '40px', borderRadius: '50%' }}
                                                                src={row?.companyInformation?.profileImage ?? CompanyImage}
                                                                alt='company-logo'
                                                            />
                                                            <Stack direction="column">
                                                                <Typography>
                                                                    {row?.companyInformation?.companyName}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {row?.companyInformation?.city} - {row?.companyInformation?.district}
                                                    </TableCell>

                                                    <TableCell align="left">{row?.companyInformation?.taxNumber}</TableCell>
                                                    <TableCell align="left">{row?.companyInformation?.taxOffice}</TableCell>


                                                    <TableCell align="left" >
                                                        <Stack direction="row" spacing={1}>
                                                            <UserNoteToUserSection targetId={row?._id} />
                                                            <CreateReminderSection user={row} />
                                                            <IconButton
                                                                sx={{ bgcolor: 'secondary.light' }}
                                                                onClick={() => setDetailDialog(row)}
                                                                title="Detayı Gör"
                                                            >
                                                                <Iconify icon="ph:eye-fill" sx={{ color: 'primary.main' }} />
                                                            </IconButton>


                                                            <CreditRequestSection user={row} sentFreeCredit={sentFreeCredit} />
                                                            <IconButton
                                                                sx={{ bgcolor: 'secondary.light' }}
                                                                onClick={() => setFreeCreditDialog(row)}
                                                                title="Ücretsiz kredi ver"
                                                            >
                                                                <Iconify icon="majesticons:creditcard-hand" sx={{ color: 'primary.main' }} />
                                                            </IconButton>

                                                        </Stack>
                                                    </TableCell>



                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableEmptyData text="Hiç bir şirket bulunamadı" colspan={headCells?.length} />
                                    )
                                ) : (
                                    <TableLoading colspan={headCells?.length} />
                                )
                            }
                        </TableBody>

                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                    count={data?.count ?? 0} // toplam eleman sayısı
                    rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                    page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                    labelRowsPerPage="Sayfa başına gösterim"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangePageSize}
                />

            </Card>


            <Dialog
                open={!!detailDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setDetailDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Stack spacing={3} py={3} >

                        {
                            true ? (
                                <>
                                    <Typography variant='h3'>
                                        Şirket Detayı
                                    </Typography>

                                    {/* ******************* Header ********************* */}
                                    <Stack spacing={3} sx={{ position: 'relative' }} >

                                        <Stack direction="row" spacing={3} alignItems="center">
                                            <Box
                                                component="img"
                                                src={detailDialog?.companyInformation?.profileImage}
                                                sx={{
                                                    width: { xs: '100px' },
                                                    height: { xs: '100%' },
                                                }}
                                            />
                                            <Stack>
                                                <Typography variant='h3'>
                                                    {detailDialog?.companyInformation?.companyName}
                                                </Typography>
                                                <Link component="a" href={"https://" + detailDialog?.companyInformation?.website} variant='body2'>
                                                    {detailDialog?.companyInformation?.website}
                                                </Link>
                                            </Stack>
                                        </Stack>

                                        <Grid container spacing={3}>

                                            <HeaderItem
                                                icon={<WhatshotOutlinedIcon />}
                                                name="Kuruluş Yılı"
                                                value={moment(detailDialog?.companyInformation?.foundationYear).format('DD MMMM YYYY')}
                                            />

                                            <HeaderItem
                                                icon={<GroupsOutlinedIcon />}
                                                name="Personel"
                                                value={enumFunctions.findAndDisplayName(NumberOfEmployeeEnum, (detailDialog?.companyInformation?.numberOfEmployees ?? "")?.toString())}
                                            />

                                            <HeaderItem
                                                icon={<RoomOutlinedIcon />}
                                                name="Konum"
                                                value={detailDialog?.companyInformation?.companyAddress}
                                            />

                                            <HeaderItem
                                                icon={<BusinessOutlinedIcon />}
                                                name="Sektör"
                                                value={detailDialog?.companyInformation?.sector}
                                            />

                                            {/* --------------------------------------------------------------- */}

                                            <HeaderItem
                                                icon={<BusinessOutlinedIcon />}
                                                name="Email"
                                                value={detailDialog?.companyInformation?.email}
                                            />

                                            <HeaderItem
                                                icon={<Iconify icon="ph:phone" />}
                                                name="Telefon No"
                                                value={detailDialog?.companyInformation?.phoneNumber}
                                            />

                                            <HeaderItem
                                                icon={<FacebookOutlinedIcon />}
                                                name="Facebook"
                                                value={detailDialog?.companyInformation?.facebook}
                                            />

                                            <HeaderItem
                                                icon={<Iconify icon="mdi:instagram" />}
                                                name="Instagram"
                                                value={detailDialog?.companyInformation?.instagram}
                                            />

                                            <HeaderItem
                                                icon={<Iconify icon="mdi:linkedin" />}
                                                name="Linkedin"
                                                value={detailDialog?.companyInformation?.linkedin}
                                            />


                                        </Grid>

                                    </Stack>

                                    {/* ********************* Main ********************** */}
                                    <Stack direction={{ xs: 'column-reverse', lg: "row" }} spacing={3}>

                                        <Stack flex={1} position="relative" spacing={3} sx={{ height: '100%' }} >

                                            <Typography variant='h3'>
                                                Şirket Hakkında
                                            </Typography>

                                            <Typography>
                                                {detailDialog?.companyInformation?.companyBrief}
                                            </Typography>

                                        </Stack>

                                    </Stack>

                                    <Stack direction="row" justifyContent="end" gap={3}>
                                        <Button
                                            onClick={() => setDetailDialog(false)}
                                            variant='outlined'
                                            color="primary"
                                        >
                                            Kapat
                                        </Button>
                                    </Stack>
                                </>
                            ) : (
                                <CircularProgress color="primary" />
                            )
                        }

                    </Stack>
                </DialogContent>
            </Dialog>


            <Dialog
                open={!!freeCreditDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setFreeCreditDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Formik
                        validationSchema={yup.object().shape({
                            creditAmountPerReceiver: yup.string().required("Gerekli")
                        })}
                        initialValues={{
                            creditAmountPerReceiver: ""
                        }}
                        onSubmit={(values) => {
                            console.log({ values });
                            handleSentFreeCredit(values)
                        }}
                    >
                        {
                            ({ handleSubmit, errors }) => {

                                return (
                                    <Stack
                                        spacing={3}
                                        py={3}
                                        minWidth={{ xs: "100%", sm: '400px' }}
                                    >

                                        <Typography variant='h3'>
                                            Ücretsiz Kredi Gönder
                                        </Typography>

                                        {/* <FormikTextField
                                            type="number"
                                            name='price'
                                            label='Ücret'
                                        /> */}

                                        <FormikTextField
                                            type="number"
                                            name='creditAmountPerReceiver'
                                            label='Kredi Miktarı'
                                        />


                                        <Stack direction="row" justifyContent="end" gap={3}>
                                            <Button
                                                onClick={() => setFreeCreditDialog(false)}
                                                variant='outlined'
                                                color="primary"
                                            >
                                                Kapat
                                            </Button>

                                            <LoadingButton
                                                variant='contained'
                                                color="primary"
                                                onClick={() => handleSubmit()}
                                                loading={sentFreeCreditLoading}
                                            >
                                                Gönder
                                            </LoadingButton>
                                        </Stack>

                                    </Stack>
                                )
                            }
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    )
}