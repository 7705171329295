export enum Role {
  admin = "Admin",
  candidate = "Aday",
  company = "Firma",
  operation = "Operasyon Uzmanı",
  callCenter = "Danışma",
  specialist = "Backoffice",
  accounter = "Muhasebe-Finans",
  hrAdvisor = "HR Danışman",
}
