import { IInsertDiscoverForm } from "interfaces/user/discoverForm/IDiscoverForm";

export interface IAnonymousCreateDiscoverFormResponse {
  _id: string;
}

export interface IAnonymousCreateDiscoverFormVariables {
  input: IInsertDiscoverForm;
}

export const anonymous_createDiscoverForm = (
  variables: IAnonymousCreateDiscoverFormVariables
) => {
  const data = JSON.stringify({
    query: `
        mutation anonymous_createDiscoverForm($input: DiscoverFormInput!)  {
          anonymous_createDiscoverForm(discoverFormInput: $input) {
            _id
          }
        }
        `,
    variables: variables,
  });

  return data;
};
