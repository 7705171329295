

import { userApi } from "context/user";
import { IAccounterCompaniesResponse, IAccounterCompaniesVariables, IAccounterCompanyResponse, IAccounterCompanyVariables, accounter_companies, accounter_company } from "./queries";

const accounterUserApi = userApi.injectEndpoints({
    endpoints: (builder) => ({

        // #################### User Queries #################
        getAccounterCompany: builder.query<IAccounterCompanyResponse, IAccounterCompanyVariables>({
            query: (variables: IAccounterCompanyVariables) => {
              return {
                url: "",
                body: accounter_company(variables),
              };
            },
            transformResponse: (response: any) => {
              return response.data.accounter_company;
            },
            providesTags: ["accounter_company"],
        }),
        getAccounterCompanies: builder.query<IAccounterCompaniesResponse, IAccounterCompaniesVariables>({
            query: (variables: IAccounterCompaniesVariables) => {
              return {
                url: "",
                body: accounter_companies(variables),
              };
            },
            transformResponse: (response: any) => {
              return response.data.accounter_companies;
            },
            providesTags: ["accounter_companies"],
        }),

        // #################### User Mutations #################
    }),
})


export const {
    // ########## queries ###############
    useGetAccounterCompanyQuery,
    useLazyGetAccounterCompanyQuery,
    useGetAccounterCompaniesQuery,
    useLazyGetAccounterCompaniesQuery

    // ########## mutation ###############
    

} = accounterUserApi