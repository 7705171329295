import IBaseResult from "interfaces/IBaseResult";

export interface IAdminSendFreeCreditVariables {
  receiverIds: string[],
  creditAmountPerReceiver: number
}

export interface IAdminSendFreeCreditResponse
  extends IBaseResult {}

export const admin_sendFreeCredits = (
  variables: IAdminSendFreeCreditVariables
) => {
  const data = JSON.stringify({
    query: `
        mutation admin_sendFreeCredits($receiverIds: [ID!]!, $creditAmountPerReceiver: Int!) {
          admin_sendFreeCredits(receiverIds: $receiverIds, creditAmountPerReceiver: $creditAmountPerReceiver) {
            success
          }
        }
        `,
    variables: variables,
  });

  return data;
};
