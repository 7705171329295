import HasFilter from "interfaces/HasFilter";
import HasPagination from "interfaces/HasPagination";
import IPaginatedListResult from "interfaces/IPaginatedListResult";
import { IFilterSendCreditRequest, ISendCreditRequest } from "interfaces/payment/finance/ISendCreditRequest";


export interface IOperationSendCreditRequestsVariables
  extends HasPagination,
  HasFilter<IFilterSendCreditRequest> { }

export interface IOperationSendCreditRequestsResponse
  extends IPaginatedListResult<ISendCreditRequest> { }

export const operation_sendCreditRequests = (variables: IOperationSendCreditRequestsVariables) => {

  const data = JSON.stringify({
    query: `
        query operation_sendCreditRequests( $pagination: Pagination, $filter: FilterSendCreditRequestInput) {
          operation_sendCreditRequests(pagination: $pagination, filter: $filter) {
            count
            data {
              _id
              status
              receiver {
                _id
                role
                displayName
                displayImageUrl
              }
              creditAmount
              price
              currency
              sender {
                _id
                role
                displayName
                displayImageUrl
              }
              statusChangeReason
              createdAt
              updatedAt
            }
          }
        }
        `,
    variables: variables
  })

  return data
}
