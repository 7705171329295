import { InformationFormTargetRole } from "enums/user/informationForm/InformationFormTargetRole";

export interface ICallCenterInformationFormsItem {
  _id: string;
  name: string;
  surname: string;
  email: string;
  phoneNumber?: string;
  targetRole?: InformationFormTargetRole;
  createdAt: Date;
  updatedAt: Date;
  createdBy: {
    _id: string
  };
  updateBy: {
    _id: string
  }
}

export interface ICallCenterInformationFormsResponse {
  count: number,
  data: Array<ICallCenterInformationFormsItem>
}

export interface ICallCenterInformationFormsVariables {
  filter?: {
    name?: string;
    surname?: string;
    email?: string;
    phoneNumber?: string;
    targetRole?: InformationFormTargetRole
  };
  pagination?: {
    page?: number,
    pageSize?: number
    sort?: string
  };
}

export const callCenter_informationForms = (
  variables: ICallCenterInformationFormsVariables
) => {
  const data = JSON.stringify({
    query: `
    query CallCenter_informationForms($filter: FilterInformationFormForCallCenterInput, $pagination: Pagination) {
      callCenter_informationForms(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          createdAt
          createdBy {
            _id
          }
          email
          name
          phoneNumber
          surname
          targetRole
          updateBy {
            _id
          }
          updatedAt
        }
      }
    }
        `,
    variables: variables
  });

  return data;
};
