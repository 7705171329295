
export interface IUserRemoveReminderResponse {
    success: boolean;
}

export interface IUserRemoveReminderVariables {
    reminderId: string
}

export const user_removeReminder = (variables: IUserRemoveReminderVariables) => {

    const data = JSON.stringify({
        query: `
        mutation User_removeReminder($reminderId: ID!) {
            user_removeReminder(reminderId: $reminderId) {
              success
            }
          }
        `,
        variables: {
            "reminderId": variables?.reminderId ?? null
        }

    })

    return data

}